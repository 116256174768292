import { gql } from "@apollo/client";

const VERIFY_CODE = gql`
  mutation adminOTPVerification($email: String!, $otp: String!) {
    adminOTPVerification(email: $email, otp: $otp) {
      email
      accessToken
      name
      id
      surname
      role
      user_sport
      isAuthenticationRequired
      isLeagueManager
      member_of_plateforms {
        platform {
          id
          title
        }
      }
    }
  }
`;

export default VERIFY_CODE;
