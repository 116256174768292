import { gql } from "@apollo/client";

const UPDATE_SUBSCRIPTION = gql`
  mutation updateSubscription(
    $updateSubscriptionInput: UpdateSubscriptionInput!
  ) {
    updateSubscription(updateSubscriptionInput: $updateSubscriptionInput) {
      id
    }
  }
`;

export default UPDATE_SUBSCRIPTION;
