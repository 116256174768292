import moment from "moment";
import toast from "react-hot-toast";
import imageCompression from "browser-image-compression";

export const parseToJSON = (value) => {
  let parsedValue = null;
  try {
    parsedValue = value ? JSON.parse(value) : null;
  } catch (error) {
    console.warn("Failed to parse parseVaue:", error);
    parsedValue = [];
  }
  return parsedValue;
};

export const getDateDifference = (fromDate, toDate) => {
  const start = moment(fromDate);
  const end = moment(toDate);

  if (start.isAfter(end)) {
    throw new Error("The 'fromDate' must be earlier than the 'toDate'");
  }

  // Calculate differences
  const years = end.diff(start, "years");
  start.add(years, "years");

  const months = end.diff(start, "months");
  start.add(months, "months");

  const days = end.diff(start, "days");

  // Build result string
  const parts = [];
  if (years > 0) parts.push(`${years} year${years > 1 ? "s" : ""}`);
  if (months > 0) parts.push(`${months} month${months > 1 ? "s" : ""}`);
  if (days > 0) parts.push(`${days} day${days > 1 ? "s" : ""}`);

  return parts.length > 0 ? parts.join(", ") : "0 days";
};

export const getTournamentStatus = (
  entryDeadlineDate,
  tournamentStartDate,
  tournamentEndDate,
  tournamentStatus
) => {
  const currentDate = new Date();

  const status = (() => {
    if (tournamentStatus === "inactive") return "inactive";
    if (currentDate <= new Date(entryDeadlineDate)) return "registration";
    // if (currentDate >= entryDeadlineDate && currentDate <= tournamentStartDate) return 'deadline past';
    if (
      currentDate >= new Date(tournamentStartDate) &&
      currentDate <= new Date(tournamentEndDate)
    )
      return "in progress";
    if (currentDate >= new Date(tournamentEndDate)) return "completed";
    return undefined;
  })();

  const getStepTitle = (): string => {
    switch (status) {
      case "registration":
        return "Upcoming";
      case "in progress":
        return "In progress";
      case "completed":
        return "Completed";
      case "inactive":
        return "Inactive";
      default:
        return "Confirmation";
    }
  };

  return getStepTitle();
};

export const Compression = async (
  file: File,
  maxSizeMB = 1,
  maxWidthOrHeight = 800
) => {
  const options = {
    maxSizeMB: maxSizeMB, // Max file size in MB
    maxWidthOrHeight: maxWidthOrHeight, // Max width or height of the image
    useWebWorker: true, // Use a web worker for better performance
  };
  try {
    // Compress the file if it's larger than 2MB
    const compressedFile = await toast.promise(
      imageCompression(file, options), // Call the compression function
      {
        loading: `Compressing...`,
        success: `Successfully compressed.`,
        error: `Error compressing the file.`,
      }
    );

    return compressedFile; // Return the compressed file
  } catch (error) {
    console.error("Error during compression:", error);
    throw error; // Rethrow the error to handle it upstream if needed
  }
};
