import { gql } from "@apollo/client";

const SUBSCRIPTIONS = gql`
  query subscriptions($filter: SubscriptionFilter) {
    subscriptions(filter: $filter) {
      subscriptions {
        id
        federationId {
          id
          organizationName
          organizationType
          country
          contactEmail
          contactPhone
          user {
            name
            surname
            user_address
            nation
            country
          }
        }
        sports {
          icon
          id
          title
        }
        paymentStatus
        paymentMethod
        currency
        amount
        validFrom
        validTo
        createdBy {
          name
        }
        plan
        planName
        createdAt
      }
      total
      organizationDetails {
        federationName
        totalPaid
        totalMonths
        activePeriod {
          isActivePeriod
          validFrom
          validTo
          totalMonths
        }
        status
      }
    }
  }
`;

export default SUBSCRIPTIONS;
