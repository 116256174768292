import { gql } from "@apollo/client";

const CREATE_USER = gql`
  mutation signUp(
    $name: String!
    $surname: String!
    $email: String!
    $phone: String
    $nation: String!
    $gender: String!
    $city: String
    $street: String
    $user_club_text: String
    $role: String
    $roles: [Int!]
    $password: String!
    $leagueId: Float
    $dob: String!
    $avatar: Upload
    $citizenship: String
    $user_address: String
    $facebook: String
    $instagram: String
    $tiktok: String
    $youtube: String
    $linkedIn: String
    $website: String
    $ipinId: String
    $duprId: String
    $fideId: String
    $teId: String
    $ltsU10Id: String
    $atpId: String
    $wtaId: String
    $teU12Id:String
    $otherPoints:String
    $user_sports: [Int!]
    $user_club_id: Float # $platformId: Float
  ) {
    signUp(
      createUserInput: {
        name: $name
        surname: $surname
        email: $email
        phone: $phone
        nation: $nation
        gender: $gender
        city: $city
        street: $street
        user_club_text: $user_club_text
        role: $role
        roles: $roles
        password: $password
        leagueId: $leagueId
        dob: $dob
        avatar: $avatar
        citizenship: $citizenship
        user_address: $user_address
        instagram: $instagram
        facebook: $facebook
        tiktok: $tiktok
        youtube: $youtube
        linkedIn: $linkedIn
        website: $website
        ipinId: $ipinId
        duprId: $duprId
        fideId: $fideId
        teId: $teId
        ltsU10Id: $ltsU10Id
        atpId: $atpId
        wtaId: $wtaId
        teU12Id:$teU12Id
        otherPoints:$otherPoints
        user_sports: $user_sports
        user_club_id: $user_club_id
      }
    ) #platformId: $platformId
    {
      id
      email
      name
      surname
      user_sport
      avatar
    }
  }
`;

export default CREATE_USER;
