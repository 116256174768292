const countries = [
  { text: "Afghanistan", value: "AFG", phoneCode: "+93" },
  { text: "Åland Islands", value: "ALA", phoneCode: "+358" },
  { text: "Albania", value: "ALB", phoneCode: "+355" },
  { text: "Algeria", value: "DZA", phoneCode: "+213" },
  { text: "American Samoa", value: "ASM", phoneCode: "+1" },
  { text: "Andorra", value: "AND", phoneCode: "+376" },
  { text: "Angola", value: "AGO", phoneCode: "+244" },
  { text: "Anguilla", value: "AIA", phoneCode: "+1" },
  { text: "Antarctica", value: "ATA", phoneCode: "+672" },
  { text: "Antigua and Barbuda", value: "ATG", phoneCode: "+1" },
  { text: "Argentina", value: "ARG", phoneCode: "+54" },
  { text: "Armenia", value: "ARM", phoneCode: "+374" },
  { text: "Aruba", value: "ABW", phoneCode: "+297" },
  { text: "Australia", value: "AUS", phoneCode: "+61" },
  { text: "Austria", value: "AUT", phoneCode: "+43" },
  { text: "Azerbaijan", value: "AZE", phoneCode: "+994" },
  { text: "Bahamas", value: "BHS", phoneCode: "+1" },
  { text: "Bahrain", value: "BHR", phoneCode: "+973" },
  { text: "Bangladesh", value: "BGD", phoneCode: "+880" },
  { text: "Barbados", value: "BRB", phoneCode: "+1" },
  { text: "Belarus", value: "BLR", phoneCode: "+375" },
  { text: "Belgium", value: "BEL", phoneCode: "+32" },
  { text: "Belize", value: "BLZ", phoneCode: "+501" },
  { text: "Benin", value: "BEN", phoneCode: "+229" },
  { text: "Bermuda", value: "BMU", phoneCode: "+1" },
  { text: "Bhutan", value: "BTN", phoneCode: "+975" },
  { text: "Bolivia", value: "BOL", phoneCode: "+591" },
  { text: "Bosnia and Herzegovina", value: "BIH", phoneCode: "+387" },
  { text: "Botswana", value: "BWA", phoneCode: "+267" },
  { text: "Bouvet Island", value: "BVT", phoneCode: "" },
  { text: "Brazil", value: "BRA", phoneCode: "+55" },
  { text: "British Indian Ocean Territory", value: "IOT", phoneCode: "" },
  { text: "Brunei Darussalam", value: "BRN", phoneCode: "+673" },
  { text: "Bulgaria", value: "BGR", phoneCode: "+359" },
  { text: "Burkina Faso", value: "BFA", phoneCode: "+226" },
  { text: "Burundi", value: "BDI", phoneCode: "+257" },
  { text: "Cambodia", value: "KHM", phoneCode: "+855" },
  { text: "Cameroon", value: "CMR", phoneCode: "+237" },
  { text: "Canada", value: "CAN", phoneCode: "+1" },
  { text: "Cape Verde", value: "CPV", phoneCode: "+238" },
  { text: "Cayman Islands", value: "CYM", phoneCode: "+1" },
  { text: "Central African Republic", value: "CAF", phoneCode: "+236" },
  { text: "Chad", value: "TCD", phoneCode: "+235" },
  { text: "Chile", value: "CHL", phoneCode: "+56" },
  { text: "China", value: "CHN", phoneCode: "+86" },
  { text: "Christmas Island", value: "CXR", phoneCode: "+61" },
  { text: "Cocos (Keeling) Islands", value: "CCK", phoneCode: "+61" },
  { text: "Colombia", value: "COL", phoneCode: "+57" },
  { text: "Comoros", value: "COM", phoneCode: "+269" },
  { text: "Congo", value: "COG", phoneCode: "+242" },
  {
    text: "Congo, The Democratic Republic of the",
    value: "COD",
    phoneCode: "+243",
  },
  { text: "Cook Islands", value: "COK", phoneCode: "+682" },
  { text: "Costa Rica", value: "CRI", phoneCode: "+506" },
  { text: "Cote D'Ivoire", value: "CIV", phoneCode: "+225" },
  { text: "Croatia", value: "HRV", phoneCode: "+385" },
  { text: "Cuba", value: "CUB", phoneCode: "+53" },
  { text: "Cyprus", value: "CYP", phoneCode: "+357" },
  { text: "Czech Republic", value: "CZE", phoneCode: "+420" },
  { text: "Denmark", value: "DNK", phoneCode: "+45" },
  { text: "Djibouti", value: "DJI", phoneCode: "+253" },
  { text: "Dominica", value: "DMA", phoneCode: "+1" },
  { text: "Dominican Republic", value: "DOM", phoneCode: "+1" },
  { text: "Ecuador", value: "ECU", phoneCode: "+593" },
  { text: "Egypt", value: "EGY", phoneCode: "+20" },
  { text: "El Salvador", value: "SLV", phoneCode: "+503" },
  { text: "Equatorial Guinea", value: "GNQ", phoneCode: "+240" },
  { text: "Eritrea", value: "ERI", phoneCode: "+291" },
  { text: "Estonia", value: "EST", phoneCode: "+372" },
  { text: "Ethiopia", value: "ETH", phoneCode: "+251" },
  { text: "Falkland Islands (Malvinas)", value: "FLK", phoneCode: "+500" },
  { text: "Faroe Islands", value: "FRO", phoneCode: "+298" },
  { text: "Fiji", value: "FJI", phoneCode: "+679" },
  { text: "Finland", value: "FIN", phoneCode: "+358" },
  { text: "France", value: "FRA", phoneCode: "+33" },
  { text: "French Guiana", value: "GUF", phoneCode: "+594" },
  { text: "French Polynesia", value: "PYF", phoneCode: "+689" },
  { text: "French Southern Territories", value: "ATF", phoneCode: "" },
  { text: "Gabon", value: "GAB", phoneCode: "+241" },
  { text: "Gambia", value: "GMB", phoneCode: "+220" },
  { text: "Georgia", value: "GEO", phoneCode: "+995" },
  { text: "Germany", value: "DEU", phoneCode: "+49" },
  { text: "Ghana", value: "GHA", phoneCode: "+233" },
  { text: "Gibraltar", value: "GIB", phoneCode: "+350" },
  { text: "Greece", value: "GRC", phoneCode: "+30" },
  { text: "Greenland", value: "GRL", phoneCode: "+299" },
  { text: "Grenada", value: "GRD", phoneCode: "+1" },
  { text: "Guadeloupe", value: "GLP", phoneCode: "+590" },
  { text: "Guam", value: "GUM", phoneCode: "+1" },
  { text: "Guatemala", value: "GTM", phoneCode: "+502" },
  { text: "Guernsey", value: "GGY", phoneCode: "+44" },
  { text: "Guinea", value: "GIN", phoneCode: "+224" },
  { text: "Guinea-Bissau", value: "GNB", phoneCode: "+245" },
  { text: "Guyana", value: "GUY", phoneCode: "+592" },
  { text: "Haiti", value: "HTI", phoneCode: "+509" },
  { text: "Heard Island and Mcdonald Islands", value: "HMD", phoneCode: "" },
  { text: "Holy See (Vatican City State)", value: "VAT", phoneCode: "+39" },
  { text: "Honduras", value: "HND", phoneCode: "+504" },
  { text: "Hong Kong", value: "HKG", phoneCode: "+852" },
  { text: "Hungary", value: "HUN", phoneCode: "+36" },
  { text: "Iceland", value: "ISL", phoneCode: "+354" },
  { text: "India", value: "IND", phoneCode: "+91" },
  { text: "Indonesia", value: "IDN", phoneCode: "+62" },
  { text: "Iran, Islamic Republic Of", value: "IRN", phoneCode: "+98" },
  { text: "Iraq", value: "IRQ", phoneCode: "+964" },
  { text: "Ireland", value: "IRL", phoneCode: "+353" },
  { text: "Isle of Man", value: "IMN", phoneCode: "+44" },
  { text: "Israel", value: "ISR", phoneCode: "+972" },
  { text: "Italy", value: "ITA", phoneCode: "+39" },
  { text: "Jamaica", value: "JAM", phoneCode: "+1" },
  { text: "Japan", value: "JPN", phoneCode: "+81" },
  { text: "Jersey", value: "JEY", phoneCode: "+44" },
  { text: "Jordan", value: "JOR", phoneCode: "+962" },
  { text: "Kazakhstan", value: "KAZ", phoneCode: "+7" },
  { text: "Kenya", value: "KEN", phoneCode: "+254" },
  { text: "Kiribati", value: "KIR", phoneCode: "+686" },
  {
    text: "Korea, Democratic People'S Republic of",
    value: "PRK",
    phoneCode: "+850",
  },
  { text: "Korea, Republic of", value: "KOR", phoneCode: "+82" },
  { text: "Kuwait", value: "KWT", phoneCode: "+965" },
  { text: "Kyrgyzstan", value: "KGZ", phoneCode: "+996" },
  { text: "Lao People'S Democratic Republic", value: "LAO", phoneCode: "+856" },
  { text: "Latvia", value: "LVA", phoneCode: "+371" },
  { text: "Lebanon", value: "LBN", phoneCode: "+961" },
  { text: "Lesotho", value: "LSO", phoneCode: "+266" },
  { text: "Liberia", value: "LBR", phoneCode: "+231" },
  { text: "Libyan Arab Jamahiriya", value: "LBY", phoneCode: "+218" },
  { text: "Liechtenstein", value: "LIE", phoneCode: "+423" },
  { text: "Lithuania", value: "LTU", phoneCode: "+370" },
  { text: "Luxembourg", value: "LUX", phoneCode: "+352" },
  { text: "Macao", value: "MAC", phoneCode: "+853" },
  {
    text: "Macedonia, The Former Yugoslav Republic of",
    value: "MKD",
    phoneCode: "+389",
  },
  { text: "Madagascar", value: "MDG", phoneCode: "+261" },
  { text: "Malawi", value: "MWI", phoneCode: "+265" },
  { text: "Malaysia", value: "MYS", phoneCode: "+60" },
  { text: "Maldives", value: "MDV", phoneCode: "+960" },
  { text: "Mali", value: "MLI", phoneCode: "+223" },
  { text: "Malta", value: "MLT", phoneCode: "+356" },
  { text: "Marshall Islands", value: "MHL", phoneCode: "+692" },
  { text: "Martinique", value: "MTQ", phoneCode: "+596" },
  { text: "Mauritania", value: "MRT", phoneCode: "+222" },
  { text: "Mauritius", value: "MUS", phoneCode: "+230" },
  { text: "Mayotte", value: "MYT", phoneCode: "+262" },
  { text: "Mexico", value: "MEX", phoneCode: "+52" },
  { text: "Micronesia, Federated States of", value: "FSM", phoneCode: "+691" },
  { text: "Moldova, Republic of", value: "MDA", phoneCode: "+373" },
  { text: "Monaco", value: "MCO", phoneCode: "+377" },
  { text: "Mongolia", value: "MNG", phoneCode: "+976" },
  { text: "Montserrat", value: "MSR", phoneCode: "+1" },
  { text: "Morocco", value: "MAR", phoneCode: "+212" },
  { text: "Mozambique", value: "MOZ", phoneCode: "+258" },
  { text: "Myanmar", value: "MMR", phoneCode: "+95" },
  { text: "Namibia", value: "NAM", phoneCode: "+264" },
  { text: "Nauru", value: "NRU", phoneCode: "+674" },
  { text: "Nepal", value: "NPL", phoneCode: "+977" },
  { text: "Netherlands", value: "NLD", phoneCode: "+31" },
  { text: "Netherlands Antilles", value: "ANT", phoneCode: "+599" },
  { text: "New Caledonia", value: "NCL", phoneCode: "+687" },
  { text: "New Zealand", value: "NZL", phoneCode: "+64" },
  { text: "Nicaragua", value: "NIC", phoneCode: "+505" },
  { text: "Niger", value: "NER", phoneCode: "+227" },
  { text: "Nigeria", value: "NGA", phoneCode: "+234" },
  { text: "Niue", value: "NIU", phoneCode: "+683" },
  { text: "Norfolk Island", value: "NFK", phoneCode: "+672" },
  { text: "Northern Mariana Islands", value: "MNP", phoneCode: "+1" },
  { text: "Norway", value: "NOR", phoneCode: "+47" },
  { text: "Oman", value: "OMN", phoneCode: "+968" },
  { text: "Pakistan", value: "PAK", phoneCode: "+92" },
  { text: "Palau", value: "PLW", phoneCode: "+680" },
  { text: "Palestinian Territory, Occupied", value: "PSE", phoneCode: "+970" },
  { text: "Panama", value: "PAN", phoneCode: "+507" },
  { text: "Papua New Guinea", value: "PNG", phoneCode: "+675" },
  { text: "Paraguay", value: "PRY", phoneCode: "+595" },
  { text: "Peru", value: "PER", phoneCode: "+51" },
  { text: "Philippines", value: "PHL", phoneCode: "+63" },
  { text: "Pitcairn", value: "PCN", phoneCode: "+64" },
  { text: "Poland", value: "POL", phoneCode: "+48" },
  { text: "Portugal", value: "PRT", phoneCode: "+351" },
  { text: "Puerto Rico", value: "PRI", phoneCode: "+1" },
  { text: "Qatar", value: "QAT", phoneCode: "+974" },
  { text: "Reunion", value: "REU", phoneCode: "+262" },
  { text: "Romania", value: "ROU", phoneCode: "+40" },
  { text: "Russian Federation", value: "RUS", phoneCode: "+7" },
  { text: "RWANDA", value: "RWA", phoneCode: "+250" },
  { text: "Saint Helena", value: "SHN", phoneCode: "+290" },
  { text: "Saint Kitts and Nevis", value: "KNA", phoneCode: "+1" },
  { text: "Saint Lucia", value: "LCA", phoneCode: "+1" },
  { text: "Saint Pierre and Miquelon", value: "SPM", phoneCode: "+508" },
  { text: "Saint Vincent and the Grenadines", value: "VCT", phoneCode: "+1" },
  { text: "San Marino", value: "SMR", phoneCode: "+378" },
  { text: "Sao Tome and Principe", value: "STP", phoneCode: "+239" },
  { text: "Saudi Arabia", value: "SAU", phoneCode: "+966" },
  { text: "Senegal", value: "SEN", phoneCode: "+221" },
  { text: "Serbia and Montenegro", value: "SCG", phoneCode: "+381" },
  { text: "Seychelles", value: "SYC", phoneCode: "+248" },
  { text: "Sierra Leone", value: "SLE", phoneCode: "+232" },
  { text: "Singapore", value: "SGP", phoneCode: "+65" },
  { text: "Slovakia", value: "SVK", phoneCode: "+421" },
  { text: "Slovenia", value: "SVN", phoneCode: "+386" },
  { text: "Solomon Islands", value: "SLB", phoneCode: "+677" },
  { text: "Somalia", value: "SOM", phoneCode: "+252" },
  { text: "South Africa", value: "ZAF", phoneCode: "+27" },
  { text: "South Georgia and the South Sandwich Islands", value: "SGS" },
  { text: "South Korea", value: "South Korea" },
  { text: "Spain", value: "ESP", phoneCode: "+34" },
  { text: "Sri Lanka", value: "LKA", phoneCode: "+94" },
  { text: "Sudan", value: "SDN", phoneCode: "+249" },
  { text: "Suriname", value: "SUR", phoneCode: "+597" },
  { text: "Svalbard and Jan Mayen", value: "SJM", phoneCode: "+47" },
  { text: "Swaziland", value: "SWZ", phoneCode: "+268" },
  { text: "Sweden", value: "SWE", phoneCode: "+46" },
  { text: "Switzerland", value: "CHE", phoneCode: "+41" },
  { text: "Syrian Arab Republic", value: "SYR", phoneCode: "+963" },
  { text: "Taiwan, Province of China", value: "TWN", phoneCode: "+886" },
  { text: "Tajikistan", value: "TJK", phoneCode: "+992" },
  { text: "Tanzania, United Republic of", value: "TZA", phoneCode: "+255" },
  { text: "Thailand", value: "THA", phoneCode: "+66" },
  { text: "Timor-Leste", value: "TLS", phoneCode: "+670" },
  { text: "Togo", value: "TGO", phoneCode: "+228" },
  { text: "Tokelau", value: "TKL", phoneCode: "+690" },
  { text: "Tonga", value: "TON", phoneCode: "+676" },
  { text: "Trinidad and Tobago", value: "TTO", phoneCode: "+1" },
  { text: "Tunisia", value: "TUN", phoneCode: "+216" },
  { text: "Turkey", value: "TUR", phoneCode: "+90" },
  { text: "Turkmenistan", value: "TKM", phoneCode: "+993" },
  { text: "Turks and Caicos Islands", value: "TCA", phoneCode: "+1" },
  { text: "Tuvalu", value: "TUV", phoneCode: "+688" },
  { text: "Uganda", value: "UGA", phoneCode: "+256" },
  { text: "Ukraine", value: "UKR", phoneCode: "+380" },
  { text: "United Arab Emirates", value: "ARE", phoneCode: "+971" },
  { text: "United Kingdom", value: "GBR", phoneCode: "+44" },
  { text: "United States", value: "USA", phoneCode: "+1" },
  {
    text: "United States Minor Outlying Islands",
    value: "UMI",
    phoneCode: "+1",
  },
  { text: "Uruguay", value: "URY", phoneCode: "+598" },
  { text: "Uzbekistan", value: "UZB", phoneCode: "+998" },
  { text: "Vanuatu", value: "VUT", phoneCode: "+678" },
  { text: "Venezuela", value: "VEN", phoneCode: "+58" },
  { text: "Viet Nam", value: "VNM", phoneCode: "+84" },
  { text: "Virgin Islands, British", value: "VGB", phoneCode: "+1" },
  { text: "Virgin Islands, U.S.", value: "VIR", phoneCode: "+1" },
  { text: "Wallis and Futuna", value: "WLF", phoneCode: "+681" },
  { text: "Western Sahara", value: "ESH", phoneCode: "+212" },
  { text: "Yemen", value: "YEM", phoneCode: "+967" },
  { text: "Zambia", value: "ZMB", phoneCode: "+260" },
  { text: "Zimbabwe", value: "ZWE", phoneCode: "+263" },
];

export function findCountryByCode(value) {
  const country = countries.find((country) => country.value === value);
  return country?.text || null;
}

export function findCountryByName(text) {
  const country = countries.find((country) => country.text === text);
  return country?.text || null;
}

export function findCountry(searchTerm) {
  const country = countries.find(
    (country) => country.text === searchTerm || country.value === searchTerm
  );
  return country?.text || null;
}

export default countries;
