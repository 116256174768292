import { gql } from "@apollo/client";

const UPDATE_USER = gql`
  mutation updateUser(
    $id: Float!
    $name: String
    $surname: String
    $email: String
    $phone: String
    $nation: String
    $gender: String
    $city: String
    $street: String
    $user_club_text: String
    $role: String
    $dob: String
    $avatar: Upload
    $citizenship: String
    $user_address: String
    $facebook: String
    $instagram: String
    $tiktok: String
    $youtube: String
    $linkedIn: String
    $website: String
    $ipinId: String
    $duprId: String
    $teId: String
    $ltsU10Id: String
    $atpId: String
    $wtaId: String
    $fideId: String
    $teU12Id:String
    $otherPoints:String
    $user_club_id: Float
  ) {
    updateUser(
      updateUserInput: {
        id: $id
        name: $name
        surname: $surname
        email: $email
        phone: $phone
        nation: $nation
        gender: $gender
        city: $city
        street: $street
        user_club_text: $user_club_text
        role: $role
        dob: $dob
        avatar: $avatar
        citizenship: $citizenship
        user_address: $user_address
        instagram: $instagram
        facebook: $facebook
        tiktok: $tiktok
        youtube: $youtube
        linkedIn: $linkedIn
        website: $website
        ipinId: $ipinId
        duprId: $duprId
        teId: $teId
        ltsU10Id: $ltsU10Id
        atpId: $atpId
        wtaId: $wtaId
        fideId: $fideId
        teU12Id:$teU12Id
        otherPoints:$otherPoints
        user_club_id: $user_club_id
      }
    ) {
      email
      name
      surname
      dob
      email
      nation
      phone
      role
      avatar
      user_club_text
      street
      city
      gender
      id
      citizenship
      user_address
      instagram
      facebook
      tiktok
      youtube
      linkedIn
      website
      ipinId
      #fideId
      #teId
      #ltsU10Id
      #atpId
      #wtaId
    }
  }
`;

export default UPDATE_USER;
