import { gql } from "@apollo/client";

const REMOVE_PURCHASE_LICENSE = gql`
  mutation removePurchasedLicense($id: Int!) {
    removePurchasedLicense(id: $id) {
      id
    }
  }
`;

export default REMOVE_PURCHASE_LICENSE;
