import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      "Athlete Portal": "Athlete Portal",
      Create: "Create",
      "Your subscription": "Your subscription",
      Notifications: "Notifications",
      "Add League": "Add League",
      "Add Tournament": "Add Tournament",
      "Add Club": "Add Club",
      "Add News": "Add News",
      "Add Team": "Add Team",
      Home: "Home",
      Platforms: "Platforms",
      "Search Platform": "Search Platform",
      "ADD NEW Platform": "ADD NEW Platform",
      "Platform name": "Platform name",
      "Platform image": "Platform image",
      Country: "Country",
      Sport: "Sport",
      Type: "Type",
      Organization: "Organization",
      Status: "Status",
      "There is no available platforms": "There is no available platforms",
      Dashboard: "Dashboard",
      Edit: "Edit",
      New: "New",
      "Add New Platform": "Add New Platform",
      "Edit Platforms": "Edit Platforms",
      "Organization account": "Organization account",
      "Platform general": "Platform general",
      Appearance: "Appearance",
      "Company details": "Company details",
      "Landing & Navigation": "Landing & Navigation",
      Integrations: "Integrations",
      "Additional requests": "Additional requests",
      "Service fees": "Service fees",
      "Update Organization Account": "Update Organization Account",
      "USER IS NOT IN THE LIST": "USER IS NOT IN THE LIST",
      Name: "Name",
      "Enter name": "Enter name",
      Surname: "Surname",
      "Enter surname": "Enter surname",
      Email: "Email",
      "Enter Email Address": "Enter Email Address",
      "Change owner": "Change owner",
      "Save new owner": "Save new owner",
      Password: "Password",
      "Confirm Password": "Confirm Password",
      "Organization full name": "Organization full name",
      "Enter Organization full name": "Enter Organization full name",
      "Organization abbreviations": "Organization abbreviations",
      "Enter Organization abbreviation": "Enter Organization abbreviation",
      "Organization type": "Organization type",
      "About organization": "About organization",
      "Contact email": "Contact email",
      "Enter Contact Email": "Enter Contact Email",
      "Contact phone number": "Contact phone number",
      "Enter Contact phone number": "Enter Contact phone number",
      Website: "Website",
      "Enter website": "Enter website",
      "Instagram link": "Instagram link",
      "Insert Instagram link": "Insert Instagram link",
      Continue: "Continue",
      "Platform General": "Platform General",
      "Platform title": "Platform title",
      "Enter Platform Title": "Enter Platform Title",
      "Platform type": "Platform type",
      "First selected country will act as primary country":
        "First selected country will act as primary country",
      "Select sports": "Select sports",
      "First selected sport will act as primary sport":
        "First selected sport will act as primary sport",
      Sports: "Sports",
      "Select discipline": "Select discipline",
      "Select third-party licence": "Select third-party licence",
      "third-party licence": "third-party licence",
      "No third-party license Found": "No third-party license Found",
      "Select languages": "Select languages",
      "First selected language will act as primary language":
        "First selected language will act as primary language",
      "Select language": "Select language",
      Timezone: "Timezone",
      "Time Zone": "Time Zone",
      Currency: "Currency",
      "First selected currency will act as primary currency":
        "First selected currency will act as primary currency",
      "Select currency": "Select currency",
      Description: "Description",
      "Do you have already your domain name?":
        "Do you have already your domain name?",
      "Enter your domain name": "Enter your domain name",
      Back: "Back",
      Save: "Save",
      "Upload primary logo (svg or png)": "Upload primary logo (svg or png)",
      "Upload secondary logo (svg or png)":
        "Upload secondary logo (svg or png)",
      "Upload cover picture": "Upload cover picture",
      "No logo, you want us to make one for you":
        "No logo, you want us to make one for you",
      "Click Here": "Click Here",
      "Select primary color": "Select primary color",
      "Select secondary color": "Select secondary color",
      "Company name": "Company name",
      "Enter Company Name": "Enter Company Name",
      "Registration Number": "Registration Number",
      "Enter Registration Number": "Enter Registration Number",
      "Full Address": "Full Address",
      "Enter Full Address": "Enter Full Address",
      "Signee full name": "Signee full name",
      "Signee role": "Signee role",
      "Contact Email": "Contact Email",
      "Landing / Home Screen": "Landing / Home Screen",
      "Do you need landing?": "Do you need landing?",
      "Select Template": "Select Template",
      "No, I will use my own website as Landing page.":
        "No, I will use my own website as Landing page.",
      "Do you need Integrations?": "Do you need Integrations?",
      "Payment Service Provider": "Payment Service Provider",
      "Make sure to get familiar with Stripe service fees":
        "Make sure to get familiar with Stripe service fees",
      "Additional services": "Additional services",
      "Service Name": "Service Name",
      Price: "Price",
      "Mobile app": "Mobile app",
      SMM: "SMM",
      "Graphics for SMM": "Graphics for SMM",
      "Your request": "Your request",
      "Type here your request": "Type here your request",
      "Upload file": "Upload file",
      "Entry service fee": "Entry service fee",
      "Tournated entry service fee": "Tournated entry service fee",
      "Entery entery fee": "Entery entery fee",
      "Partner entry service fee": "Partner entry service fee",
      "Enter partner entry fee": "Enter partner entry fee",
      "License service fee": "License service fee",
      "Tournated license service fee": "Tournated license service fee",
      "Enter tournated license fee": "Enter tournated license fee",
      "Partner license service fee": "Partner license service fee",
      "Enter partner license fee": "Enter partner license fee",
      "Enable Pricing": "Enable Pricing",
      Leagues: "Leagues",
      "Create new League": "Create new League",
      "There is no available leagues": "There is no available leagues",
      Completed: "Completed",
      Upcoming: "Upcoming",
      Active: "Active",
      Total: "Total",
      "Search Leagues": "Search Leagues",
      "League Title": "League Title",
      "League Type": "League Type",
      Logo: "Logo",
      Tournaments: "Tournaments",
      Athletes: "Athletes",
      Regulations: "Regulations",
      Platform: "Platform",
      "We recommend an image that is 256 x 256 pixels":
        "We recommend an image that is 256 x 256 pixels",
      "League Abbreviation": "League Abbreviation",
      "League owner": "League owner",
      "Select Organization": "Select Organization",
      "Select platform": "Select platform",
      "League Priority": "League Priority",
      "Go to Next": "Go to Next",
      Phone: "Phone",
      "League Website": "League Website",
      Instagram: "Instagram",
      "Field Title": "Field Title",
      "Field Type": "Field Type",
      "Add New Point": "Add New Point",
      "Title Name": "Title Name",
      Select: "Select",
      "Field Value": "Field Value",
      "Add Another Option Below": "Add Another Option Below",
      Update: "Update",
      Management: "Management",
      "League status": "League status",
      "Select one": "Select one",
      "Make this league featured": "Make this league featured",
      "Enable rankings": "Enable rankings",
      "enable ranking":
        "Create Customized Rankings for your league by connecting tournament categories, defining age/gender limitations, and adding additional rules.",
      "Enable online payments": "Enable online payments",
      "enable online payment":
        "Start accepting online payments for tournament entries and licenses. Make sure to familiarize yourself with our service fee model for managing online payments by checking it out here. (on click opens up modal with details according to our pricing in",
      pitchdeck: "pitchdeck",
      "Enable licences": "Enable licences",
      "License description": "Configure licenses, prices, periods, and rules.",
      "Make club field mandatory and display club details as organizer on Tournament page":
        "Make club field mandatory and display club details as organizer on Tournament page",
      "Create League": "Create League",
      "Organization Accounts": "Organization Accounts",
      "ADD NEW ACCOUNT": "ADD NEW ACCOUNT",
      "There is no available organizations":
        "There is no available organizations",
      "Search Organizations": "Search Organizations",
      ////////////////////////////////////////////////
      "Full name": "Full name",
      "Organization Abbrevation": "Organization Abbrevation",
      "UPDATE USER PROFILE": "UPDATE USER PROFILE",
      "Enter phone number": "Enter phone number",
      "Organization Settings": "Organization Settings",
      "Organization Type": "Organization Type",
      "Organization Logo": "Organization Logo",
      "About Organization": "About Organization",
      "Contact Details": "Contact Details",
      Users: "Users",
      "Search User": "Search User",
      "ADD NEW USER": "ADD NEW USER",
      Avatar: "Avatar",
      "Phone Number": "Phone Number",
      Role: "Role",
      "IPIN License": "IPIN License",
      "DUPR License": "DUPR License",
      "Tennis Europe License": "Tennis Europe License",
      "LTS U10 License": "LTS U10 License",
      "FIDE License": "FIDE License",
      "Add new user": "Add new user",
      "Edit User": "Edit User",
      "User Account": "User Account",
      "Date of birth": "Date of birth",
      Gender: "Gender",
      "Representing country": "Representing country",
      City: "City",
      "Enter city": "Enter city",
      Address: "Address",
      "Enter address": "Enter address",
      Career: "Career",
      Club: "Club",
      "Select club": "Select club",
      "Select coach": "Select coach",
      Parent: "Parent",
      "Select parent": "Select parent",
      "Write a bio": "Write a bio",
      CHECKBOXES: "CHECKBOXES",
      "Give access to coach to purchase licences and manage entries on your behalf":
        "Give access to coach to purchase licences and manage entries on your behalf",
      "Give access to club manager(-es) to purchase licences and manage entries on your behalf":
        "Give access to club manager(-es) to purchase licences and manage entries on your behalf",
      "Give access to parent(-s) to purchase licences and manage entries on your behalf":
        "Give access to parent(-s) to purchase licences and manage entries on your behalf",
      Socials: "Socials",
      "Insert link here": "Insert link here",
      Facebook: "Facebook",
      Linkedin: "Linkedin",
      Tiktok: "Tiktok",
      Youtube: "Youtube",
      Licence: "Licence",
      "ITF IPIN": "ITF IPIN",
      DUPRID: "DUPRID",
      "Tennis Europe ID": "Tennis Europe ID",
      "LTS U10": "LTS U10",
      ATP: "ATP",
      WTA: "WTA",
      FIDE: "FIDE",
      "Update password": "Update password",
      "Re-Enter": "Re-Enter",
      "Login settings": "Login settings",
      "Switch Standard login to Social login":
        "Switch Standard login to Social login",
      "Connect Gmail": "Connect Gmail",
      "Enable 2FA Google Authorisation": "Enable 2FA Google Authorisation",
      "Enable 2FA Email Authorisation": "Enable 2FA Email Authorisation",
      "Role Name": "Role Name",
      "Is user verified?": "Is user verified?",
      Yes: "Yes",
      No: "No",
      Tournament: "Tournament",
      "All tournaments": "All tournaments",
      "League tournaments": "League tournaments",
      "Search Tournaments": "Search Tournaments",
      "Create new Tournament": "Create new Tournament",
      "Tournament Title": "Tournament Title",
      "Start - End": "Start - End",
      Organizer: "Organizer",
      Location: "Location",
      "List of categories": "List of categories",
      "Update Status": "Update Status",
      "There is no available tournaments": "There is no available tournaments",
      "ADD NEW SPORT": "ADD NEW SPORT",
      "Search Sports": "Search Sports",
      "Sport Title": "Sport Title",
      "Add new Sport": "Add new Sport",
      "Edit Sports": "Edit Sports",
      "Sports title": "Sports title",
      "Enter title": "Enter title",
      "Sports abbreviation": "Sports abbreviation",
      "Enter abbreviation": "Enter abbreviation",
      "Resource title": "Resource title",
      "Rule how to add results/score": "Rule how to add results/score",
      Icon: "Icon",
      "Enable disciplines": "Enable disciplines",
      "Enable courts": "Enable courts",
      "Enable stages": "Enable stages",
      "Enable pairings": "Enable pairings",
      "Enable rounds": "Enable rounds",
      "Enable standings based on pairings":
        "Enable standings based on pairings",
      "Display draws": "Display draws",
      "Display groups": "Display groups",
      "Display live standings": "Display live standings",
      "Display order of play": "Display order of play",
      "Display list & results": "Display list & results",
      "Display pending results": "Display pending results",
      "Display final standings": "Display final standings",
      "Display athlete chess titles in Participants": `Display athlete chess titles in "Participants"`,
      "Display seeds column Participants": `Display seeds column "Participants"`,
      "Display segments column in Participants": `Display segments column in "Participants"`,
      "Upload file here": "Upload file here",
      Disciplines: "Disciplines",
      "ADD NEW DISCIPLINE": "ADD NEW DISCIPLINE",
      "There is no available disciplines": "There is no available disciplines",
      "Search Disciplines": "Search Disciplines",
      "Sport name": "Sport name",
      "Add new Discipline": "Add new Discipline",
      "Edit Discipline": "Edit Discipline",
      "Discipline title": "Discipline title",
      "Select Sport": "Select Sport",
      "No sports found": "No sports found",
      "Results type": "Results type",
      "Third-party licences": "Third-party licences",
      "Third party licence": "Third party licences",
      "There is no available third-party licences":
        "There is no available third-party licences",
      "ADD NEW LICENCE": "ADD NEW LICENCE",
      "Search license": "Search license",
      "license name": "license name",
      "License type": "License type",
      "Add licence": "Add licence",
      "Add Third-party licence": "Add Third-party licence",
      "Edit Third-party licence": "Edit Third-party licence",
      "Organization title": "Organization title",
      "Organization abbreviation": "Organization abbreviation",
      "Licence title": "Licence title",
      "Enter license title": "Enter license title",
      "Licence type": "Licence type",
      "Request license in user profile": "Request license in user profile",
      "Request license on user registration in platform":
        "Request license on user registration in platform",
      "Make licence mandatory": "Make licence mandatory",
      "Link to get licence": "Link to get licence",
      "Enter link to get licence": "Enter link to get licence",
      Partners: "Partners",
      "ADD NEW PARTNER": "ADD NEW PARTNER",
      "There is no available partners": "There is no available partners",
      "Search Partners": "Search Partners",
      "Create new Partner": "Create new Partner",
      Link: "Link",
      Text: "Text",
      Priority: "Priority",
      "Showing dates": "Showing dates",
      "New Partner": "New Partner",
      "Edit Partner": "Edit Partner",
      "Partner Logo": "Partner Logo",
      platform: "platform",
      "No platform available": "No platform available",
      "No league available": "No league available",
      "No Clubs available": "No Clubs available",
      "Sponsor Text": "Sponsor Text",
      "Date Settings": "Date Settings",
      "Active From": "Active From",
      "Active To": "Active To",
      "Platform managers": "Platform managers",
      "ADD NEW MANAGER": "ADD NEW MANAGER",
      "There is no available manager": "There is no available manager",
      "Search Manager": "Search Manager",
      "Export team": "Export team",
      "Export team emails": "Export team emails",
      "Add platform manager": "Add platform manager",
      "Edit platform panager": "Edit platform panager",
      "Enter Organization": "Enter Organization",
      "Select organization": "Select organization",
      "Filter users": "Filter users",
      "Export users": "Export users",
      "Export user emails": "Export user emails",
      "Age group": "Age group",
      "There is no available users": "There is no available users",
      Coaches: "Coaches",
      "ADD NEW COACH": "ADD NEW COACH",
      "There is no available account": "There is no available account",
      "Search Coach": "Search Coach",
      "Add new coach": "Add new coach",
      "Edit Coach": "Edit Coach",
      "Coach image": "Coach image",
      Category: "Category",
      "There is no available sports": "There is no available sports",
      "there are not available clubs": "there are not available clubs",
      "Date from": "Date from",
      "Date to": "Date to",
      "Display on organization's landing page":
        "Display on organization's landing page",
      "Platform tournaments": "Platform tournaments",
      Title: "Title",
      Posts: "Posts",
      "ADD POST": "ADD POST",
      ////////////////////////////////////////////////
      "There is no available post": "There is no available post",
      News: "News",
      "Search Post": "Search Post",
      Image: "Image",
      Author: "Author",
      Content: "Content",
      "Cover / featured image": "Cover / featured image",
      "Select league": "Select league",
      League: "League",
      "Add video from youtube.com": "Add video from youtube.com",
      Date: "Date",
      "Purchased Licenses": "Purchased Licenses",
      "Search Licenses": "Search Licenses",
      "Create new License": "Create new License",
      "Athlete name & surname": "Athlete name & surname",
      "License Name": "License Name",
      "License Price": "License Price",
      "License active from": "License active from",
      "License active to": "License active to",
      "Paid(online/cash)": "Paid(online/cash)",
      "There is no available purchased license":
        "There is no available purchased license",
      "New License": "New License",
      "Edit License": "Edit License",
      "Athlete Email": "Athlete Email",
      License: "License",
      "No license found": "No license found",
      "Payment Method": "Payment Method",
      "How much paid": "How much paid",
      Teams: "Teams",
      "ADD NEW TEAM": "ADD NEW TEAM",
      "There is no available teams": "There is no available teams",
      "Search team": "Search teams",
      "Create new Team": "Create new Team",
      "Team Members": "Team Members",
      Discipline: "Discipline",
      "New Team": "New Team",
      "Edit Team": "Edit Team",
      "Team Logo": "Team Logo",
      "No Clubs Found": "No Clubs Found",
      "No disciplines found": "No disciplines found",
      "Club status": "Club status",
      Members: "Members",
      "Add new Members": "Add new Members",
      "Add New Member": "Add New Member",
      "Member email": "Member email",
      "Select Role": "Select Role",
      "Add more": "Add more",
      "ADD NEW CLUB": "ADD NEW CLUB",
      "There is no available clubs": "There is no available clubs",
      Clubs: "Clubs",
      "Search Clubs": "Search Clubs",
      "Club Name": "Club Name",
      "Add new Club": "Add new Club",
      "Edit Club": "Edit Club",
      "Appearance & Location": "Appearance & Location",
      "Club title": "Club title",
      "Add club logo": "Add club logo",
      "How to find us": "How to find us",
      "Contact details": "Contact details",
      "Contact Phone": "Contact Phone",
      Twitter: "Twitter",
      "Services & Resources": "Services & Resources",
      "Select services": "Select services",
      Services: "Services",
      Resources: "Resources",
      "Resource Name": "Resource Name",
      Service: "Service",
      "Add Resources": "Add Resources",
      "Add New Resource": "Add New Resource",
      "Select Service": "Select Service",
      "Select type": "Select type",
      "Add Another": "Add Another",
      "Club page": "Club page",
      "Select Coaches": "Select Coaches",
      "Athlete Club": "Athlete Club",
      desc: "Once your club added, athletes will be able to select it in their athlete profile setting page and then they will appear on a club page to be approved/declined. Approved members will be displayed on the club page",
      "Online booking": "Online booking",
      "Club Main Administrator": "Club Main Administrator",
      "Main Adminitrator": "Main Adminitrator",
      "CLUB MAIN ADMINISTRATOR IS NOT IN THE LIST":
        "CLUB MAIN ADMINISTRATOR IS NOT IN THE LIST",
      Administrator: "Administrator",
      "ADMINISTRATOR IS NOT IN THE LIST": "ADMINISTRATOR IS NOT IN THE LIST",
      Banners: "Banners",
      "There is no available banners": "There is no available banners",
      "ADD BANNER": "ADD BANNER",
      "Search Banner": "Search Banner",
      Headline: "Headline",
      subheadline: "subheadline",
      "Button Text": "Button Text",
      "New Banner": "New Banner",
      "Edit Banner": "Edit Banner",
      Subheadline: "Subheadline",
      "Button text": "Button text",
      "Button link": "Button link",
      "Select roles": "Select roles",
      "Banner images": "Banner images",
      "Control Panel": "Control Panel",
      "Run the tournament step by step from this control panel":
        "Run the tournament step by step from this control panel",
      "Edit & Setup league": "Edit & Setup league",
      "Edit Now": "Edit Now",
      "Create & Setup league": "Create & Setup league",
      "Start Now": "Start Now",
      "Add league members": "Add league members",
      "league member description":
        "Include league members such as managers, tournament organizers, and referees for your league. Managers will have full access to the dashboard and all managerial features, organizers will be able to add tournaments and manage them, and referees will have access to entries, draws, groups, live standings, order of play, and results management for the tournaments to which they are assigned.",
      "Add Now": "Add Now",
      "Add tournament categories": "Add tournament categories",
      "tournament category description":
        "Set up tournament categories for your league, specifying formats (singles/doubles/team), age/gender limitations, and connecting them to ranking categories (once you have added those ranking categories in the next step).",
      "Add rankings categories": "Add rankings categories",
      "ranking category description":
        "Establish ranking categories for your league. Choose tournament categories to include in rankings, specify how many tournaments to count, set the timeframe, connect with international leagues, and establish other rules.",
      "Setup ranking points": "Setup ranking points",
      "ranking description":
        "Configure the points table for each tournament category, specifying the number of points for each placement. A more detailed table will streamline and automate the process.",
      Setup: "Setup",
      "Setup licences": "Setup licences",
      "Add tournament": "Add tournament",
      "tournament description":
        "Follow the steps to create your tournament and commence the management of your  event. Rest assured, you will receive a comprehensive list of instructions covering the numerous features we offer for the management of sports events",
      "Leagues Members": "Leagues Members",
      "Add new Member": "Add new Member",
      "There is no available members": "There is no available members",
      "League members": "League members",
      "Search Members": "Search Members",
      "ADD NEW MEMBER": "ADD NEW MEMBER",
      "Delete league member": "Delete league member",
      "Are you sure you want to delete?": "Are you sure you want to delete?",
      Cancel: "Cancel",
      Delete: "Delete",
      "Tournament Categories": "Tournament Categories",
      "There is no available categories": "There is no available categories",
      "ADD NEW CATEGORY": "ADD NEW CATEGORY",
      "Search Categories": "Search Categories",
      "Copy Categories": "Copy Categories",
      "Delete Categories": "Delete Categories",
      "Category Name": "Category Name",
      "Category Type": "Category Type",
      "Select League": "Select League",
      Copy: "Copy",
      "Are you sure you want to delete these categories?":
        "Are you sure you want to delete these categories?",
      "New Category": "New Category",
      "Edit Category": "Edit Category",
      "Category name": "Category name",
      "Add team selection on tournament registration":
        "Add team selection on tournament registration",
      ////////////////////////////////////////////////
      "Add club selection on tournament registration":
        "Add club selection on tournament registration",
      "Skills level": "Skills level",
      "Category Gender": "Category Gender",
      "Age from": "Age from",
      "Age to": "Age to",
      "Age Limit Type": "Age Limit Type",
      "No Types Found!": "No Types Found!",
      "Ask skills level on a tournament registration":
        "Ask skills level on a tournament registration",
      "Enable License": "Enable License",
      "Display athlete club in Participants": `Display athlete club in "Participants"`,
      "Display athlete city in Participants": `Display athlete city in "Participants"`,
      "Select License": "Select License",
      "No License Found!": "No License Found!",
      "Ranking Category for men": "Ranking Category for men",
      "No Category Found!": "No Category Found!",
      "Ranking Category for Women": "Ranking Category for Women",
      "Third-party license": "Third-party license",
      "No third-party license Found!": "No third-party license Found!",
      "Time control": "Time control",
      "No time control found": "No time control found",
      "Order display for seeding": "Order display for seeding",
      "Sport fishing settings": "Sport fishing settings",
      "Identify team members on registration":
        "Identify team members on registration",
      "Min number of members": "Min number of members",
      "Max number of members": "Max number of members",
      "Reservists required": "Reservists required",
      "Min number of reservists": "Min number of reservists",
      "Max number of reservists": "Max number of reservists",
      "Captain required": "Captain required",
      "Transfer members to singles category":
        "Transfer members to singles category",
      "Select singles category": "Select singles category",
      "No Category Found": "No Category Found",
      "National tournament (country vs country)":
        "National tournament (country vs country)",
      "Club championships (club vs club)": "Club championships (club vs club)",
      "Create matches from team members and count points result based on matches result":
        "Create matches from team members and count points result based on matches result",
      "Count individual total": "Count individual total",
      "Count teams total": "Count teams total",
      "Number of athletes to count min": "Number of athletes to count min",
      "Number of athletes to count max": "Number of athletes to count max",
      "Max Entries per team": "Max Entries per team",
      "Allow registration without team": "Allow registration without team",
      "Category Status": "Category Status",
      "Ranking Categories": "Ranking Categories",
      "There is no available ranking categories":
        "There is no available ranking categories",
      Categories: "Categories",
      "No of Tournaments": "No of Tournaments",
      "Time Period": "Time Period",
      "No Leagues found": "No Leagues found",
      "New Ranking Category": "New Ranking Category",
      "Select Categories": "Select Categories",
      "No Categories Found!": "No Categories Found!",
      "Max number of tournaments": "Max number of tournaments",
      "Count specific tournaments": "Count specific tournaments",
      "Add penalty points for missing a tournament":
        "Add penalty points for missing a tournament",
      "Penalty rule": "Penalty rule",
      "Enter points": "Enter points",
      "Additional Rules": "Additional Rules",
      Sector: "Sector",
      Coefficient: "Coefficient",
      "No of tournaments": "No of tournaments",
      "Edit additional rule(s)": "Edit additional rule(s)",
      "Add additional rule(s)": "Add additional rule(s)",
      "During which period tournament points are counted":
        "During which period tournament points are counted",
      "Select Time Period": "Select Time Period",
      "Points sorting": "Points sorting",
      "Sport Fishing": "Sport Fishing",
      "Select Stage form which take points":
        "Select Stage form which take points",
      "Ranking Points": "Ranking Points",
      "Search Rankings": "Search Rankings",
      "Add points block": "Add points block",
      "Select / UnSelect All": "Select / UnSelect All",
      Place: "Place",
      Points: "Points",
      "Bracket type": "Bracket type",
      Segment: "Segment",
      "Num of Entries": "Num of Entries",
      "Ad.Rules": "Ad.Rules",
      "view rule": "view rule",
      "Add new row": "Add new row",
      "There is no available ranking points":
        "There is no available ranking points",
      "Edit Points": "Edit Points",
      "Add Points": "Add Points",
      "Points block name": "Points block name",
      Specification: "Specification",
      "Rule Settings": "Rule Settings",
      "Select operator": "Select operator",
      "Select skills level": "Select skills level",
      "Number of players": "Number of players",
      "Number of entries": "Number of entries",
      update: "update",
      SAVE: "SAVE",
      DISCARD: "DISCARD",
      "SET NEW RULE": "SET NEW RULE",
      "Edit Row": "Edit Row",
      "Add Row": "Add Row",
      ADD: "ADD",
      "Copy Ranking Point": "Copy Ranking Point",
      "Select Ranking Points": "Select Ranking Points",
      "Copy Ranking Point Blocks": "Copy Ranking Point Blocks",
      "Delete Ranking Point Block": "Delete Ranking Point Block",
      Licenses: "Licenses",
      "There is no available licenses": "There is no available licenses",
      "Price Currency": "Price Currency",
      "Select Leagues": "Select Leagues",
      "No League Found!": "No League Found!",
      "License price": "License price",
      "License Type": "License Type",
      "License Status": "License Status",
      "Additional questions": "Additional questions",
      "Add additional question": "Add additional question",
      "Edit this": "Edit this",
      "Add new": "Add new",
      question: "question",
      Question: "Question",
      "Field Option": "Field Option",
      Remove: "Remove",
      "Add new option": "Add new option",
      "Ask this question to each athlete": "Ask this question to each athlete",
      Submit: "Submit",
      "Delete Warning": "Delete Warning",
      "Are you sure you want to delete this question?":
        "Are you sure you want to delete this question?",
      "Confirm Deletion": "Confirm Deletion",
      Rankings: "Rankings",
      Search: "Search",
      "ranking Category": "ranking Category",
      "No date Found": "No date Found",
      "Create Rankings": "Create Rankings",
      "Update Rankings": "Update Rankings",
      close: "close",
      Year: "Year",
      "Points Details": "Points Details",
      "No points yet": "No points yet",
      Position: "Position",
      Athlete: "Athlete",
      "There is no available rankings": "There is no available rankings",
      "Select Ranking categories": "Select Ranking categories",
      "Remove Rankings": "Remove Rankings",
      "Select Date": "Select Date",
      "Are you sure you want to delete rankings?":
        "Are you sure you want to delete rankings?",
      "Rankings History": "Rankings History",
      "Ranking Category": "Ranking Category",
      "Last Updated": "Last Updated",
      Public: "Public",
      "Delete warning": "Delete warning",
      "Are you sure you want to delete this ranking?":
        "Are you sure you want to delete this ranking?",
      "There is no available rankings history":
        "There is no available rankings history",
      "Tournament Results": "Tournament Results",
      Entry: "Entry",
      "Add new result": "Add new result",
      "There is no available tournament results":
        "There is no available tournament results",
      "Pendding tournaments": "Pendding tournaments",
      "There is no available athletes": "There is no available athletes",
      "Search Athletes": "Search Athletes",
      "Country/Residency": "Country/Residency",
      "Super Data": "Super Data",
      "All Tournaments": "All Tournaments",
      Data: "Data",
      "Pending Tournaments": "Pending Tournaments",
      Financials: "Financials",
      Accounting: "Accounting",
      Extra: "Extra",
      "Live scoring": "Live scoring",
      "Live stream": "Live stream",
      "Platform Settings": "Platform Settings",
      "My Platforms": "My Platforms",
      Team: "Team",
      Instructions: "Instructions",
      "League Settings": "League Settings",
      "League Members": "League Members",
      /////////////////////////////////////////////
      Upload: "Upload",
      "primary logo": "primary logo",
      "secondary logo": "secondary logo",
      "cover picture": "cover picture",
      "Start Typing": "Start Typing",
      "Leagues list": "Leagues list",
      "General & Appearance": "General & Appearance",
      "Instagram Link": "Instagram Link",
      "Fact Sheet": "Fact Sheet",
      "Fact sheet": "Fact sheet",
      "Upload regulations": "Upload regulations",
      "Organization Abbreviation": "Organization Abbreviation",
      "Drag and Drop some file": "Drag and Drop some file",
      "here, or click to Select file": "here, or click to Select file",
      UPLOAD: "UPLOAD",
      "DISCARD AND CANCEL": "DISCARD AND CANCEL",
      SUBMIT: "SUBMIT",
      "Add new Organization Account": "Add new Organization Account",
      "Edit Organization Account": "Edit Organization Account",
      "Full Name": "Full Name",
      Biography: "Biography",
      "Add post": "Add post",
      "Edit Post": "Edit Post",
      "Edit Member": "Edit Member",
      "Organization name": "Organization name",
      "Organization email": "Organization email",
      "Organization phone": "Organization phone",
      "Display member on organizations public team page":
        "Display member on organizations public team page",
      "Select Roles": "Select Roles",
      "For assiging points": "For assiging points",
      "Third party license": "Third party license",
      ///////////////////////////////////////////////////////// new ///////////////
      "Must be a valid email": "Must be a valid email",
      "Email is required": "Email is required",
      "Organization Name is required": "Organization Name is required",
      "Organization Type is required": "Organization Type is required",
      "organization abbreviation is required":
        "organization abbreviation is required",
      "Contact Email is required": "Contact Email is required",
      "Password is Required": "Password is Required",
      "Password should be 8 chars minimum.":
        "Password should be 8 chars minimum.",
      "Passwords must match": "Passwords must match",
      "company name is required": "company name is required",
      "company number is required": "company number is required",
      "full address is required": "full address is required",
      "Invalid email address": "Invalid email address",
      "signee role is required": "signee role is required",
      "signee full name is required": "signee full name is required",
      "partner entry fee is required": "partner entry fee is required",
      "tournated license fee is required": "tournated license fee is required",
      "partner license fee  is required": "partner license fee  is required",
      "Creating platform...": "Creating platform...",
      "Platform created successfully": "Platform created successfully",
      "Updating...": "Updating...",
      "Platform updated": "Platform updated",
      "Add New Club": "Add New Club",
      "organization is required": "organization is required",
      "Adding new fact...": "Adding new fact...",
      "Fact added successfully": "Fact added successfully",
      "Deleting fact...": "Deleting fact...",
      "fact deleted successfully": "fact deleted successfully",
      "Updating fact...": "Updating fact...",
      "Fact updated successfully": "Fact updated successfully",
      "Creating league...": "Creating league...",
      "League created successfully": "League created successfully",
      "Updating league...": "Updating league...",
      "League updated successfully": "League updated successfully",
      "Approved & Public": "Approved & Public",
      "ATP points": "ATP points",
      "WTA points": "WTA points",
      "name Email is required": "name Email is required",
      "title is require": "title is required",
      "platform type required": "platform type required",
      "time zone is required": "time zone is required",
      "description is required": "description is required",
      "title is required": "title is required",
      "abbreviation is required": "abbreviation is required",
      "platform is required": "platform is required",
      "priority is required": "priority is required",
      "type is required": "type is required",
      "sports is required": "sports is required",
      "email is required": "email is required",
      "Organization added Successfully!": "Organization added Successfully!",
      "name is required": "name is required",
      "surname is required": "surname is required",
      "organization name is required": "organization name is required",
      "organization type is required": "organization type is required",
      "Organization Account Updated Successfully!":
        "Organization Account Updated Successfully!",
      "Creating organization...": "Creating organization...",
      "Organization created successfully": "Organization created successfully",
      "This User is already exist": "This User is already exist",
      "Password is required": "Password is required",
      "Re-Enter password is required": "Re-Enter password is required",
      "Password updated": "Password updated",
      "User Updated": "User Updated",
      "Sports Updated": "Sports Updated",
      "Sports Created": "Sports Created",
      "Discipline Updated": "Discipline Updated",
      "Discipline Created": "Discipline Created",
      "At least one sport must be selected":
        "At least one sport must be selected",
      "License Updated": "License Updated",
      "License Created": "License Created",
      "Please provide Image": "Please provide Image",
      "Partner updated Successfully!": "Partner updated Successfully!",
      "Partner created successfully!": "Partner created successfully!",
      "Please add correct user email": "Please add correct user email",
      "Coach added!": "Coach added!",
      "user found": "user found",
      "user not found": "user not found",
      "country is required": "country is required",
      "gender is required": "gender is required",
      "confirm password is required": "confirm password is required",
      "Coache Updated Successfully!": "Coache Updated Successfully!",
      "Image field is required": "Image field is required",
      "author is required": "author is required",
      "Updating ...": "Updating ...",
      "Post updated successfully": "Post updated successfully",
      "Creating post ...": "Creating post ...",
      "Post created successfully": "Post created successfully",
      "User found": "User found",
      "User not found": "User not found",
      "paid is required": "paid is required",
      "currency is required": "currency is required",
      "Athlete is required": "Athlete is required",
      "license is required": "license is required",
      "start date is required": "start date is required",
      "end date is required": "end date is required",
      "License created successfully!": "License created successfully!",
      "sport is required": "sport is required",
      "discipline is required": "discipline is required",
      "Team updated successfully!": "Team updated successfully!",
      "Team created successfully!": "Team created successfully!",
      "city is required": "city is required",
      "address is required": "address is required",
      "headline is required": "headline is required",
      "subheadline is required": "subheadline is required",
      "button text required": "button text required",
      "button link required": "button link required",
      "Image are required": "Image are required",
      "Updating banner...": "Updating banner...",
      "Banner updated successfully": "Banner updated successfully",
      "Creating banner...": "Creating banner...",
      "Banner created successfully": "Banner created successfully",
      "League member added!": "League member added!",
      "League Member Updated Successfully!":
        "League Member Updated Successfully!",
      "order is required": "order is required",
      "Age from must an integer": "Age from must an integer",
      "Age must be at least 0": "Age must be at least 0",
      "Age to must an integer": "Age to must an integer",
      "Min members must an integer": "Min members must an integer",
      "Min members  must be at least 0": "Min members  must be at least 0",
      "Max members must an integer": "Max members must an integer",
      "Max members must be at least 0": "Max members must be at least 0",
      "Max members must be greater than and equal to min members":
        "Max members must be greater than and equal to min members",
      "Min reservists must an integer": "Min reservists must an integer",
      "Min reservists  must be at least 0":
        "Min reservists  must be at least 0",
      "Max reservists must an integer": "Max reservists must an integer",
      "Max reservists must be at least 0": "Max reservists must be at least 0",
      "Max reservists must be greater than and equal to min reservists":
        "Max reservists must be greater than and equal to min reservists",
      "Min number of athletes  must an integer":
        "Min number of athletes  must an integer",
      "Min number of athletes  must be at least 0":
        "Min number of athletes  must be at least 0",
      "Max number of athletes must an integer":
        "Max number of athletes must an integer",
      "Max number of athletes must be at least 0":
        "Max number of athletes must be at least 0",
      "Max number of athletes must be greater than min number of athletes":
        "Max number of athletes must be greater than min number of athletes",
      "status is required": "status is required",
      "Category Updated Successfully!": "Category Updated Successfully!",
      "Category Created!": "Category Created!",
      "time period is required": "time period is required",
      "sort is required": "sort is required",
      "at-least-one": "at-least-one",
      "At least one category is required": "At least one category is required",
      "Value cannot be less than 1": "Value cannot be less than 1",
      "place is required": "place is required",
      "entries is required": "entries is required",
      "points is required": "points is required",
      "You can't remove this category because this is used into addtional rules":
        "You can't remove this category because this is used into addtional rules",
      "Rule added successfully": "Rule added successfully",
      "Rule updated successfully": "Rule updated successfully",
      "Points updated successfully": "Points updated successfully",
      "Points added successfully": "Points added successfully",
      "Removing...": "Removing...",
      "Removed successfully!": "Removed successfully!",
      "fee is required": "fee is required",
      "duration is required": "duration is required",
      "partner license fee required": "partner license fee required",
      "tournated license fee required": "tournated license fee required",
      "License updating ...": "License updating ...",
      "License updated Successfully!": "License updated Successfully!",
      "License creating ...": "License creating ...",
      "License created successfully": "License created successfully",
      deleteRankingsWarning:
        "Rankings for this date already exist. By updating, the old rankings for this date will be deleted. Do you want to proceed?",
      "Display athlete birth year in Participants": `Display athlete birth year in "Participants"`,
      "Display athlete club": "Display athlete club",
      "Display athlete city": "Display athlete city",
      "Display athlete birth year": "Display athlete birth year",
    },
  },
  la: {
    translation: {
      "Athlete Portal": "Sportista Portāls",
      Create: "Izveidot",
      "Your subscription": "Jūsu abonements",
      Notifications: "Paziņojumi",
      "Add League": "Pievienot Līgu",
      "Add Tournament": "Pievienot Turnīru",
      "Add Club": "Pievienot Klubu",
      "Add News": "Pievienot Jaunumus",
      "Add Team": "Pievienot Komandu",
      Home: "Sākums",
      Platforms: "Platformas",
      "Search Platform": "Meklēt Platformu",
      "ADD NEW Platform": "PIEVIENOT JAUNU Platformu",
      "Platform name": "Platformas nosaukums",
      "Platform image": "Platformas attēls",
      Country: "Valsts",
      Sport: "Sports",
      Type: "Tips",
      Organization: "Organizācija",
      Status: "Statuss",
      "There is no available platforms": "Nav pieejamas platformas",
      Dashboard: "Vadības panelis",
      Edit: "Rediģēt",
      New: "Jauns",
      "Add New Platform": "Pievienot Jaunu Platformu",
      "Edit Platforms": "Rediģēt Platformas",
      "Organization account": "Organizācijas konts",
      "Platform general": "Platformas vispārīgais",
      Appearance: "Izskats",
      "Company details": "Uzņēmuma detaļas",
      "Landing & Navigation": "Galvenā Lapa & Navigācija",
      Integrations: "Integrācijas",
      "Additional requests": "Papildu pieprasījumi",
      "Service fees": "Pakalpojumu maksas",
      "Update Organization Account": "Atjaunināt Organizācijas Kontu",
      "USER IS NOT IN THE LIST": "LIETOTĀJS NAV SARAKSTĀ",
      Name: "Vārds",
      "Enter name": "Ievadiet vārdu",
      Surname: "Uzvārds",
      "Enter surname": "Ievadiet uzvārdu",
      Email: "E-pasts",
      "Enter Email Address": "Ievadiet e-pasta adresi",
      "Change owner": "Mainīt īpašnieku",
      "Save new owner": "Saglabāt jauno īpašnieku",
      Password: "Parole",
      "Confirm Password": "Apstipriniet paroli",
      "Organization full name": "Organizācijas pilns nosaukums",
      "Enter Organization full name": "Ievadiet Organizācijas pilnu nosaukumu",
      "Organization abbreviations": "Organizācijas saīsinājumi",
      "Enter Organization abbreviation": "Ievadiet Organizācijas saīsinājumu",
      "Organization type": "Organizācijas tips",
      "About organization": "Par organizāciju",
      "Contact email": "Kontakta e-pasts",
      "Enter Contact Email": "Ievadiet kontakta e-pastu",
      "Contact phone number": "Kontakta tālruņa numurs",
      "Enter Contact phone number": "Ievadiet kontakta tālruņa numuru",
      Website: "Tīmekļa vietne",
      "Enter website": "Ievadiet tīmekļa vietni",
      "Instagram link": "Instagram saite",
      "Insert Instagram link": "Ievietojiet Instagram saiti",
      Continue: "Turpināt",
      "Platform General": "Platformas vispārīgais",
      "Platform title": "Platformas nosaukums",
      "Enter Platform Title": "Ievadiet Platformas nosaukumu",
      "Platform type": "Platformas tips",
      "First selected country will act as primary country":
        "Pirmā izvēlētā valsts darbosies kā galvenā valsts",
      "Select sports": "Izvēlieties sportu",
      "First selected sport will act as primary sport":
        "Pirmais izvēlētais sports darbosies kā galvenais sports",
      Sports: "Sporti",
      "Select discipline": "Izvēlieties disciplīnu",
      "Select third-party licence": "Izvēlieties trešās puses licenci",
      "third-party licence": "trešās puses licence",
      "No third-party license Found": "Nav atrasta trešās puses licence",
      "Select languages": "Izvēlieties valodas",
      "First selected language will act as primary language":
        "Pirmā izvēlētā valoda darbosies kā galvenā valoda",
      "Select language": "Izvēlieties valodu",
      Timezone: "Laika zona",
      "Time Zone": "Laika zona",
      Currency: "Valūta",
      "First selected currency will act as primary currency":
        "Pirmā izvēlētā valūta darbosies kā galvenā valūta",
      "Select currency": "Izvēlieties valūtu",
      Description: "Apraksts",
      "Do you have already your domain name?":
        "Vai jums jau ir jūsu domēna nosaukums?",
      "Enter your domain name": "Ievadiet savu domēna nosaukumu",
      Back: "Atpakaļ",
      Save: "Saglabāt",
      "Upload primary logo (svg or png)":
        "Augšupielādējiet galveno logotipu (svg vai png)",
      "Upload secondary logo (svg or png)":
        "Augšupielādējiet sekundāro logotipu (svg vai png)",
      "Upload cover picture": "Augšupielādējiet vāka attēlu",
      "No logo, you want us to make one for you":
        "Nav logotipa, vēlaties, lai mēs to izveidotu jums",
      "Click Here": "Noklikšķiniet Šeit",
      "Select primary color": "Izvēlieties galveno krāsu",
      "Select secondary color": "Izvēlieties sekundāro krāsu",
      "Company name": "Uzņēmuma nosaukums",
      "Enter Company Name": "Ievadiet uzņēmuma nosaukumu",
      "Registration Number": "Reģistrācijas numurs",
      "Enter Registration Number": "Ievadiet reģistrācijas numuru",
      "Full Address": "Pilna adrese",
      "Enter Full Address": "Ievadiet pilnu adresi",
      "Signee full name": "Parakstītāja pilns vārds",
      "Signee role": "Parakstītāja loma",
      "Contact Email": "Kontakta e-pasts",
      "Landing / Home Screen": "Galvenā lapa / Sākuma ekrāns",
      "Do you need landing?": "Vai jums nepieciešama galvenā lapa?",
      "Select Template": "Izvēlieties veidni",
      "No, I will use my own website as Landing page.":
        "Nē, es izmantos savu vietni kā galveno lapu.",
      "Do you need Integrations?": "Vai jums nepieciešamas integrācijas?",
      "Payment Service Provider": "Maksājumu pakalpojumu sniedzējs",
      "Make sure to get familiar with Stripe service fees":
        "Pārliecinieties, ka esat iepazinušies ar Stripe pakalpojumu maksām",
      "Additional services": "Papildu pakalpojumi",
      "Service Name": "Pakalpojuma nosaukums",
      Price: "Cena",
      "Mobile app": "Mobilā lietotne",
      SMM: "SMM",
      "Graphics for SMM": "Grafika SMM",
      "Your request": "Jūsu pieprasījums",
      "Type here your request": "Ierakstiet šeit savu pieprasījumu",
      "Upload file": "Augšupielādēt failu",
      "Entry service fee": "Ieejas pakalpojuma maksa",
      "Tournated entry service fee": "Turnīra ieejas pakalpojuma maksa",
      "Entery entery fee": "Ievadiet ieejas maksu",
      "Partner entry service fee": "Partnera ieejas pakalpojuma maksa",
      "Enter partner entry fee": "Ievadiet partnera ieejas maksu",
      "License service fee": "Licences pakalpojuma maksa",
      "Tournated license service fee": "Turnīra licences pakalpojuma maksa",
      "Enter tournated license fee": "Ievadiet turnīra licences maksu",
      "Partner license service fee": "Partnera licences pakalpojuma maksa",
      "Enter partner license fee": "Ievadiet partnera licences maksu",
      "Enable Pricing": "Iespējot cenas",
      Leagues: "Līgas",
      "Create new League": "Izveidot jaunu līgu",
      "There is no available leagues": "Nav pieejamas līgas",
      Completed: "Pabeigts",
      Upcoming: "Gaidāms",
      Active: "Aktīvs",
      Total: "Kopā",
      "Search Leagues": "Meklēt līgas",
      "League Title": "Līgas nosaukums",
      "League Type": "Līgas tips",
      Logo: "Logotips",
      Tournaments: "Turnīri",
      Athletes: "Sportisti",
      Regulations: "Noteikumi",
      Platform: "Platforma",
      "We recommend an image that is 256 x 256 pixels":
        "Mēs iesakām attēlu, kas ir 256 x 256 pikseļi",
      "League Abbreviation": "Līgas saīsinājums",
      "League owner": "Līgas īpašnieks",
      "Select Organization": "Izvēlieties organizāciju",
      "Select platform": "Izvēlieties platformu",
      "League Priority": "Līgas prioritāte",
      "Go to Next": "Pāriet uz nākamo",
      Phone: "Tālrunis",
      "League Website": "Līgas tīmekļa vietne",
      Instagram: "Instagram",
      "Field Title": "Lauka nosaukums",
      "Field Type": "Lauka tips",
      "Add New Point": "Pievienot jaunu punktu",
      "Title Name": "Nosaukuma vārds",
      Select: "Izvēlieties",
      "Field Value": "Lauka vērtība",
      "Add Another Option Below": "Pievienot citu opciju zemāk",
      Update: "Atjaunināt",
      Management: "Vadība",
      "League status": "Līgas statuss",
      "Select one": "Izvēlieties vienu",
      "Make this league featured": "Padarīt šo līgu izceltu",
      "Enable rankings": "Iespējot klasifikācijas",
      "enable ranking":
        "Izveidojiet pielāgotas klasifikācijas savai līgai, savienojot turnīru kategorijas, nosakot vecuma/dzimuma ierobežojumus un pievienojot papildu noteikumus.",
      "Enable online payments": "Iespējot tiešsaistes maksājumus",
      "enable online payment":
        "Sāciet pieņemt tiešsaistes maksājumus par turnīra pieteikumiem un licencēm. Pārliecinieties, ka esat iepazinies ar mūsu pakalpojumu maksas modeli tiešsaistes maksājumu pārvaldībai, pārbaudot to šeit. (noklikšķinot atveras modāls ar sīkākām informācijām atbilstoši mūsu cenrādim",
      pitchdeck: "piķis",
      "Enable licences": "Iespējot licences",
      "License description":
        "Konfigurējiet licences, cenas, periodus un noteikumus.",
      "Make club field mandatory and display club details as organizer on Tournament page":
        "Padariet kluba lauku obligātu un parādiet kluba detaļas kā organizatoru turnīra lapā",
      "Create League": "Izveidot līgu",
      "Organization Accounts": "Organizācijas konti",
      "ADD NEW ACCOUNT": "PIEVIENOT JAUNU KONTU",
      "There is no available organizations": "Nav pieejamu organizāciju",
      "Search Organizations": "Meklēt organizācijas",
      "Full name": "Pilns vārds",
      "Organization Abbrevation": "Organizācijas saīsinājums",
      "UPDATE USER PROFILE": "ATJAUNINĀT LIETOTĀJA PROFILU",
      "Enter phone number": "Ievadiet tālruņa numuru",
      "Organization Settings": "Organizācijas iestatījumi",
      "Organization Type": "Organizācijas veids",
      "Organization Logo": "Organizācijas logotips",
      "About Organization": "Par organizāciju",
      "Contact Details": "Kontakta informācija",
      Users: "Lietotāji",
      "Search User": "Meklēt lietotāju",
      "ADD NEW USER": "PIEVIENOT JAUNU LIETOTĀJU",
      Avatar: "Avatārs",
      "Phone Number": "Tālruņa numurs",
      Role: "Loma",
      "IPIN License": "IPIN licence",
      "DUPR License": "DUPR licence",
      "Tennis Europe License": "Tennis Europe licence",
      "LTS U10 License": "LTS U10 licence",
      "FIDE License": "FIDE licence",
      "Add new user": "Pievienot jaunu lietotāju",
      "Edit User": "Rediģēt lietotāju",
      "User Account": "Lietotāja konts",
      "Date of birth": "Dzimšanas datums",
      Gender: "Dzimums",
      "Representing country": "Pārstāvošā valsts",
      City: "Pilsēta",
      "Enter city": "Ievadiet pilsētu",
      Address: "Adrese",
      "Enter address": "Ievadiet adresi",
      Career: "Karjera",
      Club: "Klubs",
      "Select club": "Izvēlieties klubu",
      "Select coach": "Izvēlieties treneri",
      Parent: "Vecāks",
      "Select parent": "Izvēlieties vecāku",
      "Write a bio": "Uzrakstiet biogrāfiju",
      CHECKBOXES: "ATZĪMJU LOKI",
      "Give access to coach to purchase licences and manage entries on your behalf":
        "Dodiet trenerim piekļuvi iegādāties licences un pārvaldīt pieteikumus jūsu vārdā",
      "Give access to club manager(-es) to purchase licences and manage entries on your behalf":
        "Dodiet kluba menedžerim(-iem) piekļuvi iegādāties licences un pārvaldīt pieteikumus jūsu vārdā",
      "Give access to parent(-s) to purchase licences and manage entries on your behalf":
        "Dodiet vecākam(-iem) piekļuvi iegādāties licences un pārvaldīt pieteikumus jūsu vārdā",
      Socials: "Sociālie tīkli",
      "Insert link here": "Ievietojiet saiti šeit",
      Facebook: "Facebook",
      Linkedin: "Linkedin",
      Tiktok: "Tiktok",
      Youtube: "Youtube",
      Licence: "Licence",
      "ITF IPIN": "ITF IPIN",
      DUPRID: "DUPRID",
      "Tennis Europe ID": "Tennis Europe ID",
      "LTS U10": "LTS U10",
      ATP: "ATP",
      WTA: "WTA",
      FIDE: "FIDE",
      "Update password": "Atjaunināt paroli",
      "Re-Enter": "Ievadiet vēlreiz",
      "Login settings": "Pieteikšanās iestatījumi",
      "Switch Standard login to Social login":
        "Pārslēgt no standarta pieteikšanās uz sociālo pieteikšanos",
      "Connect Gmail": "Pievienot Gmail",
      "Enable 2FA Google Authorisation": "Iespējot 2FA Google autorizāciju",
      "Enable 2FA Email Authorisation": "Iespējot 2FA e-pasta autorizāciju",
      "Role Name": "Lomas nosaukums",
      "Is user verified?": "Vai lietotājs ir verificēts?",
      Yes: "Jā",
      No: "Nē",
      Tournament: "Turnīrs",
      "All tournaments": "Visi turnīri",
      "League tournaments": "Līgas turnīri",
      "Search Tournaments": "Meklēt turnīrus",
      "Create new Tournament": "Izveidot jaunu turnīru",
      "Tournament Title": "Turnīra nosaukums",
      "Start - End": "Sākums - Beigas",
      Organizer: "Organizators",
      Location: "Atrašanās vieta",
      "List of categories": "Kategoriju saraksts",
      "Update Status": "Atjaunināt statusu",
      "There is no available tournaments": "Nav pieejami turnīri",
      "ADD NEW SPORT": "PIEVIENOT JAUNU SPORTA VEIDU",
      "Search Sports": "Meklēt sporta veidus",
      "Sport Title": "Sporta veida nosaukums",
      "Add new Sport": "Pievienot jaunu sporta veidu",
      "Edit Sports": "Rediģēt sporta veidus",
      "Sports title": "Sporta veida nosaukums",
      "Enter title": "Ievadiet nosaukumu",
      "Sports abbreviation": "Sporta veida saīsinājums",
      "Enter abbreviation": "Ievadiet saīsinājumu",
      "Resource title": "Resursa nosaukums",
      "Rule how to add results/score":
        "Noteikums, kā pievienot rezultātus/punktus",
      Icon: "Ikona",
      "Enable disciplines": "Iespējot disciplīnas",
      "Enable courts": "Iespējot laukumus",
      "Enable stages": "Iespējot posmus",
      "Enable pairings": "Iespējot pāru izveidi",
      "Enable rounds": "Iespējot kārtas",
      "Enable standings based on pairings":
        "Iespējot klasifikācijas, balstoties uz pāriem",
      "Display draws": "Rādīt izlozes",
      "Display groups": "Rādīt grupas",
      "Display live standings": "Rādīt tiešraides klasifikācijas",
      "Display order of play": "Rādīt spēles secību",
      "Display list & results": "Rādīt sarakstu un rezultātus",
      "Display pending results": "Rādīt gaidāmos rezultātus",
      "Display final standings": "Rādīt gala klasifikācijas",
      "Display athlete chess titles in Participants": `Rādīt sportistu šaha titulus "Dalībniekos"`,
      "Display seeds column Participants": `Rādīt sēklu kolonnu "Dalībniekos"`,
      "Display segments column in Participants": `Rādīt segmentu kolonnu "Dalībniekos"`,
      "Upload file here": "Augšupielādējiet failu šeit",
      Disciplines: "Disciplīnas",
      "ADD NEW DISCIPLINE": "PIEVIENOT JAUNU DISCIPLĪNU",
      "There is no available disciplines": "Nav pieejamu disciplīnu",
      "Search Disciplines": "Meklēt disciplīnas",
      "Sport name": "Sporta veida nosaukums",
      "Add new Discipline": "Pievienot jaunu disciplīnu",
      "Edit Discipline": "Rediģēt disciplīnu",
      "Discipline title": "Disciplīnas nosaukums",
      "Select Sport": "Izvēlieties sporta veidu",
      "No sports found": "Sporta veidi nav atrasti",
      "Results type": "Rezultātu tips",
      "Third-party licences": "Trešās puses licences",
      "Third party licence": "Trešās puses licences",
      "There is no available third-party licences":
        "Nav pieejamu trešās puses licenču",
      "ADD NEW LICENCE": "PIEVIENOT JAUNU LICENCI",
      "Search license": "Meklēt licenci",
      "license name": "Licences nosaukums",
      "License type": "Licences veids",
      "Add licence": "Pievienot licenci",
      "Add Third-party licence": "Pievienot trešās puses licenci",
      "Edit Third-party licence": "Rediģēt trešās puses licenci",
      "Organization title": "Organizācijas nosaukums",
      "Organization abbreviation": "Organizācijas saīsinājums",
      "Licence title": "Licences nosaukums",
      "Enter license title": "Ievadiet licences nosaukumu",
      "Licence type": "Licences veids",
      "Request license in user profile": "Pieprasīt licenci lietotāja profilā",
      "Request license on user registration in platform":
        "Pieprasīt licenci lietotāja reģistrācijā platformā",
      "Make licence mandatory": "Padarīt licenci obligātu",
      "Link to get licence": "Saite, lai iegūtu licenci",
      "Enter link to get licence": "Ievadiet saiti, lai iegūtu licenci",
      Partners: "Partneri",
      "ADD NEW PARTNER": "PIEVIENOT JAUNU PARTNERI",
      "There is no available partners": "Nav pieejamu partneru",
      "Search Partners": "Meklēt partnerus",
      "Create new Partner": "Izveidot jaunu partneri",
      Link: "Saite",
      Text: "Teksts",
      Priority: "Prioritāte",
      "Showing dates": "Rādīt datumi",
      "New Partner": "Jauns partneris",
      "Edit Partner": "Rediģēt partneri",
      "Partner Logo": "Partnera logotips",
      platform: "platforma",
      "No platform available": "Nav pieejama platforma",
      "No league available": "Nav pieejama līga",
      "No Clubs available": "Nav pieejamu klubu",
      "Sponsor Text": "Sponsora teksts",
      "Date Settings": "Datuma iestatījumi",
      "Active From": "Aktīvs no",
      "Active To": "Aktīvs līdz",
      "Platform managers": "Platformas vadītāji",
      "ADD NEW MANAGER": "PIEVIENOT JAUNU VADĪTĀJU",
      "There is no available manager": "Nav pieejamu vadītāju",
      "Search Manager": "Meklēt vadītāju",
      "Export team": "Eksportēt komandu",
      "Export team emails": "Eksportēt komandas e-pastus",
      "Add platform manager": "Pievienot platformas vadītāju",
      "Edit platform panager": "Rediģēt platformas vadītāju",
      "Enter Organization": "Ievadiet organizāciju",
      "Select organization": "Izvēlieties organizāciju",
      "Filter users": "Filtrēt lietotājus",
      "Export users": "Eksportēt lietotājus",
      "Export user emails": "Eksportēt lietotāju e-pastus",
      "Age group": "Vecuma grupa",
      "There is no available users": "Nav pieejamu lietotāju",
      Coaches: "Treneri",
      "ADD NEW COACH": "PIEVIENOT JAUNU TRENERI",
      "There is no available account": "Nav pieejama konta",
      "Search Coach": "Meklēt treneri",
      "Add new coach": "Pievienot jaunu treneri",
      "Edit Coach": "Rediģēt treneri",
      "Coach image": "Trenera attēls",
      Category: "Kategorija",
      "There is no available sports": "Nav pieejamu sporta veidu",
      "there are not available clubs": "nav pieejamu klubu",
      "Date from": "Datums no",
      "Date to": "Datums līdz",
      "Display on organization's landing page": "Rādīt organizācijas mājaslapā",
      "Platform tournaments": "Platformas turnīri",
      Title: "Nosaukums",
      Posts: "Ziņas",
      "ADD POST": "PIEVIENOT ZIŅU",
      "There is no available post": "Nav pieejama ziņa",
      News: "Ziņas",
      "Search Post": "Meklēt ziņu",
      Image: "Attēls",
      Author: "Autors",
      Content: "Saturs",
      "Cover / featured image": "Vāka / izceltā attēls",
      "Select league": "Izvēlieties līgu",
      League: "Līga",
      "Add video from youtube.com": "Pievienot video no youtube.com",
      Date: "Datums",
      "Purchased Licenses": "Iegādātās licences",
      "Search Licenses": "Meklēt licences",
      "Create new License": "Izveidot jaunu licenci",
      "Athlete name & surname": "Sportista vārds un uzvārds",
      "License Name": "Licences nosaukums",
      "License Price": "Licences cena",
      "License active from": "Licence aktīva no",
      "License active to": "Licence aktīva līdz",
      "Paid(online/cash)": "Apmaksāts (tiešsaistē / skaidrā naudā)",
      "There is no available purchased license":
        "Nav pieejama iegādātā licence",
      "New License": "Jauna licence",
      "Edit License": "Rediģēt licenci",
      "Athlete Email": "Sportista e-pasts",
      License: "Licence",
      "No license found": "Licence nav atrasta",
      "Payment Method": "Maksāšanas veids",
      "How much paid": "Cik daudz samaksāts",
      Teams: "Komandas",
      "ADD NEW TEAM": "PIEVIENOT JAUNU KOMANDU",
      "There is no available teams": "Nav pieejamu komandu",
      "Search team": "Meklēt komandu",
      "Create new Team": "Izveidot jaunu komandu",
      "Team Members": "Komandas locekļi",
      Discipline: "Disciplīna",
      "New Team": "Jauna komanda",
      "Edit Team": "Rediģēt komandu",
      "Team Logo": "Komandas logotips",
      "No Clubs Found": "Klubi nav atrasti",
      "No disciplines found": "Disciplīnas nav atrastas",
      "Club status": "Kluba statuss",
      Members: "Locekļi",
      "Add new Members": "Pievienot jaunus locekļus",
      "Add New Member": "Pievienot jaunu locekli",
      "Member email": "Locekļa e-pasts",
      "Select Role": "Izvēlieties lomu",
      "Add more": "Pievienot vēl",
      "ADD NEW CLUB": "PIEVIENOT JAUNU KLUBU",
      "There is no available clubs": "Nav pieejamu klubu",
      Clubs: "Klubi",
      "Search Clubs": "Meklēt klubus",
      "Club Name": "Kluba nosaukums",
      "Add new Club": "Pievienot jaunu klubu",
      "Edit Club": "Rediģēt klubu",
      "Appearance & Location": "Izskats un atrašanās vieta",
      "Club title": "Kluba nosaukums",
      "Add club logo": "Pievienot kluba logotipu",
      "How to find us": "Kā mūs atrast",
      "Contact details": "Kontakta informācija",
      "Contact Phone": "Kontakta tālrunis",
      Twitter: "Twitter",
      "Services & Resources": "Pakalpojumi un resursi",
      "Select services": "Izvēlieties pakalpojumus",
      Services: "Pakalpojumi",
      Resources: "Resursi",
      "Resource Name": "Resursa nosaukums",
      Service: "Pakalpojums",
      "Add Resources": "Pievienot resursus",
      "Add New Resource": "Pievienot jaunu resursu",
      "Select Service": "Izvēlieties pakalpojumu",
      "Select type": "Izvēlieties veidu",
      "Add Another": "Pievienot vēl vienu",
      "Club page": "Kluba lapa",
      "Select Coaches": "Izvēlieties trenerus",
      "Athlete Club": "Sportista klubs",
      desc: "Kad jūsu klubs ir pievienots, sportisti varēs to izvēlēties savā sportista profila iestatījumu lapā, un tad viņi parādīsies kluba lapā, lai tiktu apstiprināti / noraidīti. Apstiprinātie locekļi tiks parādīti kluba lapā",
      "Online booking": "Tiešsaistes rezervācija",
      "Club Main Administrator": "Kluba galvenais administrators",
      "Main Adminitrator": "Galvenais administrators",
      "CLUB MAIN ADMINISTRATOR IS NOT IN THE LIST":
        "KLUBA GALVENAIS ADMINISTRATORS NAV SARAKSTĀ",
      Administrator: "Administrators",
      "ADMINISTRATOR IS NOT IN THE LIST": "ADMINISTRATORS NAV SARAKSTĀ",
      Banners: "Baneri",
      "There is no available banners": "Nav pieejamu baneru",
      "ADD BANNER": "PIEVIENOT BANERI",
      "Search Banner": "Meklēt baneri",
      Headline: "Virsraksts",
      subheadline: "apakšvirsraksts",
      "Button Text": "Pogas teksts",
      "New Banner": "Jauns baneris",
      "Edit Banner": "Rediģēt baneri",
      Subheadline: "Apakšvirsraksts",
      "Button text": "Pogas teksts",
      "Button link": "Pogas saite",
      "Select roles": "Izvēlieties lomas",
      "Banner images": "Banera attēli",
      "Control Panel": "Vadības panelis",
      "Run the tournament step by step from this control panel":
        "Vadiet turnīru soli pa solim no šī vadības paneļa",
      "Edit & Setup league": "Rediģēt un iestatīt līgu",
      "Edit Now": "Rediģēt tagad",
      "Create & Setup league": "Izveidot un iestatīt līgu",
      "Start Now": "Sākt tagad",
      "Add league members": "Pievienot līgas locekļus",
      "league member description":
        "Iekļaujiet līgas locekļus, piemēram, vadītājus, turnīru organizatorus un tiesnešus savai līgai. Vadītājiem būs pilna piekļuve panelim un visām vadības funkcijām, organizatori varēs pievienot turnīrus un tos pārvaldīt, un tiesneši varēs piekļūt pieteikumiem, izlozēm, grupām, tiešsaistes klasifikācijām, spēles kārtībai un turnīru rezultātu pārvaldībai, kurām viņi ir piešķirti.",
      "Add Now": "Pievienot tagad",
      "Add tournament categories": "Pievienot turnīru kategorijas",
      "tournament category description":
        "Iestatiet turnīru kategorijas savai līgai, norādot formātus (vienspēles/dubultspēles/komandas), vecuma/dzimuma ierobežojumus un piesaistot tos reitingu kategorijām (kad esat pievienojis šīs reitingu kategorijas nākamajā solī).",
      "Add rankings categories": "Pievienot reitingu kategorijas",
      "ranking category description":
        "Izveidojiet reitingu kategorijas savai līgai. Izvēlieties turnīru kategorijas, kuras iekļaut reitingos, norādiet, cik daudz turnīru iekļaut, iestatiet termiņu, saistiet ar starptautiskajām līgām un izveidojiet citus noteikumus.",
      "Setup ranking points": "Iestatīt reitinga punktus",
      "ranking description":
        "Konfigurējiet punktu tabulu katrai turnīru kategorijai, norādot punktu skaitu katrai vietai. Detalizētāka tabula vienkāršos un automatizēs procesu.",
      Setup: "Iestatīšana",
      "Setup licences": "Iestatīt licences",
      "Add tournament": "Pievienot turnīru",
      "tournament description":
        "Izpildiet soļus, lai izveidotu savu turnīru un sāktu tā pārvaldību. Esiet droši, jūs saņemsiet visaptverošu instrukciju sarakstu, kas aptver daudzās funkcijas, ko piedāvājam sporta pasākumu pārvaldībai",
      "Leagues Members": "Līgas locekļi",
      "Add new Member": "Pievienot jaunu locekli",
      "There is no available members": "Nav pieejamu locekļu",
      "League members": "Līgas locekļi",
      "Search Members": "Meklēt locekļus",
      "ADD NEW MEMBER": "PIEVIENOT JAUNU LOCEKLI",
      "Delete league member": "Dzēst līgas locekli",
      "Are you sure you want to delete?": "Vai tiešām vēlaties dzēst?",
      Cancel: "Atcelt",
      Delete: "Dzēst",
      "Tournament Categories": "Turnīru kategorijas",
      "There is no available categories": "Nav pieejamu kategoriju",
      "ADD NEW CATEGORY": "PIEVIENOT JAUNU KATEGORIJU",
      "Search Categories": "Meklēt kategorijas",
      "Copy Categories": "Kopēt kategorijas",
      "Delete Categories": "Dzēst kategorijas",
      "Category Name": "Kategorijas nosaukums",
      "Category Type": "Kategorijas veids",
      "Select League": "Izvēlieties līgu",
      Copy: "Kopēt",
      "Are you sure you want to delete these categories?":
        "Vai tiešām vēlaties dzēst šīs kategorijas?",
      "New Category": "Jauna kategorija",
      "Edit Category": "Rediģēt kategoriju",
      "Category name": "Kategorijas nosaukums",
      "Add team selection on tournament registration":
        "Pievienot komandas izvēli turnīra reģistrācijā",
      "Add club selection on tournament registration":
        "Pievienot kluba izvēli turnīra reģistrācijā",
      "Skills level": "Prasmju līmenis",
      "Category Gender": "Kategorijas dzimums",
      "Age from": "Vecums no",
      "Age to": "Vecums līdz",
      "Age Limit Type": "Vecuma ierobežojuma veids",
      "No Types Found!": "Veidi nav atrasti!",
      "Ask skills level on a tournament registration":
        "Jautāt par prasmju līmeni turnīra reģistrācijā",
      "Enable License": "Iespējot licenci",
      "Display athlete club in Participants":
        "Rādīt sportista klubu dalībniekos",
      "Display athlete city in Participants":
        "Rādīt sportista pilsētu dalībniekos",
      "Select License": "Izvēlieties licenci",
      "No License Found!": "Licence nav atrasta!",
      "For assigning points": "Punktu piešķiršanai",
      "Ranking Category for men": "Reitingu kategorija vīriešiem",
      "No Category Found!": "Kategorija nav atrasta!",
      "Ranking Category for Women": "Reitingu kategorija sievietēm",
      "Third-party license": "Trešās puses licence",
      "No third-party license Found!": "Trešās puses licence nav atrasta!",
      "Time control": "Laika kontrole",
      "No time control found": "Laika kontrole nav atrasta",
      "Order display for seeding": "Parādīšanas kārtība sēšanai",
      "Sport fishing settings": "Sporta makšķerēšanas iestatījumi",
      "Identify team members on registration":
        "Identificēt komandas locekļus reģistrācijā",
      "Min number of members": "Minimālais locekļu skaits",
      "Max number of members": "Maksimālais locekļu skaits",
      "Reservists required": "Nepieciešami rezervisti",
      "Min number of reservists": "Minimālais rezervistu skaits",
      "Max number of reservists": "Maksimālais rezervistu skaits",
      "Captain required": "Nepieciešams kapteinis",
      "Transfer members to singles category":
        "Pārnest locekļus uz vienspēļu kategoriju",
      "Select singles category": "Izvēlieties vienspēļu kategoriju",
      "No Category Found": "Kategorija nav atrasta",
      "National tournament (country vs country)":
        "Nacionālais turnīrs (valsts pret valsti)",
      "Club championships (club vs club)":
        "Klubu čempionāti (klubs pret klubu)",
      "Create matches from team members and count points result based on matches result":
        "Izveidot mačus no komandas locekļiem un saskaitīt punktu rezultātu, pamatojoties uz maču rezultātu",
      "Count individual total": "Skaitīt individuālo kopējo",
      "Count teams total": "Skaitīt komandu kopējo",
      "Number of athletes to count min": "Sportistu skaits, ko skaitīt, min",
      "Number of athletes to count max": "Sportistu skaits, ko skaitīt, max",
      "Max Entries per team": "Maksimālais pieteikumu skaits vienai komandai",
      "Allow registration without team": "Atļaut reģistrāciju bez komandas",
      "Category Status": "Kategorijas statuss",
      "Ranking Categories": "Reitingu kategorijas",
      "There is no available ranking categories":
        "Nav pieejamu reitingu kategoriju",
      Categories: "Kategorijas",
      "No of Tournaments": "Turnīru skaits",
      "Time Period": "Laika periods",
      "No Leagues found": "Līgas nav atrastas",
      "New Ranking Category": "Jauna reitingu kategorija",
      "Select Categories": "Izvēlieties kategorijas",
      "No Categories Found!": "Kategorijas nav atrastas!",
      "Max number of tournaments": "Maksimālais turnīru skaits",
      "Count specific tournaments": "Skaitīt konkrētus turnīrus",
      "Add penalty points for missing a tournament":
        "Pievienot sodapunktus par turnīra neapmeklēšanu",
      "Penalty rule": "Soda noteikums",
      "Enter points": "Ievadiet punktus",
      "Additional Rules": "Papildu noteikumi",
      Sector: "Sektors",
      Coefficient: "Koeficients",
      "No of tournaments": "Turnīru skaits",
      "Edit additional rule(s)": "Rediģēt papildu noteikumu(-s)",
      "Add additional rule(s)": "Pievienot papildu noteikumu(-s)",
      "During which period tournament points are counted":
        "Kurā periodā tiek skaitīti turnīru punkti",
      "Select Time Period": "Izvēlieties laika periodu",
      "Points sorting": "Punktu kārtošana",
      "Sport Fishing": "Sporta makšķerēšana",
      "Select Stage from which take points":
        "Izvēlieties posmu, no kura ņemt punktus",
      "Ranking Points": "Reitinga punkti",
      "Search Rankings": "Meklēt reitingus",
      "Add points block": "Pievienot punktu bloku",
      "Select / UnSelect All": "Izvēlēties / Neizvēlēties visu",
      Place: "Vieta",
      Points: "Punkti",
      "Bracket type": "Iekavveida tips",
      Segment: "Segments",
      "Num of Entries": "Pieteikumu skaits",
      "Ad.Rules": "Papildu noteikumi",
      "view rule": "skatīt noteikumu",
      "Add new row": "Pievienot jaunu rindu",
      "There is no available ranking points": "Nav pieejamu reitinga punktu",
      "Edit Points": "Rediģēt punktus",
      "Add Points": "Pievienot punktus",
      "Points block name": "Punktu bloka nosaukums",
      Specification: "Specifikācija",
      "Rule Settings": "Noteikumu iestatījumi",
      "Select operator": "Izvēlieties operatoru",
      "Select skills level": "Izvēlieties prasmju līmeni",
      "Number of players": "Spēlētāju skaits",
      "Number of entries": "Pieteikumu skaits",
      update: "atjaunināt",
      SAVE: "SAGLABĀT",
      DISCARD: "IZMEST",
      "SET NEW RULE": "IESTATĪT JAUNU NOTEIKUMU",
      "Edit Row": "Rediģēt rindu",
      "Add Row": "Pievienot rindu",
      ADD: "PIEVIENOT",
      "Copy Ranking Point": "Kopēt reitinga punktu",
      "Select Ranking Points": "Izvēlieties reitinga punktus",
      "Copy Ranking Point Blocks": "Kopēt reitinga punktu blokus",
      "Delete Ranking Point Block": "Dzēst reitinga punktu bloku",
      Licenses: "Licences",
      "There is no available licenses": "Nav pieejamu licenču",
      "Price Currency": "Cenas valūta",
      "Select Leagues": "Izvēlieties līgas",
      "No League Found!": "Līga nav atrasta!",
      "License price": "Licences cena",
      "License Type": "Licences veids",
      "License Status": "Licences statuss",
      "Additional questions": "Papildu jautājumi",
      "Add additional question": "Pievienot papildu jautājumu",
      "Edit this": "Rediģēt šo",
      "Add new": "Pievienot jaunu",
      question: "jautājums",
      Question: "Jautājums",
      "Field Option": "Lauka opcija",
      Remove: "Noņemt",
      "Add new option": "Pievienot jaunu opciju",
      "Ask this question to each athlete":
        "Jautāt šo jautājumu katram sportistam",
      Submit: "Iesniegt",
      "Delete Warning": "Dzēšanas brīdinājums",
      "Are you sure you want to delete this question?":
        "Vai tiešām vēlaties dzēst šo jautājumu?",
      "Confirm Deletion": "Apstiprināt dzēšanu",
      Rankings: "Reitingi",
      Search: "Meklēt",
      "ranking Category": "reitinga kategorija",
      "No date Found": "Datums nav atrasts",
      "Create Rankings": "Izveidot reitingus",
      "Update Rankings": "Atjaunināt reitingus",
      close: "aizvērt",
      Year: "Gads",
      "Points Details": "Punktu detaļas",
      "No points yet": "Vēl nav punktu",
      Position: "Pozīcija",
      Athlete: "Sportists",
      "There is no available rankings": "Nav pieejamu reitingu",
      "Select Ranking categories": "Izvēlieties reitinga kategorijas",
      "Remove Rankings": "Noņemt reitingus",
      "Select Date": "Izvēlieties datumu",
      "Are you sure you want to delete rankings?":
        "Vai tiešām vēlaties dzēst reitingus?",
      "Rankings History": "Reitingu vēsture",
      "Ranking Category": "Reitinga kategorija",
      "Last Updated": "Pēdējoreiz atjaunināts",
      Public: "Publisks",
      "Delete warning": "Dzēšanas brīdinājums",
      "Are you sure you want to delete this ranking?":
        "Vai tiešām vēlaties dzēst šo reitingu?",
      "There is no available rankings history": "Nav pieejama reitingu vēsture",
      "Tournament Results": "Turnīra rezultāti",
      Entry: "Ieeja",
      "Add new result": "Pievienot jaunu rezultātu",
      "There is no available tournament results":
        "Nav pieejamu turnīra rezultātu",
      "Pendding tournaments": "Gaidītie turnīri",
      "There is no available athletes": "Nav pieejamu sportistu",
      "Search Athletes": "Meklēt sportistus",
      "Country/Residency": "Valsts/Rezidence",
      "Super Data": "Super Dati",
      "All Tournaments": "Visi turnīri",
      Data: "Dati",
      "Pending Tournaments": "Gaidītie turnīri",
      Financials: "Finanses",
      Accounting: "Grāmatvedība",
      Extra: "Papildu",
      "Live scoring": "Tiešraides rezultāti",
      "Live stream": "Tiešraides straumēšana",
      "Platform Settings": "Platformas iestatījumi",
      "My Platforms": "Manas platformas",
      Team: "Komanda",
      Instructions: "Instrukcijas",
      "League Settings": "Līgas iestatījumi",
      "League Members": "Līgas locekļi",
      Upload: "Augšupielādēt",
      "primary logo": "primārais logotips",
      "secondary logo": "sekundārais logotips",
      "cover picture": "vāka attēls",
      "Start Typing": "Sāciet rakstīt",
      "Leagues list": "Līgu saraksts",
      "General & Appearance": "Vispārīgs un izskats",
      "Instagram Link": "Instagram saite",
      "Fact Sheet": "Faktu lapa",
      "Upload regulations": "Augšupielādēt noteikumus",
      "Organization Abbreviation": "Organizācijas saīsinājums",
      "Drag and Drop some file": "Velciet un nometiet failu",
      "here, or click to Select file":
        "šeit, vai klikšķiniet, lai izvēlētos failu",
      UPLOAD: "AUGŠUPIELĀDĒT",
      "DISCARD AND CANCEL": "ATTEIKTIES UN ATCELT",
      SUBMIT: "IESNIEGT",
      "Add new Organization Account": "Pievienot jaunu organizācijas kontu",
      "Edit Organization Account": "Rediģēt organizācijas kontu",
      "Full Name": "Pilns vārds",
      Biography: "Biogrāfija",
      "Add post": "Pievienot ierakstu",
      "Edit Post": "Rediģēt ierakstu",
      "Edit Member": "Rediģēt locekli",
      "Organization name": "Organizācijas nosaukums",
      "Organization email": "Organizācijas e-pasts",
      "Organization phone": "Organizācijas tālrunis",
      "Display member on organizations public team page":
        "Parādīt locekli organizācijas publiskajā komandas lapā",
      "Select Roles": "Izvēlieties lomas",
      "For assiging points": "Punktu piešķiršanai",
      "Fact sheet": "Faktu lapa",
      "Third party license": "Trešās puses licences",
      "Must be a valid email": "Jābūt derīgai e-pasta adresei",
      "Email is required": "E-pasts ir nepieciešams",
      "Organization Name is required":
        "Organizācijas nosaukums ir nepieciešams",
      "Organization Type is required": "Organizācijas tips ir nepieciešams",
      "organization abbreviation is required":
        "organizācijas saīsinājums ir nepieciešams",
      "Contact Email is required": "Kontaktpersonas e-pasts ir nepieciešams",
      "Password is Required": "Parole ir nepieciešama",
      "Password should be 8 chars minimum.":
        "Parolei jābūt vismaz 8 rakstzīmēm.",
      "Passwords must match": "Parolēm jāsakrīt",
      "company name is required": "uzņēmuma nosaukums ir nepieciešams",
      "company number is required": "uzņēmuma numurs ir nepieciešams",
      "full address is required": "pilna adrese ir nepieciešama",
      "Invalid email address": "Nederīga e-pasta adrese",
      "signee role is required": "parakstītāja loma ir nepieciešama",
      "signee full name is required":
        "parakstītāja pilns vārds ir nepieciešams",
      "partner entry fee is required":
        "partnera ieceļošanas maksa ir nepieciešama",
      "tournated license fee is required": "tournated license fee is required",
      "partner license fee is required":
        "partnera licences maksa ir nepieciešama",
      "Creating platform...": "Veido platformu...",
      "Platform created successfully": "Platforma veiksmīgi izveidota",
      "Updating...": "Atjaunināšana...",
      "Platform updated": "Platforma atjaunināta",
      "Add New Club": "Pievienot jaunu klubu",
      "organization is required": "organizācija ir nepieciešama",
      "Adding new fact...": "Pievienojot jaunu faktu...",
      "Fact added successfully": "Fakts veiksmīgi pievienots",
      "Deleting fact...": "Dzēšot faktu...",
      "fact deleted successfully": "fakts veiksmīgi izdzēsts",
      "Updating fact...": "Atjauninot faktu...",
      "Fact updated successfully": "Fakts veiksmīgi atjaunināts",
      "Creating league...": "Veidojot līgu...",
      "League created successfully": "Līga veiksmīgi izveidota",
      "Updating league...": "Atjauninot līgu...",
      "League updated successfully": "Līga veiksmīgi atjaunināta",
      "Approved & Public": "Apstiprināts un publisks",
      "ATP points": "ATP punkti",
      "WTA points": "WTA punkti",
      "name Email is required": "vārds E-pasts ir nepieciešams",
      "title is require": "nosaukums ir nepieciešams",
      "platform type required": "platformas tips ir nepieciešams",
      "time zone is required": "laika zona ir nepieciešama",
      "description is required": "apraksts ir nepieciešams",
      "title is required": "nosaukums ir nepieciešams",
      "abbreviation is required": "saīsinājums ir nepieciešams",
      "platform is required": "platforma ir nepieciešama",
      "priority is required": "prioritāte ir nepieciešama",
      "type is required": "tips ir nepieciešams",
      "sports is required": "sports ir nepieciešams",
      "email is required": "e-pasts ir nepieciešams",
      "Organization added Successfully!": "Organizācija veiksmīgi pievienota!",
      "name is required": "vārds ir nepieciešams",
      "surname is required": "uzvārds ir nepieciešams",
      "organization name is required":
        "organizācijas nosaukums ir nepieciešams",
      "organization type is required": "organizācijas tips ir nepieciešams",
      "Organization Account Updated Successfully!":
        "Organizācijas konts veiksmīgi atjaunināts!",
      "Creating organization...": "Veidojot organizāciju...",
      "Organization created successfully": "Organizācija veiksmīgi izveidota",
      "This User is already exist": "Šis lietotājs jau pastāv",
      "Password is required": "Parole ir nepieciešama",
      "Re-Enter password is required":
        "Atkārtota paroles ievade ir nepieciešama",
      "Password updated": "Parole atjaunināta",
      "User Updated": "Lietotājs atjaunināts",
      "Sports Updated": "Sports atjaunināts",
      "Sports Created": "Sports izveidots",
      "Discipline Updated": "Disciplīna atjaunināta",
      "Discipline Created": "Disciplīna izveidota",
      "At least one sport must be selected": "Jāizvēlas vismaz viens sports",
      "License Updated": "Licence atjaunināta",
      "License Created": "Licence izveidota",
      "Please provide Image": "Lūdzu, nodrošiniet attēlu",
      "Partner updated Successfully!": "Partneris veiksmīgi atjaunināts!",
      "Partner created successfully!": "Partneris veiksmīgi izveidots!",
      "Please add correct user email":
        "Lūdzu, pievienojiet pareizu lietotāja e-pastu",
      "Coach added!": "Treneris pievienots!",
      "user found": "lietotājs atrasts",
      "user not found": "lietotājs nav atrasts",
      "country is required": "valsts ir nepieciešama",
      "gender is required": "dzimums ir nepieciešams",
      "confirm password is required": "paroles apstiprināšana ir nepieciešama",
      "Coache Updated Successfully!": "Treneris veiksmīgi atjaunināts!",
      "Image field is required": "Attēla lauks ir nepieciešams",
      "author is required": "autors ir nepieciešams",
      "Updating ...": "Atjaunināšana ...",
      "Post updated successfully": "Ziņa veiksmīgi atjaunināta",
      "Creating post ...": "Veidojot ziņu ...",
      "Post created successfully": "Ziņa veiksmīgi izveidota",
      "User found": "Lietotājs atrasts",
      "User not found": "Lietotājs nav atrasts",
      "paid is required": "samaksa ir nepieciešama",
      "currency is required": "valūta ir nepieciešama",
      "Athlete is required": "Sportists ir nepieciešams",
      "license is required": "licence ir nepieciešama",
      "start date is required": "sākuma datums ir nepieciešams",
      "end date is required": "beigu datums ir nepieciešams",
      "License created successfully!": "Licence veiksmīgi izveidota!",
      "sport is required": "sports ir nepieciešams",
      "discipline is required": "disciplīna ir nepieciešama",
      "Team updated successfully!": "Komanda veiksmīgi atjaunināta!",
      "Team created successfully!": "Komanda veiksmīgi izveidota!",
      "city is required": "pilsēta ir nepieciešama",
      "address is required": "adrese ir nepieciešama",
      "headline is required": "virsraksts ir nepieciešams",
      "subheadline is required": "apakšvirsraksts ir nepieciešams",
      "button text required": "pogas teksts ir nepieciešams",
      "button link required": "pogas saite ir nepieciešama",
      "Image are required": "Attēli ir nepieciešami",
      "Updating banner...": "Atjauninot baneri...",
      "Banner updated successfully": "Baneris veiksmīgi atjaunināts",
      "Creating banner...": "Veidojot baneri...",
      "Banner created successfully": "Baneris veiksmīgi izveidots",
      "League member added!": "Līgas dalībnieks pievienots!",
      "League Member Updated Successfully!":
        "Līgas dalībnieks veiksmīgi atjaunināts!",
      "order is required": "pasūtījums ir nepieciešams",
      "Age from must an integer": "Vecumam no jābūt veselam skaitlim",
      "Age must be at least 0": "Vecumam jābūt vismaz 0",
      "Age to must an integer": "Vecumam līdz jābūt veselam skaitlim",
      "Min members must an integer":
        "Minimālajam dalībnieku skaitam jābūt veselam skaitlim",
      "Min members must be at least 0":
        "Minimālajam dalībnieku skaitam jābūt vismaz 0",
      "Max members must an integer":
        "Maksimālajam dalībnieku skaitam jābūt veselam skaitlim",
      "Max members must be at least 0":
        "Maksimālajam dalībnieku skaitam jābūt vismaz 0",
      "Max members must be greater than and equal to min members":
        "Maksimālajam dalībnieku skaitam jābūt lielākam vai vienādam ar minimālo dalībnieku skaitu",
      "Min reservists must an integer":
        "Minimālajam rezervistu skaitam jābūt veselam skaitlim",
      "Min reservists must be at least 0":
        "Minimālajam rezervistu skaitam jābūt vismaz 0",
      "Max reservists must an integer":
        "Maksimālajam rezervistu skaitam jābūt veselam skaitlim",
      "Max reservists must be at least 0":
        "Maksimālajam rezervistu skaitam jābūt vismaz 0",
      "Max reservists must be greater than and equal to min reservists":
        "Maksimālajam rezervistu skaitam jābūt lielākam vai vienādam ar minimālo rezervistu skaitu",
      "Min number of athletes must an integer":
        "Minimālajam sportistu skaitam jābūt veselam skaitlim",
      "Min number of athletes must be at least 0":
        "Minimālajam sportistu skaitam jābūt vismaz 0",
      "Max number of athletes must an integer":
        "Maksimālajam sportistu skaitam jābūt veselam skaitlim",
      "Max number of athletes must be at least 0":
        "Maksimālajam sportistu skaitam jābūt vismaz 0",
      "Max number of athletes must be greater than min number of athletes":
        "Maksimālajam sportistu skaitam jābūt lielākam par minimālo sportistu skaitu",
      "status is required": "statuss ir nepieciešams",
      "Category Updated Successfully!": "Kategorija veiksmīgi atjaunināta!",
      "Category Created!": "Kategorija izveidota!",
      "time period is required": "laika periods ir nepieciešams",
      "sort is required": "kārtība ir nepieciešama",
      "at-least-one": "vismaz viens",
      "At least one category is required":
        "Nepieciešama vismaz viena kategorija",
      "Value cannot be less than 1": "Vērtība nevar būt mazāka par 1",
      "place is required": "vieta ir nepieciešama",
      "entries is required": "ieraksti ir nepieciešami",
      "points is required": "punkti ir nepieciešami",
      "You can't remove this category because this is used into additional rules":
        "Jūs nevarat noņemt šo kategoriju, jo tā tiek izmantota papildu noteikumos",
      "Rule added successfully": "Noteikums veiksmīgi pievienots",
      "Rule updated successfully": "Noteikums veiksmīgi atjaunināts",
      "Points updated successfully": "Punkti veiksmīgi atjaunināti",
      "Points added successfully": "Punkti veiksmīgi pievienoti",
      "Removing...": "Noņemšana...",
      "Removed successfully!": "Veiksmīgi noņemts!",
      "fee is required": "maksa ir nepieciešama",
      "duration is required": "ilgums ir nepieciešams",
      "partner license fee required": "partnera licences maksa ir nepieciešama",
      "tournated license fee required": "tournated license fee ir nepieciešama",
      "License updating ...": "Licences atjaunināšana ...",
      "License updated Successfully!": "Licence veiksmīgi atjaunināta!",
      "License creating ...": "Licences veidošana ...",
      "License created successfully": "Licence veiksmīgi izveidota",
      deleteRankingsWarning:
        "Reitings šim datumam jau pastāv. Atjauninot, vecie reitingi šim datumam tiks dzēsti. Vai vēlaties turpināt?",
      "Display athlete birth year in Participants": `Rādīt sportista dzimšanas gadu "Dalībniekos"`,
      "Display athlete club": "Rādīt sportista klubu",
      "Display athlete city": "Rādīt sportista pilsētu",
      "Display athlete birth year": "Rādīt sportista dzimšanas gadu",
    },
  },
  esp: {
    translation: {
      "Athlete Portal": "Portal del Atleta",
      Create: "Crear",
      "Your subscription": "Tu suscripción",
      Notifications: "Notificaciones",
      "Add League": "Agregar Liga",
      "Add Tournament": "Agregar Torneo",
      "Add Club": "Agregar Club",
      "Add News": "Agregar Noticias",
      "Add Team": "Agregar Equipo",
      Home: "Inicio",
      Platforms: "Plataformas",
      "Search Platform": "Buscar Plataforma",
      "ADD NEW Platform": "AGREGAR Nueva Plataforma",
      "Platform name": "Nombre de la Plataforma",
      "Platform image": "Imagen de la Plataforma",
      Country: "País",
      Sport: "Deporte",
      Type: "Tipo",
      Organization: "Organización",
      Status: "Estado",
      "There is no available platforms": "No hay plataformas disponibles",
      Dashboard: "Tablero",
      Edit: "Editar",
      New: "Nuevo",
      "Add New Platform": "Agregar Nueva Plataforma",
      "Edit Platforms": "Editar Plataformas",
      "Organization account": "Cuenta de la Organización",
      "Platform general": "General de la Plataforma",
      Appearance: "Apariencia",
      "Company details": "Detalles de la Empresa",
      "Landing & Navigation": "Aterrizaje y Navegación",
      Integrations: "Integraciones",
      "Additional requests": "Solicitudes Adicionales",
      "Service fees": "Tarifas de Servicio",
      "Update Organization Account": "Actualizar Cuenta de la Organización",
      "USER IS NOT IN THE LIST": "EL USUARIO NO ESTÁ EN LA LISTA",
      Name: "Nombre",
      "Enter name": "Ingrese nombre",
      Surname: "Apellido",
      "Enter surname": "Ingrese apellido",
      Email: "Correo electrónico",
      "Enter Email Address": "Ingrese dirección de correo electrónico",
      "Change owner": "Cambiar propietario",
      "Save new owner": "Guardar nuevo propietario",
      Password: "Contraseña",
      "Confirm Password": "Confirmar contraseña",
      "Organization full name": "Nombre completo de la Organización",
      "Enter Organization full name":
        "Ingrese nombre completo de la organización",
      "Organization abbreviations": "Abreviaturas de la Organización",
      "Enter Organization abbreviation":
        "Ingrese abreviatura de la organización",
      "Organization type": "Tipo de Organización",
      "About organization": "Acerca de la organización",
      "Contact email": "Correo electrónico de contacto",
      "Enter Contact Email": "Ingrese correo electrónico de contacto",
      "Contact phone number": "Número de teléfono de contacto",
      "Enter Contact phone number": "Ingrese número de teléfono de contacto",
      Website: "Sitio web",
      "Enter website": "Ingrese sitio web",
      "Instagram link": "Enlace de Instagram",
      "Insert Instagram link": "Insertar enlace de Instagram",
      Continue: "Continuar",
      "Platform General": "General de la Plataforma",
      "Platform title": "Título de la Plataforma",
      "Enter Platform Title": "Ingrese Título de la Plataforma",
      "Platform type": "Tipo de Plataforma",
      "First selected country will act as primary country":
        "El primer país seleccionado actuará como país principal",
      "Select sports": "Seleccionar deportes",
      "First selected sport will act as primary sport":
        "El primer deporte seleccionado actuará como deporte principal",
      Sports: "Deportes",
      "Select discipline": "Seleccionar disciplina",
      "Select third-party licence": "Seleccionar licencia de terceros",
      "third-party licence": "Licencia de terceros",
      "No third-party license Found": "No se encontró licencia de terceros",
      "Select languages": "Seleccionar idiomas",
      "First selected language will act as primary language":
        "El primer idioma seleccionado actuará como idioma principal",
      "Select language": "Seleccionar idioma",
      Timezone: "Zona horaria",
      "Time Zone": "Zona horaria",
      Currency: "Moneda",
      "First selected currency will act as primary currency":
        "La primera moneda seleccionada actuará como moneda principal",
      "Select currency": "Seleccionar moneda",
      Description: "Descripción",
      "Do you have already your domain name?":
        "¿Ya tienes tu nombre de dominio?",
      "Enter your domain name": "Ingresa tu nombre de dominio",
      Back: "Volver",
      Save: "Guardar",
      "Upload primary logo (svg or png)": "Cargar logo principal (svg o png)",
      "Upload secondary logo (svg or png)":
        "Cargar logo secundario (svg o png)",
      "Upload cover picture": "Cargar imagen de portada",
      "No logo, you want us to make one for you":
        "Sin logo, ¿quieres que hagamos uno para ti?",
      "Click Here": "Haz clic aquí",
      "Select primary color": "Seleccionar color primario",
      "Select secondary color": "Seleccionar color secundario",
      "Company name": "Nombre de la empresa",
      "Enter Company Name": "Ingrese nombre de la empresa",
      "Registration Number": "Número de registro",
      "Enter Registration Number": "Ingrese número de registro",
      "Full Address": "Dirección completa",
      "Enter Full Address": "Ingrese dirección completa",
      "Signee full name": "Nombre completo del firmante",
      "Signee role": "Rol del firmante",
      "Contact Email": "Correo electrónico de contacto",
      "Landing / Home Screen": "Pantalla de Aterrizaje / Inicio",
      "Do you need landing?": "¿Necesitas aterrizaje?",
      "Select Template": "Seleccionar Plantilla",
      "No, I will use my own website as Landing page.":
        "No, usaré mi propio sitio web como página de aterrizaje.",
      "Do you need Integrations?": "¿Necesitas integraciones?",
      "Payment Service Provider": "Proveedor de Servicios de Pago",
      "Make sure to get familiar with Stripe service fees":
        "Asegúrate de familiarizarte con las tarifas de servicio de Stripe",
      "Additional services": "Servicios adicionales",
      "Service Name": "Nombre del Servicio",
      Price: "Precio",
      "Mobile app": "Aplicación móvil",
      SMM: "SMM",
      "Graphics for SMM": "Gráficos para SMM",
      "Your request": "Tu solicitud",
      "Type here your request": "Escribe aquí tu solicitud",
      "Upload file": "Subir archivo",
      "Entry service fee": "Tarifa de servicio de entrada",
      "Tournated entry service fee": "Tarifa de servicio de entrada de torneo",
      "Entery entery fee": "Tarifa de entrada",
      "Partner entry service fee": "Tarifa de servicio de entrada para socios",
      "Enter partner entry fee": "Ingrese tarifa de entrada para socios",
      "License service fee": "Tarifa de servicio de licencia",
      "Tournated license service fee":
        "Tarifa de servicio de licencia de torneo",
      "Enter tournated license fee": "Ingrese tarifa de licencia de torneo",
      "Partner license service fee":
        "Tarifa de servicio de licencia para socios",
      "Enter partner license fee": "Ingrese tarifa de licencia para socios",
      "Enable Pricing": "Activar Precios",
      Leagues: "Ligas",
      "Create new League": "Crear nueva Liga",
      "There is no available leagues": "No hay ligas disponibles",
      Completed: "Completado",
      Upcoming: "Próximo",
      Active: "Activo",
      Total: "Total",
      "Search Leagues": "Buscar Ligas",
      "League Title": "Título de la Liga",
      "League Type": "Tipo de Liga",
      Logo: "Logo",
      Tournaments: "Torneos",
      Athletes: "Atletas",
      Regulations: "Reglamentos",
      Platform: "Plataforma",
      "We recommend an image that is 256 x 256 pixels":
        "Recomendamos una imagen de 256 x 256 píxeles",
      "League Abbreviation": "Abreviatura de la Liga",
      "League owner": "Propietario de la Liga",
      "Select Organization": "Seleccionar Organización",
      "Select platform": "Seleccionar plataforma",
      "League Priority": "Prioridad de la Liga",
      "Go to Next": "Ir al Siguiente",
      Phone: "Teléfono",
      "League Website": "Sitio web de la Liga",
      Instagram: "Instagram",
      "Field Title": "Título del Campo",
      "Field Type": "Tipo de Campo",
      "Add New Point": "Agregar Nuevo Punto",
      "Title Name": "Nombre del Título",
      Select: "Seleccionar",
      "Field Value": "Valor del Campo",
      "Add Another Option Below": "Agregar Otra Opción Abajo",
      Update: "Actualizar",
      Management: "Gestión",
      "League status": "Estado de la Liga",
      "Select one": "Seleccionar uno",
      "Make this league featured": "Destacar esta liga",
      "Enable rankings": "Activar clasificaciones",
      "enable ranking":
        "Crea clasificaciones personalizadas para tu liga conectando categorías de torneos, definiendo limitaciones de edad/género y agregando reglas adicionales.",
      "Enable online payments": "Activar pagos en línea",
      "enable online payment":
        "Comienza a aceptar pagos en línea para inscripciones y licencias de torneos. Asegúrate de familiarizarte con nuestro modelo de tarifas de servicio para administrar pagos en línea consultándolo aquí. (hacer clic abre un modal con detalles según nuestro pitchdeck en",
      pitchdeck: "pitchdeck",
      "Enable licences": "Activar licencias",
      "License description":
        "Configurar licencias, precios, períodos y reglas.",
      "Make club field mandatory and display club details as organizer on Tournament page":
        "Hacer obligatorio el campo del club y mostrar detalles del club como organizador en la página del torneo",
      "Create League": "Crear Liga",
      "Organization Accounts": "Cuentas de Organización",
      "ADD NEW ACCOUNT": "AGREGAR NUEVA CUENTA",
      "There is no available organizations":
        "No hay organizaciones disponibles",
      "Search Organizations": "Buscar Organizaciones",
      "Full name": "Nombre completo",
      "Organization Abbrevation": "Abreviatura de la organización",
      "UPDATE USER PROFILE": "ACTUALIZAR PERFIL DE USUARIO",
      "Enter phone number": "Ingrese número de teléfono",
      "Organization Settings": "Configuración de la organización",
      "Organization Type": "Tipo de organización",
      "Organization Logo": "Logo de la organización",
      "About Organization": "Acerca de la organización",
      "Contact Details": "Detalles de contacto",
      Users: "Usuarios",
      "Search User": "Buscar usuario",
      "ADD NEW USER": "AGREGAR NUEVO USUARIO",
      Avatar: "Avatar",
      "Phone Number": "Número de teléfono",
      Role: "Rol",
      "IPIN License": "Licencia IPIN",
      "DUPR License": "Licencia DUPR",
      "Tennis Europe License": "Licencia Tennis Europe",
      "LTS U10 License": "Licencia LTS U10",
      "FIDE License": "Licencia FIDE",
      "Add new user": "Agregar nuevo usuario",
      "Edit User": "Editar usuario",
      "User Account": "Cuenta de usuario",
      "Date of birth": "Fecha de nacimiento",
      Gender: "Género",
      "Representing country": "País que representa",
      City: "Ciudad",
      "Enter city": "Ingrese ciudad",
      Address: "Dirección",
      "Enter address": "Ingrese dirección",
      Career: "Carrera",
      Club: "Club",
      "Select club": "Seleccionar club",
      "Select coach": "Seleccionar entrenador",
      Parent: "Padre",
      "Select parent": "Seleccionar padre",
      "Write a bio": "Escribir una biografía",
      CHECKBOXES: "CASILLAS DE VERIFICACIÓN",
      "Give access to coach to purchase licences and manage entries on your behalf":
        "Permitir acceso al entrenador para comprar licencias y gestionar inscripciones en tu nombre",
      "Give access to club manager(-es) to purchase licences and manage entries on your behalf":
        "Dar acceso al manager del club para comprar licencias y gestionar inscripciones en tu nombre",
      "Give access to parent(-s) to purchase licences and manage entries on your behalf":
        "Dar acceso a los padres para comprar licencias y gestionar inscripciones en tu nombre",
      Socials: "Redes sociales",
      "Insert link here": "Insertar enlace aquí",
      Facebook: "Facebook",
      Linkedin: "Linkedin",
      Tiktok: "Tiktok",
      Youtube: "Youtube",
      Licence: "Licencia",
      "ITF IPIN": "ITF IPIN",
      DUPRID: "DUPRID",
      "Tennis Europe ID": "ID de Tennis Europe",
      "LTS U10": "LTS U10",
      ATP: "ATP",
      WTA: "WTA",
      FIDE: "FIDE",
      "Update password": "Actualizar contraseña",
      "Re-Enter": "Re-ingresar",
      "Login settings": "Configuraciones de inicio de sesión",
      "Switch Standard login to Social login":
        "Cambiar inicio de sesión estándar a inicio de sesión social",
      "Connect Gmail": "Conectar Gmail",
      "Enable 2FA Google Authorisation": "Activar autorización 2FA de Google",
      "Enable 2FA Email Authorisation":
        "Activar autorización 2FA por correo electrónico",
      "Role Name": "Nombre del rol",
      "Is user verified?": "¿Usuario verificado?",
      Yes: "Sí",
      No: "No",
      Tournament: "Torneo",
      "All tournaments": "Todos los torneos",
      "League tournaments": "Torneos de la liga",
      "Search Tournaments": "Buscar torneos",
      "Create new Tournament": "Crear nuevo torneo",
      "Tournament Title": "Título del torneo",
      "Start - End": "Inicio - Fin",
      Organizer: "Organizador",
      Location: "Ubicación",
      "List of categories": "Lista de categorías",
      "Update Status": "Actualizar estado",
      "There is no available tournaments": "No hay torneos disponibles",
      "ADD NEW SPORT": "AGREGAR NUEVO DEPORTE",
      "Search Sports": "Buscar deportes",
      "Sport Title": "Título del deporte",
      "Add new Sport": "Agregar nuevo deporte",
      "Edit Sports": "Editar deportes",
      "Sports title": "Título del deporte",
      "Enter title": "Ingrese título",
      "Sports abbreviation": "Abreviatura del deporte",
      "Enter abbreviation": "Ingrese abreviatura",
      "Resource title": "Título del recurso",
      "Rule how to add results/score":
        "Regla de cómo agregar resultados/puntuación",
      Icon: "Ícono",
      "Enable disciplines": "Activar disciplinas",
      "Enable courts": "Activar canchas",
      "Enable stages": "Activar etapas",
      "Enable pairings": "Activar emparejamientos",
      "Enable rounds": "Activar rondas",
      "Enable standings based on pairings":
        "Activar clasificaciones basadas en emparejamientos",
      "Display draws": "Mostrar sorteos",
      "Display groups": "Mostrar grupos",
      "Display live standings": "Mostrar clasificaciones en vivo",
      "Display order of play": "Mostrar orden de juego",
      "Display list & results": "Mostrar lista y resultados",
      "Display pending results": "Mostrar resultados pendientes",
      "Display final standings": "Mostrar clasificaciones finales",
      "Display athlete chess titles in Participants":
        "Mostrar títulos de ajedrez de los atletas en 'Participantes'",
      "Display seeds column Participants":
        "Mostrar columna de semillas en 'Participantes'",
      "Display segments column in Participants":
        "Mostrar columna de segmentos en 'Participantes'",
      "Upload file here": "Cargar archivo aquí",
      Disciplines: "Disciplinas",
      "ADD NEW DISCIPLINE": "AGREGAR NUEVA DISCIPLINA",
      "There is no available disciplines": "No hay disciplinas disponibles",
      "Search Disciplines": "Buscar disciplinas",
      "Sport name": "Nombre del deporte",
      "Add new Discipline": "Agregar nueva disciplina",
      "Edit Discipline": "Editar disciplina",
      "Discipline title": "Título de la disciplina",
      "Select Sport": "Seleccionar deporte",
      "No sports found": "No se encontraron deportes",
      "Results type": "Tipo de resultados",
      "Third-party licences": "Licencias de terceros",
      "Third party licence": "Licencias de terceros",
      "There is no available third-party licences":
        "No hay licencias de terceros disponibles",
      "ADD NEW LICENCE": "AGREGAR NUEVA LICENCIA",
      "Search license": "Buscar licencia",
      "license name": "Nombre de la licencia",
      "License type": "Tipo de licencia",
      "Add licence": "Agregar licencia",
      "Add Third-party licence": "Agregar licencia de terceros",
      "Edit Third-party licence": "Editar licencia de terceros",
      "Organization title": "Título de la organización",
      "Organization abbreviation": "Abreviatura de la organización",
      "Licence title": "Título de la licencia",
      "Enter license title": "Ingrese título de la licencia",
      "Licence type": "Tipo de licencia",
      "Request license in user profile":
        "Solicitar licencia en el perfil de usuario",
      "Request license on user registration in platform":
        "Solicitar licencia en el registro de usuario en la plataforma",
      "Make licence mandatory": "Hacer obligatoria la licencia",
      "Link to get licence": "Enlace para obtener la licencia",
      "Enter link to get licence": "Ingrese enlace para obtener la licencia",
      Partners: "Socios",
      "ADD NEW PARTNER": "AGREGAR NUEVO SOCIO",
      "There is no available partners": "No hay socios disponibles",
      "Search Partners": "Buscar socios",
      "Create new Partner": "Crear nuevo socio",
      Link: "Enlace",
      Text: "Texto",
      Priority: "Prioridad",
      "Showing dates": "Mostrando fechas",
      "New Partner": "Nuevo socio",
      "Edit Partner": "Editar socio",
      "Partner Logo": "Logo del socio",
      platform: "plataforma",
      "No platform available": "No hay plataforma disponible",
      "No league available": "No hay liga disponible",
      "No Clubs available": "No hay clubes disponibles",
      "Sponsor Text": "Texto del patrocinador",
      "Date Settings": "Configuraciones de fecha",
      "Active From": "Activo desde",
      "Active To": "Activo hasta",
      "Platform managers": "Administradores de la plataforma",
      "ADD NEW MANAGER": "AGREGAR NUEVO ADMINISTRADOR",
      "There is no available manager": "No hay administradores disponibles",
      "Search Manager": "Buscar administrador",
      "Export team": "Exportar equipo",
      "Export team emails": "Exportar correos electrónicos del equipo",
      "Add platform manager": "Agregar administrador de plataforma",
      "Edit platform panager": "Editar administrador de plataforma",
      "Enter Organization": "Ingrese Organización",
      "Select organization": "Seleccionar organización",
      "Filter users": "Filtrar usuarios",
      "Export users": "Exportar usuarios",
      "Export user emails": "Exportar correos electrónicos de usuarios",
      "Age group": "Grupo de edad",
      "There is no available users": "No hay usuarios disponibles",
      Coaches: "Entrenadores",
      "ADD NEW COACH": "AGREGAR NUEVO ENTRENADOR",
      "There is no available account": "No hay cuenta disponible",
      "Search Coach": "Buscar entrenador",
      "Add new coach": "Agregar nuevo entrenador",
      "Edit Coach": "Editar entrenador",
      "Coach image": "Imagen del entrenador",
      Category: "Categoría",
      "There is no available sports": "No hay deportes disponibles",
      "there are not available clubs": "no hay disponibles clubs",
      "Date from": "Fecha desde",
      "Date to": "Fecha hasta",
      "Display on organization's landing page":
        "Mostrar en la página de inicio de la organización",
      "Platform tournaments": "Torneos de la plataforma",
      Title: "Título",
      Posts: "Publicaciones",
      "ADD POST": "AGREGAR PUBLICACIÓN",
      "There is no available post": "No hay publicaciones disponibles",
      News: "Noticias",
      "Search Post": "Buscar publicación",
      Image: "Imagen",
      Author: "Autor",
      Content: "Contenido",
      "Cover / featured image": "Imagen de portada / destacada",
      "Select league": "Seleccionar liga",
      League: "Liga",
      "Add video from youtube.com": "Agregar vídeo de youtube.com",
      Date: "Fecha",
      "Purchased Licenses": "Licencias compradas",
      "Search Licenses": "Buscar licencias",
      "Create new License": "Crear nueva licencia",
      "Athlete name & surname": "Nombre y apellido del atleta",
      "License Name": "Nombre de la licencia",
      "License Price": "Precio de la licencia",
      "License active from": "Licencia activa desde",
      "License active to": "Licencia activa hasta",
      "Paid(online/cash)": "Pagado (online/efectivo)",
      "There is no available purchased license":
        "No hay licencias compradas disponibles",
      "New License": "Nueva licencia",
      "Edit License": "Editar licencia",
      "Athlete Email": "Correo electrónico del atleta",
      License: "Licencia",
      "No license found": "No se encontró la licencia",
      "Payment Method": "Método de pago",
      "How much paid": "Cuánto pagado",
      Teams: "Equipos",
      "ADD NEW TEAM": "AGREGAR NUEVO EQUIPO",
      "There is no available teams": "No hay equipos disponibles",
      "Search team": "Buscar equipo",
      "Create new Team": "Crear nuevo equipo",
      "Team Members": "Miembros del equipo",
      Discipline: "Disciplina",
      "New Team": "Nuevo equipo",
      "Edit Team": "Editar equipo",
      "Team Logo": "Logo del equipo",
      "No Clubs Found": "No se encontraron clubes",
      "No disciplines found": "No se encontraron disciplinas",
      "Club status": "Estado del club",
      Members: "Miembros",
      "Add new Members": "Agregar nuevos miembros",
      "Add New Member": "Agregar nuevo miembro",
      "Member email": "Correo electrónico del miembro",
      "Select Role": "Seleccionar rol",
      "Add more": "Agregar más",
      "ADD NEW CLUB": "AGREGAR NUEVO CLUB",
      "There is no available clubs": "No hay clubes disponibles",
      Clubs: "Clubes",
      "Search Clubs": "Buscar clubes",
      "Club Name": "Nombre del club",
      "Add new Club": "Agregar nuevo club",
      "Edit Club": "Editar club",
      "Appearance & Location": "Apariencia y ubicación",
      "Club title": "Título del club",
      "Add club logo": "Agregar logo del club",
      "How to find us": "Cómo encontrarnos",
      "Contact details": "Detalles de contacto",
      "Contact Phone": "Teléfono de contacto",
      Twitter: "Twitter",
      "Services & Resources": "Servicios y recursos",
      "Select services": "Seleccionar servicios",
      Services: "Servicios",
      Resources: "Recursos",
      "Resource Name": "Nombre del recurso",
      Service: "Servicio",
      "Add Resources": "Agregar recursos",
      "Add New Resource": "Agregar nuevo recurso",
      "Select Service": "Seleccionar servicio",
      "Select type": "Seleccionar tipo",
      "Add Another": "Agregar otro",
      "Club page": "Página del club",
      "Select Coaches": "Seleccionar entrenadores",
      "Athlete Club": "Club del atleta",
      desc: "Una vez que se agregue tu club, los atletas podrán seleccionarlo en su página de configuración de perfil de atleta y luego aparecerán en una página de club para ser aprobados/rechazados. Los miembros aprobados se mostrarán en la página del club",
      "Online booking": "Reserva online",
      "Club Main Administrator": "Administrador principal del club",
      "Main Adminitrator": "Administrador principal",
      "CLUB MAIN ADMINISTRATOR IS NOT IN THE LIST":
        "EL ADMINISTRADOR PRINCIPAL DEL CLUB NO ESTÁ EN LA LISTA",
      Administrator: "Administrador",
      "ADMINISTRATOR IS NOT IN THE LIST":
        "EL ADMINISTRADOR NO ESTÁ EN LA LISTA",
      Banners: "Banners",
      "There is no available banners": "No hay banners disponibles",
      "ADD BANNER": "AGREGAR BANNER",
      "Search Banner": "Buscar banner",
      Headline: "Titular",
      subheadline: "Subtítulo",
      "Button Text": "Texto del botón",
      "New Banner": "Nuevo banner",
      "Edit Banner": "Editar banner",
      Subheadline: "Subtítulo",
      "Button text": "Texto del botón",
      "Button link": "Enlace del botón",
      "Select roles": "Seleccionar roles",
      "Banner images": "Imágenes del banner",
      "Control Panel": "Panel de control",
      "Run the tournament step by step from this control panel":
        "Ejecutar el torneo paso a paso desde este panel de control",
      "Edit & Setup league": "Editar y configurar la liga",
      "Edit Now": "Editar ahora",
      "Create & Setup league": "Crear y configurar la liga",
      "Start Now": "Comenzar ahora",
      "Add league members": "Agregar miembros de la liga",
      "league member description":
        "Incluye miembros de la liga como administradores, organizadores de torneos y árbitros para tu liga. Los administradores tendrán acceso completo al panel de control y todas las funciones de gestión, los organizadores podrán agregar torneos y gestionarlos, y los árbitros tendrán acceso a inscripciones, sorteos, grupos, clasificaciones en vivo, orden de juego y gestión de resultados para los torneos a los que estén asignados.",
      "Add Now": "Agregar ahora",
      "Add tournament categories": "Agregar categorías de torneo",
      "tournament category description":
        "Configura las categorías de torneo para tu liga, especificando formatos (individuales/dobles/equipos), limitaciones de edad/género y conectándolas con categorías de ranking (una vez que hayas agregado esas categorías de ranking en el siguiente paso).",
      "Add rankings categories": "Agregar categorías de rankings",
      "ranking category description":
        "Establece categorías de ranking para tu liga. Elige las categorías de torneos para incluir en los rankings, especifica cuántos torneos contar, establece el marco de tiempo, conéctate con ligas internacionales y establece otras reglas.",
      "Setup ranking points": "Configurar puntos de ranking",
      "ranking description":
        "Configura la tabla de puntos para cada categoría de torneo, especificando la cantidad de puntos para cada posición. Una tabla más detallada optimizará y automatizará el proceso.",
      Setup: "Configuración",
      "Setup licences": "Configurar licencias",
      "Add tournament": "Agregar torneo",
      "tournament description":
        "Sigue los pasos para crear tu torneo y comenzar la gestión de tu evento. Ten la seguridad de que recibirás una lista completa de instrucciones que cubren las numerosas características que ofrecemos para la gestión de eventos deportivos.",
      "Leagues Members": "Miembros de la liga",
      "Add new Member": "Agregar nuevo miembro",
      "There is no available members": "No hay miembros disponibles",
      "League members": "Miembros de la liga",
      "Search Members": "Buscar miembros",
      "ADD NEW MEMBER": "AGREGAR NUEVO MIEMBRO",
      "Delete league member": "Eliminar miembro de la liga",
      "Are you sure you want to delete?":
        "¿Estás seguro de que quieres eliminar?",
      Cancel: "Cancelar",
      Delete: "Eliminar",
      "Tournament Categories": "Categorías de torneo",
      "There is no available categories": "No hay categorías disponibles",
      "ADD NEW CATEGORY": "AGREGAR NUEVA CATEGORÍA",
      "Search Categories": "Buscar categorías",
      "Copy Categories": "Copiar categorías",
      "Delete Categories": "Eliminar categorías",
      "Category Name": "Nombre de la categoría",
      "Category Type": "Tipo de categoría",
      "Select League": "Seleccionar liga",
      Copy: "Copiar",
      "Are you sure you want to delete these categories?":
        "¿Estás seguro de que quieres eliminar estas categorías?",
      "New Category": "Nueva categoría",
      "Edit Category": "Editar categoría",
      "Category name": "Nombre de la categoría",
      "Add team selection on tournament registration":
        "Agregar selección de equipo en el registro del torneo",

      "Add club selection on tournament registration":
        "Agregar selección de club en el registro del torneo",
      "Skills level": "Nivel de habilidades",
      "Category Gender": "Género de la categoría",
      "Age from": "Edad desde",
      "Age to": "Edad hasta",
      "Age Limit Type": "Tipo de límite de edad",
      "No Types Found!": "¡No se encontraron tipos!",
      "Ask skills level on a tournament registration":
        "Preguntar nivel de habilidades en el registro del torneo",
      "Enable License": "Activar licencia",
      "Display athlete club in Participants": `Mostrar club del atleta en "Participantes"`,
      "Display athlete city in Participants": `Mostrar ciudad del atleta en "Participantes"`,
      "Select License": "Seleccionar licencia",
      "No License Found!": "¡No se encontró la licencia!",
      "Ranking Category for men": "Categoría de ranking para hombres",
      "No Category Found!": "¡No se encontró la categoría!",
      "Ranking Category for Women": "Categoría de ranking para mujeres",
      "Third-party license": "Licencia de terceros",
      "No third-party license Found!": "¡No se encontró licencia de terceros!",
      "Time control": "Control de tiempo",
      "No time control found": "No se encontró control de tiempo",
      "Order display for seeding": "Orden de visualización para siembra",
      "Sport fishing settings": "Configuraciones de pesca deportiva",
      "Identify team members on registration":
        "Identificar miembros del equipo en el registro",
      "Min number of members": "Número mínimo de miembros",
      "Max number of members": "Número máximo de miembros",
      "Reservists required": "Reservistas requeridos",
      "Min number of reservists": "Número mínimo de reservistas",
      "Max number of reservists": "Número máximo de reservistas",
      "Captain required": "Capitán requerido",
      "Transfer members to singles category":
        "Transferir miembros a categoría individual",
      "Select singles category": "Seleccionar categoría individual",
      "No Category Found": "No se encontró categoría",
      "National tournament (country vs country)":
        "Torneo nacional (país contra país)",
      "Club championships (club vs club)":
        "Campeonatos de club (club contra club)",
      "Create matches from team members and count points result based on matches result":
        "Crear partidos a partir de miembros del equipo y contar puntos basados en los resultados de los partidos",
      "Count individual total": "Contar total individual",
      "Count teams total": "Contar total de equipos",
      "Number of athletes to count min": "Número mínimo de atletas para contar",
      "Number of athletes to count max": "Número máximo de atletas para contar",
      "Max Entries per team": "Entradas máximas por equipo",
      "Allow registration without team": "Permitir registro sin equipo",
      "Category Status": "Estado de la categoría",
      "Ranking Categories": "Categorías de ranking",
      "There is no available ranking categories":
        "No hay categorías de ranking disponibles",
      Categories: "Categorías",
      "No of Tournaments": "Número de torneos",
      "Time Period": "Período de tiempo",
      "No Leagues found": "No se encontraron ligas",
      "New Ranking Category": "Nueva categoría de ranking",
      "Select Categories": "Seleccionar categorías",
      "No Categories Found!": "¡No se encontraron categorías!",
      "Max number of tournaments": "Número máximo de torneos",
      "Count specific tournaments": "Contar torneos específicos",
      "Add penalty points for missing a tournament":
        "Agregar puntos de penalización por falta a un torneo",
      "Penalty rule": "Regla de penalización",
      "Enter points": "Ingresar puntos",
      "Additional Rules": "Reglas adicionales",
      Sector: "Sector",
      Coefficient: "Coeficiente",
      "No of tournaments": "Número de torneos",
      "Edit additional rule(s)": "Editar regla(s) adicional(es)",
      "Add additional rule(s)": "Agregar regla(s) adicional(es)",
      "During which period tournament points are counted":
        "Durante qué período se cuentan los puntos del torneo",
      "Select Time Period": "Seleccionar período de tiempo",
      "Points sorting": "Ordenación de puntos",
      "Sport Fishing": "Pesca deportiva",
      "Select Stage form which take points":
        "Seleccionar etapa de la cual tomar puntos",
      "Ranking Points": "Puntos de ranking",
      "Search Rankings": "Buscar rankings",
      "Add points block": "Agregar bloque de puntos",
      "Select / UnSelect All": "Seleccionar / Deseleccionar todo",
      Place: "Lugar",
      Points: "Puntos",
      "Bracket type": "Tipo de cuadro",
      Segment: "Segmento",
      "Num of Entries": "Núm. de entradas",
      "Ad.Rules": "Reglas adicionales",
      "view rule": "ver regla",
      "Add new row": "Agregar nueva fila",
      "There is no available ranking points":
        "No hay puntos de ranking disponibles",
      "Edit Points": "Editar Puntos",
      "Add Points": "Agregar Puntos",
      "Points block name": "Nombre del bloque de puntos",
      Specification: "Especificación",
      "Rule Settings": "Configuración de reglas",
      "Select operator": "Seleccionar operador",
      "Select skills level": "Seleccionar nivel de habilidades",
      "Number of players": "Número de jugadores",
      "Number of entries": "Número de entradas",
      update: "actualizar",
      SAVE: "GUARDAR",
      DISCARD: "DESCARTAR",
      "SET NEW RULE": "ESTABLECER NUEVA REGLA",
      "Edit Row": "Editar Fila",
      "Add Row": "Agregar Fila",
      ADD: "AGREGAR",
      "Copy Ranking Point": "Copiar Punto de Ranking",
      "Select Ranking Points": "Seleccionar Puntos de Ranking",
      "Copy Ranking Point Blocks": "Copiar Bloques de Puntos de Ranking",
      "Delete Ranking Point Block": "Eliminar Bloque de Puntos de Ranking",
      Licenses: "Licencias",
      "There is no available licenses": "No hay licencias disponibles",
      "Price Currency": "Moneda de precio",
      "Select Leagues": "Seleccionar Ligas",
      "No League Found!": "¡No se encontró liga!",
      "License price": "Precio de la licencia",
      "License Type": "Tipo de licencia",
      "License Status": "Estado de la licencia",
      "Additional questions": "Preguntas adicionales",
      "Add additional question": "Agregar pregunta adicional",
      "Edit this": "Editar esto",
      "Add new": "Agregar nuevo",
      question: "pregunta",
      Question: "Pregunta",
      "Field Option": "Opción de campo",
      Remove: "Eliminar",
      "Add new option": "Agregar nueva opción",
      "Ask this question to each athlete": "Hacer esta pregunta a cada atleta",
      Submit: "Enviar",
      "Delete Warning": "Advertencia de eliminación",
      "Are you sure you want to delete this question?":
        "¿Estás seguro de que quieres eliminar esta pregunta?",
      "Confirm Deletion": "Confirmar Eliminación",
      Rankings: "Rankings",
      Search: "Buscar",
      "ranking Category": "Categoría de ranking",
      "No date Found": "No se encontró fecha",
      "Create Rankings": "Crear Rankings",
      "Update Rankings": "Actualizar Rankings",
      close: "cerrar",
      Year: "Año",
      "Points Details": "Detalles de puntos",
      "No points yet": "Aún no hay puntos",
      Position: "Posición",
      Athlete: "Atleta",
      "There is no available rankings": "No hay rankings disponibles",
      "Select Ranking categories": "Seleccionar categorías de ranking",
      "Remove Rankings": "Eliminar Rankings",
      "Select Date": "Seleccionar Fecha",
      "Are you sure you want to delete rankings?":
        "¿Estás seguro de que quieres eliminar los rankings?",
      "Rankings History": "Historial de Rankings",
      "Ranking Category": "Categoría de Ranking",
      "Last Updated": "Última Actualización",
      Public: "Público",
      "Delete warning": "Advertencia de eliminación",
      "Are you sure you want to delete this ranking?":
        "¿Estás seguro de que quieres eliminar este ranking?",
      "There is no available rankings history":
        "No hay historial de rankings disponible",
      "Tournament Results": "Resultados del Torneo",
      Entry: "Entrada",
      "Add new result": "Agregar nuevo resultado",
      "There is no available tournament results":
        "No hay resultados de torneo disponibles",
      "Pendding tournaments": "Torneos pendientes",
      "There is no available athletes": "No hay atletas disponibles",
      "Search Athletes": "Buscar Atletas",
      "Country/Residency": "País/Residencia",
      "Super Data": "Super Datos",
      "All Tournaments": "Todos los Torneos",
      Data: "Datos",
      "Pending Tournaments": "Torneos Pendientes",
      Financials: "Finanzas",
      Accounting: "Contabilidad",
      Extra: "Extra",
      "Live scoring": "Puntuación en vivo",
      "Live stream": "Transmisión en vivo",
      "Platform Settings": "Configuraciones de Plataforma",
      "My Platforms": "Mis Plataformas",
      Team: "Equipo",
      Instructions: "Instrucciones",
      "League Settings": "Configuraciones de Liga",
      "League Members": "Miembros de la Liga",
      Upload: "Subir",
      "primary logo": "logo principal",
      "secondary logo": "logo secundario",
      "cover picture": "imagen de portada",
      "Start Typing": "Comenzar a escribir",
      "Leagues list": "Lista de ligas",
      "General & Appearance": "General y apariencia",
      "Instagram Link": "Enlace de Instagram",
      "Fact Sheet": "Hoja informativa",
      "Fact sheet": "Hoja informativa",
      "Upload regulations": "Subir regulaciones",
      "Organization Abbreviation": "Abreviatura de la organización",
      "Drag and Drop some file": "Arrastra y suelta algún archivo",
      "here, or click to Select file":
        "aquí, o haz clic para seleccionar archivo",
      UPLOAD: "SUBIR",
      "DISCARD AND CANCEL": "DESCARTAR Y CANCELAR",
      SUBMIT: "ENVIAR",
      "Add new Organization Account": "Agregar nueva cuenta de organización",
      "Edit Organization Account": "Editar cuenta de organización",
      "Full Name": "Nombre completo",
      Biography: "Biografía",
      "Add post": "Agregar publicación",
      "Edit Post": "Editar publicación",
      "Edit Member": "Editar miembro",
      "Organization name": "Nombre de la organización",
      "Organization email": "Correo electrónico de la organización",
      "Organization phone": "Teléfono de la organización",
      "Display member on organizations public team page":
        "Mostrar miembro en la página pública del equipo de la organización",
      "Select Roles": "Seleccionar roles",
      "For assiging points": "Para asignar puntos",
      "Third party license": "Licencia de terceros",
      "Must be a valid email": "Debe ser un correo electrónico válido",
      "Email is required": "Correo electrónico es obligatorio",
      "Organization Name is required":
        "Nombre de la organización es obligatorio",
      "Organization Type is required": "Tipo de organización es obligatorio",
      "organization abbreviation is required":
        "abreviatura de la organización es obligatoria",
      "Contact Email is required":
        "Correo electrónico de contacto es obligatorio",
      "Password is Required": "Contraseña es obligatoria",
      "Password should be 8 chars minimum.":
        "La contraseña debe tener al menos 8 caracteres.",
      "Passwords must match": "Las contraseñas deben coincidir",
      "company name is required": "nombre de la empresa es obligatorio",
      "company number is required": "número de la empresa es obligatorio",
      "full address is required": "dirección completa es obligatoria",
      "Invalid email address": "Dirección de correo electrónico no válida",
      "signee role is required": "rol del firmante es obligatorio",
      "signee full name is required":
        "nombre completo del firmante es obligatorio",
      "partner entry fee is required":
        "tarifa de entrada del socio es obligatoria",
      "tournated license fee is required":
        "tarifa de licencia de torneo es obligatoria",
      "partner license fee is required":
        "tarifa de licencia del socio es obligatoria",
      "Creating platform...": "Creando plataforma...",
      "Platform created successfully": "Plataforma creada con éxito",
      "Updating...": "Actualizando...",
      "Platform updated": "Plataforma actualizada",
      "Add New Club": "Añadir Nuevo Club",
      "organization is required": "organización es obligatoria",
      "Adding new fact...": "Añadiendo nuevo hecho...",
      "Fact added successfully": "Hecho añadido con éxito",
      "Deleting fact...": "Eliminando hecho...",
      "fact deleted successfully": "hecho eliminado con éxito",
      "Updating fact...": "Actualizando hecho...",
      "Fact updated successfully": "Hecho actualizado con éxito",
      "Creating league...": "Creando liga...",
      "League created successfully": "Liga creada con éxito",
      "Updating league...": "Actualizando liga...",
      "League updated successfully": "Liga actualizada con éxito",
      "Approved & Public": "Aprobado y Público",
      "ATP points": "Puntos ATP",
      "WTA points": "Puntos WTA",
      "name Email is required": "nombre Correo electrónico es obligatorio",
      "title is require": "título es obligatorio",
      "platform type required": "tipo de plataforma es obligatorio",
      "time zone is required": "zona horaria es obligatoria",
      "description is required": "descripción es obligatoria",
      "title is required": "título es obligatorio",
      "abbreviation is required": "abreviatura es obligatoria",
      "platform is required": "plataforma es obligatoria",
      "priority is required": "prioridad es obligatoria",
      "type is required": "tipo es obligatorio",
      "sports is required": "deportes es obligatorio",
      "email is required": "correo electrónico es obligatorio",
      "Organization added Successfully!": "¡Organización añadida con éxito!",
      "name is required": "nombre es obligatorio",
      "surname is required": "apellido es obligatorio",
      "organization name is required":
        "nombre de la organización es obligatorio",
      "organization type is required": "tipo de organización es obligatorio",
      "Organization Account Updated Successfully!":
        "¡Cuenta de la organización actualizada con éxito!",
      "Creating organization...": "Creando organización...",
      "Organization created successfully": "Organización creada con éxito",
      "This User is already exist": "Este usuario ya existe",
      "Password is required": "Contraseña es obligatoria",
      "Re-Enter password is required": "Reingresar contraseña es obligatorio",
      "Password updated": "Contraseña actualizada",
      "User Updated": "Usuario actualizado",
      "Sports Updated": "Deportes actualizados",
      "Sports Created": "Deportes creados",
      "Discipline Updated": "Disciplina actualizada",
      "Discipline Created": "Disciplina creada",
      "At least one sport must be selected":
        "Se debe seleccionar al menos un deporte",
      "License Updated": "Licencia actualizada",
      "License Created": "Licencia creada",
      "Please provide Image": "Por favor proporcione una imagen",
      "Partner updated Successfully!": "¡Socio actualizado con éxito!",
      "Partner created successfully!": "¡Socio creado con éxito!",
      "Please add correct user email":
        "Por favor agregue un correo electrónico de usuario correcto",
      "Coach added!": "¡Entrenador añadido!",
      "user found": "usuario encontrado",
      "user not found": "usuario no encontrado",
      "country is required": "país es obligatorio",
      "gender is required": "género es obligatorio",
      "confirm password is required": "confirmar contraseña es obligatorio",
      "Coache Updated Successfully!": "¡Entrenador actualizado con éxito!",
      "Image field is required": "Campo de imagen es obligatorio",
      "author is required": "autor es obligatorio",
      "Updating ...": "Actualizando ...",
      "Post updated successfully": "Publicación actualizada con éxito",
      "Creating post ...": "Creando publicación ...",
      "Post created successfully": "Publicación creada con éxito",
      "User found": "Usuario encontrado",
      "User not found": "Usuario no encontrado",
      "paid is required": "pago es obligatorio",
      "currency is required": "moneda es obligatoria",
      "Athlete is required": "Atleta es obligatorio",
      "license is required": "licencia es obligatoria",
      "start date is required": "fecha de inicio es obligatoria",
      "end date is required": "fecha de finalización es obligatoria",
      "License created successfully!": "¡Licencia creada con éxito!",
      "sport is required": "deporte es obligatorio",
      "discipline is required": "disciplina es obligatoria",
      "Team updated successfully!": "¡Equipo actualizado con éxito!",
      "Team created successfully!": "¡Equipo creado con éxito!",
      "city is required": "ciudad es obligatoria",
      "address is required": "dirección es obligatoria",
      "headline is required": "titular es obligatorio",
      "subheadline is required": "subtítulo es obligatorio",
      "button text required": "texto del botón es obligatorio",
      "button link required": "enlace del botón es obligatorio",
      "Image are required": "Imágenes son obligatorias",
      "Updating banner...": "Actualizando banner...",
      "Banner updated successfully": "Banner actualizado con éxito",
      "Creating banner...": "Creando banner...",
      "Banner created successfully": "Banner creado con éxito",
      "League member added!": "¡Miembro de la liga añadido!",
      "League Member Updated Successfully!":
        "¡Miembro de la liga actualizado con éxito!",
      "order is required": "orden es obligatoria",
      "Age from must an integer": "Edad desde debe ser un entero",
      "Age must be at least 0": "La edad debe ser al menos 0",
      "Age to must an integer": "Edad hasta debe ser un entero",
      "Min members must an integer": "Mínimo de miembros debe ser un entero",
      "Min members must be at least 0":
        "Mínimo de miembros debe ser al menos 0",
      "Max members must an integer": "Máximo de miembros debe ser un entero",
      "Max members must be at least 0":
        "Máximo de miembros debe ser al menos 0",
      "Max members must be greater than and equal to min members":
        "Máximo de miembros debe ser mayor o igual al mínimo de miembros",
      "Min reservists must an integer":
        "Mínimo de reservistas debe ser un entero",
      "Min reservists must be at least 0":
        "Mínimo de reservistas debe ser al menos 0",
      "Max reservists must an integer":
        "Máximo de reservistas debe ser un entero",
      "Max reservists must be at least 0":
        "Máximo de reservistas debe ser al menos 0",
      "Max reservists must be greater than and equal to min reservists":
        "Máximo de reservistas debe ser mayor o igual al mínimo de reservistas",
      "Min number of athletes must an integer":
        "Mínimo de atletas debe ser un entero",
      "Min number of athletes must be at least 0":
        "Mínimo de atletas debe ser al menos 0",
      "Max number of athletes must an integer":
        "Máximo de atletas debe ser un entero",
      "Max number of athletes must be at least 0":
        "Máximo de atletas debe ser al menos 0",
      "Max number of athletes must be greater than min number of athletes":
        "Máximo de atletas debe ser mayor que el mínimo de atletas",
      "status is required": "estado es obligatorio",
      "Category Updated Successfully!": "¡Categoría actualizada con éxito!",
      "Category Created!": "¡Categoría creada!",
      "time period is required": "periodo de tiempo es obligatorio",
      "sort is required": "orden es obligatoria",
      "at-least-one": "al menos uno",
      "At least one category is required": "Se requiere al menos una categoría",
      "Value cannot be less than 1": "El valor no puede ser menor a 1",
      "place is required": "lugar es obligatorio",
      "entries is required": "entradas son obligatorias",
      "points is required": "puntos son obligatorios",
      "You can't remove this category because this is used into additional rules":
        "No puede eliminar esta categoría porque se usa en reglas adicionales",
      "Rule added successfully": "Regla añadida con éxito",
      "Rule updated successfully": "Regla actualizada con éxito",
      "Points updated successfully": "Puntos actualizados con éxito",
      "Points added successfully": "Puntos añadidos con éxito",
      "Removing...": "Eliminando...",
      "Removed successfully!": "¡Eliminado con éxito!",
      "fee is required": "tarifa es obligatoria",
      "duration is required": "duración es obligatoria",
      "partner license fee required":
        "tarifa de licencia del socio es obligatoria",
      "tournated license fee required":
        "tarifa de licencia de torneo es obligatoria",
      "License updating ...": "Actualizando licencia ...",
      "License updated Successfully!": "¡Licencia actualizada con éxito!",
      "License creating ...": "Creando licencia ...",
      "License created successfully": "Licencia creada con éxito",
      deleteRankingsWarning:
        "Ya existen clasificaciones para esta fecha. Al actualizar, se eliminarán las clasificaciones antiguas para esta fecha. ¿Quieres continuar?",
      "Display athlete birth year in Participants": `Mostrar año de nacimiento del atleta en "Participantes"`,
      "Display athlete club": "Mostrar club del atleta",
      "Display athlete city": "Mostrar ciudad del atleta",
      "Display athlete birth year": "Mostrar año de nacimiento del atleta",
    },
  },
  ru: {
    translation: {
      "Athlete Portal": "Портал спортсмена",
      Create: "Создать",
      "Your subscription": "Ваша подписка",
      Notifications: "Уведомления",
      "Add League": "Добавить Лигу",
      "Add Tournament": "Добавить Турнир",
      "Add Club": "Добавить Клуб",
      "Add News": "Добавить Новости",
      "Add Team": "Добавить Команду",
      Home: "Главная",
      Platforms: "Платформы",
      "Search Platform": "Поиск платформы",
      "ADD NEW Platform": "Добавить новую платформу",
      "Platform name": "Название платформы",
      "Platform image": "Изображение платформы",
      Country: "Страна",
      Sport: "Вид спорта",
      Type: "Тип",
      Organization: "Организация",
      Status: "Статус",
      "There is no available platforms": "Нет доступных платформ",
      Dashboard: "Панель управления",
      Edit: "Редактировать",
      New: "Новый",
      "Add New Platform": "Добавить новую платформу",
      "Edit Platforms": "Редактировать платформы",
      "Organization account": "Аккаунт организации",
      "Platform general": "Общие настройки платформы",
      Appearance: "Внешний вид",
      "Company details": "Детали компании",
      "Landing & Navigation": "Лендинг и навигация",
      Integrations: "Интеграции",
      "Additional requests": "Дополнительные запросы",
      "Service fees": "Сервисные сборы",
      "Update Organization Account": "Обновить аккаунт организации",
      "USER IS NOT IN THE LIST": "ПОЛЬЗОВАТЕЛЬ НЕ В СПИСКЕ",
      Name: "Имя",
      "Enter name": "Введите имя",
      Surname: "Фамилия",
      "Enter surname": "Введите фамилию",
      Email: "Email",
      "Enter Email Address": "Введите адрес электронной почты",
      "Change owner": "Сменить владельца",
      "Save new owner": "Сохранить нового владельца",
      Password: "Пароль",
      "Confirm Password": "Подтвердите пароль",
      "Organization full name": "Полное название организации",
      "Enter Organization full name": "Введите полное название организации",
      "Organization abbreviations": "Аббревиатура организации",
      "Enter Organization abbreviation": "Введите аббревиатуру организации",
      "Organization type": "Тип организации",
      "About organization": "Организация",
      "Contact email": "Контактный email",
      "Enter Contact Email": "Введите контактный email",
      "Contact phone number": "Контактный номер телефона",
      "Enter Contact phone number": "Введите контактный номер телефона",
      Website: "Вебсайт",
      "Enter website": "Введите вебсайт",
      "Instagram link": "Ссылка на Instagram",
      "Insert Instagram link": "Вставьте ссылку на Instagram",
      Continue: "Продолжить",
      "Platform General": "Общие настройки платформы",
      "Platform title": "Название платформы",
      "Enter Platform Title": "Введите название платформы",
      "Platform type": "Тип платформы",
      "First selected country will act as primary country":
        "Первая выбранная страна будет действовать как основная страна",
      "Select sports": "Выберите виды спорта",
      "First selected sport will act as primary sport":
        "Первый выбранный вид спорта будет основным видом спорта",
      Sports: "Спорт",
      "Select discipline": "Выберите дисциплину",
      "Select third-party licence": "Выберите лицензию третьей стороны",
      "third-party licence": "Лицензия третьей стороны",
      "No third-party license Found": "Лицензия третьей стороны не найдена",
      "Select languages": "Выберите языки",
      "First selected language will act as primary language":
        "Первый выбранный язык будет основным языком",
      "Select language": "Выберите язык",
      Timezone: "Часовой пояс",
      "Time Zone": "Часовой пояс",
      Currency: "Валюта",
      "First selected currency will act as primary currency":
        "Первая выбранная валюта будет основной валютой",
      "Select currency": "Выберите валюту",
      Description: "Описание",
      "Do you have already your domain name?": "У вас уже есть доменное имя?",
      "Enter your domain name": "Введите ваше доменное имя",
      Back: "Назад",
      Save: "Сохранить",
      "Upload primary logo (svg or png)":
        "Загрузить основной логотип (svg или png)",
      "Upload secondary logo (svg or png)":
        "Загрузить вторичный логотип (svg или png)",
      "Upload cover picture": "Загрузить обложку",
      "No logo, you want us to make one for you":
        "Нет логотипа, вы хотите, чтобы мы сделали его для вас",
      "Click Here": "Нажмите здесь",
      "Select primary color": "Выберите основной цвет",
      "Select secondary color": "Выберите вторичный цвет",
      "Company name": "Название компании",
      "Enter Company Name": "Введите название компании",
      "Registration Number": "Регистрационный номер",
      "Enter Registration Number": "Введите регистрационный номер",
      "Full Address": "Полный адрес",
      "Enter Full Address": "Введите полный адрес",
      "Signee full name": "Полное имя подписанта",
      "Signee role": "Роль подписанта",
      "Contact Email": "Контактный Email",
      "Landing / Home Screen": "Экран Лендинга / Главный экран",
      "Do you need landing?": "Вам нужен лендинг?",
      "Select Template": "Выберите шаблон",
      "No, I will use my own website as Landing page.":
        "Нет, я буду использовать свой собственный веб-сайт как лендинг-страницу.",
      "Do you need Integrations?": "Вам нужны интеграции?",
      "Payment Service Provider": "Провайдер платежных услуг",
      "Make sure to get familiar with Stripe service fees":
        "Убедитесь, что ознакомились с сервисными сборами Stripe",
      "Additional services": "Дополнительные услуги",
      "Service Name": "Название услуги",
      Price: "Цена",
      "Mobile app": "Мобильное приложение",
      SMM: "SMM",
      "Graphics for SMM": "Графика для SMM",
      "Your request": "Ваш запрос",
      "Type here your request": "Введите здесь ваш запрос",
      "Upload file": "Загрузить файл",
      "Entry service fee": "Сбор за вход",
      "Tournated entry service fee": "Сбор за турнир",
      "Entery entery fee": "Вступительный взнос",
      "Partner entry service fee": "Сбор за партнёрство",
      "Enter partner entry fee": "Введите сбор за партнёрство",
      "License service fee": "Сбор за лицензию",
      "Tournated license service fee": "Сбор за лицензию на турнир",
      "Enter tournated license fee": "Введите сбор за лицензию на турнир",
      "Partner license service fee": "Сбор за лицензию партнёра",
      "Enter partner license fee": "Введите сбор за лицензию партнёра",
      "Enable Pricing": "Включить ценообразование",
      Leagues: "Лиги",
      "Create new League": "Создать новую Лигу",
      "There is no available leagues": "Нет доступных лиг",
      Completed: "Завершённые",
      Upcoming: "Предстоящие",
      Active: "Активные",
      Total: "Всего",
      "Search Leagues": "Поиск Лиг",
      "League Title": "Название Лиги",
      "League Type": "Тип Лиги",
      Logo: "Логотип",
      Tournaments: "Турниры",
      Athletes: "Спортсмены",
      Regulations: "Регламенты",
      Platform: "Платформа",
      "We recommend an image that is 256 x 256 pixels":
        "Мы рекомендуем изображение размером 256 x 256 пикселей",
      "League Abbreviation": "Аббревиатура Лиги",
      "League owner": "Владелец Лиги",
      "Select Organization": "Выберите Организацию",
      "Select platform": "Выберите платформу",
      "League Priority": "Приоритет Лиги",
      "Go to Next": "Перейти дальше",
      Phone: "Телефон",
      "League Website": "Вебсайт Лиги",
      Instagram: "Instagram",
      "Field Title": "Название поля",
      "Field Type": "Тип поля",
      "Add New Point": "Добавить новую точку",
      "Title Name": "Название заголовка",
      Select: "Выбрать",
      "Field Value": "Значение поля",
      "Add Another Option Below": "Добавить другой вариант ниже",
      Update: "Обновить",
      Management: "Управление",
      "League status": "Статус Лиги",
      "Select one": "Выбрать одно",
      "Make this league featured": "Сделать эту Лигу рекомендованной",
      "Enable rankings": "Включить рейтинги",
      "enable ranking":
        "Создайте индивидуальные рейтинги для вашей Лиги, подключив категории турниров, определив ограничения по возрасту / полу и добавив дополнительные правила.",
      "Enable online payments": "Включить онлайн-платежи",
      "enable online payment":
        "Начните принимать онлайн-платежи за вступление в турниры и лицензии. Убедитесь, что вы ознакомились с нашей моделью сервисных сборов для управления онлайн-платежами, проверив здесь (при нажатии открывается модальное окно с деталями в соответствии с нашими ценами в презентации).",
      pitchdeck: "презентация",
      "Enable licences": "Включить лицензии",
      "License description": "Настройте лицензии, цены, сроки и правила.",
      "Make club field mandatory and display club details as organizer on Tournament page":
        "Сделать поле клуба обязательным и отображать детали клуба в качестве организатора на странице Турнира.",
      "Create League": "Создать Лигу",
      "Organization Accounts": "Учётные записи организации",
      "ADD NEW ACCOUNT": "ДОБАВИТЬ НОВЫЙ АККАУНТ",
      "There is no available organizations": "Нет доступных организаций",
      "Search Organizations": "Поиск Организаций",
      "Full name": "Полное имя",
      "Organization Abbreviation": "Аббревиатура организации",
      "UPDATE USER PROFILE": "ОБНОВИТЬ ПРОФИЛЬ ПОЛЬЗОВАТЕЛЯ",
      "Enter phone number": "Введите номер телефона",
      "Organization Settings": "Настройки организации",
      "Organization Type": "Тип организации",
      "Organization Logo": "Логотип организации",
      "About Organization": "Об организации",
      "Contact Details": "Контактные данные",
      Users: "Пользователи",
      "Search User": "Поиск пользователя",
      "ADD NEW USER": "ДОБАВИТЬ НОВОГО ПОЛЬЗОВАТЕЛЯ",
      Avatar: "Аватар",
      "Phone Number": "Номер телефона",
      Role: "Роль",
      "IPIN License": "Лицензия IPIN",
      "DUPR License": "Лицензия DUPR",
      "Tennis Europe License": "Лицензия Tennis Europe",
      "LTS U10 License": "Лицензия LTS U10",
      "FIDE License": "Лицензия FIDE",
      "Add new user": "Добавить нового пользователя",
      "Edit User": "Редактировать пользователя",
      "User Account": "Аккаунт пользователя",
      "Date of birth": "Дата рождения",
      Gender: "Пол",
      "Representing country": "Представляемая страна",
      City: "Город",
      "Enter city": "Введите город",
      Address: "Адрес",
      "Enter address": "Введите адрес",
      Career: "Карьера",
      Club: "Клуб",
      "Select club": "Выбрать клуб",
      "Select coach": "Выбрать тренера",
      Parent: "Родитель",
      "Select parent": "Выбрать родителя",
      "Write a bio": "Напишите биографию",
      CHECKBOXES: "Флажки",
      "Give access to coach to purchase licences and manage entries on your behalf":
        "Дать тренеру доступ к покупке лицензий и управлению заявками от вашего имени",
      "Give access to club manager(-es) to purchase licences and manage entries on your behalf":
        "Дать менеджеру клуба доступ к покупке лицензий и управлению заявками от вашего имени",
      "Give access to parent(-s) to purchase licences and manage entries on your behalf":
        "Дать родителю доступ к покупке лицензий и управлению заявками от вашего имени",
      Socials: "Социальные сети",
      "Insert link here": "Вставьте ссылку здесь",
      Facebook: "Facebook",
      Linkedin: "Linkedin",
      Tiktok: "Tiktok",
      Youtube: "Youtube",
      Licence: "Лицензия",
      "ITF IPIN": "ITF IPIN",
      DUPRID: "DUPRID",
      "Tennis Europe ID": "Tennis Europe ID",
      "LTS U10": "LTS U10",
      ATP: "ATP",
      WTA: "WTA",
      FIDE: "FIDE",
      "Update password": "Обновить пароль",
      "Re-Enter": "Повторно введите",
      "Login settings": "Настройки входа",
      "Switch Standard login to Social login":
        "Переключить стандартный вход на вход через социальные сети",
      "Connect Gmail": "Подключить Gmail",
      "Enable 2FA Google Authorisation": "Включить 2FA Google авторизацию",
      "Enable 2FA Email Authorisation": "Включить 2FA авторизацию по email",
      "Role Name": "Название роли",
      "Is user verified?": "Пользователь проверен?",
      Yes: "Да",
      No: "Нет",
      Tournament: "Турнир",
      "All tournaments": "Все турниры",
      "League tournaments": "Лиговые турниры",
      "Search Tournaments": "Поиск турниров",
      "Create new Tournament": "Создать новый турнир",
      "Tournament Title": "Название турнира",
      "Start - End": "Начало - Конец",
      Organizer: "Организатор",
      Location: "Место проведения",
      "List of categories": "Список категорий",
      "Update Status": "Обновить статус",
      "There is no available tournaments": "Нет доступных турниров",
      "ADD NEW SPORT": "ДОБАВИТЬ НОВЫЙ ВИД СПОРТА",
      "Search Sports": "Поиск видов спорта",
      "Sport Title": "Название вида спорта",
      "Add new Sport": "Добавить новый вид спорта",
      "Edit Sports": "Редактировать виды спорта",
      "Sports title": "Название вида спорта",
      "Enter title": "Введите название",
      "Sports abbreviation": "Аббревиатура вида спорта",
      "Enter abbreviation": "Введите аббревиатуру",
      "Resource title": "Название ресурса",
      "Rule how to add results/score": "Правило как добавить результаты/очки",
      Icon: "Иконка",
      "Enable disciplines": "Включить дисциплины",
      "Enable courts": "Включить корты",
      "Enable stages": "Включить этапы",
      "Enable pairings": "Включить пары",
      "Enable rounds": "Включить раунды",
      "Enable standings based on pairings":
        "Включить таблицы результатов на основе пар",
      "Display draws": "Показывать жеребьёвку",
      "Display groups": "Показывать группы",
      "Display live standings": "Показывать текущие результаты",
      "Display order of play": "Показывать порядок игры",
      "Display list & results": "Показывать список и результаты",
      "Display pending results": "Показывать ожидаемые результаты",
      "Display final standings": "Показывать окончательные результаты",
      "Display athlete chess titles in Participants": `Показывать шахматные титулы участников в "Участники"`,
      "Display seeds column Participants": `Показывать колонку посевов в "Участники"`,
      "Display segments column in Participants": `Показывать колонку сегментов в "Участники"`,
      "Upload file here": "Загрузить файл здесь",
      Disciplines: "Дисциплины",
      "ADD NEW DISCIPLINE": "ДОБАВИТЬ НОВУЮ ДИСЦИПЛИНУ",
      "There is no available disciplines": "Нет доступных дисциплин",
      "Search Disciplines": "Поиск дисциплин",
      "Sport name": "Название вида спорта",
      "Add new Discipline": "Добавить новую дисциплину",
      "Edit Discipline": "Редактировать дисциплину",
      "Discipline title": "Название дисциплины",
      "Select Sport": "Выбрать вид спорта",
      "No sports found": "Виды спорта не найдены",
      "Results type": "Тип результатов",
      "Third-party licences": "Лицензии третьих сторон",
      "Third party licence": "Лицензия третьей стороны",
      "There is no available third-party licences":
        "Нет доступных лицензий третьих сторон",
      "ADD NEW LICENCE": "ДОБАВИТЬ НОВУЮ ЛИЦЕНЗИЮ",
      "Search license": "Поиск лицензии",
      "license name": "Название лицензии",
      "License type": "Тип лицензии",
      "Add licence": "Добавить лицензию",
      "Add Third-party licence": "Добавить лицензию третьей стороны",
      "Edit Third-party licence": "Редактировать лицензию третьей стороны",
      "Organization title": "Название организации",
      "Organization abbreviation": "Аббревиатура организации",
      "Licence title": "Название лицензии",
      "Enter license title": "Введите название лицензии",
      "Licence type": "Тип лицензии",
      "Request license in user profile":
        "Запрос лицензии в профиле пользователя",
      "Request license on user registration in platform":
        "Запрос лицензии при регистрации пользователя на платформе",
      "Make licence mandatory": "Сделать лицензию обязательной",
      "Link to get licence": "Ссылка для получения лицензии",
      "Enter link to get licence": "Введите ссылку для получения лицензии",
      Partners: "Партнеры",
      "ADD NEW PARTNER": "ДОБАВИТЬ НОВОГО ПАРТНЕРА",
      "There is no available partners": "Нет доступных партнеров",
      "Search Partners": "Поиск партнеров",
      "Create new Partner": "Создать нового партнера",
      Link: "Ссылка",
      Text: "Текст",
      Priority: "Приоритет",
      "Showing dates": "Показать даты",
      "New Partner": "Новый партнер",
      "Edit Partner": "Редактировать партнера",
      "Partner Logo": "Логотип партнера",
      platform: "платформа",
      "No platform available": "Нет доступной платформы",
      "No league available": "Нет доступной лиги",
      "No Clubs available": "Нет доступных клубов",
      "Sponsor Text": "Текст спонсора",
      "Date Settings": "Настройки даты",
      "Active From": "Активно с",
      "Active To": "Активно до",
      "Platform managers": "Менеджеры платформы",
      "ADD NEW MANAGER": "ДОБАВИТЬ НОВОГО МЕНЕДЖЕРА",
      "There is no available manager": "Нет доступных менеджеров",
      "Search Manager": "Поиск менеджера",
      "Export team": "Экспортировать команду",
      "Export team emails": "Экспортировать электронные адреса команды",
      "Add platform manager": "Добавить менеджера платформы",
      "Edit platform manager": "Редактировать менеджера платформы",
      "Enter Organization": "Введите организацию",
      "Select organization": "Выбрать организацию",
      "Filter users": "Фильтровать пользователей",
      "Export users": "Экспортировать пользователей",
      "Export user emails": "Экспортировать электронные адреса пользователей",
      "Age group": "Возрастная группа",
      "There is no available users": "Нет доступных пользователей",
      Coaches: "Тренеры",
      "ADD NEW COACH": "ДОБАВИТЬ НОВОГО ТРЕНЕРА",
      "There is no available account": "Нет доступного аккаунта",
      "Search Coach": "Поиск тренера",
      "Add new coach": "Добавить нового тренера",
      "Edit Coach": "Редактировать тренера",
      "Coach image": "Изображение тренера",
      Category: "Категория",
      "There is no available sports": "Нет доступных видов спорта",
      "there are not available clubs": "нет доступных клубов",
      "Date from": "Дата с",
      "Date to": "Дата по",
      "Display on organization's landing page":
        "Отображать на главной странице организации",
      "Platform tournaments": "Турниры платформы",
      Title: "Название",
      Posts: "Посты",
      "ADD POST": "ДОБАВИТЬ ПОСТ",
      "There is no available post": "Нет доступных постов",
      News: "Новости",
      "Search Post": "Поиск поста",
      Image: "Изображение",
      Author: "Автор",
      Content: "Контент",
      "Cover / featured image": "Обложка / главное изображение",
      "Select league": "Выбрать лигу",
      League: "Лига",
      "Add video from youtube.com": "Добавить видео с youtube.com",
      Date: "Дата",
      "Purchased Licenses": "Приобретенные лицензии",
      "Search Licenses": "Поиск лицензий",
      "Create new License": "Создать новую лицензию",
      "Athlete name & surname": "Имя и фамилия атлета",
      "License Name": "Название лицензии",
      "License Price": "Цена лицензии",
      "License active from": "Лицензия активна с",
      "License active to": "Лицензия активна до",
      "Paid(online/cash)": "Оплачено (онлайн/наличные)",
      "There is no available purchased license":
        "Нет доступных приобретенных лицензий",
      "New License": "Новая лицензия",
      "Edit License": "Редактировать лицензию",
      "Athlete Email": "Электронная почта атлета",
      License: "Лицензия",
      "No license found": "Лицензия не найдена",
      "Payment Method": "Способ оплаты",
      "How much paid": "Сколько оплачено",
      Teams: "Команды",
      "ADD NEW TEAM": "ДОБАВИТЬ НОВУЮ КОМАНДУ",
      "There is no available teams": "Нет доступных команд",
      "Search team": "Поиск команды",
      "Create new Team": "Создать новую команду",
      "Team Members": "Члены команды",
      Discipline: "Дисциплина",
      "New Team": "Новая команда",
      "Edit Team": "Редактировать команду",
      "Team Logo": "Логотип команды",
      "No Clubs Found": "Клубы не найдены",
      "No disciplines found": "Дисциплины не найдены",
      "Club status": "Статус клуба",
      Members: "Члены",
      "Add new Members": "Добавить новых членов",
      "Add New Member": "Добавить нового члена",
      "Member email": "Электронная почта члена",
      "Select Role": "Выбрать роль",
      "Add more": "Добавить еще",
      "ADD NEW CLUB": "ДОБАВИТЬ НОВЫЙ КЛУБ",
      "There is no available clubs": "Нет доступных клубов",
      Clubs: "Клубы",
      "Search Clubs": "Поиск клубов",
      "Club Name": "Название клуба",
      "Add new Club": "Добавить новый клуб",
      "Edit Club": "Редактировать клуб",
      "Appearance & Location": "Внешний вид и местоположение",
      "Club title": "Название клуба",
      "Add club logo": "Добавить логотип клуба",
      "How to find us": "Как нас найти",
      "Contact details": "Контактные данные",
      "Contact Phone": "Контактный телефон",
      Twitter: "Twitter",
      "Services & Resources": "Услуги и ресурсы",
      "Select services": "Выбрать услуги",
      Services: "Услуги",
      Resources: "Ресурсы",
      "Resource Name": "Название ресурса",
      Service: "Услуга",
      "Add Resources": "Добавить ресурсы",
      "Add New Resource": "Добавить новый ресурс",
      "Select Service": "Выбрать услугу",
      "Select type": "Выбрать тип",
      "Add Another": "Добавить еще",
      "Club page": "Страница клуба",
      "Select Coaches": "Выбрать тренеров",
      "Athlete Club": "Клуб атлетов",
      desc: "После добавления вашего клуба, спортсмены смогут выбрать его в настройках своего профиля спортсмена, и они появятся на странице клуба для одобрения/отклонения. Одобренные члены будут отображаться на странице клуба",
      "Online booking": "Онлайн-бронирование",
      "Club Main Administrator": "Главный администратор клуба",
      "Main Administrator": "Главный администратор",
      "CLUB MAIN ADMINISTRATOR IS NOT IN THE LIST":
        "ГЛАВНЫЙ АДМИНИСТРАТОР КЛУБА НЕ В СПИСКЕ",
      Administrator: "Администратор",
      "ADMINISTRATOR IS NOT IN THE LIST": "АДМИНИСТРАТОР НЕ В СПИСКЕ",
      Banners: "Баннеры",
      "There is no available banners": "Нет доступных баннеров",
      "ADD BANNER": "ДОБАВИТЬ БАННЕР",
      "Search Banner": "Поиск баннера",
      Headline: "Заголовок",
      subheadline: "Подзаголовок",
      "Button Text": "Текст кнопки",
      "New Banner": "Новый баннер",
      "Edit Banner": "Редактировать баннер",
      Subheadline: "Подзаголовок",
      "Button text": "Текст кнопки",
      "Button link": "Ссылка на кнопку",
      "Select roles": "Выбрать роли",
      "Banner images": "Изображения баннера",
      "Control Panel": "Панель управления",
      "Run the tournament step by step from this control panel":
        "Проводите турнир шаг за шагом с этой панели управления",
      "Edit & Setup league": "Редактировать и настраивать лигу",
      "Edit Now": "Редактировать сейчас",
      "Create & Setup league": "Создать и настроить лигу",
      "Start Now": "Начать сейчас",
      "Add league members": "Добавить членов лиги",
      "league member description":
        "Добавьте членов лиги, таких как менеджеры, организаторы турниров и судьи для вашей лиги. Менеджеры будут иметь полный доступ к панели управления и всем управленческим функциям, организаторы смогут добавлять турниры и управлять ими, а судьи будут иметь доступ к заявкам, жеребьевкам, группам, текущим результатам, порядку игры и управлению результатами турниров, к которым они прикреплены.",
      "Add Now": "Добавить сейчас",
      "Add tournament categories": "Добавить категории турниров",
      "tournament category description":
        "Настройте категории турниров для вашей лиги, указав форматы (одиночные/парные/командные), возрастные/гендерные ограничения и связывая их с категориями рейтинга (после добавления этих категорий рейтинга на следующем шаге).",
      "Add rankings categories": "Добавить категории рейтингов",
      "ranking category description":
        "Установите категории рейтингов для вашей лиги. Выберите категории турниров для включения в рейтинги, укажите количество турниров для учета, установите временные рамки, свяжите с международными лигами и установите другие правила.",
      "Setup ranking points": "Настроить рейтинговые очки",
      "ranking description":
        "Настройте таблицу очков для каждой категории турниров, указав количество очков для каждого места. Более подробная таблица упростит и автоматизирует процесс.",
      Setup: "Настройка",
      "Setup licences": "Настроить лицензии",
      "Add tournament": "Добавить турнир",
      "tournament description":
        "Следуйте шагам для создания вашего турнира и начните управление вашим событием. Будьте уверены, вы получите полный список инструкций, охватывающих многочисленные функции, которые мы предлагаем для управления спортивными мероприятиями",
      "Leagues Members": "Члены лиг",
      "Add new Member": "Добавить нового члена",
      "There is no available members": "Нет доступных членов",
      "League members": "Члены лиги",
      "Search Members": "Поиск членов",
      "ADD NEW MEMBER": "ДОБАВИТЬ НОВОГО ЧЛЕНА",
      "Delete league member": "Удалить члена лиги",
      "Are you sure you want to delete?": "Вы уверены, что хотите удалить?",
      Cancel: "Отмена",
      Delete: "Удалить",
      "Tournament Categories": "Категории турниров",
      "There is no available categories": "Нет доступных категорий",
      "ADD NEW CATEGORY": "ДОБАВИТЬ НОВУЮ КАТЕГОРИЮ",
      "Search Categories": "Поиск категорий",
      "Copy Categories": "Копировать категории",
      "Delete Categories": "Удалить категории",
      "Category Name": "Название категории",
      "Category Type": "Тип категории",
      "Select League": "Выбрать лигу",
      Copy: "Копировать",
      "Are you sure you want to delete these categories?":
        "Вы уверены, что хотите удалить эти категории?",
      "New Category": "Новая категория",
      "Edit Category": "Редактировать категорию",
      "Category name": "Название категории",
      "Add team selection on tournament registration":
        "Добавить выбор команды при регистрации на турнир",
      "Add club selection on tournament registration":
        "Добавить выбор клуба при регистрации на турнир",
      "Skills level": "Уровень навыков",
      "Category Gender": "Пол категории",
      "Age from": "Возраст от",
      "Age to": "Возраст до",
      "Age Limit Type": "Тип возрастного ограничения",
      "No Types Found!": "Типы не найдены!",
      "Ask skills level on a tournament registration":
        "Запрашивать уровень навыков при регистрации на турнир",
      "Enable License": "Включить лицензию",
      "Display athlete club in Participants":
        "Отображать клуб спортсмена в 'Участниках'",
      "Display athlete city in Participants":
        "Отображать город спортсмена в 'Участниках'",
      "Select License": "Выбрать лицензию",
      "No License Found!": "Лицензии не найдены!",
      "Ranking Category for men": "Рейтинговая категория для мужчин",
      "No Category Found!": "Категории не найдены!",
      "Ranking Category for Women": "Рейтинговая категория для женщин",
      "Third-party license": "Сторонняя лицензия",
      "No third-party license Found!": "Сторонние лицензии не найдены!",
      "Time control": "Контроль времени",
      "No time control found": "Контроль времени не найден",
      "Order display for seeding": "Порядок отображения для посева",
      "Sport fishing settings": "Настройки спортивной рыбалки",
      "Identify team members on registration":
        "Идентифицировать членов команды при регистрации",
      "Min number of members": "Минимальное количество участников",
      "Max number of members": "Максимальное количество участников",
      "Reservists required": "Требуются резервисты",
      "Min number of reservists": "Минимальное количество резервистов",
      "Max number of reservists": "Максимальное количество резервистов",
      "Captain required": "Требуется капитан",
      "Transfer members to singles category":
        "Перевести участников в одиночную категорию",
      "Select singles category": "Выбрать одиночную категорию",
      "No Category Found": "Категории не найдены",
      "National tournament (country vs country)":
        "Национальный турнир (страна против страны)",
      "Club championships (club vs club)":
        "Клубные чемпионаты (клуб против клуба)",
      "Create matches from team members and count points result based on matches result":
        "Создавать матчи из членов команды и учитывать результат очков на основе результата матчей",
      "Count individual total": "Считать общий результат индивидуально",
      "Count teams total": "Считать общий результат команды",
      "Number of athletes to count min":
        "Минимальное количество спортсменов для учета",
      "Number of athletes to count max":
        "Максимальное количество спортсменов для учета",
      "Max Entries per team": "Максимальное количество заявок на команду",
      "Allow registration without team": "Разрешить регистрацию без команды",
      "Category Status": "Статус категории",
      "Ranking Categories": "Рейтинговые категории",
      "There is no available ranking categories":
        "Нет доступных рейтинговых категорий",
      Categories: "Категории",
      "No of Tournaments": "Количество турниров",
      "Time Period": "Период времени",
      "No Leagues found": "Лиги не найдены",
      "New Ranking Category": "Новая рейтинговая категория",
      "Select Categories": "Выбрать категории",
      "No Categories Found!": "Категории не найдены!",
      "Max number of tournaments": "Максимальное количество турниров",
      "Count specific tournaments": "Учитывать определенные турниры",
      "Add penalty points for missing a tournament":
        "Добавить штрафные очки за пропуск турнира",
      "Penalty rule": "Правило штрафа",
      "Enter points": "Ввести очки",
      "Additional Rules": "Дополнительные правила",
      Sector: "Сектор",
      Coefficient: "Коэффициент",
      "No of tournaments": "Количество турниров",
      "Edit additional rule(s)": "Редактировать дополнительные правило(а)",
      "Add additional rule(s)": "Добавить дополнительные правило(а)",
      "During which period tournament points are counted":
        "В течение какого периода учитываются очки турнира",
      "Select Time Period": "Выбрать период времени",
      "Points sorting": "Сортировка очков",
      "Sport Fishing": "Спортивная рыбалка",
      "Select Stage form which take points":
        "Выбрать этап, с которого учитывать очки",
      "Ranking Points": "Рейтинговые очки",
      "Search Rankings": "Поиск рейтингов",
      "Add points block": "Добавить блок очков",
      "Select / UnSelect All": "Выбрать / Отменить выбор всех",
      Place: "Место",
      Points: "Очки",
      "Bracket type": "Тип сетки",
      Segment: "Сегмент",
      "Num of Entries": "Количество заявок",
      "Ad.Rules": "Доп. правила",
      "view rule": "просмотреть правило",
      "Add new row": "Добавить новую строку",
      "There is no available ranking points": "Нет доступных рейтинговых очков",
      "Edit Points": "Редактировать очки",
      "Add Points": "Добавить очки",
      "Points block name": "Название блока очков",
      Specification: "Спецификация",
      "Rule Settings": "Настройки правил",
      "Select operator": "Выбрать оператора",
      "Select skills level": "Выбрать уровень навыков",
      "Number of players": "Количество игроков",
      "Number of entries": "Количество заявок",
      update: "обновить",
      SAVE: "СОХРАНИТЬ",
      DISCARD: "ОТМЕНИТЬ",
      "SET NEW RULE": "УСТАНОВИТЬ НОВОЕ ПРАВИЛО",
      "Edit Row": "Редактировать строку",
      "Add Row": "Добавить строку",
      ADD: "ДОБАВИТЬ",
      "Copy Ranking Point": "Копировать рейтинговые очки",
      "Select Ranking Points": "Выбрать рейтинговые очки",
      "Copy Ranking Point Blocks": "Копировать блоки рейтинговых очков",
      "Delete Ranking Point Block": "Удалить блок рейтинговых очков",
      Licenses: "Лицензии",
      "There is no available licenses": "Нет доступных лицензий",
      "Price Currency": "Валюта цены",
      "Select Leagues": "Выбрать лиги",
      "No League Found!": "Лига не найдена!",
      "License price": "Цена лицензии",
      "License Type": "Тип лицензии",
      "License Status": "Статус лицензии",
      "Additional questions": "Дополнительные вопросы",
      "Add additional question": "Добавить дополнительный вопрос",
      "Edit this": "Редактировать это",
      "Add new": "Добавить новый",
      question: "вопрос",
      Question: "Вопрос",
      "Field Option": "Вариант поля",
      Remove: "Удалить",
      "Add new option": "Добавить новый вариант",
      "Ask this question to each athlete":
        "Задать этот вопрос каждому спортсмену",
      Submit: "Отправить",
      "Delete Warning": "Предупреждение об удалении",
      "Are you sure you want to delete this question?":
        "Вы уверены, что хотите удалить этот вопрос?",
      "Confirm Deletion": "Подтвердить удаление",
      Rankings: "Рейтинги",
      Search: "Поиск",
      "ranking Category": "рейтинговая категория",
      "No date Found": "Дата не найдена",
      "Create Rankings": "Создать рейтинги",
      "Update Rankings": "Обновить рейтинги",
      close: "закрыть",
      Year: "Год",
      "Points Details": "Детали очков",
      "No points yet": "Очков пока нет",
      Position: "Позиция",
      Athlete: "Спортсмен",
      "There is no available rankings": "Нет доступных рейтингов",
      "Select Ranking categories": "Выбрать рейтинговые категории",
      "Remove Rankings": "Удалить рейтинги",
      "Select Date": "Выбрать дату",
      "Are you sure you want to delete rankings?":
        "Вы уверены, что хотите удалить рейтинги?",
      "Rankings History": "История рейтингов",
      "Ranking Category": "Рейтинговая категория",
      "Last Updated": "Последнее обновление",
      Public: "Публичный",
      "Delete warning": "Предупреждение об удалении",
      "Are you sure you want to delete this ranking?":
        "Вы уверены, что хотите удалить этот рейтинг?",
      "There is no available rankings history":
        "Нет доступной истории рейтингов",
      "Tournament Results": "Результаты турнира",
      Entry: "Запись",
      "Add new result": "Добавить новый результат",
      "There is no available tournament results":
        "Нет доступных результатов турнира",
      "Pendding tournaments": "Ожидающие турниры",
      "There is no available athletes": "Нет доступных спортсменов",
      "Search Athletes": "Поиск спортсменов",
      "Country/Residency": "Страна/Проживание",
      "Super Data": "Супер данные",
      "All Tournaments": "Все турниры",
      Data: "Данные",
      "Pending Tournaments": "Ожидающие турниры",
      Financials: "Финансы",
      Accounting: "Бухгалтерия",
      Extra: "Дополнительно",
      "Live scoring": "Живой счет",
      "Live stream": "Прямая трансляция",
      "Platform Settings": "Настройки платформы",
      "My Platforms": "Мои платформы",
      Team: "Команда",
      Instructions: "Инструкции",
      "League Settings": "Настройки лиги",
      "League Members": "Члены лиги",
      Upload: "Загрузить",
      "primary logo": "основной логотип",
      "secondary logo": "вторичный логотип",
      "cover picture": "обложка",
      "Start Typing": "Начните печатать",
      "Leagues list": "Список лиг",
      "General & Appearance": "Общее и внешний вид",
      "Instagram Link": "Ссылка на Instagram",
      "Fact Sheet": "Фактический лист",
      "Fact sheet": "Фактический лист",
      "Upload regulations": "Загрузить правила",
      "Drag and Drop some file": "Перетащите файл",
      "here, or click to Select file": "сюда или нажмите для выбора файла",
      UPLOAD: "ЗАГРУЗИТЬ",
      "DISCARD AND CANCEL": "ОТМЕНИТЬ И ОТМЕНИТЬ",
      SUBMIT: "ОТПРАВИТЬ",
      "Add new Organization Account":
        "Добавить новую учетную запись организации",
      "Edit Organization Account": "Редактировать учетную запись организации",
      "Full Name": "Полное имя",
      Biography: "Биография",
      "Add post": "Добавить пост",
      "Edit Post": "Редактировать пост",
      "Edit Member": "Редактировать участника",
      "Organization name": "Название организации",
      "Organization email": "Электронная почта организации",
      "Organization phone": "Телефон организации",
      "Display member on organizations public team page":
        "Отображать участника на публичной странице команды организации",
      "Select Roles": "Выбрать роли",
      "For assiging points": "Для назначения очков",
      "Third party license": "Сторонняя лицензия",
      "Must be a valid email":
        "Должен быть действующий адрес электронной почты",
      "Email is required": "Электронная почта обязательна",
      "Organization Name is required": "Название организации обязательно",
      "Organization Type is required": "Тип организации обязателен",
      "organization abbreviation is required":
        "аббревиатура организации обязательна",
      "Contact Email is required":
        "Контактный адрес электронной почты обязателен",
      "Password is Required": "Пароль обязателен",
      "Password should be 8 chars minimum.":
        "Пароль должен содержать минимум 8 символов.",
      "Passwords must match": "Пароли должны совпадать",
      "company name is required": "название компании обязательно",
      "company number is required": "номер компании обязателен",
      "full address is required": "полный адрес обязателен",
      "Invalid email address": "Недействительный адрес электронной почты",
      "signee role is required": "роль подписанта обязательна",
      "signee full name is required": "полное имя подписанта обязательно",
      "partner entry fee is required":
        "вступительный взнос партнера обязателен",
      "tournated license fee is required":
        "лицензионный сбор турнира обязателен",
      "partner license fee is required":
        "лицензионный сбор партнера обязателен",
      "Creating platform...": "Создание платформы...",
      "Platform created successfully": "Платформа успешно создана",
      "Updating...": "Обновление...",
      "Platform updated": "Платформа обновлена",
      "Add New Club": "Добавить новый клуб",
      "organization is required": "организация обязательна",
      "Adding new fact...": "Добавление нового факта...",
      "Fact added successfully": "Факт успешно добавлен",
      "Deleting fact...": "Удаление факта...",
      "fact deleted successfully": "факт успешно удален",
      "Updating fact...": "Обновление факта...",
      "Fact updated successfully": "Факт успешно обновлен",
      "Creating league...": "Создание лиги...",
      "League created successfully": "Лига успешно создана",
      "Updating league...": "Обновление лиги...",
      "League updated successfully": "Лига успешно обновлена",
      "Approved & Public": "Одобрено и публично",
      "ATP points": "Очки ATP",
      "WTA points": "Очки WTA",
      "name Email is required": "имя Электронная почта обязательна",
      "title is require": "заголовок обязателен",
      "platform type required": "тип платформы обязателен",
      "time zone is required": "часовой пояс обязателен",
      "description is required": "описание обязательно",
      "title is required": "заголовок обязателен",
      "abbreviation is required": "аббревиатура обязательна",
      "platform is required": "платформа обязательна",
      "priority is required": "приоритет обязателен",
      "type is required": "тип обязателен",
      "sports is required": "вид спорта обязателен",
      "email is required": "электронная почта обязательна",
      "Organization added Successfully!": "Организация успешно добавлена!",
      "name is required": "имя обязательно",
      "surname is required": "фамилия обязательна",
      "organization name is required": "название организации обязательно",
      "organization type is required": "тип организации обязателен",
      "Organization Account Updated Successfully!":
        "Учетная запись организации успешно обновлена!",
      "Creating organization...": "Создание организации...",
      "Organization created successfully": "Организация успешно создана",
      "This User is already exist": "Этот пользователь уже существует",
      "Password is required": "Пароль обязателен",
      "Re-Enter password is required": "Повторное введение пароля обязательно",
      "Password updated": "Пароль обновлен",
      "User Updated": "Пользователь обновлен",
      "Sports Updated": "Виды спорта обновлены",
      "Sports Created": "Виды спорта созданы",
      "Discipline Updated": "Дисциплина обновлена",
      "Discipline Created": "Дисциплина создана",
      "At least one sport must be selected":
        "Должен быть выбран хотя бы один вид спорта",
      "License Updated": "Лицензия обновлена",
      "License Created": "Лицензия создана",
      "Please provide Image": "Пожалуйста, предоставьте изображение",
      "Partner updated Successfully!": "Партнер успешно обновлен!",
      "Partner created successfully!": "Партнер успешно создан!",
      "Please add correct user email":
        "Пожалуйста, добавьте правильный адрес электронной почты пользователя",
      "Coach added!": "Тренер добавлен!",
      "user found": "пользователь найден",
      "user not found": "пользователь не найден",
      "country is required": "страна обязательна",
      "gender is required": "пол обязателен",
      "confirm password is required": "подтверждение пароля обязательно",
      "Coache Updated Successfully!": "Тренер успешно обновлен!",
      "Image field is required": "Поле изображения обязательно",
      "author is required": "автор обязателен",
      "Updating ...": "Обновление ...",
      "Post updated successfully": "Запись успешно обновлена",
      "Creating post ...": "Создание записи ...",
      "Post created successfully": "Запись успешно создана",
      "User found": "Пользователь найден",
      "User not found": "Пользователь не найден",
      "paid is required": "оплата обязательна",
      "currency is required": "валюта обязательна",
      "Athlete is required": "Спортсмен обязателен",
      "license is required": "лицензия обязательна",
      "start date is required": "дата начала обязательна",
      "end date is required": "дата окончания обязательна",
      "License created successfully!": "Лицензия успешно создана!",
      "sport is required": "вид спорта обязателен",
      "discipline is required": "дисциплина обязательна",
      "Team updated successfully!": "Команда успешно обновлена!",
      "Team created successfully!": "Команда успешно создана!",
      "city is required": "город обязателен",
      "address is required": "адрес обязателен",
      "headline is required": "заголовок обязателен",
      "subheadline is required": "подзаголовок обязателен",
      "button text required": "текст кнопки обязателен",
      "button link required": "ссылка кнопки обязательна",
      "Image are required": "Изображения обязательны",
      "Updating banner...": "Обновление баннера...",
      "Banner updated successfully": "Баннер успешно обновлен",
      "Creating banner...": "Создание баннера...",
      "Banner created successfully": "Баннер успешно создан",
      "League member added!": "Член лиги добавлен!",
      "League Member Updated Successfully!": "Член лиги успешно обновлен!",
      "order is required": "порядок обязателен",
      "Age from must an integer": "Возраст с должен быть целым числом",
      "Age must be at least 0": "Возраст должен быть не менее 0",
      "Age to must an integer": "Возраст до должен быть целым числом",
      "Min members must an integer":
        "Минимальное количество членов должно быть целым числом",
      "Min members must be at least 0":
        "Минимальное количество членов должно быть не менее 0",
      "Max members must an integer":
        "Максимальное количество членов должно быть целым числом",
      "Max members must be at least 0":
        "Максимальное количество членов должно быть не менее 0",
      "Max members must be greater than and equal to min members":
        "Максимальное количество членов должно быть больше или равно минимальному количеству членов",
      "Min reservists must an integer":
        "Минимальное количество резервистов должно быть целым числом",
      "Min reservists must be at least 0":
        "Минимальное количество резервистов должно быть не менее 0",
      "Max reservists must an integer":
        "Максимальное количество резервистов должно быть целым числом",
      "Max reservists must be at least 0":
        "Максимальное количество резервистов должно быть не менее 0",
      "Max reservists must be greater than and equal to min reservists":
        "Максимальное количество резервистов должно быть больше или равно минимальному количеству резервистов",
      "Min number of athletes must an integer":
        "Минимальное количество спортсменов должно быть целым числом",
      "Min number of athletes must be at least 0":
        "Минимальное количество спортсменов должно быть не менее 0",
      "Max number of athletes must an integer":
        "Максимальное количество спортсменов должно быть целым числом",
      "Max number of athletes must be at least 0":
        "Максимальное количество спортсменов должно быть не менее 0",
      "Max number of athletes must be greater than min number of athletes":
        "Максимальное количество спортсменов должно быть больше минимального количества спортсменов",
      "status is required": "статус обязателен",
      "Category Updated Successfully!": "Категория успешно обновлена!",
      "Category Created!": "Категория создана!",
      "time period is required": "временной период обязателен",
      "sort is required": "сортировка обязательна",
      "at-least-one": "как минимум один",
      "At least one category is required":
        "Требуется как минимум одна категория",
      "Value cannot be less than 1": "Значение не может быть меньше 1",
      "place is required": "место обязательно",
      "entries is required": "записи обязательны",
      "points is required": "очки обязательны",
      "You can't remove this category because this is used into additional rules":
        "Вы не можете удалить эту категорию, так как она используется в дополнительных правилах",
      "Rule added successfully": "Правило успешно добавлено",
      "Rule updated successfully": "Правило успешно обновлено",
      "Points updated successfully": "Очки успешно обновлены",
      "Points added successfully": "Очки успешно добавлены",
      "Removing...": "Удаление...",
      "Removed successfully!": "Успешно удалено!",
      "fee is required": "плата обязательна",
      "duration is required": "продолжительность обязательна",
      "partner license fee required": "лицензионный сбор партнера обязателен",
      "tournated license fee required": "лицензионный сбор турнира обязателен",
      "License updating ...": "Обновление лицензии ...",
      "License updated Successfully!": "Лицензия успешно обновлена!",
      "License creating ...": "Создание лицензии ...",
      "License created successfully": "Лицензия успешно создана",
      "Display athlete birth year in Participants": `Показывать год рождения спортсмена в "Участниках"`,
      "Display athlete club": "Показывать клуб спортсмена",
      "Display athlete city": "Показывать город спортсмена",
      "Display athlete birth year": "Показывать год рождения спортсмена",
    },
  },
  pt: {
    translation: {
      "Athlete Portal": "Portal do Atleta",
      Create: "Criar",
      "Your subscription": "Sua assinatura",
      Notifications: "Notificações",
      "Add League": "Adicionar Liga",
      "Add Tournament": "Adicionar Torneio",
      "Add Club": "Adicionar Clube",
      "Add News": "Adicionar Notícia",
      "Add Team": "Adicionar Equipe",
      Home: "Início",
      Platforms: "Plataformas",
      "Search Platform": "Buscar Plataforma",
      "ADD NEW Platform": "ADICIONAR NOVA Plataforma",
      "Platform name": "Nome da Plataforma",
      "Platform image": "Imagem da Plataforma",
      Country: "País",
      Sport: "Esporte",
      Type: "Tipo",
      Organization: "Organização",
      Status: "Status",
      "There is no available platforms": "Não há plataformas disponíveis",
      Dashboard: "Painel",
      Edit: "Editar",
      New: "Novo",
      "Add New Platform": "Adicionar Nova Plataforma",
      "Edit Platforms": "Editar Plataformas",
      "Organization account": "Conta da Organização",
      "Platform general": "Plataforma geral",
      Appearance: "Aparência",
      "Company details": "Detalhes da empresa",
      "Landing & Navigation": "Página inicial & Navegação",
      Integrations: "Integrações",
      "Additional requests": "Solicitações adicionais",
      "Service fees": "Taxas de serviço",
      "Update Organization Account": "Atualizar Conta da Organização",
      "USER IS NOT IN THE LIST": "O USUÁRIO NÃO ESTÁ NA LISTA",
      Name: "Nome",
      "Enter name": "Digite o nome",
      Surname: "Sobrenome",
      "Enter surname": "Digite o sobrenome",
      Email: "E-mail",
      "Enter Email Address": "Digite o Endereço de E-mail",
      "Change owner": "Mudar proprietário",
      "Save new owner": "Salvar novo proprietário",
      Password: "Senha",
      "Confirm Password": "Confirmar Senha",
      "Organization full name": "Nome completo da Organização",
      "Enter Organization full name": "Digite o nome completo da Organização",
      "Organization abbreviations": "Abreviações da Organização",
      "Enter Organization abbreviation": "Digite a abreviação da Organização",
      "Organization type": "Tipo de Organização",
      "About organization": "Sobre a organização",
      "Contact email": "E-mail de contato",
      "Enter Contact Email": "Digite o E-mail de Contato",
      "Contact phone number": "Número de telefone de contato",
      "Enter Contact phone number": "Digite o Número de Telefone de Contato",
      Website: "Site",
      "Enter website": "Digite o site",
      "Instagram link": "Link do Instagram",
      "Insert Instagram link": "Insira o link do Instagram",
      Continue: "Continuar",
      "Platform General": "Plataforma Geral",
      "Platform title": "Título da Plataforma",
      "Enter Platform Title": "Digite o Título da Plataforma",
      "Platform type": "Tipo de Plataforma",
      "First selected country will act as primary country":
        "O primeiro país selecionado será o país principal",
      "Select sports": "Selecione esportes",
      "First selected sport will act as primary sport":
        "O primeiro esporte selecionado será o esporte principal",
      Sports: "Esportes",
      "Select discipline": "Selecione a disciplina",
      "Select third-party licence": "Selecione licença de terceiros",
      "third-party licence": "licença de terceiros",
      "No third-party license Found": "Nenhuma licença de terceiros encontrada",
      "Select languages": "Selecione idiomas",
      "First selected language will act as primary language":
        "O primeiro idioma selecionado será o idioma principal",
      "Select language": "Selecione o idioma",
      Timezone: "Fuso horário",
      "Time Zone": "Fuso Horário",
      Currency: "Moeda",
      "First selected currency will act as primary currency":
        "A primeira moeda selecionada será a moeda principal",
      "Select currency": "Selecione a moeda",
      Description: "Descrição",
      "Do you have already your domain name?":
        "Você já tem seu nome de domínio?",
      "Enter your domain name": "Digite seu nome de domínio",
      Back: "Voltar",
      Save: "Salvar",
      "Upload primary logo (svg or png)":
        "Carregar logotipo primário (svg ou png)",
      "Upload secondary logo (svg or png)":
        "Carregar logotipo secundário (svg ou png)",
      "Upload cover picture": "Carregar imagem de capa",
      "No logo, you want us to make one for you":
        "Sem logotipo, você quer que façamos um para você",
      "Click Here": "Clique Aqui",
      "Select primary color": "Selecione a cor primária",
      "Select secondary color": "Selecione a cor secundária",
      "Company name": "Nome da empresa",
      "Enter Company Name": "Digite o Nome da Empresa",
      "Registration Number": "Número de Registro",
      "Enter Registration Number": "Digite o Número de Registro",
      "Full Address": "Endereço Completo",
      "Enter Full Address": "Digite o Endereço Completo",
      "Signee full name": "Nome completo do signatário",
      "Signee role": "Função do signatário",
      "Contact Email": "E-mail de Contato",
      "Landing / Home Screen": "Tela Inicial / Página inicial",
      "Do you need landing?": "Você precisa de uma página inicial?",
      "Select Template": "Selecione o Template",
      "No, I will use my own website as Landing page.":
        "Não, eu usarei meu próprio site como página inicial.",
      "Do you need Integrations?": "Você precisa de Integrações?",
      "Payment Service Provider": "Provedor de Serviço de Pagamento",
      "Make sure to get familiar with Stripe service fees":
        "Certifique-se de se familiarizar com as taxas de serviço do Stripe",
      "Additional services": "Serviços adicionais",
      "Service Name": "Nome do Serviço",
      Price: "Preço",
      "Mobile app": "Aplicativo móvel",
      SMM: "SMM",
      "Graphics for SMM": "Gráficos para SMM",
      "Your request": "Sua solicitação",
      "Type here your request": "Digite sua solicitação aqui",
      "Upload file": "Carregar arquivo",
      "Entry service fee": "Taxa de serviço de entrada",
      "Tournated entry service fee": "Taxa de serviço de entrada do torneio",
      "Entery entery fee": "Taxa de entrada",
      "Partner entry service fee": "Taxa de serviço de entrada do parceiro",
      "Enter partner entry fee": "Digite a taxa de entrada do parceiro",
      "License service fee": "Taxa de serviço de licença",
      "Tournated license service fee": "Taxa de serviço de licença do torneio",
      "Enter tournated license fee": "Digite a taxa de licença do torneio",
      "Partner license service fee": "Taxa de serviço de licença do parceiro",
      "Enter partner license fee": "Digite a taxa de licença do parceiro",
      "Enable Pricing": "Habilitar Preços",
      Leagues: "Ligas",
      "Create new League": "Criar nova Liga",
      "There is no available leagues": "Não há ligas disponíveis",
      Completed: "Concluído",
      Upcoming: "Próximos",
      Active: "Ativo",
      Total: "Total",
      "Search Leagues": "Buscar Ligas",
      "League Title": "Título da Liga",
      "League Type": "Tipo de Liga",
      Logo: "Logotipo",
      Tournaments: "Torneios",
      Athletes: "Atletas",
      Regulations: "Regulamentos",
      Platform: "Plataforma",
      "We recommend an image that is 256 x 256 pixels":
        "Recomendamos uma imagem de 256 x 256 pixels",
      "League Abbreviation": "Abreviação da Liga",
      "League owner": "Proprietário da Liga",
      "Select Organization": "Selecionar Organização",
      "Select platform": "Selecionar plataforma",
      "League Priority": "Prioridade da Liga",
      "Go to Next": "Ir para o Próximo",
      Phone: "Telefone",
      "League Website": "Site da Liga",
      Instagram: "Instagram",
      "Field Title": "Título do Campo",
      "Field Type": "Tipo de Campo",
      "Add New Point": "Adicionar Novo Ponto",
      "Title Name": "Nome do Título",
      Select: "Selecionar",
      "Field Value": "Valor do Campo",
      "Add Another Option Below": "Adicionar Outra Opção Abaixo",
      Update: "Atualizar",
      Management: "Gestão",
      "League status": "Status da Liga",
      "Select one": "Selecione um",
      "Make this league featured": "Tornar esta liga em destaque",
      "Enable rankings": "Habilitar classificações",
      "enable ranking":
        "Crie classificações personalizadas para sua liga conectando categorias de torneios, definindo limitações de idade/gênero e adicionando regras adicionais.",
      "Enable online payments": "Habilitar pagamentos online",
      "enable online payment":
        "Comece a aceitar pagamentos online para inscrições em torneios e licenças. Certifique-se de se familiarizar com nosso modelo de taxa de serviço para gerenciar pagamentos online verificando-o aqui. (ao clicar abre um modal com detalhes de acordo com nossos preços)",
      pitchdeck: "pitchdeck",
      "Enable licences": "Habilitar licenças",
      "License description": "Configure licenças, preços, períodos e regras.",
      "Make club field mandatory and display club details as organizer on Tournament page":
        "Tornar o campo do clube obrigatório e exibir os detalhes do clube como organizador na página do Torneio",
      "Create League": "Criar Liga",
      "Organization Accounts": "Contas da Organização",
      "ADD NEW ACCOUNT": "ADICIONAR NOVA CONTA",
      "There is no available organizations": "Não há organizações disponíveis",
      "Search Organizations": "Buscar Organizações",
      "Full name": "Nome completo",
      "Organization Abbrevation": "Abreviação da organização",
      "UPDATE USER PROFILE": "ATUALIZAR PERFIL DO USUÁRIO",
      "Enter phone number": "Insira o número de telefone",
      "Organization Settings": "Configurações da organização",
      "Organization Type": "Tipo de organização",
      "Organization Logo": "Logotipo da organização",
      "About Organization": "Sobre a organização",
      "Contact Details": "Detalhes de contato",
      Users: "Usuários",
      "Search User": "Buscar usuário",
      "ADD NEW USER": "ADICIONAR NOVO USUÁRIO",
      Avatar: "Avatar",
      "Phone Number": "Número de telefone",
      Role: "Função",
      "IPIN License": "Licença IPIN",
      "DUPR License": "Licença DUPR",
      "Tennis Europe License": "Licença Tennis Europe",
      "LTS U10 License": "Licença LTS U10",
      "FIDE License": "Licença FIDE",
      "Add new user": "Adicionar novo usuário",
      "Edit User": "Editar usuário",
      "User Account": "Conta do usuário",
      "Date of birth": "Data de nascimento",
      Gender: "Gênero",
      "Representing country": "País representado",
      City: "Cidade",
      "Enter city": "Insira a cidade",
      Address: "Endereço",
      "Enter address": "Insira o endereço",
      Career: "Carreira",
      Club: "Clube",
      "Select club": "Selecionar clube",
      "Select coach": "Selecionar treinador",
      Parent: "Responsável",
      "Select parent": "Selecionar responsável",
      "Write a bio": "Escrever uma biografia",
      CHECKBOXES: "CAIXAS DE SELEÇÃO",
      "Give access to coach to purchase licences and manage entries on your behalf":
        "Conceder acesso ao treinador para comprar licenças e gerenciar inscrições em seu nome",
      "Give access to club manager(-es) to purchase licences and manage entries on your behalf":
        "Conceder acesso ao gerente do clube para comprar licenças e gerenciar inscrições em seu nome",
      "Give access to parent(-s) to purchase licences and manage entries on your behalf":
        "Conceder acesso ao responsável para comprar licenças e gerenciar inscrições em seu nome",
      Socials: "Redes sociais",
      "Insert link here": "Insira o link aqui",
      Facebook: "Facebook",
      Linkedin: "Linkedin",
      Tiktok: "Tiktok",
      Youtube: "Youtube",
      Licence: "Licença",
      "ITF IPIN": "ITF IPIN",
      DUPRID: "DUPRID",
      "Tennis Europe ID": "ID Tennis Europe",
      "LTS U10": "LTS U10",
      ATP: "ATP",
      WTA: "WTA",
      FIDE: "FIDE",
      "Update password": "Atualizar senha",
      "Re-Enter": "Reinsira",
      "Login settings": "Configurações de login",
      "Switch Standard login to Social login":
        "Alterar login padrão para login social",
      "Connect Gmail": "Conectar Gmail",
      "Enable 2FA Google Authorisation": "Habilitar autorização 2FA do Google",
      "Enable 2FA Email Authorisation": "Habilitar autorização 2FA por e-mail",
      "Role Name": "Nome da função",
      "Is user verified?": "O usuário está verificado?",
      Yes: "Sim",
      No: "Não",
      Tournament: "Torneio",
      "All tournaments": "Todos os torneios",
      "League tournaments": "Torneios da liga",
      "Search Tournaments": "Buscar torneios",
      "Create new Tournament": "Criar novo torneio",
      "Tournament Title": "Título do torneio",
      "Start - End": "Início - Fim",
      Organizer: "Organizador",
      Location: "Localização",
      "List of categories": "Lista de categorias",
      "Update Status": "Atualizar status",
      "There is no available tournaments": "Não há torneios disponíveis",
      "ADD NEW SPORT": "ADICIONAR NOVO ESPORTE",
      "Search Sports": "Buscar esportes",
      "Sport Title": "Título do esporte",
      "Add new Sport": "Adicionar novo esporte",
      "Edit Sports": "Editar esportes",
      "Sports title": "Título dos esportes",
      "Enter title": "Insira o título",
      "Sports abbreviation": "Abreviação dos esportes",
      "Enter abbreviation": "Insira a abreviação",
      "Resource title": "Título do recurso",
      "Rule how to add results/score":
        "Regra de como adicionar resultados/pontuação",
      Icon: "Ícone",
      "Enable disciplines": "Habilitar disciplinas",
      "Enable courts": "Habilitar quadras",
      "Enable stages": "Habilitar fases",
      "Enable pairings": "Habilitar pareamentos",
      "Enable rounds": "Habilitar rodadas",
      "Enable standings based on pairings":
        "Habilitar classificações com base nos pareamentos",
      "Display draws": "Exibir sorteios",
      "Display groups": "Exibir grupos",
      "Display live standings": "Exibir classificações ao vivo",
      "Display order of play": "Exibir ordem de jogo",
      "Display list & results": "Exibir lista e resultados",
      "Display pending results": "Exibir resultados pendentes",
      "Display final standings": "Exibir classificações finais",
      "Display athlete chess titles in Participants":
        "Exibir títulos de xadrez dos atletas nos participantes",
      "Display seeds column Participants":
        "Exibir coluna de cabeças de chave nos participantes",
      "Display segments column in Participants":
        "Exibir coluna de segmentos nos participantes",
      "Upload file here": "Carregar arquivo aqui",
      Disciplines: "Disciplinas",
      "ADD NEW DISCIPLINE": "ADICIONAR NOVA DISCIPLINA",
      "There is no available disciplines": "Não há disciplinas disponíveis",
      "Search Disciplines": "Buscar disciplinas",
      "Sport name": "Nome do esporte",
      "Add new Discipline": "Adicionar nova disciplina",
      "Edit Discipline": "Editar disciplina",
      "Discipline title": "Título da disciplina",
      "Select Sport": "Selecionar esporte",
      "No sports found": "Nenhum esporte encontrado",
      "Results type": "Tipo de resultados",
      "Third-party licences": "Licenças de terceiros",
      "Third party licence": "Licença de terceiros",
      "There is no available third-party licences":
        "Não há licenças de terceiros disponíveis",
      "ADD NEW LICENCE": "ADICIONAR NOVA LICENÇA",
      "Search license": "Buscar licença",
      "license name": "Nome da licença",
      "License type": "Tipo de licença",
      "Add licence": "Adicionar licença",
      "Add Third-party licence": "Adicionar licença de terceiros",
      "Edit Third-party licence": "Editar licença de terceiros",
      "Organization title": "Título da organização",
      "Organization abbreviation": "Abreviação da organização",
      "Licence title": "Título da licença",
      "Enter license title": "Insira o título da licença",
      "Licence type": "Tipo de licença",
      "Request license in user profile":
        "Solicitar licença no perfil do usuário",
      "Request license on user registration in platform":
        "Solicitar licença no registro do usuário na plataforma",
      "Make licence mandatory": "Tornar a licença obrigatória",
      "Link to get licence": "Link para obter licença",
      "Enter link to get licence": "Insira o link para obter licença",
      Partners: "Parceiros",
      "ADD NEW PARTNER": "ADICIONAR NOVO PARCEIRO",
      "There is no available partners": "Não há parceiros disponíveis",
      "Search Partners": "Buscar parceiros",
      "Create new Partner": "Criar novo parceiro",
      Link: "Link",
      Text: "Texto",
      Priority: "Prioridade",
      "Showing dates": "Mostrando datas",
      "New Partner": "Novo parceiro",
      "Edit Partner": "Editar parceiro",
      "Partner Logo": "Logotipo do parceiro",
      "No platform available": "Nenhuma plataforma disponível",
      "No league available": "Nenhuma liga disponível",
      "No Clubs available": "Nenhum clube disponível",
      "Sponsor Text": "Texto do patrocinador",
      "Date Settings": "Configurações de data",
      "Active From": "Ativo a partir de",
      "Active To": "Ativo até",
      "Platform managers": "Gerentes da plataforma",
      "ADD NEW MANAGER": "ADICIONAR NOVO GERENTE",
      "There is no available manager": "Não há gerente disponível",
      "Search Manager": "Buscar gerente",
      "Export team": "Exportar equipe",
      "Export team emails": "Exportar e-mails da equipe",
      "Add platform manager": "Adicionar gerente da plataforma",
      "Edit platform manager": "Editar gerente da plataforma",
      "Enter Organization": "Insira a organização",
      "Select organization": "Selecionar organização",
      "Filter users": "Filtrar usuários",
      "Export users": "Exportar usuários",
      "Export user emails": "Exportar e-mails dos usuários",
      "Age group": "Faixa etária",
      "There is no available users": "Não há usuários disponíveis",
      Coaches: "Treinadores",
      "ADD NEW COACH": "ADICIONAR NOVO TREINADOR",
      "There is no available account": "Não há conta disponível",
      "Search Coach": "Buscar treinador",
      "Add new coach": "Adicionar novo treinador",
      "Edit Coach": "Editar treinador",
      "Coach image": "Imagem do treinador",
      Category: "Categoria",
      "There is no available sports": "Não há esportes disponíveis",
      "there are not available clubs": "não há clubes disponíveis",
      "Date from": "Data de",
      "Date to": "Data até",
      "Display on organization's landing page":
        "Exibir na página inicial da organização",
      "Platform tournaments": "Torneios da plataforma",
      Title: "Título",
      Posts: "Postagens",
      "ADD POST": "ADICIONAR POSTAGEM",
      News: "Notícias",
      "Search Post": "Buscar postagem",
      Image: "Imagem",
      Author: "Autor",
      Content: "Conteúdo",
      "Cover / featured image": "Imagem de capa / destaque",
      "Select league": "Selecionar liga",
      League: "Liga",
      "Add video from youtube.com": "Adicionar vídeo do youtube.com",
      Date: "Data",
      "Purchased Licenses": "Licenças compradas",
      "Search Licenses": "Buscar licenças",
      "Create new License": "Criar nova licença",
      "Athlete name & surname": "Nome e sobrenome do atleta",
      "License Name": "Nome da licença",
      "License Price": "Preço da licença",
      "License active from": "Licença ativa a partir de",
      "License active to": "Licença ativa até",
      "Paid(online/cash)": "Pago (online/dinheiro)",
      "There is no available purchased license":
        "Não há licença comprada disponível",
      "New License": "Nova licença",
      "Edit License": "Editar licença",
      "Athlete Email": "E-mail do atleta",
      License: "Licença",
      "No license found": "Nenhuma licença encontrada",
      "Payment Method": "Método de pagamento",
      "How much paid": "Quanto pago",
      Teams: "Equipes",
      "ADD NEW TEAM": "ADICIONAR NOVA EQUIPE",
      "There is no available teams": "Não há equipes disponíveis",
      "Search team": "Buscar equipe",
      "Create new Team": "Criar nova equipe",
      "Team Members": "Membros da equipe",
      Discipline: "Disciplina",
      "New Team": "Nova equipe",
      "Edit Team": "Editar equipe",
      "Team Logo": "Logo da equipe",
      "No Clubs Found": "Nenhum clube encontrado",
      "No disciplines found": "Nenhuma disciplina encontrada",
      "Club status": "Status do clube",
      Members: "Membros",
      "Add new Members": "Adicionar novos membros",
      "Add New Member": "Adicionar novo membro",
      "Member email": "E-mail do membro",
      "Select Role": "Selecionar função",
      "Add more": "Adicionar mais",
      "ADD NEW CLUB": "ADICIONAR NOVO CLUBE",
      "There is no available clubs": "Não há clubes disponíveis",
      Clubs: "Clubes",
      "Search Clubs": "Buscar clubes",
      "Club Name": "Nome do clube",
      "Add new Club": "Adicionar novo clube",
      "Edit Club": "Editar clube",
      "Appearance & Location": "Aparência e localização",
      "Club title": "Título do clube",
      "Add club logo": "Adicionar logo do clube",
      "How to find us": "Como nos encontrar",
      "Contact details": "Detalhes de contato",
      "Contact Phone": "Telefone de contato",
      Twitter: "Twitter",
      "Services & Resources": "Serviços e recursos",
      "Select services": "Selecionar serviços",
      Services: "Serviços",
      Resources: "Recursos",
      "Resource Name": "Nome do recurso",
      Service: "Serviço",
      "Add Resources": "Adicionar recursos",
      "Add New Resource": "Adicionar novo recurso",
      "Select Service": "Selecionar serviço",
      "Select type": "Selecionar tipo",
      "Add Another": "Adicionar outro",
      "Club page": "Página do clube",
      "Select Coaches": "Selecionar treinadores",
      "Athlete Club": "Clube do atleta",
      desc: "Uma vez que seu clube seja adicionado, os atletas poderão selecioná-lo na página de configurações do perfil do atleta e, em seguida, aparecerão em uma página do clube para serem aprovados/rejeitados. Membros aprovados serão exibidos na página do clube",
      "Online booking": "Reserva online",
      "Club Main Administrator": "Administrador principal do clube",
      "Main Adminitrator": "Administrador principal",
      "CLUB MAIN ADMINISTRATOR IS NOT IN THE LIST":
        "O ADMINISTRADOR PRINCIPAL DO CLUBE NÃO ESTÁ NA LISTA",
      Administrator: "Administrador",
      "ADMINISTRATOR IS NOT IN THE LIST": "ADMINISTRADOR NÃO ESTÁ NA LISTA",
      Banners: "Banners",
      "There is no available banners": "Não há banners disponíveis",
      "ADD BANNER": "ADICIONAR BANNER",
      "Search Banner": "Buscar banner",
      Headline: "Título",
      subheadline: "Subtítulo",
      "Button Text": "Texto do botão",
      "New Banner": "Novo banner",
      "Edit Banner": "Editar banner",
      Subheadline: "Subtítulo",
      "Button text": "Texto do botão",
      "Button link": "Link do botão",
      "Select roles": "Selecionar funções",
      "Banner images": "Imagens do banner",
      "Control Panel": "Painel de controle",
      "Run the tournament step by step from this control panel":
        "Execute o torneio passo a passo a partir deste painel de controle",
      "Edit & Setup league": "Editar e configurar liga",
      "Edit Now": "Editar agora",
      "Create & Setup league": "Criar e configurar liga",
      "Start Now": "Começar agora",
      "Add league members": "Adicionar membros da liga",
      "league member description":
        "Inclua membros da liga, como gerentes, organizadores de torneios e árbitros para sua liga. Gerentes terão acesso completo ao painel e a todos os recursos gerenciais, organizadores poderão adicionar torneios e gerenciá-los, e árbitros terão acesso a inscrições, sorteios, grupos, classificações ao vivo, ordem de jogo e gestão de resultados dos torneios aos quais foram designados.",
      "Add Now": "Adicionar agora",
      "Add tournament categories": "Adicionar categorias de torneio",
      "tournament category description":
        "Configure categorias de torneio para sua liga, especificando formatos (simples/duplas/equipe), limitações de idade/gênero e conectando-as a categorias de classificação (depois de adicionar essas categorias de classificação no próximo passo).",
      "Add rankings categories": "Adicionar categorias de classificação",
      "ranking category description":
        "Estabeleça categorias de classificação para sua liga. Escolha categorias de torneio para incluir nas classificações, especifique quantos torneios contar, defina o período de tempo, conecte-se com ligas internacionais e estabeleça outras regras.",
      "Setup ranking points": "Configurar pontos de classificação",
      "ranking description":
        "Configure a tabela de pontos para cada categoria de torneio, especificando o número de pontos para cada colocação. Uma tabela mais detalhada agilizará e automatizará o processo.",
      Setup: "Configurar",
      "Setup licences": "Configurar licenças",
      "Add tournament": "Adicionar torneio",
      "tournament description":
        "Siga os passos para criar seu torneio e iniciar a gestão do seu evento. Fique tranquilo, você receberá uma lista abrangente de instruções cobrindo os inúmeros recursos que oferecemos para a gestão de eventos esportivos",
      "Leagues Members": "Membros das ligas",
      "Add new Member": "Adicionar novo membro",
      "There is no available members": "Não há membros disponíveis",
      "League members": "Membros da liga",
      "Search Members": "Buscar membros",
      "ADD NEW MEMBER": "ADICIONAR NOVO MEMBRO",
      "Delete league member": "Excluir membro da liga",
      "Are you sure you want to delete?":
        "Você tem certeza de que deseja excluir?",
      Cancel: "Cancelar",
      Delete: "Excluir",
      "Tournament Categories": "Categorias de torneio",
      "There is no available categories": "Não há categorias disponíveis",
      "ADD NEW CATEGORY": "ADICIONAR NOVA CATEGORIA",
      "Search Categories": "Buscar categorias",
      "Copy Categories": "Copiar categorias",
      "Delete Categories": "Excluir categorias",
      "Category Name": "Nome da categoria",
      "Category Type": "Tipo de categoria",
      "Select League": "Selecionar liga",
      Copy: "Copiar",
      "Are you sure you want to delete these categories?":
        "Tem certeza de que deseja excluir essas categorias?",
      "New Category": "Nova categoria",
      "Edit Category": "Editar categoria",
      "Category name": "Nome da categoria",
      "Add team selection on tournament registration":
        "Adicionar seleção de equipe na inscrição do torneio",
      "Add club selection on tournament registration":
        "Adicionar seleção de clube no registro do torneio",
      "Skills level": "Nível de habilidades",
      "Category Gender": "Gênero da categoria",
      "Age from": "Idade mínima",
      "Age to": "Idade máxima",
      "Age Limit Type": "Tipo de limite de idade",
      "No Types Found!": "Nenhum tipo encontrado!",
      "Ask skills level on a tournament registration":
        "Perguntar nível de habilidades no registro do torneio",
      "Enable License": "Ativar licença",
      "Display athlete club in Participants":
        "Exibir clube do atleta em 'Participantes'",
      "Display athlete city in Participants":
        "Exibir cidade do atleta em 'Participantes'",
      "Select License": "Selecionar licença",
      "No License Found!": "Nenhuma licença encontrada!",
      "Ranking Category for men": "Categoria de ranking para homens",
      "No Category Found!": "Nenhuma categoria encontrada!",
      "Ranking Category for Women": "Categoria de ranking para mulheres",
      "Third-party license": "Licença de terceiros",
      "No third-party license Found!":
        "Nenhuma licença de terceiros encontrada!",
      "Time control": "Controle de tempo",
      "No time control found": "Nenhum controle de tempo encontrado",
      "Order display for seeding": "Ordem de exibição para semeadura",
      "Sport fishing settings": "Configurações de pesca esportiva",
      "Identify team members on registration":
        "Identificar membros da equipe no registro",
      "Min number of members": "Número mínimo de membros",
      "Max number of members": "Número máximo de membros",
      "Reservists required": "Reservistas necessários",
      "Min number of reservists": "Número mínimo de reservistas",
      "Max number of reservists": "Número máximo de reservistas",
      "Captain required": "Capitão necessário",
      "Transfer members to singles category":
        "Transferir membros para categoria individual",
      "Select singles category": "Selecionar categoria individual",
      "No Category Found": "Nenhuma categoria encontrada",
      "National tournament (country vs country)":
        "Torneio nacional (país vs país)",
      "Club championships (club vs club)":
        "Campeonatos de clube (clube vs clube)",
      "Create matches from team members and count points result based on matches result":
        "Criar partidas a partir dos membros da equipe e contar pontos com base nos resultados das partidas",
      "Count individual total": "Contar total individual",
      "Count teams total": "Contar total de equipes",
      "Number of athletes to count min": "Número mínimo de atletas para contar",
      "Number of athletes to count max": "Número máximo de atletas para contar",
      "Max Entries per team": "Entradas máximas por equipe",
      "Allow registration without team": "Permitir registro sem equipe",
      "Category Status": "Status da categoria",
      "Ranking Categories": "Categorias de ranking",
      "There is no available ranking categories":
        "Não há categorias de ranking disponíveis",
      Categories: "Categorias",
      "No of Tournaments": "Número de torneios",
      "Time Period": "Período de tempo",
      "No Leagues found": "Nenhuma liga encontrada",
      "New Ranking Category": "Nova categoria de ranking",
      "Select Categories": "Selecionar categorias",
      "No Categories Found!": "Nenhuma categoria encontrada!",
      "Max number of tournaments": "Número máximo de torneios",
      "Count specific tournaments": "Contar torneios específicos",
      "Add penalty points for missing a tournament":
        "Adicionar pontos de penalidade por falta a um torneio",
      "Penalty rule": "Regra de penalidade",
      "Enter points": "Inserir pontos",
      "Additional Rules": "Regras adicionais",
      Sector: "Setor",
      Coefficient: "Coeficiente",
      "No of tournaments": "Número de torneios",
      "Edit additional rule(s)": "Editar regra(s) adicional(is)",
      "Add additional rule(s)": "Adicionar regra(s) adicional(is)",
      "During which period tournament points are counted":
        "Durante qual período os pontos do torneio são contados",
      "Select Time Period": "Selecionar Período de Tempo",
      "Points sorting": "Ordenação de pontos",
      "Sport Fishing": "Pesca esportiva",
      "Select Stage form which take points":
        "Selecionar estágio de onde pegar pontos",
      "Ranking Points": "Pontos de ranking",
      "Search Rankings": "Buscar Rankings",
      "Add points block": "Adicionar bloco de pontos",
      "Select / UnSelect All": "Selecionar / Desselecionar Tudo",
      Place: "Posição",
      Points: "Pontos",
      "Bracket type": "Tipo de bracket",
      Segment: "Segmento",
      "Num of Entries": "Número de entradas",
      "Ad.Rules": "Regras Ad.",
      "view rule": "ver regra",
      "Add new row": "Adicionar nova linha",
      "There is no available ranking points":
        "Não há pontos de ranking disponíveis",
      "Edit Points": "Editar Pontos",
      "Add Points": "Adicionar Pontos",
      "Points block name": "Nome do bloco de pontos",
      Specification: "Especificação",
      "Rule Settings": "Configurações de regra",
      "Select operator": "Selecionar operador",
      "Select skills level": "Selecionar nível de habilidades",
      "Number of players": "Número de jogadores",
      "Number of entries": "Número de entradas",
      update: "atualizar",
      SAVE: "SALVAR",
      DISCARD: "DESCARTAR",
      "SET NEW RULE": "DEFINIR NOVA REGRA",
      "Edit Row": "Editar Linha",
      "Add Row": "Adicionar Linha",
      ADD: "ADICIONAR",
      "Copy Ranking Point": "Copiar Ponto de Ranking",
      "Select Ranking Points": "Selecionar Pontos de Ranking",
      "Copy Ranking Point Blocks": "Copiar Blocos de Pontos de Ranking",
      "Delete Ranking Point Block": "Excluir Bloco de Pontos de Ranking",
      Licenses: "Licenças",
      "There is no available licenses": "Não há licenças disponíveis",
      "Price Currency": "Moeda de preço",
      "Select Leagues": "Selecionar Ligas",
      "No League Found!": "Nenhuma liga encontrada!",
      "License price": "Preço da licença",
      "License Type": "Tipo de licença",
      "License Status": "Status da licença",
      "Additional questions": "Perguntas adicionais",
      "Add additional question": "Adicionar pergunta adicional",
      "Edit this": "Editar isso",
      "Add new": "Adicionar novo",
      question: "pergunta",
      Question: "Pergunta",
      "Field Option": "Opção de campo",
      Remove: "Remover",
      "Add new option": "Adicionar nova opção",
      "Ask this question to each athlete":
        "Fazer esta pergunta para cada atleta",
      Submit: "Enviar",
      "Delete Warning": "Aviso de exclusão",
      "Are you sure you want to delete this question?":
        "Tem certeza de que deseja excluir esta pergunta?",
      "Confirm Deletion": "Confirmar Exclusão",
      Rankings: "Rankings",
      Search: "Buscar",
      "ranking Category": "categoria de ranking",
      "No date Found": "Nenhuma data encontrada",
      "Create Rankings": "Criar Rankings",
      "Update Rankings": "Atualizar Rankings",
      close: "fechar",
      Year: "Ano",
      "Points Details": "Detalhes dos pontos",
      "No points yet": "Ainda não há pontos",
      Position: "Posição",
      Athlete: "Atleta",
      "There is no available rankings": "Não há rankings disponíveis",
      "Select Ranking categories": "Selecionar categorias de ranking",
      "Remove Rankings": "Remover Rankings",
      "Select Date": "Selecionar Data",
      "Are you sure you want to delete rankings?":
        "Tem certeza de que deseja excluir os rankings?",
      "Rankings History": "Histórico de Rankings",
      "Ranking Category": "Categoria de Ranking",
      "Last Updated": "Última Atualização",
      Public: "Público",
      "Delete warning": "Aviso de exclusão",
      "Are you sure you want to delete this ranking?":
        "Tem certeza de que deseja excluir este ranking?",
      "There is no available rankings history":
        "Não há histórico de rankings disponível",
      "Tournament Results": "Resultados do Torneio",
      Entry: "Entrada",
      "Add new result": "Adicionar novo resultado",
      "There is no available tournament results":
        "Não há resultados de torneio disponíveis",
      "Pendding tournaments": "Torneios pendentes",
      "There is no available athletes": "Não há atletas disponíveis",
      "Search Athletes": "Buscar Atletas",
      "Country/Residency": "País/Residência",
      "Super Data": "Super Dados",
      "All Tournaments": "Todos os Torneios",
      "Pending Tournaments": "Torneios Pendentes",
      Financials: "Financeiro",
      Accounting: "Contabilidade",
      Extra: "Extra",
      "Live scoring": "Pontuação ao vivo",
      "Live stream": "Transmissão ao vivo",
      "Platform Settings": "Configurações da Plataforma",
      "My Platforms": "Minhas Plataformas",
      Team: "Equipe",
      Instructions: "Instruções",
      "League Settings": "Configurações da Liga",
      "League Members": "Membros da Liga",
      Upload: "Enviar",
      "primary logo": "logotipo principal",
      "secondary logo": "logotipo secundário",
      "cover picture": "foto de capa",
      "Start Typing": "Comece a digitar",
      "Leagues list": "Lista de ligas",
      "General & Appearance": "Geral e Aparência",
      "Instagram Link": "Link do Instagram",
      "Fact Sheet": "Ficha Técnica",
      "Fact sheet": "Ficha técnica",
      "Upload regulations": "Enviar regulamentos",
      "Organization Abbreviation": "Abreviação da organização",
      "Drag and Drop some file": "Arraste e solte algum arquivo",
      "here, or click to Select file":
        "aqui, ou clique para selecionar o arquivo",
      UPLOAD: "ENVIAR",
      "DISCARD AND CANCEL": "DESCARTAR E CANCELAR",
      SUBMIT: "ENVIAR",
      "Add new Organization Account": "Adicionar nova conta de organização",
      "Edit Organization Account": "Editar conta de organização",
      "Full Name": "Nome completo",
      Biography: "Biografia",
      "Add post": "Adicionar postagem",
      "Edit Post": "Editar postagem",
      "Edit Member": "Editar membro",
      "Organization name": "Nome da organização",
      "Organization email": "E-mail da organização",
      "Organization phone": "Telefone da organização",
      "Display member on organizations public team page":
        "Exibir membro na página pública da equipe da organização",
      "Select Roles": "Selecionar funções",
      "For assigning points": "Para atribuir pontos",
      "Third party license": "Licença de terceiros",
      "Must be a valid email": "Deve ser um email válido",
      "Email is required": "O email é obrigatório",
      "Organization Name is required": "O nome da organização é obrigatório",
      "Organization Type is required": "O tipo de organização é obrigatório",
      "organization abbreviation is required":
        "A abreviação da organização é obrigatória",
      "Contact Email is required": "O email de contacto é obrigatório",
      "Password is Required": "A senha é obrigatória",
      "Password should be 8 chars minimum.":
        "A senha deve ter no mínimo 8 caracteres.",
      "Passwords must match": "As senhas devem coincidir",
      "company name is required": "O nome da empresa é obrigatório",
      "company number is required": "O número da empresa é obrigatório",
      "full address is required": "O endereço completo é obrigatório",
      "Invalid email address": "Endereço de email inválido",
      "signee role is required": "A função do assinante é obrigatória",
      "signee full name is required":
        "O nome completo do assinante é obrigatório",
      "partner entry fee is required":
        "A taxa de entrada do parceiro é obrigatória",
      "tournated license fee is required":
        "A taxa de licença do torneio é obrigatória",
      "partner license fee is required":
        "A taxa de licença do parceiro é obrigatória",
      "Creating platform...": "Criando plataforma...",
      "Platform created successfully": "Plataforma criada com sucesso",
      "Updating...": "Atualizando...",
      "Platform updated": "Plataforma atualizada",
      "Add New Club": "Adicionar novo clube",
      "organization is required": "A organização é obrigatória",
      "Adding new fact...": "Adicionando novo fato...",
      "Fact added successfully": "Fato adicionado com sucesso",
      "Deleting fact...": "Excluindo fato...",
      "fact deleted successfully": "Fato excluído com sucesso",
      "Updating fact...": "Atualizando fato...",
      "Fact updated successfully": "Fato atualizado com sucesso",
      "Creating league...": "Criando liga...",
      "League created successfully": "Liga criada com sucesso",
      "Updating league...": "Atualizando liga...",
      "League updated successfully": "Liga atualizada com sucesso",
      "Approved & Public": "Aprovado e Público",
      "ATP points": "Pontos ATP",
      "WTA points": "Pontos WTA",
      "name Email is required": "O email do nome é obrigatório",
      "title is require": "O título é obrigatório",
      "platform type required": "O tipo de plataforma é obrigatório",
      "time zone is required": "O fuso horário é obrigatório",
      "description is required": "A descrição é obrigatória",
      "title is required": "O título é obrigatório",
      "abbreviation is required": "A abreviação é obrigatória",
      "platform is required": "A plataforma é obrigatória",
      "priority is required": "A prioridade é obrigatória",
      "type is required": "O tipo é obrigatório",
      "sports is required": "Os desportos são obrigatórios",
      "email is required": "O email é obrigatório",
      "Organization added Successfully!": "Organização adicionada com sucesso!",
      "name is required": "O nome é obrigatório",
      "surname is required": "O sobrenome é obrigatório",
      "organization name is required": "O nome da organização é obrigatório",
      "organization type is required": "O tipo de organização é obrigatório",
      "Organization Account Updated Successfully!":
        "Conta da organização atualizada com sucesso!",
      "Creating organization...": "Criando organização...",
      "Organization created successfully": "Organização criada com sucesso",
      "This User is already exist": "Este utilizador já existe",
      "Password is required": "A senha é obrigatória",
      "Re-Enter password is required": "A confirmação da senha é obrigatória",
      "Password updated": "Senha atualizada",
      "User Updated": "Utilizador atualizado",
      "Sports Updated": "Desportos atualizados",
      "Sports Created": "Desportos criados",
      "Discipline Updated": "Disciplina atualizada",
      "Discipline Created": "Disciplina criada",
      "At least one sport must be selected":
        "Pelo menos um desporto deve ser selecionado",
      "License Updated": "Licença atualizada",
      "License Created": "Licença criada",
      "Please provide Image": "Por favor, forneça uma imagem",
      "Partner updated Successfully!": "Parceiro atualizado com sucesso!",
      "Partner created successfully!": "Parceiro criado com sucesso!",
      "Please add correct user email":
        "Por favor, adicione o email correto do utilizador",
      "Coach added!": "Treinador adicionado!",
      "user found": "Utilizador encontrado",
      "user not found": "Utilizador não encontrado",
      "country is required": "O país é obrigatório",
      "gender is required": "O género é obrigatório",
      "confirm password is required": "A confirmação da senha é obrigatória",
      "Coache Updated Successfully!": "Treinador atualizado com sucesso!",
      "Image field is required": "O campo de imagem é obrigatório",
      "author is required": "O autor é obrigatório",
      "Updating ...": "Atualizando ...",
      "Post updated successfully": "Publicação atualizada com sucesso",
      "Creating post ...": "Criando publicação ...",
      "Post created successfully": "Publicação criada com sucesso",
      "User found": "Utilizador encontrado",
      "User not found": "Utilizador não encontrado",
      "paid is required": "O pagamento é obrigatório",
      "currency is required": "A moeda é obrigatória",
      "Athlete is required": "O atleta é obrigatório",
      "license is required": "A licença é obrigatória",
      "start date is required": "A data de início é obrigatória",
      "end date is required": "A data de término é obrigatória",
      "License created successfully!": "Licença criada com sucesso!",
      "sport is required": "O desporto é obrigatório",
      "discipline is required": "A disciplina é obrigatória",
      "Team updated successfully!": "Equipa atualizada com sucesso!",
      "Team created successfully!": "Equipa criada com sucesso!",
      "city is required": "A cidade é obrigatória",
      "address is required": "O endereço é obrigatório",
      "headline is required": "O título é obrigatório",
      "subheadline is required": "O subtítulo é obrigatório",
      "button text required": "O texto do botão é obrigatório",
      "button link required": "O link do botão é obrigatório",
      "Image are required": "As imagens são obrigatórias",
      "Updating banner...": "Atualizando banner...",
      "Banner updated successfully": "Banner atualizado com sucesso",
      "Creating banner...": "Criando banner...",
      "Banner created successfully": "Banner criado com sucesso",
      "League member added!": "Membro da liga adicionado!",
      "League Member Updated Successfully!":
        "Membro da liga atualizado com sucesso!",
      "order is required": "A ordem é obrigatória",
      "Age from must an integer": "A idade deve ser um número inteiro",
      "Age must be at least 0": "A idade deve ser pelo menos 0",
      "Age to must an integer": "A idade até deve ser um número inteiro",
      "Min members must an integer":
        "O número mínimo de membros deve ser um número inteiro",
      "Min members must be at least 0":
        "O número mínimo de membros deve ser pelo menos 0",
      "Max members must an integer":
        "O número máximo de membros deve ser um número inteiro",
      "Max members must be at least 0":
        "O número máximo de membros deve ser pelo menos 0",
      "Max members must be greater than and equal to min members":
        "O número máximo de membros deve ser maior ou igual ao número mínimo de membros",
      "Min reservists must an integer":
        "O número mínimo de reservistas deve ser um número inteiro",
      "Min reservists must be at least 0":
        "O número mínimo de reservistas deve ser pelo menos 0",
      "Max reservists must an integer":
        "O número máximo de reservistas deve ser um número inteiro",
      "Max reservists must be at least 0":
        "O número máximo de reservistas deve ser pelo menos 0",
      "Max reservists must be greater than and equal to min reservists":
        "O número máximo de reservistas deve ser maior ou igual ao número mínimo de reservistas",
      "Min number of athletes must an integer":
        "O número mínimo de atletas deve ser um número inteiro",
      "Min number of athletes must be at least 0":
        "O número mínimo de atletas deve ser pelo menos 0",
      "Max number of athletes must an integer":
        "O número máximo de atletas deve ser um número inteiro",
      "Max number of athletes must be at least 0":
        "O número máximo de atletas deve ser pelo menos 0",
      "Max number of athletes must be greater than min number of athletes":
        "O número máximo de atletas deve ser maior que o número mínimo de atletas",
      "status is required": "O status é obrigatório",
      "Category Updated Successfully!": "Categoria atualizada com sucesso!",
      "Category Created!": "Categoria criada!",
      "time period is required": "O período de tempo é obrigatório",
      "sort is required": "A ordenação é obrigatória",
      "at-least-one": "pelo menos um",
      "At least one category is required":
        "Pelo menos uma categoria é obrigatória",
      "Value cannot be less than 1": "O valor não pode ser menor que 1",
      "place is required": "O local é obrigatório",
      "entries is required": "As inscrições são obrigatórias",
      "points is required": "Os pontos são obrigatórios",
      "You can't remove this category because this is used into additional rules":
        "Você não pode remover esta categoria porque ela é usada em regras adicionais",
      "Rule added successfully": "Regra adicionada com sucesso",
      "Rule updated successfully": "Regra atualizada com sucesso",
      "Points updated successfully": "Pontos atualizados com sucesso",
      "Points added successfully": "Pontos adicionados com sucesso",
      "Removing...": "Removendo...",
      "Removed successfully!": "Removido com sucesso!",
      "fee is required": "A taxa é obrigatória",
      "duration is required": "A duração é obrigatória",
      "partner license fee required":
        "A taxa de licença do parceiro é obrigatória",
      "tournated license fee required":
        "A taxa de licença do torneio é obrigatória",
      "License updating ...": "Atualizando licença ...",
      "License updated Successfully!": "Licença atualizada com sucesso!",
      "License creating ...": "Criando licença ...",
      "License created successfully": "Licença criada com sucesso",
      deleteRankingsWarning:
        "Já existem classificações para esta data. Ao atualizar, os rankings antigos desta data serão eliminados. Quer prosseguir?",
      "Display athlete birth year in Participants": `Exibir ano de nascimento do atleta em "Participantes"`,
      "Display athlete club": "Exibir clube do atleta",
      "Display athlete city": "Exibir cidade do atleta",
      "Display athlete birth year": "Exibir ano de nascimento do atleta",
    },
  },
  ja: {
    translation: {
      "Athlete Portal": "選手ポータル",
      Create: "作成する",
      "Your subscription": "あなたのサブスクリプション",
      Notifications: "通知",
      "Add League": "リーグを追加する",
      "Add Tournament": "トーナメントを追加する",
      "Add Club": "クラブを追加する",
      "Add News": "ニュースを追加する",
      "Add Team": "チームを追加する",
      Home: "ホーム",
      Platforms: "プラットフォーム",
      "Search Platform": "プラットフォームを検索する",
      "ADD NEW Platform": "新しいプラットフォームを追加する",
      "Platform name": "プラットフォーム名",
      "Platform image": "プラットフォーム画像",
      Country: "国",
      Sport: "スポーツ",
      Type: "タイプ",
      Organization: "組織",
      Status: "ステータス",
      "There is no available platforms":
        "利用可能なプラットフォームはありません",
      Dashboard: "ダッシュボード",
      Edit: "編集する",
      New: "新しい",
      "Add New Platform": "新しいプラットフォームを追加する",
      "Edit Platforms": "プラットフォームを編集する",
      "Organization account": "組織アカウント",
      "Platform general": "プラットフォーム全般",
      Appearance: "外観",
      "Company details": "会社の詳細",
      "Landing & Navigation": "ランディングページとナビゲーション",
      Integrations: "統合",
      "Additional requests": "追加のリクエスト",
      "Service fees": "サービス料金",
      "Update Organization Account": "組織アカウントを更新する",
      "USER IS NOT IN THE LIST": "ユーザーがリストにありません",
      Name: "名前",
      "Enter name": "名前を入力してください",
      Surname: "姓",
      "Enter surname": "姓を入力してください",
      Email: "メール",
      "Enter Email Address": "メールアドレスを入力してください",
      "Change owner": "所有者を変更する",
      "Save new owner": "新しい所有者を保存する",
      Password: "パスワード",
      "Confirm Password": "パスワードを確認する",
      "Organization full name": "組織のフルネーム",
      "Enter Organization full name": "組織のフルネームを入力してください",
      "Organization abbreviations": "組織の略称",
      "Enter Organization abbreviation": "組織の略称を入力してください",
      "Organization type": "組織のタイプ",
      "About organization": "組織について",
      "Contact email": "連絡先メール",
      "Enter Contact Email": "連絡先メールアドレスを入力してください",
      "Contact phone number": "連絡先電話番号",
      "Enter Contact phone number": "連絡先電話番号を入力してください",
      Website: "ウェブサイト",
      "Enter website": "ウェブサイトを入力してください",
      "Instagram link": "Instagramリンク",
      "Insert Instagram link": "Instagramリンクを挿入する",
      Continue: "続ける",
      "Platform General": "プラットフォーム全般",
      "Platform title": "プラットフォームタイトル",
      "Enter Platform Title": "プラットフォームタイトルを入力してください",
      "Platform type": "プラットフォームタイプ",
      "First selected country will act as primary country":
        "最初に選択した国が主要な国として機能します",
      "Select sports": "スポーツを選択する",
      "First selected sport will act as primary sport":
        "最初に選択したスポーツが主要なスポーツとして機能します",
      Sports: "スポーツ",
      "Select discipline": "ディシプリンを選択する",
      "Select third-party licence": "サードパーティのライセンスを選択する",
      "third-party licence": "サードパーティのライセンス",
      "No third-party license Found":
        "サードパーティのライセンスが見つかりません",
      "Select languages": "言語を選択する",
      "First selected language will act as primary language":
        "最初に選択した言語が主要な言語として機能します",
      "Select language": "言語を選択する",
      Timezone: "タイムゾーン",
      "Time Zone": "タイムゾーン",
      Currency: "通貨",
      "First selected currency will act as primary currency":
        "最初に選択した通貨が主要な通貨として機能します",
      "Select currency": "通貨を選択する",
      Description: "説明",
      "Do you have already your domain name?":
        "すでにドメイン名をお持ちですか？",
      "Enter your domain name": "ドメイン名を入力してください",
      Back: "戻る",
      Save: "保存する",
      "Upload primary logo (svg or png)":
        "主要なロゴをアップロードする（svgまたはpng）",
      "Upload secondary logo (svg or png)":
        "セカンダリロゴをアップロードする（svgまたはpng）",
      "Upload cover picture": "カバー写真をアップロードする",
      "No logo, you want us to make one for you":
        "ロゴがない場合、私たちが作成することを希望しますか？",
      "Click Here": "こちらをクリック",
      "Select primary color": "主要な色を選択する",
      "Select secondary color": "セカンダリ色を選択する",
      "Company name": "会社名",
      "Enter Company Name": "会社名を入力してください",
      "Registration Number": "登録番号",
      "Enter Registration Number": "登録番号を入力してください",
      "Full Address": "完全な住所",
      "Enter Full Address": "完全な住所を入力してください",
      "Signee full name": "署名者のフルネーム",
      "Signee role": "署名者の役割",
      "Landing / Home Screen": "ランディングページ / ホーム画面",
      "Do you need landing?": "ランディングページが必要ですか？",
      "Select Template": "テンプレートを選択する",
      "No, I will use my own website as Landing page.":
        "いいえ、独自のウェブサイトをランディングページとして使用します。",
      "Do you need Integrations?": "統合が必要ですか？",
      "Payment Service Provider": "支払いサービスプロバイダ",
      "Make sure to get familiar with Stripe service fees":
        "Stripeのサービス料金について詳しくなるようにしてください",
      "Additional services": "追加サービス",
      "Service Name": "サービス名",
      Price: "価格",
      "Mobile app": "モバイルアプリ",
      SMM: "SMM",
      "Graphics for SMM": "SMM用のグラフィック",
      "Your request": "あなたのリクエスト",
      "Type here your request": "ここにあなたのリクエストを入力してください",
      "Upload file": "ファイルをアップロードする",
      "Entry service fee": "エントリーサービス料金",
      "Tournated entry service fee": "トーナメントエントリーサービス料金",
      "Entery entery fee": "エントリーエントリー料金",
      "Partner entry service fee": "パートナーエントリーサービス料金",
      "Enter partner entry fee": "パートナーエントリー料金を入力してください",
      "License service fee": "ライセンスサービス料金",
      "Tournated license service fee": "トーナメントライセンスサービス料金",
      "Enter tournated license fee":
        "トーナメントライセンス料金を入力してください",
      "Partner license service fee": "パートナーライセンスサービス料金",
      "Enter partner license fee": "パートナーライセンス料金を入力してください",
      "Enable Pricing": "価格設定を有効にする",
      Leagues: "リーグ",
      "Create new League": "新しいリーグを作成する",
      "There is no available leagues": "利用可能なリーグはありません",
      Completed: "完了",
      Upcoming: "今後",
      Active: "アクティブ",
      Total: "合計",
      "Search Leagues": "リーグを検索する",
      "League Title": "リーグタイトル",
      "League Type": "リーグタイプ",
      Logo: "ロゴ",
      Tournaments: "トーナメント",
      Athletes: "選手",
      Regulations: "規制",
      Platform: "プラットフォーム",
      "We recommend an image that is 256 x 256 pixels":
        "256 x 256ピクセルの画像を推奨します",
      "League Abbreviation": "リーグ略称",
      "League owner": "リーグ所有者",
      "Select Organization": "組織を選択する",
      "Select platform": "プラットフォームを選択する",
      "League Priority": "リーグ優先度",
      "Go to Next": "次に進む",
      Phone: "電話",
      "League Website": "リーグのウェブサイト",
      Instagram: "Instagram",
      "Field Title": "フィールドタイトル",
      "Field Type": "フィールドタイプ",
      "Add New Point": "新しいポイントを追加する",
      "Title Name": "タイトル名",
      Select: "選択する",
      "Field Value": "フィールドの値",
      "Add Another Option Below": "以下に別のオプションを追加する",
      Update: "更新する",
      Management: "管理",
      "League status": "リーグのステータス",
      "Select one": "選択してください",
      "Make this league featured": "このリーグを特集にする",
      "Enable rankings": "ランキングを有効にする",
      "enable ranking":
        "トーナメントカテゴリを接続し、年齢/性別制限を定義し、追加のルールを追加することで、リーグのカスタマイズされたランキングを作成します。",
      "Enable online payments": "オンライン支払いを有効にする",
      "enable online payment":
        "トーナメントエントリーやライセンスのオンライン支払いを受け付け始めます。オンライン支払いの管理に関するサービス料金モデルについては、こちらをクリックして詳細を確認してください。",
      pitchdeck: "ピッチデッキ",
      "Enable licences": "ライセンスを有効にする",
      "License description":
        "ライセンスの設定、価格、期間、ルールを設定します。",
      "Make club field mandatory and display club details as organizer on Tournament page":
        "クラブフィールドを必須にし、トーナメントページでクラブの詳細をオーガナイザーとして表示します。",
      "Create League": "リーグを作成する",
      "Organization Accounts": "組織アカウント",
      "ADD NEW ACCOUNT": "新しいアカウントを追加する",
      "There is no available organizations": "利用可能な組織はありません",
      "Search Organizations": "組織を検索する",
      "Full name": "フルネーム",
      "Organization Abbrevation": "組織略称",
      "UPDATE USER PROFILE": "ユーザープロファイルを更新する",
      "Enter phone number": "電話番号を入力してください",
      "Organization Settings": "組織設定",
      "Organization Type": "組織タイプ",
      "Organization Logo": "組織ロゴ",
      "About Organization": "組織について",
      "Contact Details": "連絡先詳細",
      Users: "ユーザー",
      "Search User": "ユーザーを検索",
      "ADD NEW USER": "新しいユーザーを追加",
      Avatar: "アバター",
      "Phone Number": "電話番号",
      Role: "役割",
      "IPIN License": "IPINライセンス",
      "DUPR License": "DUPRライセンス",
      "Tennis Europe License": "テニスヨーロッパライセンス",
      "LTS U10 License": "LTS U10ライセンス",
      "FIDE License": "FIDEライセンス",
      "Add new user": "新しいユーザーを追加",
      "Edit User": "ユーザーを編集する",
      "User Account": "ユーザーアカウント",
      "Date of birth": "生年月日",
      Gender: "性別",
      "Representing country": "代表国",
      City: "市町村",
      "Enter city": "市町村を入力してください",
      Address: "住所",
      "Enter address": "住所を入力してください",
      Career: "経歴",
      Club: "クラブ",
      "Select club": "クラブを選択",
      "Select coach": "コーチを選択",
      Parent: "保護者",
      "Select parent": "保護者を選択",
      "Write a bio": "バイオを書く",
      CHECKBOXES: "チェックボックス",
      "Give access to coach to purchase licences and manage entries on your behalf":
        "コーチにライセンスを購入し、エントリーを管理する権限を与える",
      "Give access to club manager(-es) to purchase licences and manage entries on your behalf":
        "クラブマネージャーにライセンスを購入し、エントリーを管理する権限を与える",
      "Give access to parent(-s) to purchase licences and manage entries on your behalf":
        "保護者にライセンスを購入し、エントリーを管理する権限を与える",
      Socials: "ソーシャル",
      "Insert link here": "ここにリンクを挿入",
      Facebook: "Facebook",
      Linkedin: "Linkedin",
      Tiktok: "Tiktok",
      Youtube: "Youtube",
      Licence: "ライセンス",
      "ITF IPIN": "ITF IPIN",
      DUPRID: "DUPRID",
      "Tennis Europe ID": "テニスヨーロッパID",
      "LTS U10": "LTS U10",
      ATP: "ATP",
      WTA: "WTA",
      FIDE: "FIDE",
      "Update password": "パスワードを更新する",
      "Re-Enter": "再入力",
      "Login settings": "ログイン設定",
      "Switch Standard login to Social login":
        "標準ログインをソーシャルログインに切り替える",
      "Connect Gmail": "Gmailを接続",
      "Enable 2FA Google Authorisation": "2段階認証のGoogle認証を有効にする",
      "Enable 2FA Email Authorisation": "2段階認証のメール認証を有効にする",
      "Role Name": "役割名",
      "Is user verified?": "ユーザーは確認されましたか？",
      Yes: "はい",
      No: "いいえ",
      Tournament: "トーナメント",
      "All tournaments": "すべてのトーナメント",
      "League tournaments": "リーグトーナメント",
      "Search Tournaments": "トーナメントを検索",
      "Create new Tournament": "新しいトーナメントを作成",
      "Tournament Title": "トーナメントタイトル",
      "Start - End": "開始 - 終了",
      Organizer: "主催者",
      Location: "場所",
      "List of categories": "カテゴリのリスト",
      "Update Status": "ステータスを更新",
      "There is no available tournaments": "利用可能なトーナメントはありません",
      "ADD NEW SPORT": "新しいスポーツを追加",
      "Search Sports": "スポーツを検索",
      "Sport Title": "スポーツタイトル",
      "Add new Sport": "新しいスポーツを追加",
      "Edit Sports": "スポーツを編集",
      "Sports title": "スポーツタイトル",
      "Enter title": "タイトルを入力してください",
      "Sports abbreviation": "スポーツ略称",
      "Enter abbreviation": "略称を入力してください",
      "Resource title": "リソースタイトル",
      "Rule how to add results/score": "結果/スコアの追加ルール",
      Icon: "アイコン",
      "Enable disciplines": "ディシプリンを有効にする",
      "Enable courts": "コートを有効にする",
      "Enable stages": "ステージを有効にする",
      "Enable pairings": "ペアリングを有効にする",
      "Enable rounds": "ラウンドを有効にする",
      "Enable standings based on pairings":
        "ペアリングに基づいた順位表を表示する",
      "Display draws": "ドローを表示する",
      "Display groups": "グループを表示する",
      "Display live standings": "ライブ順位表を表示する",
      "Display order of play": "プレイオーダーを表示する",
      "Display list & results": "リストと結果を表示する",
      "Display pending results": "保留中の結果を表示する",
      "Display final standings": "最終順位表を表示する",
      "Display athlete chess titles in Participants":
        "参加者のチェスタイトルを表示する",
      "Display seeds column Participants": "参加者のシード列を表示する",
      "Display segments column in Participants":
        "参加者のセグメント列を表示する",
      "Upload file here": "ここにファイルをアップロード",
      Disciplines: "ディシプリン",
      "ADD NEW DISCIPLINE": "新しいディシプリンを追加",
      "There is no available disciplines": "利用可能なディシプリンはありません",
      "Search Disciplines": "ディシプリンを検索",
      "Sport name": "スポーツ名",
      "Add new Discipline": "新しいディシプリンを追加",
      "Edit Discipline": "ディシプリンを編集",
      "Discipline title": "ディシプリンタイトル",
      "Select Sport": "スポーツを選択",
      "No sports found": "スポーツが見つかりません",
      "Results type": "結果タイプ",
      "Third-party licences": "サードパーティライセンス",
      "Third party licence": "サードパーティライセンス",
      "There is no available third-party licences":
        "利用可能なサードパーティライセンスはありません",
      "ADD NEW LICENCE": "新しいライセンスを追加",
      "Search license": "ライセンスを検索",
      "license name": "ライセンス名",
      "License type": "ライセンスタイプ",
      "Add licence": "ライセンスを追加",
      "Add Third-party licence": "サードパーティライセンスを追加",
      "Edit Third-party licence": "サードパーティライセンスを編集",
      "Organization title": "組織タイトル",
      "Organization abbreviation": "組織略称",
      "Licence title": "ライセンスタイトル",
      "Enter license title": "ライセンスタイトルを入力してください",
      "Licence type": "ライセンスタイプ",
      "Request license in user profile":
        "ユーザープロファイルでライセンスをリクエスト",
      "Request license on user registration in platform":
        "プラットフォームでのユーザー登録時にライセンスをリクエスト",
      "Make licence mandatory": "ライセンスを必須にする",
      "Link to get licence": "ライセンス取得のリンク",
      "Enter link to get licence": "ライセンス取得のリンクを入力してください",
      Partners: "パートナー",
      "ADD NEW PARTNER": "新しいパートナーを追加",
      "There is no available partners": "利用可能なパートナーはありません",
      "Search Partners": "パートナーを検索",
      "Create new Partner": "新しいパートナーを作成",
      Link: "リンク",
      Text: "テキスト",
      Priority: "優先度",
      "Showing dates": "表示する日付",
      "New Partner": "新しいパートナー",
      "Edit Partner": "パートナーを編集",
      "Partner Logo": "パートナーロゴ",
      platform: "プラットフォーム",
      "No platform available": "利用可能なプラットフォームはありません",
      "No league available": "利用可能なリーグはありません",
      "No Clubs available": "利用可能なクラブはありません",
      "Sponsor Text": "スポンサーテキスト",
      "Date Settings": "日付設定",
      "Active From": "有効期間（開始）",
      "Active To": "有効期間（終了）",
      "Platform managers": "プラットフォームマネージャー",
      "ADD NEW MANAGER": "新しいマネージャーを追加",
      "There is no available manager": "利用可能なマネージャーはいません",
      "Search Manager": "マネージャーを検索",
      "Export team": "チームをエクスポート",
      "Export team emails": "チームのメールアドレスをエクスポート",
      "Add platform manager": "プラットフォームマネージャーを追加",
      "Edit platform panager": "プラットフォームマネージャーを編集",
      "Enter Organization": "組織を入力してください",
      "Select organization": "組織を選択",
      "Filter users": "ユーザーをフィルター",
      "Export users": "ユーザーをエクスポート",
      "Export user emails": "ユーザーのメールアドレスをエクスポート",
      "Age group": "年齢層",
      "There is no available users": "利用可能なユーザーはいません",
      Coaches: "コーチ",
      "ADD NEW COACH": "新しいコーチを追加",
      "There is no available account": "利用可能なアカウントはありません",
      "Search Coach": "コーチを検索",
      "Add new coach": "新しいコーチを追加",
      "Edit Coach": "コーチを編集",
      "Coach image": "コーチ画像",
      Category: "カテゴリー",
      "There is no available sports": "利用可能なスポーツはありません",
      "there are not available clubs": "利用可能なクラブはありません",
      "Date from": "日付（開始）",
      "Date to": "日付（終了）",
      "Display on organization's landing page":
        "組織のランディングページに表示",
      "Platform tournaments": "プラットフォームトーナメント",
      Title: "タイトル",
      Posts: "ポスト",
      "ADD POST": "ポストを追加",
      "There is no available post": "利用可能な投稿はありません",
      News: "ニュース",
      "Search Post": "投稿を検索",
      Image: "画像",
      Author: "著者",
      Content: "内容",
      "Cover / featured image": "カバー / 注目の画像",
      "Select league": "リーグを選択",
      League: "リーグ",
      "Add video from youtube.com": "YouTubeからビデオを追加",
      Date: "日付",
      "Purchased Licenses": "購入したライセンス",
      "Search Licenses": "ライセンスを検索",
      "Create new License": "新しいライセンスを作成",
      "Athlete name & surname": "選手名と姓",
      "License Name": "ライセンス名",
      "License Price": "ライセンス価格",
      "License active from": "ライセンス有効開始日",
      "License active to": "ライセンス有効終了日",
      "Paid(online/cash)": "支払い（オンライン/現金）",
      "There is no available purchased license":
        "購入済みのライセンスはありません",
      "New License": "新しいライセンス",
      "Edit License": "ライセンスを編集",
      "Athlete Email": "選手のメールアドレス",
      License: "ライセンス",
      "No license found": "ライセンスが見つかりません",
      "Payment Method": "支払い方法",
      "How much paid": "支払い金額",
      Teams: "チーム",
      "ADD NEW TEAM": "新しいチームを追加",
      "There is no available teams": "利用可能なチームはありません",
      "Search team": "チームを検索",
      "Create new Team": "新しいチームを作成",
      "Team Members": "チームメンバー",
      Discipline: "ディシプリン",
      "New Team": "新しいチーム",
      "Edit Team": "チームを編集",
      "Team Logo": "チームロゴ",
      "No Clubs Found": "クラブが見つかりません",
      "No disciplines found": "ディシプリンが見つかりません",
      "Club status": "クラブの状態",
      Members: "メンバー",
      "Add new Members": "新しいメンバーを追加",
      "Add New Member": "新しいメンバーを追加",
      "Member email": "メンバーのメールアドレス",
      "Select Role": "役割を選択",
      "Add more": "さらに追加",
      "ADD NEW CLUB": "新しいクラブを追加",
      "There is no available clubs": "利用可能なクラブはありません",
      Clubs: "クラブ",
      "Search Clubs": "クラブを検索",
      "Club Name": "クラブ名",
      "Add new Club": "新しいクラブを追加",
      "Edit Club": "クラブを編集",
      "Appearance & Location": "外観と場所",
      "Club title": "クラブタイトル",
      "Add club logo": "クラブロゴを追加",
      "How to find us": "見つける方法",
      "Contact details": "連絡先詳細",
      "Contact Phone": "連絡先電話",
      Twitter: "Twitter",
      "Services & Resources": "サービスとリソース",
      "Select services": "サービスを選択",
      Services: "サービス",
      Resources: "リソース",
      "Resource Name": "リソース名",
      Service: "サービス",
      "Add Resources": "リソースを追加",
      "Add New Resource": "新しいリソースを追加",
      "Select Service": "サービスを選択",
      "Select type": "タイプを選択",
      "Add Another": "別の追加",
      "Club page": "クラブページ",
      "Select Coaches": "コーチを選択",
      "Athlete Club": "選手クラブ",
      desc: "クラブが追加されると、選手は選手プロファイル設定ページでそれを選択し、承認/否認されるためのクラブページに表示されます。 承認されたメンバーはクラブページに表示されます",
      "Online booking": "オンライン予約",
      "Club Main Administrator": "クラブメイン管理者",
      "Main Adminitrator": "メイン管理者",
      "CLUB MAIN ADMINISTRATOR IS NOT IN THE LIST":
        "クラブのメイン管理者がリストにありません",
      Administrator: "管理者",
      "ADMINISTRATOR IS NOT IN THE LIST": "管理者がリストにありません",
      Banners: "バナー",
      "There is no available banners": "利用可能なバナーはありません",
      "ADD BANNER": "バナーを追加",
      "Search Banner": "バナーを検索",
      Headline: "ヘッドライン",
      subheadline: "サブヘッドライン",
      "Button Text": "ボタンテキスト",
      "New Banner": "新しいバナー",
      "Edit Banner": "バナーを編集",
      Subheadline: "サブヘッドライン",
      "Button text": "ボタンテキスト",
      "Button link": "ボタンリンク",
      "Select roles": "役割を選択",
      "Banner images": "バナー画像",
      "Control Panel": "コントロールパネル",
      "Run the tournament step by step from this control panel":
        "このコントロールパネルからトーナメントをステップバイステップで実行する",
      "Edit & Setup league": "リーグを編集してセットアップする",
      "Edit Now": "今すぐ編集",
      "Create & Setup league": "リーグを作成してセットアップする",
      "Start Now": "今すぐ開始",
      "Add league members": "リーグメンバーを追加する",
      "league member description":
        "マネージャーや大会主催者、審判などのリーグメンバーを含めます。 マネージャーはダッシュボードとすべての管理機能にフルアクセスでき、主催者は大会を追加して管理でき、審判は割り当てられた大会のエントリ、抽選、グループ、ライブ順位、プレイオーダー、結果管理にアクセスできます。",
      "Add Now": "今すぐ追加",
      "Add tournament categories": "大会カテゴリを追加する",
      "tournament category description":
        "リーグのために大会カテゴリを設定し、形式（シングルス/ダブルス/チーム）、年齢/性別制限を指定し、それらをランキングカテゴリに接続します（次のステップでランキングカテゴリを追加した後）。",
      "Add rankings categories": "ランキングカテゴリを追加する",
      "ranking category description":
        "リーグのためにランキングカテゴリを確立します。 ランキングカテゴリを含める大会カテゴリを選択し、カウントする大会の数を指定し、タイムフレームを設定し、国際リーグと接続し、その他の規則を確立します。",
      "Setup ranking points": "ランキングポイントを設定する",
      "ranking description":
        "各大会カテゴリのポイントテーブルを設定し、各配置のポイント数を指定します。 より詳細な表がプロセスを効率化し自動化します。",
      Setup: "セットアップ",
      "Setup licences": "ライセンスをセットアップする",
      "Add tournament": "トーナメントを追加する",
      "tournament description":
        "トーナメントを作成し、イベントの管理を開始するためのステップに従います。 お知らせしますが、スポーツイベントの管理に関する数多くの機能をカバーする包括的な手順リストを受け取ります。",
      "Leagues Members": "リーグメンバー",
      "Add new Member": "新しいメンバーを追加",
      "There is no available members": "利用可能なメンバーはいません",
      "League members": "リーグメンバー",
      "Search Members": "メンバーを検索",
      "ADD NEW MEMBER": "新しいメンバーを追加",
      "Delete league member": "リーグメンバーを削除する",
      "Are you sure you want to delete?": "本当に削除しますか？",
      Cancel: "キャンセル",
      Delete: "削除",
      "Tournament Categories": "トーナメントカテゴリ",
      "There is no available categories": "利用可能なカテゴリはありません",
      "ADD NEW CATEGORY": "新しいカテゴリを追加",
      "Search Categories": "カテゴリを検索",
      "Copy Categories": "カテゴリをコピー",
      "Delete Categories": "カテゴリを削除",
      "Category Name": "カテゴリ名",
      "Category Type": "カテゴリタイプ",
      "Select League": "リーグを選択",
      Copy: "コピー",
      "Are you sure you want to delete these categories?":
        "これらのカテゴリを削除してもよろしいですか？",
      "New Category": "新しいカテゴリ",
      "Edit Category": "カテゴリを編集",
      "Category name": "カテゴリ名",
      "Add team selection on tournament registration":
        "トーナメント登録時にチーム選択を追加する",
      "Add club selection on tournament registration":
        "トーナメント登録時にクラブ選択を追加する",
      "Skills level": "スキルレベル",
      "Category Gender": "カテゴリー性別",
      "Age from": "開始年齢",
      "Age to": "終了年齢",
      "Age Limit Type": "年齢制限タイプ",
      "No Types Found!": "タイプが見つかりません！",
      "Ask skills level on a tournament registration":
        "トーナメント登録時にスキルレベルを問う",
      "Enable License": "ライセンスを有効にする",
      "Display athlete club in Participants": "参加者に選手のクラブを表示する",
      "Display athlete city in Participants": "参加者に選手の都市を表示する",
      "Select License": "ライセンスを選択",
      "No License Found!": "ライセンスが見つかりません！",
      "Ranking Category for men": "男性用ランキングカテゴリー",
      "No Category Found!": "カテゴリーが見つかりません！",
      "Ranking Category for Women": "女性用ランキングカテゴリー",
      "Third-party license": "サードパーティーのライセンス",
      "No third-party license Found!":
        "サードパーティーのライセンスが見つかりません！",
      "Time control": "時間制御",
      "No time control found": "時間制御が見つかりません",
      "Order display for seeding": "シード順位の表示順",
      "Sport fishing settings": "スポーツフィッシング設定",
      "Identify team members on registration":
        "登録時にチームメンバーを識別する",
      "Min number of members": "最小メンバー数",
      "Max number of members": "最大メンバー数",
      "Reservists required": "予備員が必要",
      "Min number of reservists": "最小予備員数",
      "Max number of reservists": "最大予備員数",
      "Captain required": "キャプテンが必要",
      "Transfer members to singles category":
        "メンバーをシングルスカテゴリーに移動",
      "Select singles category": "シングルスカテゴリーを選択",
      "No Category Found": "カテゴリーが見つかりません",
      "National tournament (country vs country)":
        "ナショナルトーナメント（国対国）",
      "Club championships (club vs club)": "クラブ選手権大会（クラブ対クラブ）",
      "Create matches from team members and count points result based on matches result":
        "チームメンバーから試合を作成し、試合結果に基づいてポイントをカウントする",
      "Count individual total": "個人合計をカウント",
      "Count teams total": "チーム合計をカウント",
      "Number of athletes to count min": "最小カウントする選手数",
      "Number of athletes to count max": "最大カウントする選手数",
      "Max Entries per team": "チームごとの最大エントリー数",
      "Allow registration without team": "チームなしでの登録を許可",
      "Category Status": "カテゴリーのステータス",
      "Ranking Categories": "ランキングカテゴリー",
      "There is no available ranking categories":
        "利用可能なランキングカテゴリーはありません",
      Categories: "カテゴリー",
      "No of Tournaments": "大会の数",
      "Time Period": "時間帯",
      "No Leagues found": "リーグが見つかりません",
      "New Ranking Category": "新しいランキングカテゴリー",
      "Select Categories": "カテゴリーを選択",
      "No Categories Found!": "カテゴリーが見つかりません！",
      "Max number of tournaments": "最大大会数",
      "Count specific tournaments": "特定の大会をカウント",
      "Add penalty points for missing a tournament":
        "大会不参加のペナルティポイントを追加",
      "Penalty rule": "ペナルティルール",
      "Enter points": "ポイントを入力",
      "Additional Rules": "追加ルール",
      Sector: "セクター",
      Coefficient: "係数",
      "No of tournaments": "大会数",
      "Edit additional rule(s)": "追加ルールを編集",
      "Add additional rule(s)": "追加ルールを追加",
      "During which period tournament points are counted":
        "どの期間に大会ポイントがカウントされますか",
      "Select Time Period": "時間帯を選択",
      "Points sorting": "ポイントのソート",
      "Sport Fishing": "スポーツフィッシング",
      "Select Stage form which take points": "ポイントを取るステージを選択",
      "Ranking Points": "ランキングポイント",
      "Search Rankings": "ランキングを検索",
      "Add points block": "ポイントブロックを追加",
      "Select / UnSelect All": "すべて選択 / 選択解除",
      Place: "場所",
      Points: "ポイント",
      "Bracket type": "ブラケットタイプ",
      Segment: "セグメント",
      "Num of Entries": "エントリー数",
      "Ad.Rules": "追加ルール",
      "view rule": "ルールを表示",
      "Add new row": "新しい行を追加",
      "There is no available ranking points":
        "利用可能なランキングポイントはありません",
      "Edit Points": "ポイントを編集",
      "Add Points": "ポイントを追加",
      "Points block name": "ポイントブロック名",
      Specification: "仕様",
      "Rule Settings": "ルール設定",
      "Select operator": "演算子を選択",
      "Select skills level": "スキルレベルを選択",
      "Number of players": "選手数",
      "Number of entries": "エントリー数",
      update: "更新",
      SAVE: "保存",
      DISCARD: "破棄",
      "SET NEW RULE": "新しいルールを設定",
      "Edit Row": "行を編集",
      "Add Row": "行を追加",
      ADD: "追加",
      "Copy Ranking Point": "ランキングポイントをコピー",
      "Select Ranking Points": "ランキングポイントを選択",
      "Copy Ranking Point Blocks": "ランキングポイントブロックをコピー",
      "Delete Ranking Point Block": "ランキングポイントブロックを削除",
      Licenses: "ライセンス",
      "There is no available licenses": "利用可能なライセンスはありません",
      "Price Currency": "価格通貨",
      "Select Leagues": "リーグを選択",
      "No League Found!": "リーグが見つかりません！",
      "License price": "ライセンス価格",
      "License Type": "ライセンスタイプ",
      "License Status": "ライセンスステータス",
      "Additional questions": "追加質問",
      "Add additional question": "追加質問を追加",
      "Edit this": "これを編集",
      "Add new": "新規追加",
      question: "質問",
      Question: "質問",
      "Field Option": "フィールドオプション",
      Remove: "削除",
      "Add new option": "新しいオプションを追加",
      "Ask this question to each athlete": "各選手にこの質問を尋ねる",
      Submit: "送信",
      "Delete Warning": "削除警告",
      "Are you sure you want to delete this question?":
        "この質問を削除してもよろしいですか？",
      "Confirm Deletion": "削除を確認",
      Rankings: "ランキング",
      Search: "検索",
      "ranking Category": "ランキングカテゴリー",
      "No date Found": "データが見つかりません",
      "Create Rankings": "ランキングを作成",
      "Update Rankings": "ランキングを更新",
      close: "閉じる",
      Year: "年",
      "Points Details": "ポイントの詳細",
      "No points yet": "まだポイントがありません",
      Position: "位置",
      Athlete: "選手",
      "There is no available rankings": "利用可能なランキングはありません",
      "Select Ranking categories": "ランキングカテゴリーを選択",
      "Remove Rankings": "ランキングを削除",
      "Select Date": "日付を選択",
      "Are you sure you want to delete rankings?":
        "このランキングを削除してもよろしいですか？",
      "Rankings History": "ランキング履歴",
      "Ranking Category": "ランキングカテゴリー",
      "Last Updated": "最終更新日",
      Public: "公開",
      "Delete warning": "削除警告",
      "Are you sure you want to delete this ranking?":
        "このランキングを削除してもよろしいですか？",
      "There is no available rankings history":
        "利用可能なランキング履歴はありません",
      "Tournament Results": "大会結果",
      Entry: "エントリー",
      "Add new result": "新しい結果を追加",
      "There is no available tournament results":
        "利用可能な大会結果はありません",
      "Pendding tournaments": "保留中の大会",
      "There is no available athletes": "利用可能な選手はいません",
      "Search Athletes": "選手を検索",
      "Country/Residency": "国/居住地",
      "Super Data": "スーパーデータ",
      "All Tournaments": "すべての大会",
      "Pending Tournaments": "保留中の大会",
      Financials: "財務",
      Accounting: "会計",
      Extra: "エクストラ",
      "Live scoring": "ライブスコアリング",
      "Live stream": "ライブストリーム",
      "Platform Settings": "プラットフォーム設定",
      "My Platforms": "マイプラットフォーム",
      Team: "チーム",
      Instructions: "指示",
      "League Settings": "リーグ設定",
      "League Members": "リーグメンバー",
      Upload: "アップロード",
      "primary logo": "プライマリーロゴ",
      "secondary logo": "セカンダリーロゴ",
      "cover picture": "カバー画像",
      "Start Typing": "入力を開始",
      "Leagues list": "リーグリスト",
      "General & Appearance": "一般と外観",
      "Instagram Link": "Instagramリンク",
      "Fact Sheet": "ファクトシート",
      "Fact sheet": "ファクトシート",
      "Upload regulations": "規則をアップロード",
      "Organization Abbreviation": "組織略称",
      "Drag and Drop some file": "ファイルをドラッグアンドドロップ",
      "here, or click to Select file":
        "ここにファイルを選択するかクリックしてください",
      UPLOAD: "アップロード",
      "DISCARD AND CANCEL": "破棄してキャンセル",
      SUBMIT: "送信",
      "Add new Organization Account": "新しい組織アカウントを追加",
      "Edit Organization Account": "組織アカウントを編集",
      "Full Name": "フルネーム",
      Biography: "経歴",
      "Add post": "投稿を追加",
      "Edit Post": "投稿を編集",
      "Edit Member": "メンバーを編集",
      "Organization name": "組織名",
      "Organization email": "組織のメールアドレス",
      "Organization phone": "組織の電話番号",
      "Display member on organizations public team page":
        "組織の公開チームページにメンバーを表示",
      "Select Roles": "役割を選択",
      "For assiging points": "ポイントを割り当てるために",
      "Third party license": "サードパーティーのライセンス",
      Data: "データ",
      "Must be a valid email": "有効なメールアドレスを入力してください",
      "Email is required": "メールアドレスは必須です",
      "Organization Name is required": "組織名は必須です",
      "Organization Type is required": "組織の種類は必須です",
      "organization abbreviation is required": "組織の略称は必須です",
      "Contact Email is required": "連絡先メールアドレスは必須です",
      "Password is Required": "パスワードは必須です",
      "Password should be 8 chars minimum.":
        "パスワードは8文字以上で入力してください",
      "Passwords must match": "パスワードが一致しません",
      "company name is required": "会社名は必須です",
      "company number is required": "会社番号は必須です",
      "full address is required": "住所は必須です",
      "Invalid email address": "無効なメールアドレスです",
      "signee role is required": "署名者の役割は必須です",
      "signee full name is required": "署名者のフルネームは必須です",
      "partner entry fee is required": "パートナーの参加費は必須です",
      "tournated license fee is required": "トーネードライセンス料金は必須です",
      "partner license fee is required": "パートナーライセンス料金は必須です",
      "Creating platform...": "プラットフォームを作成中...",
      "Platform created successfully": "プラットフォームが作成されました",
      "Updating...": "更新中...",
      "Platform updated": "プラットフォームが更新されました",
      "Add New Club": "新しいクラブを追加",
      "organization is required": "組織は必須です",
      "Adding new fact...": "新しい事実を追加中...",
      "Fact added successfully": "事実が追加されました",
      "Deleting fact...": "事実を削除中...",
      "fact deleted successfully": "事実が削除されました",
      "Updating fact...": "事実を更新中...",
      "Fact updated successfully": "事実が更新されました",
      "Creating league...": "リーグを作成中...",
      "League created successfully": "リーグが作成されました",
      "Updating league...": "リーグを更新中...",
      "League updated successfully": "リーグが更新されました",
      "Approved & Public": "承認済み＆公開",
      "ATP points": "ATPポイント",
      "WTA points": "WTAポイント",
      "name Email is required": "名前のメールアドレスは必須です",
      "title is require": "タイトルは必須です",
      "platform type required": "プラットフォームの種類は必須です",
      "time zone is required": "タイムゾーンは必須です",
      "description is required": "説明は必須です",
      "title is required": "タイトルは必須です",
      "abbreviation is required": "略語は必須です",
      "platform is required": "プラットフォームは必須です",
      "priority is required": "優先度は必須です",
      "type is required": "タイプは必須です",
      "sports is required": "スポーツは必須です",
      "email is required": "メールアドレスは必須です",
      "Organization added Successfully!": "組織が正常に追加されました！",
      "name is required": "名前は必須です",
      "surname is required": "姓は必須です",
      "organization name is required": "組織名は必須です",
      "organization type is required": "組織の種類は必須です",
      "Organization Account Updated Successfully!":
        "組織アカウントが正常に更新されました！",
      "Creating organization...": "組織を作成中...",
      "Organization created successfully": "組織が作成されました",
      "This User is already exist": "このユーザーはすでに存在します",
      "Password is required": "パスワードは必須です",
      "Re-Enter password is required": "パスワードの再入力は必須です",
      "Password updated": "パスワードが更新されました",
      "User Updated": "ユーザーが更新されました",
      "Sports Updated": "スポーツが更新されました",
      "Sports Created": "スポーツが作成されました",
      "Discipline Updated": "ディシプリンが更新されました",
      "Discipline Created": "ディシプリンが作成されました",
      "At least one sport must be selected":
        "少なくとも1つのスポーツを選択してください",
      "License Updated": "ライセンスが更新されました",
      "License Created": "ライセンスが作成されました",
      "Please provide Image": "画像を提供してください",
      "Partner updated Successfully!": "パートナーが正常に更新されました！",
      "Partner created successfully!": "パートナーが正常に作成されました！",
      "Please add correct user email":
        "正しいユーザーのメールアドレスを追加してください",
      "Coach added!": "コーチが追加されました！",
      "user found": "ユーザーが見つかりました",
      "user not found": "ユーザーが見つかりません",
      "country is required": "国は必須です",
      "gender is required": "性別は必須です",
      "confirm password is required": "パスワードの確認は必須です",
      "Coache Updated Successfully!": "コーチが正常に更新されました！",
      "Image field is required": "画像フィールドは必須です",
      "author is required": "著者は必須です",
      "Updating ...": "更新中...",
      "Post updated successfully": "投稿が正常に更新されました",
      "Creating post ...": "投稿を作成中...",
      "Post created successfully": "投稿が正常に作成されました",
      "User found": "ユーザーが見つかりました",
      "User not found": "ユーザーが見つかりません",
      "paid is required": "支払いが必要です",
      "currency is required": "通貨は必須です",
      "Athlete is required": "アスリートは必須です",
      "license is required": "ライセンスは必須です",
      "start date is required": "開始日は必須です",
      "end date is required": "終了日は必須です",
      "License created successfully!": "ライセンスが正常に作成されました！",
      "sport is required": "スポーツは必須です",
      "discipline is required": "ディシプリンは必須です",
      "Team updated successfully!": "チームが正常に更新されました！",
      "Team created successfully!": "チームが正常に作成されました！",
      "city is required": "市区町村は必須です",
      "address is required": "住所は必須です",
      "headline is required": "見出しは必須です",
      "subheadline is required": "サブ見出しは必須です",
      "button text required": "ボタンのテキストが必要です",
      "button link required": "ボタンのリンクが必要です",
      "Image are required": "画像が必要です",
      "Updating banner...": "バナーを更新中...",
      "Banner updated successfully": "バナーが正常に更新されました",
      "Creating banner...": "バナーを作成中...",
      "Banner created successfully": "バナーが正常に作成されました",
      "League member added!": "リーグメンバーが追加されました！",
      "League Member Updated Successfully!":
        "リーグメンバーが正常に更新されました！",
      "order is required": "オーダーは必須です",
      "Age from must an integer": "年齢（開始）は整数でなければなりません",
      "Age must be at least 0": "年齢は0以上でなければなりません",
      "Age to must an integer": "年齢（終了）は整数でなければなりません",
      "Min members must an integer": "最小メンバー数は整数でなければなりません",
      "Min members must be at least 0":
        "最小メンバー数は0以上でなければなりません",
      "Max members must an integer": "最大メンバー数は整数でなければなりません",
      "Max members must be at least 0":
        "最大メンバー数は0以上でなければなりません",
      "Max members must be greater than and equal to min members":
        "最大メンバー数は最小メンバー数以上でなければなりません",
      "Min reservists must an integer":
        "最小予備員数は整数でなければなりません",
      "Min reservists must be at least 0":
        "最小予備員数は0以上でなければなりません",
      "Max reservists must an integer":
        "最大予備員数は整数でなければなりません",
      "Max reservists must be at least 0":
        "最大予備員数は0以上でなければなりません",
      "Max reservists must be greater than and equal to min reservists":
        "最大予備員数は最小予備員数以上でなければなりません",
      "Min number of athletes must an integer":
        "最小アスリート数は整数でなければなりません",
      "Min number of athletes must be at least 0":
        "最小アスリート数は0以上でなければなりません",
      "Max number of athletes must an integer":
        "最大アスリート数は整数でなければなりません",
      "Max number of athletes must be at least 0":
        "最大アスリート数は0以上でなければなりません",
      "Max number of athletes must be greater than min number of athletes":
        "最大アスリート数は最小アスリート数より大きくなければなりません",
      "status is required": "ステータスは必須です",
      "Category Updated Successfully!": "カテゴリが正常に更新されました！",
      "Category Created!": "カテゴリが作成されました！",
      "time period is required": "時間帯は必須です",
      "sort is required": "ソート順は必須です",
      "at-least-one": "少なくとも1つ",
      "At least one category is required": "少なくとも1つのカテゴリが必要です",
      "Value cannot be less than 1": "値は1未満にすることはできません",
      "place is required": "場所は必須です",
      "entries is required": "エントリーは必須です",
      "points is required": "ポイントは必須です",
      "You can't remove this category because this is used into additional rules":
        "このカテゴリを削除できません。追加ルールで使用されています",
      "Rule added successfully": "ルールが正常に追加されました",
      "Rule updated successfully": "ルールが正常に更新されました",
      "Points updated successfully": "ポイントが正常に更新されました",
      "Points added successfully": "ポイントが正常に追加されました",
      "Removing...": "削除中...",
      "Removed successfully!": "正常に削除されました！",
      "fee is required": "料金は必須です",
      "duration is required": "期間は必須です",
      "partner license fee required": "パートナーライセンス料金が必要です",
      "tournated license fee required": "トーネードライセンス料金が必要です",
      "License updating ...": "ライセンスを更新中...",
      "License updated Successfully!": "ライセンスが正常に更新されました！",
      "License creating ...": "ライセンスを作成中...",
      "License created successfully": "ライセンスが正常に作成されました",
      deleteRankingsWarning:
        "この日付のランキングはすでに存在します。更新すると、この日付の古いランキングは削除されます。続行しますか?",
      "Display athlete birth year in Participants": `参加者にアスリートの生年を表示する`,
      "Display athlete club": "アスリートのクラブを表示する",
      "Display athlete city": "アスリートの都市を表示する",
      "Display athlete birth year": "アスリートの生年を表示する",
    },
  },
  el: {
    translation: {
      "Athlete Portal": "Πύλη Αθλητών",
      Create: "Δημιουργία",
      "Your subscription": "Η συνδρομή σας",
      Notifications: "Ειδοποιήσεις",
      "Add League": "Προσθήκη Λίγκας",
      "Add Tournament": "Προσθήκη Τουρνουά",
      "Add Club": "Προσθήκη Συλλόγου",
      "Add News": "Προσθήκη Νέων",
      "Add Team": "Προσθήκη Ομάδας",
      Home: "Αρχική",
      Platforms: "Πλατφόρμες",
      "Search Platform": "Αναζήτηση Πλατφόρμας",
      "ADD NEW Platform": "ΠΡΟΣΘΗΚΗ ΝΕΑΣ Πλατφόρμας",
      "Platform name": "Όνομα Πλατφόρμας",
      "Platform image": "Εικόνα Πλατφόρμας",
      Country: "Χώρα",
      Sport: "Άθλημα",
      Type: "Τύπος",
      Organization: "Οργάνωση",
      Status: "Κατάσταση",
      "There is no available platforms": "Δεν υπάρχουν διαθέσιμες πλατφόρμες",
      Dashboard: "Πίνακας Ελέγχου",
      Edit: "Επεξεργασία",
      New: "Νέο",
      "Add New Platform": "Προσθήκη Νέας Πλατφόρμας",
      "Edit Platforms": "Επεξεργασία Πλατφορμών",
      "Organization account": "Λογαριασμός Οργάνωσης",
      "Platform general": "Γενικά της Πλατφόρμας",
      Appearance: "Εμφάνιση",
      "Company details": "Στοιχεία Εταιρείας",
      "Landing & Navigation": "Αρχική & Πλοήγηση",
      Integrations: "Ενσωματώσεις",
      "Additional requests": "Επιπλέον αιτήματα",
      "Service fees": "Τέλη υπηρεσιών",
      "Update Organization Account": "Ενημέρωση Λογαριασμού Οργάνωσης",
      "USER IS NOT IN THE LIST": "Ο ΧΡΗΣΤΗΣ ΔΕΝ ΒΡΙΣΚΕΤΑΙ ΣΤΗ ΛΙΣΤΑ",
      Name: "Όνομα",
      "Enter name": "Εισάγετε όνομα",
      Surname: "Επώνυμο",
      "Enter surname": "Εισάγετε επώνυμο",
      Email: "Ηλεκτρονικό ταχυδρομείο",
      "Enter Email Address": "Εισάγετε διεύθυνση ηλεκτρονικού ταχυδρομείου",
      "Change owner": "Αλλαγή ιδιοκτήτη",
      "Save new owner": "Αποθήκευση νέου ιδιοκτήτη",
      Password: "Κωδικός πρόσβασης",
      "Confirm Password": "Επιβεβαίωση Κωδικού Πρόσβασης",
      "Organization full name": "Πλήρες όνομα Οργάνωσης",
      "Enter Organization full name": "Εισάγετε το πλήρες όνομα της Οργάνωσης",
      "Organization abbreviations": "Συντομογραφίες Οργάνωσης",
      "Enter Organization abbreviation": "Εισάγετε συντομογραφία Οργάνωσης",
      "Organization type": "Τύπος Οργάνωσης",
      "About organization": "Σχετικά με την οργάνωση",
      "Contact email": "Επικοινωνία μέσω ηλεκτρονικού ταχυδρομείου",
      "Enter Contact Email":
        "Εισάγετε διεύθυνση ηλεκτρονικού ταχυδρομείου επικοινωνίας",
      "Contact phone number": "Τηλέφωνο επικοινωνίας",
      "Enter Contact phone number": "Εισάγετε αριθμό τηλεφώνου επικοινωνίας",
      Website: "Ιστοσελίδα",
      "Enter website": "Εισάγετε ιστοσελίδα",
      "Instagram link": "Σύνδεσμος Instagram",
      "Insert Instagram link": "Εισάγετε σύνδεσμο Instagram",
      Continue: "Συνέχεια",
      "Platform General": "Γενικά της Πλατφόρμας",
      "Platform title": "Τίτλος Πλατφόρμας",
      "Enter Platform Title": "Εισάγετε τίτλο Πλατφόρμας",
      "Platform type": "Τύπος Πλατφόρμας",
      "First selected country will act as primary country":
        "Η πρώτη επιλεγμένη χώρα θα λειτουργεί ως κύρια χώρα",
      "Select sports": "Επιλογή αθλημάτων",
      "First selected sport will act as primary sport":
        "Το πρώτο επιλεγμένο άθλημα θα λειτουργεί ως κύριο άθλημα",
      Sports: "Αθλήματα",
      "Select discipline": "Επιλογή πειθαρχίας",
      "Select third-party licence": "Επιλογή άδειας τρίτου",
      "third-party licence": "άδεια τρίτου",
      "No third-party license Found": "Δεν βρέθηκε άδεια τρίτου",
      "Select languages": "Επιλογή γλωσσών",
      "First selected language will act as primary language":
        "Η πρώτη επιλεγμένη γλώσσα θα λειτουργεί ως κύρια γλώσσα",
      "Select language": "Επιλογή γλώσσας",
      Timezone: "Ζώνη ώρας",
      "Time Zone": "Ζώνη Ώρας",
      Currency: "Νόμισμα",
      "First selected currency will act as primary currency":
        "Το πρώτο επιλεγμένο νόμισμα θα λειτουργεί ως κύριο νόμισμα",
      "Select currency": "Επιλογή νομίσματος",
      Description: "Περιγραφή",
      "Do you have already your domain name?": "Έχετε ήδη το όνομα τομέα σας;",
      "Enter your domain name": "Εισάγετε το όνομα τομέα σας",
      Back: "Πίσω",
      Save: "Αποθήκευση",
      "Upload primary logo (svg or png)":
        "Μεταφορτώστε το κύριο λογότυπο (svg ή png)",
      "Upload secondary logo (svg or png)":
        "Μεταφορτώστε το δευτερεύον λογότυπο (svg ή png)",
      "Upload cover picture": "Μεταφορτώστε εικόνα εξωφύλλου",
      "No logo, you want us to make one for you":
        "Δεν υπάρχει λογότυπο, θέλετε να δημιουργήσουμε ένα για εσάς",
      "Click Here": "Κάντε κλικ εδώ",
      "Select primary color": "Επιλέξτε κύριο χρώμα",
      "Select secondary color": "Επιλέξτε δευτερεύον χρώμα",
      "Company name": "Όνομα εταιρείας",
      "Enter Company Name": "Εισάγετε όνομα εταιρείας",
      "Registration Number": "Αριθμός Εγγραφής",
      "Enter Registration Number": "Εισάγετε αριθμό εγγραφής",
      "Full Address": "Πλήρης διεύθυνση",
      "Enter Full Address": "Εισάγετε πλήρη διεύθυνση",
      "Signee full name": "Πλήρες όνομα υπογράφοντος",
      "Signee role": "Ρόλος υπογράφοντος",
      "Contact Email": "Ηλεκτρονικό ταχυδρομείο επικοινωνίας",
      "Landing / Home Screen": "Αρχική / Οθόνη Αρχικής",
      "Do you need landing?": "Χρειάζεστε αρχική σελίδα;",
      "Select Template": "Επιλογή Προτύπου",
      "No, I will use my own website as Landing page.":
        "Όχι, θα χρησιμοποιήσω τη δική μου ιστοσελίδα ως Αρχική σελίδα.",
      "Do you need Integrations?": "Χρειάζεστε Ενσωματώσεις;",
      "Payment Service Provider": "Πάροχος Υπηρεσιών Πληρωμών",
      "Make sure to get familiar with Stripe service fees":
        "Βεβαιωθείτε ότι είστε εξοικειωμένοι με τα τέλη υπηρεσιών της Stripe",
      "Additional services": "Επιπλέον υπηρεσίες",
      "Service Name": "Όνομα Υπηρεσίας",
      Price: "Τιμή",
      "Mobile app": "Κινητή εφαρμογή",
      SMM: "Κοινωνική Διαχείριση Μέσων",
      "Graphics for SMM": "Γραφικά για Κοινωνική Διαχείριση Μέσων",
      "Your request": "Το αίτημά σας",
      "Type here your request": "Πληκτρολογήστε εδώ το αίτημά σας",
      "Upload file": "Μεταφορτώστε αρχείο",
      "Entry service fee": "Τέλος υπηρεσίας εισόδου",
      "Tournated entry service fee": "Τέλος υπηρεσίας εισόδου τουρνουά",
      "Entery entery fee": "Εισάγετε τέλος εισόδου",
      "Partner entry service fee": "Τέλος υπηρεσίας εισόδου συνεργάτη",
      "Enter partner entry fee": "Εισάγετε τέλος εισόδου συνεργάτη",
      "License service fee": "Τέλος υπηρεσίας άδειας",
      "Tournated license service fee": "Τέλος υπηρεσίας άδειας τουρνουά",
      "Enter tournated license fee": "Εισάγετε τέλος άδειας τουρνουά",
      "Partner license service fee": "Τέλος υπηρεσίας άδειας συνεργάτη",
      "Enter partner license fee": "Εισάγετε τέλος άδειας συνεργάτη",
      "Enable Pricing": "Ενεργοποίηση Τιμολόγησης",
      Leagues: "Λίγκες",
      "Create new League": "Δημιουργία νέας Λίγκας",
      "There is no available leagues": "Δεν υπάρχουν διαθέσιμες λίγκες",
      Completed: "Ολοκληρωμένες",
      Upcoming: "Επερχόμενες",
      Active: "Ενεργές",
      Total: "Σύνολο",
      "Search Leagues": "Αναζήτηση Λιγκών",
      "League Title": "Τίτλος Λίγκας",
      "League Type": "Τύπος Λίγκας",
      Logo: "Λογότυπο",
      Tournaments: "Τουρνουά",
      Athletes: "Αθλητές",
      Regulations: "Κανονισμοί",
      Platform: "Πλατφόρμα",
      "We recommend an image that is 256 x 256 pixels":
        "Συνιστούμε μια εικόνα που είναι 256 x 256 pixel",
      "League Abbreviation": "Συντομογραφία Λίγκας",
      "League owner": "Ιδιοκτήτης Λίγκας",
      "Select Organization": "Επιλογή Οργάνωσης",
      "Select platform": "Επιλογή πλατφόρμας",
      "League Priority": "Προτεραιότητα Λίγκας",
      "Go to Next": "Πηγαίνετε στο Επόμενο",
      Phone: "Τηλέφωνο",
      "League Website": "Ιστοσελίδα Λίγκας",
      Instagram: "Instagram",
      "Field Title": "Τίτλος Πεδίου",
      "Field Type": "Τύπος Πεδίου",
      "Add New Point": "Προσθήκη Νέου Σημείου",
      "Title Name": "Όνομα Τίτλου",
      Select: "Επιλογή",
      "Field Value": "Τιμή Πεδίου",
      "Add Another Option Below": "Προσθέστε Άλλη Επιλογή Παρακάτω",
      Update: "Ενημέρωση",
      Management: "Διαχείριση",
      "League status": "Κατάσταση Λίγκας",
      "Select one": "Επιλέξτε ένα",
      "Make this league featured": "Κάντε αυτή τη λίγκα προτεινόμενη",
      "Enable rankings": "Ενεργοποίηση κατατάξεων",
      "enable ranking":
        "Δημιουργήστε εξατομικευμένες κατατάξεις για τη λίγκα σας συνδέοντας κατηγορίες τουρνουά, καθορίζοντας περιορισμούς ηλικίας/φύλου και προσθέτοντας επιπλέον κανόνες.",
      "Enable online payments": "Ενεργοποίηση ηλεκτρονικών πληρωμών",
      "enable online payment":
        "Ξεκινήστε να δέχεστε ηλεκτρονικές πληρωμές για συμμετοχές σε τουρνουά και άδειες. Βεβαιωθείτε ότι έχετε εξοικειωθεί με το μοντέλο τέλους υπηρεσιών μας για τη διαχείριση ηλεκτρονικών πληρωμών ελέγχοντας το εδώ. (με κλικ ανοίγει ένα modal με λεπτομέρειες σύμφωνα με τις τιμές μας σε",
      pitchdeck: "pitchdeck",
      "Enable licences": "Ενεργοποίηση αδειών",
      "License description": "Ρυθμίστε άδειες, τιμές, περιόδους και κανόνες.",
      "Make club field mandatory and display club details as organizer on Tournament page":
        "Κάντε το πεδίο συλλόγου υποχρεωτικό και εμφανίστε τα στοιχεία του συλλόγου ως διοργανωτή στη σελίδα του τουρνουά",
      "Create League": "Δημιουργία Λίγκας",
      "Organization Accounts": "Λογαριασμοί Οργάνωσης",
      "ADD NEW ACCOUNT": "ΠΡΟΣΘΗΚΗ ΝΕΟΥ ΛΟΓΑΡΙΑΣΜΟΥ",
      "There is no available organizations":
        "Δεν υπάρχουν διαθέσιμες οργανώσεις",
      "Search Organizations": "Αναζήτηση Οργανώσεων",
      "Full name": "Πλήρες όνομα",
      "Organization Abbrevation": "Συντομογραφία Οργάνωσης",
      "UPDATE USER PROFILE": "ΕΝΗΜΕΡΩΣΗ ΠΡΟΦΙΛ ΧΡΗΣΤΗ",
      "Enter phone number": "Εισάγετε αριθμό τηλεφώνου",
      "Organization Settings": "Ρυθμίσεις Οργάνωσης",
      "Organization Type": "Τύπος Οργάνωσης",
      "Organization Logo": "Λογότυπο Οργάνωσης",
      "About Organization": "Σχετικά με την Οργάνωση",
      "Contact Details": "Στοιχεία Επικοινωνίας",
      Users: "Χρήστες",
      "Search User": "Αναζήτηση Χρήστη",
      "ADD NEW USER": "ΠΡΟΣΘΗΚΗ ΝΕΟΥ ΧΡΗΣΤΗ",
      Avatar: "Άβαταρ",
      "Phone Number": "Αριθμός Τηλεφώνου",
      Role: "Ρόλος",
      "IPIN License": "Άδεια IPIN",
      "DUPR License": "Άδεια DUPR",
      "Tennis Europe License": "Άδεια Tennis Europe",
      "LTS U10 License": "Άδεια LTS U10",
      "FIDE License": "Άδεια FIDE",
      "Add new user": "Προσθήκη νέου χρήστη",
      "Edit User": "Επεξεργασία Χρήστη",
      "User Account": "Λογαριασμός Χρήστη",
      "Date of birth": "Ημερομηνία γέννησης",
      Gender: "Φύλο",
      "Representing country": "Χώρα εκπροσώπησης",
      City: "Πόλη",
      "Enter city": "Εισάγετε πόλη",
      Address: "Διεύθυνση",
      "Enter address": "Εισάγετε διεύθυνση",
      Career: "Καριέρα",
      Club: "Σύλλογος",
      "Select club": "Επιλογή συλλόγου",
      "Select coach": "Επιλογή προπονητή",
      Parent: "Γονέας",
      "Select parent": "Επιλογή γονέα",
      "Write a bio": "Γράψτε βιογραφικό",
      CHECKBOXES: "Κουτάκια επιλογής",
      "Give access to coach to purchase licences and manage entries on your behalf":
        "Δώστε πρόσβαση στον προπονητή να αγοράζει άδειες και να διαχειρίζεται συμμετοχές εκ μέρους σας",
      "Give access to club manager(-es) to purchase licences and manage entries on your behalf":
        "Δώστε πρόσβαση στον διαχειριστή του συλλόγου να αγοράζει άδειες και να διαχειρίζεται συμμετοχές εκ μέρους σας",
      "Give access to parent(-s) to purchase licences and manage entries on your behalf":
        "Δώστε πρόσβαση στους γονείς να αγοράζουν άδειες και να διαχειρίζονται συμμετοχές εκ μέρους σας",
      Socials: "Κοινωνικά Δίκτυα",
      "Insert link here": "Εισάγετε σύνδεσμο εδώ",
      Facebook: "Facebook",
      Linkedin: "Linkedin",
      Tiktok: "Tiktok",
      Youtube: "Youtube",
      Licence: "Άδεια",
      "ITF IPIN": "ITF IPIN",
      DUPRID: "DUPRID",
      "Tennis Europe ID": "Ταυτότητα Tennis Europe",
      "LTS U10": "LTS U10",
      ATP: "ATP",
      WTA: "WTA",
      FIDE: "FIDE",
      "Update password": "Ενημέρωση κωδικού πρόσβασης",
      "Re-Enter": "Επανακαταχώρηση",
      "Login settings": "Ρυθμίσεις εισόδου",
      "Switch Standard login to Social login":
        "Μετατροπή από τυπική είσοδο σε κοινωνική είσοδο",
      "Connect Gmail": "Σύνδεση Gmail",
      "Enable 2FA Google Authorisation":
        "Ενεργοποίηση 2FA Google Authorisation",
      "Enable 2FA Email Authorisation": "Ενεργοποίηση 2FA Email Authorisation",
      "Role Name": "Όνομα Ρόλου",
      "Is user verified?": "Ο χρήστης είναι επαληθευμένος;",
      Yes: "Ναι",
      No: "Όχι",
      Tournament: "Τουρνουά",
      "All tournaments": "Όλα τα τουρνουά",
      "League tournaments": "Τουρνουά Λίγκας",
      "Search Tournaments": "Αναζήτηση Τουρνουά",
      "Create new Tournament": "Δημιουργία νέου Τουρνουά",
      "Tournament Title": "Τίτλος Τουρνουά",
      "Start - End": "Έναρξη - Τέλος",
      Organizer: "Διοργανωτής",
      Location: "Τοποθεσία",
      "List of categories": "Λίστα κατηγοριών",
      "Update Status": "Ενημέρωση Κατάστασης",
      "There is no available tournaments": "Δεν υπάρχουν διαθέσιμα τουρνουά",
      "ADD NEW SPORT": "ΠΡΟΣΘΗΚΗ ΝΕΟΥ ΑΘΛΗΜΑΤΟΣ",
      "Search Sports": "Αναζήτηση Αθλημάτων",
      "Sport Title": "Τίτλος Αθλήματος",
      "Add new Sport": "Προσθήκη νέου Αθλήματος",
      "Edit Sports": "Επεξεργασία Αθλημάτων",
      "Sports title": "Τίτλος Αθλήματος",
      "Enter title": "Εισάγετε τίτλο",
      "Sports abbreviation": "Συντομογραφία Αθλήματος",
      "Enter abbreviation": "Εισάγετε συντομογραφία",
      "Resource title": "Τίτλος πόρου",
      "Rule how to add results/score":
        "Κανόνας για την προσθήκη αποτελεσμάτων/σκορ",
      Icon: "Εικονίδιο",
      "Enable disciplines": "Ενεργοποίηση πειθαρχιών",
      "Enable courts": "Ενεργοποίηση γηπέδων",
      "Enable stages": "Ενεργοποίηση σταδίων",
      "Enable pairings": "Ενεργοποίηση ζευγαρωμάτων",
      "Enable rounds": "Ενεργοποίηση γύρων",
      "Enable standings based on pairings":
        "Ενεργοποίηση κατάταξης βάσει ζευγαρωμάτων",
      "Display draws": "Εμφάνιση κληρώσεων",
      "Display groups": "Εμφάνιση ομάδων",
      "Display live standings": "Εμφάνιση ζωντανών κατατάξεων",
      "Display order of play": "Εμφάνιση σειράς παιχνιδιού",
      "Display list & results": "Εμφάνιση λίστας και αποτελεσμάτων",
      "Display pending results": "Εμφάνιση εκκρεμών αποτελεσμάτων",
      "Display final standings": "Εμφάνιση τελικών κατατάξεων",
      "Display athlete chess titles in Participants":
        "Εμφάνιση τίτλων σκακιού αθλητών στους Συμμετέχοντες",
      "Display seeds column Participants":
        "Εμφάνιση στήλης σπόρων στους Συμμετέχοντες",
      "Display segments column in Participants":
        "Εμφάνιση στήλης τμημάτων στους Συμμετέχοντες",
      "Upload file here": "Μεταφορτώστε αρχείο εδώ",
      Disciplines: "Πειθαρχίες",
      "ADD NEW DISCIPLINE": "ΠΡΟΣΘΗΚΗ ΝΕΑΣ ΠΕΙΘΑΡΧΙΑΣ",
      "There is no available disciplines": "Δεν υπάρχουν διαθέσιμες πειθαρχίες",
      "Search Disciplines": "Αναζήτηση Πειθαρχιών",
      "Sport name": "Όνομα Αθλήματος",
      "Add new Discipline": "Προσθήκη νέας Πειθαρχίας",
      "Edit Discipline": "Επεξεργασία Πειθαρχίας",
      "Discipline title": "Τίτλος Πειθαρχίας",
      "Select Sport": "Επιλογή Αθλήματος",
      "No sports found": "Δεν βρέθηκαν αθλήματα",
      "Results type": "Τύπος αποτελεσμάτων",
      "Third-party licences": "Άδειες τρίτου",
      "Third party licence": "Άδεια τρίτου",
      "There is no available third-party licences":
        "Δεν υπάρχουν διαθέσιμες άδειες τρίτου",
      "ADD NEW LICENCE": "ΠΡΟΣΘΗΚΗ ΝΕΑΣ ΑΔΕΙΑΣ",
      "Search license": "Αναζήτηση άδειας",
      "license name": "όνομα άδειας",
      "License type": "Τύπος άδειας",
      "Add licence": "Προσθήκη άδειας",
      "Add Third-party licence": "Προσθήκη άδειας τρίτου",
      "Edit Third-party licence": "Επεξεργασία άδειας τρίτου",
      "Organization title": "Τίτλος Οργάνωσης",
      "Organization abbreviation": "Συντομογραφία Οργάνωσης",
      "Licence title": "Τίτλος άδειας",
      "Enter license title": "Εισάγετε τίτλο άδειας",
      "Licence type": "Τύπος άδειας",
      "Request license in user profile": "Αίτηση άδειας στο προφίλ χρήστη",
      "Request license on user registration in platform":
        "Αίτηση άδειας κατά την εγγραφή χρήστη στην πλατφόρμα",
      "Make licence mandatory": "Κάντε την άδεια υποχρεωτική",
      "Link to get licence": "Σύνδεσμος για λήψη άδειας",
      "Enter link to get licence": "Εισάγετε σύνδεσμο για λήψη άδειας",
      Partners: "Συνεργάτες",
      "ADD NEW PARTNER": "ΠΡΟΣΘΗΚΗ ΝΕΟΥ ΣΥΝΕΡΓΑΤΗ",
      "There is no available partners": "Δεν υπάρχουν διαθέσιμοι συνεργάτες",
      "Search Partners": "Αναζήτηση Συνεργατών",
      "Create new Partner": "Δημιουργία νέου Συνεργάτη",
      Link: "Σύνδεσμος",
      Text: "Κείμενο",
      Priority: "Προτεραιότητα",
      "Showing dates": "Εμφάνιση ημερομηνιών",
      "New Partner": "Νέος Συνεργάτης",
      "Edit Partner": "Επεξεργασία Συνεργάτη",
      "Partner Logo": "Λογότυπο Συνεργάτη",
      platform: "πλατφόρμα",
      "No platform available": "Δεν υπάρχει διαθέσιμη πλατφόρμα",
      "No league available": "Δεν υπάρχει διαθέσιμη λίγκα",
      "No Clubs available": "Δεν υπάρχουν διαθέσιμοι σύλλογοι",
      "Sponsor Text": "Κείμενο Χορηγού",
      "Date Settings": "Ρυθμίσεις Ημερομηνίας",
      "Active From": "Ενεργό Από",
      "Active To": "Ενεργό Έως",
      "Platform managers": "Διαχειριστές Πλατφόρμας",
      "ADD NEW MANAGER": "ΠΡΟΣΘΗΚΗ ΝΕΟΥ ΔΙΑΧΕΙΡΙΣΤΗ",
      "There is no available manager": "Δεν υπάρχει διαθέσιμος διαχειριστής",
      "Search Manager": "Αναζήτηση Διαχειριστή",
      "Export team": "Εξαγωγή ομάδας",
      "Export team emails": "Εξαγωγή email ομάδας",
      "Add platform manager": "Προσθήκη διαχειριστή πλατφόρμας",
      "Edit platform panager": "Επεξεργασία διαχειριστή πλατφόρμας",
      "Enter Organization": "Εισάγετε Οργάνωση",
      "Select organization": "Επιλογή Οργάνωσης",
      "Filter users": "Φίλτρο χρηστών",
      "Export users": "Εξαγωγή χρηστών",
      "Export user emails": "Εξαγωγή email χρηστών",
      "Age group": "Ηλικιακή ομάδα",
      "There is no available users": "Δεν υπάρχουν διαθέσιμοι χρήστες",
      Coaches: "Προπονητές",
      "ADD NEW COACH": "ΠΡΟΣΘΗΚΗ ΝΕΟΥ ΠΡΟΠΟΝΗΤΗ",
      "There is no available account": "Δεν υπάρχει διαθέσιμος λογαριασμός",
      "Search Coach": "Αναζήτηση Προπονητή",
      "Add new coach": "Προσθήκη νέου προπονητή",
      "Edit Coach": "Επεξεργασία Προπονητή",
      "Coach image": "Εικόνα Προπονητή",
      Category: "Κατηγορία",
      "There is no available sports": "Δεν υπάρχουν διαθέσιμα αθλήματα",
      "there are not available clubs": "Δεν υπάρχουν διαθέσιμοι σύλλογοι",
      "Date from": "Ημερομηνία από",
      "Date to": "Ημερομηνία έως",
      "Display on organization's landing page":
        "Εμφάνιση στην αρχική σελίδα της οργάνωσης",
      "Platform tournaments": "Τουρνουά Πλατφόρμας",
      Title: "Τίτλος",
      Posts: "Αναρτήσεις",
      "ADD POST": "ΠΡΟΣΘΗΚΗ ΑΝΑΡΤΗΣΗΣ",
      "There is no available post": "Δεν υπάρχει διαθέσιμη ανάρτηση",
      News: "Ειδήσεις",
      "Search Post": "Αναζήτηση Ανάρτησης",
      Image: "Εικόνα",
      Author: "Συγγραφέας",
      Content: "Περιεχόμενο",
      "Cover / featured image": "Κεντρική εικόνα",
      "Select league": "Επιλογή πρωταθλήματος",
      League: "Πρωτάθλημα",
      "Add video from youtube.com": "Προσθήκη βίντεο από το youtube.com",
      Date: "Ημερομηνία",
      "Purchased Licenses": "Αγορασμένες άδειες",
      "Search Licenses": "Αναζήτηση Άδειας",
      "Create new License": "Δημιουργία νέας άδειας",
      "Athlete name & surname": "Όνομα και επώνυμο αθλητή",
      "License Name": "Όνομα άδειας",
      "License Price": "Τιμή άδειας",
      "License active from": "Η άδεια είναι ενεργή από",
      "License active to": "Η άδεια είναι ενεργή έως",
      "Paid(online/cash)": "Πληρωμή (online/μετρητά)",
      "There is no available purchased license":
        "Δεν υπάρχει διαθέσιμη αγορασμένη άδεια",
      "New License": "Νέα άδεια",
      "Edit License": "Επεξεργασία άδειας",
      "Athlete Email": "Email αθλητή",
      License: "Άδεια",
      "No license found": "Δεν βρέθηκε άδεια",
      "Payment Method": "Μέθοδος πληρωμής",
      "How much paid": "Ποσό που καταβλήθηκε",
      Teams: "Ομάδες",
      "ADD NEW TEAM": "ΠΡΟΣΘΗΚΗ ΝΕΑΣ ΟΜΑΔΑΣ",
      "There is no available teams": "Δεν υπάρχουν διαθέσιμες ομάδες",
      "Search team": "Αναζήτηση ομάδας",
      "Create new Team": "Δημιουργία νέας ομάδας",
      "Team Members": "Μέλη ομάδας",
      Discipline: "Πειθαρχία",
      "New Team": "Νέα Ομάδα",
      "Edit Team": "Επεξεργασία Ομάδας",
      "Team Logo": "Λογότυπο ομάδας",
      "No Clubs Found": "Δεν βρέθηκαν συλλόγοι",
      "No disciplines found": "Δεν βρέθηκαν πειθαρχίες",
      "Club status": "Κατάσταση συλλόγου",
      Members: "Μέλη",
      "Add new Members": "Προσθήκη νέων μελών",
      "Add New Member": "Προσθήκη νέου μέλους",
      "Member email": "Email μέλους",
      "Select Role": "Επιλογή ρόλου",
      "Add more": "Προσθήκη περισσότερων",
      "ADD NEW CLUB": "ΠΡΟΣΘΗΚΗ ΝΕΟΥ ΣΥΛΛΟΓΟΥ",
      "There is no available clubs": "Δεν υπάρχουν διαθέσιμοι σύλλογοι",
      Clubs: "Σύλλογοι",
      "Search Clubs": "Αναζήτηση Συλλόγων",
      "Club Name": "Όνομα Συλλόγου",
      "Add new Club": "Προσθήκη νέου Συλλόγου",
      "Edit Club": "Επεξεργασία Συλλόγου",
      "Appearance & Location": "Εμφάνιση και τοποθεσία",
      "Club title": "Τίτλος Συλλόγου",
      "Add club logo": "Προσθήκη λογότυπου συλλόγου",
      "How to find us": "Πώς να μας βρείτε",
      "Contact details": "Στοιχεία επικοινωνίας",
      "Contact Phone": "Τηλέφωνο επικοινωνίας",
      Twitter: "Twitter",
      "Services & Resources": "Υπηρεσίες και Πόροι",
      "Select services": "Επιλογή υπηρεσιών",
      Services: "Υπηρεσίες",
      Resources: "Πόροι",
      "Resource Name": "Όνομα Πόρου",
      Service: "Υπηρεσία",
      "Add Resources": "Προσθήκη Πόρων",
      "Add New Resource": "Προσθήκη Νέου Πόρου",
      "Select Service": "Επιλογή Υπηρεσίας",
      "Select type": "Επιλογή τύπου",
      "Add Another": "Προσθήκη άλλου",
      "Club page": "Σελίδα Συλλόγου",
      "Select Coaches": "Επιλογή Προπονητών",
      "Athlete Club": "Συλλόγος αθλητή",
      desc: "Μόλις προσθέσετε τον σύλλογό σας, οι αθλητές θα μπορούν να τον επιλέξουν στη σελίδα ρυθμίσεων του προφίλ τους και στη συνέχεια θα εμφανιστούν σε μια σελίδα συλλόγου για έγκριση / απόρριψη. Τα εγκεκριμένα μέλη θα εμφανιστούν στη σελίδα του συλλόγου",
      "Online booking": "Διαδικτυακή κράτηση",
      "Club Main Administrator": "Κύριος Διαχειριστής Συλλόγου",
      "Main Adminitrator": "Κύριος Διαχειριστής",
      "CLUB MAIN ADMINISTRATOR IS NOT IN THE LIST":
        "Ο ΚΥΡΙΟΣ ΔΙΑΧΕΙΡΙΣΤΗΣ ΣΥΛΛΟΓΟΥ ΔΕΝ ΕΙΝΑΙ ΣΤΗ ΛΙΣΤΑ",
      Administrator: "Διαχειριστής",
      "ADMINISTRATOR IS NOT IN THE LIST": "Ο ΔΙΑΧΕΙΡΙΣΤΗΣ ΔΕΝ ΕΙΝΑΙ ΣΤΗ ΛΙΣΤΑ",
      Banners: "Πανό",
      "There is no available banners": "Δεν υπάρχουν διαθέσιμα πανό",
      "ADD BANNER": "ΠΡΟΣΘΗΚΗ ΠΑΝΟΥ",
      "Search Banner": "Αναζήτηση Πανό",
      Headline: "Κεφαλίδα",
      subheadline: "Υπότιτλος",
      "Button Text": "Κείμενο κουμπιού",
      "New Banner": "Νέο Πανό",
      "Edit Banner": "Επεξεργασία Πανό",
      Subheadline: "Υπότιτλος",
      "Button text": "Κείμενο κουμπιού",
      "Button link": "Σύνδεσμος κουμπιού",
      "Select roles": "Επιλογή ρόλων",
      "Banner images": "Εικόνες πανό",
      "Control Panel": "Πίνακας ελέγχου",
      "Run the tournament step by step from this control panel":
        "Εκτελέστε το τουρνουά βήμα προς βήμα από αυτόν τον πίνακα ελέγχου",
      "Edit & Setup league": "Επεξεργασία & Διαμόρφωση πρωταθλήματος",
      "Edit Now": "Επεξεργασία τώρα",
      "Create & Setup league": "Δημιουργία & Διαμόρφωση πρωταθλήματος",
      "Start Now": "Ξεκινήστε τώρα",
      "Add league members": "Προσθήκη μελών πρωταθλήματος",
      "league member description":
        "Συμπεριλάβετε μέλη πρωταθλήματος, όπως διαχειριστές, διοργανωτές τουρνουά και διαιτητές για το πρωτάθλημά σας. Οι διαχειριστές θα έχουν πλήρη πρόσβαση στον πίνακα ελέγχου και όλα τα διοικητικά χαρακτηριστικά, οι διοργανωτές θα μπορούν να προσθέτουν τουρνουά και να τα διαχειρίζονται, και οι διαιτητές θα έχουν πρόσβαση στις καταχωρήσεις, τους κληρώσεις, τις ομάδες, τις ζωντανές βαθμολογίες, τη σειρά παιχνιδιού και τη διαχείριση αποτελεσμάτων για τα τουρνουά στα οποία έχουν ανατεθεί.",
      "Add Now": "Προσθήκη τώρα",
      "Add tournament categories": "Προσθήκη κατηγοριών τουρνουά",
      "tournament category description":
        "Ορίστε κατηγορίες τουρνουά για το πρωτάθλημά σας, καθορίζοντας μορφές (μονός/διπλός/ομαδικός), περιορισμούς ηλικίας/φύλου και σύνδεσή τους με κατηγορίες κατάταξης (αφού προσθέσετε αυτές τις κατηγορίες κατάταξης στον επόμενο βήμα).",
      "Add rankings categories": "Προσθήκη κατηγοριών κατάταξης",
      "ranking category description":
        "Καθιερώστε κατηγορίες κατάταξης για το πρωτάθλημά σας. Επιλέξτε κατηγορίες τουρνουά για συμπερίληψη στις κατηγορίες κατάταξης, καθορίστε πόσα τουρνουά να ληφθούν υπόψη, ορίστε τη χρονική περίοδο, συνδέστε με διεθνείς πρωταθλητισμούς και θεσπίστε άλλους κανόνες.",
      "Setup ranking points": "Ρύθμιση πόντων κατάταξης",
      "ranking description":
        "Διαμορφώστε τον πίνακα πόντων για κάθε κατηγορία τουρνουά, καθορίζοντας τον αριθμό των πόντων για κάθε τοποθέτηση. Ένα πιο λεπτομερής πίνακας θα διευκολύνει και θα αυτοματοποιήσει τη διαδικασία.",
      Setup: "Διαμόρφωση",
      "Setup licences": "Ρύθμιση άδειων",
      "Add tournament": "Προσθήκη τουρνουά",
      "tournament description":
        "Ακολουθήστε τα βήματα για τη δημιουργία του τουρνουά σας και την έναρξη της διαχείρισης της αθλητικής εκδήλωσής σας. Μπορείτε να λάβετε μια εκτενή λίστα οδηγιών που καλύπτουν τα πολλαπλά χαρακτηριστικά που προσφέρουμε για τη διαχείριση αθλητικών εκδηλώσεων",
      "Leagues Members": "Μέλη Πρωταθλημάτων",
      "Add new Member": "Προσθήκη νέου μέλους",
      "There is no available members": "Δεν υπάρχουν διαθέσιμα μέλη",
      "League members": "Μέλη πρωταθλήματος",
      "Search Members": "Αναζήτηση Μελών",
      "ADD NEW MEMBER": "ΠΡΟΣΘΗΚΗ ΝΕΟΥ ΜΕΛΟΥΣ",
      "Delete league member": "Διαγραφή μέλους πρωταθλήματος",
      "Are you sure you want to delete?":
        "Είστε σίγουρος ότι θέλετε να διαγράψετε;",
      Cancel: "Ακύρωση",
      Delete: "Διαγραφή",
      "Tournament Categories": "Κατηγορίες Τουρνουά",
      "There is no available categories": "Δεν υπάρχουν διαθέσιμες κατηγορίες",
      "ADD NEW CATEGORY": "ΠΡΟΣΘΗΚΗ ΝΕΑΣ ΚΑΤΗΓΟΡΙΑΣ",
      "Search Categories": "Αναζήτηση Κατηγοριών",
      "Copy Categories": "Αντιγραφή Κατηγοριών",
      "Delete Categories": "Διαγραφή Κατηγοριών",
      "Category Name": "Όνομα Κατηγορίας",
      "Category Type": "Τύπος Κατηγορίας",
      "Select League": "Επιλογή Πρωταθλήματος",
      Copy: "Αντιγραφή",
      "Are you sure you want to delete these categories?":
        "Είστε σίγουρος ότι θέλετε να διαγράψετε αυτές τις κατηγορίες;",
      "New Category": "Νέα Κατηγορία",
      "Edit Category": "Επεξεργασία Κατηγορίας",
      "Category name": "Όνομα Κατηγορίας",
      "Add team selection on tournament registration":
        "Προσθήκη επιλογής ομάδας κατά την εγγραφή σε τουρνουά",
      "Add club selection on tournament registration":
        "Προσθήκη επιλογής συλλόγου κατά την εγγραφή σε τουρνουά",
      "Skills level": "Επίπεδο δεξιοτήτων",
      "Category Gender": "Φύλο κατηγορίας",
      "Age from": "Ηλικία από",
      "Age to": "Ηλικία έως",
      "Age Limit Type": "Τύπος ορίου ηλικίας",
      "No Types Found!": "Δεν βρέθηκαν τύποι!",
      "Ask skills level on a tournament registration":
        "Ρωτήστε το επίπεδο δεξιοτήτων κατά την εγγραφή σε τουρνουά",
      "Enable License": "Ενεργοποίηση άδειας",
      "Display athlete club in Participants": `Εμφάνιση συλλόγου αθλητή στους "Συμμετέχοντες"`,
      "Display athlete city in Participants": `Εμφάνιση πόλης αθλητή στους "Συμμετέχοντες"`,
      "Select License": "Επιλογή άδειας",
      "No License Found!": "Δεν βρέθηκαν άδειες!",
      "Ranking Category for men": "Κατηγορία κατάταξης για άνδρες",
      "No Category Found!": "Δεν βρέθηκαν κατηγορίες!",
      "Ranking Category for Women": "Κατηγορία κατάταξης για γυναίκες",
      "Third-party license": "Άδεια τρίτων",
      "No third-party license Found!": "Δεν βρέθηκε άδεια τρίτων!",
      "Time control": "Έλεγχος χρόνου",
      "No time control found": "Δεν βρέθηκε έλεγχος χρόνου",
      "Order display for seeding": "Εμφάνιση σειράς για σπορά",
      "Sport fishing settings": "Ρυθμίσεις ψαρέματος",
      "Identify team members on registration":
        "Αναγνώριση μελών ομάδας κατά την εγγραφή",
      "Min number of members": "Ελάχιστος αριθμός μελών",
      "Max number of members": "Μέγιστος αριθμός μελών",
      "Reservists required": "Απαιτούμενοι αναπληρωματικοί",
      "Min number of reservists": "Ελάχιστος αριθμός αναπληρωματικών",
      "Max number of reservists": "Μέγιστος αριθμός αναπληρωματικών",
      "Captain required": "Απαιτείται αρχηγός",
      "Transfer members to singles category":
        "Μεταφορά μελών σε κατηγορία μονών",
      "Select singles category": "Επιλογή κατηγορίας μονών",
      "No Category Found": "Δεν βρέθηκε κατηγορία",
      "National tournament (country vs country)":
        "Εθνικό τουρνουά (χώρα έναντι χώρας)",
      "Club championships (club vs club)":
        "Πρωταθλήματα συλλόγων (σύλλογος έναντι συλλόγου)",
      "Create matches from team members and count points result based on matches result":
        "Δημιουργία αγώνων από μέλη ομάδας και υπολογισμός αποτελέσματος βαθμολόγησης βάσει αποτελέσματος αγώνων",
      "Count individual total": "Υπολογισμός συνολικού ατόμου",
      "Count teams total": "Υπολογισμός συνολικού ομάδων",
      "Number of athletes to count min":
        "Αριθμός αθλητών για ελάχιστο υπολογισμό",
      "Number of athletes to count max":
        "Αριθμός αθλητών για μέγιστο υπολογισμό",
      "Max Entries per team": "Μέγιστες καταχωρήσεις ανά ομάδα",
      "Allow registration without team": "Να επιτρέπεται η εγγραφή χωρίς ομάδα",
      "Category Status": "Κατάσταση κατηγορίας",
      "Ranking Categories": "Κατηγορίες κατάταξης",
      "There is no available ranking categories":
        "Δεν υπάρχουν διαθέσιμες κατηγορίες κατάταξης",
      Categories: "Κατηγορίες",
      "No of Tournaments": "Αριθμός τουρνουά",
      "Time Period": "Χρονική περίοδος",
      "No Leagues found": "Δεν βρέθηκαν πρωταθλήματα",
      "New Ranking Category": "Νέα κατηγορία κατάταξης",
      "Select Categories": "Επιλογή κατηγοριών",
      "No Categories Found!": "Δεν βρέθηκαν κατηγορίες!",
      "Max number of tournaments": "Μέγιστος αριθμός τουρνουά",
      "Count specific tournaments": "Υπολογισμός συγκεκριμένων τουρνουά",
      "Add penalty points for missing a tournament":
        "Προσθήκη ποινικών πόντων για απουσία από τουρνουά",
      "Penalty rule": "Κανόνας ποινής",
      "Enter points": "Εισαγωγή πόντων",
      "Additional Rules": "Επιπλέον κανόνες",
      Sector: "Τομέας",
      Coefficient: "Συντελεστής",
      "No of tournaments": "Αριθμός τουρνουά",
      "Edit additional rule(s)": "Επεξεργασία επιπλέον κανόνων",
      "Add additional rule(s)": "Προσθήκη επιπλέον κανόνων",
      "During which period tournament points are counted":
        "Κατά τη διάρκεια ποιου περιόδου θα μετρώνται οι πόντοι του τουρνουά",
      "Select Time Period": "Επιλογή χρονικής περιόδου",
      "Points sorting": "Ταξινόμηση πόντων",
      "Sport Fishing": "Αθλητικό ψάρεμα",
      "Select Stage form which take points":
        "Επιλογή σταδίου από το οποίο να πάρετε πόντους",
      "Ranking Points": "Βαθμολογία Βαθμολογίας",
      "Search Rankings": "Αναζήτηση βαθμολογίας",
      "Add points block": "Προσθήκη μπλοκ πόντων",
      "Select / UnSelect All": "Επιλογή / Αποεπιλογή όλων",
      Place: "Τοποθεσία",
      Points: "Πόντοι",
      "Bracket type": "Τύπος θυρίδας",
      Segment: "Τμήμα",
      "Num of Entries": "Αριθμός καταχωρήσεων",
      "Ad.Rules": "Κανόνες Διαφήμισης",
      "view rule": "προβολή κανόνα",
      "Add new row": "Προσθήκη νέας σειράς",
      "There is no available ranking points":
        "Δεν υπάρχουν διαθέσιμοι πόντοι κατάταξης",
      "Edit Points": "Επεξεργασία Πόντων",
      "Add Points": "Προσθήκη Πόντων",
      "Points block name": "Όνομα μπλοκ πόντων",
      Specification: "Προδιαγραφή",
      "Rule Settings": "Ρυθμίσεις κανόνων",
      "Select operator": "Επιλογή τελεστή",
      "Select skills level": "Επιλογή επιπέδου δεξιοτήτων",
      "Number of players": "Αριθμός παικτών",
      "Number of entries": "Αριθμός καταχωρήσεων",
      update: "ενημέρωση",
      SAVE: "ΑΠΟΘΗΚΕΥΣΗ",
      DISCARD: "ΑΠΟΡΡΙΨΗ",
      "SET NEW RULE": "ΟΡΙΣΤΕ ΝΕΟ ΚΑΝΟΝΑ",
      "Edit Row": "Επεξεργασία σειράς",
      "Add Row": "Προσθήκη σειράς",
      ADD: "ΠΡΟΣΘΗΚΗ",
      "Copy Ranking Point": "Αντιγραφή βαθμολογίας",
      "Select Ranking Points": "Επιλογή βαθμολογίας",
      "Copy Ranking Point Blocks": "Αντιγραφή μπλοκ βαθμολογίας",
      "Delete Ranking Point Block": "Διαγραφή μπλοκ βαθμολογίας",
      Licenses: "Άδειες",
      "There is no available licenses": "Δεν υπάρχουν διαθέσιμες άδειες",
      "Price Currency": "Νόμισμα τιμής",
      "Select Leagues": "Επιλογή πρωταθλημάτων",
      "No League Found!": "Δεν βρέθηκε πρωτάθλημα!",
      "License price": "Τιμή άδειας",
      "License Type": "Τύπος άδειας",
      "License Status": "Κατάσταση άδειας",
      "Additional questions": "Επιπλέον ερωτήσεις",
      "Add additional question": "Προσθήκη επιπλέον ερώτησης",
      "Edit this": "Επεξεργασία αυτού",
      "Add new": "Προσθήκη νέου",
      question: "ερώτηση",
      Question: "Ερώτηση",
      "Field Option": "Επιλογή πεδίου",
      Remove: "Αφαίρεση",
      "Add new option": "Προσθήκη νέας επιλογής",
      "Ask this question to each athlete":
        "Ρωτήστε αυτήν την ερώτηση σε κάθε αθλητή",
      Submit: "Υποβολή",
      "Delete Warning": "Διαγραφή προειδοποίησης",
      "Are you sure you want to delete this question?":
        "Είστε σίγουρος ότι θέλετε να διαγράψετε αυτήν την ερώτηση;",
      "Confirm Deletion": "Επιβεβαίωση Διαγραφής",
      Rankings: "Βαθμολογίες",
      Search: "Αναζήτηση",
      "ranking Category": "Κατηγορία βαθμολογίας",
      "No date Found": "Δεν βρέθηκε ημερομηνία",
      "Create Rankings": "Δημιουργία Βαθμολογίας",
      "Update Rankings": "Ενημέρωση Βαθμολογίας",
      close: "κλείσιμο",
      Year: "Έτος",
      "Points Details": "Λεπτομέρειες πόντων",
      "No points yet": "Δεν υπάρχουν ακόμα πόντοι",
      Position: "Θέση",
      Athlete: "Αθλητής",
      "There is no available rankings": "Δεν υπάρχουν διαθέσιμες βαθμολογίες",
      "Select Ranking categories": "Επιλογή κατηγοριών βαθμολογίας",
      "Remove Rankings": "Αφαίρεση Βαθμολογίας",
      "Select Date": "Επιλογή Ημερομηνίας",
      "Are you sure you want to delete rankings?":
        "Είστε σίγουρος ότι θέλετε να διαγράψετε τις βαθμολογίες;",
      "Rankings History": "Ιστορικό Βαθμολογίας",
      "Ranking Category": "Κατηγορία Βαθμολογίας",
      "Last Updated": "Τελευταία ενημέρωση",
      Public: "Δημόσιο",
      "Delete warning": "Διαγραφή προειδοποίησης",
      "Are you sure you want to delete this ranking?":
        "Είστε σίγουρος ότι θέλετε να διαγράψετε αυτήν την κατάταξη;",
      "There is no available rankings history":
        "Δεν υπάρχει ιστορικό διαθέσιμων βαθμολογιών",
      "Tournament Results": "Αποτελέσματα τουρνουά",
      Entry: "Καταχώρηση",
      "Add new result": "Προσθήκη νέου αποτελέσματος",
      "There is no available tournament results":
        "Δεν υπάρχουν διαθέσιμα αποτελέσματα τουρνουά",
      "Pendding tournaments": "Εκκρεμή τουρνουά",
      "There is no available athletes": "Δεν υπάρχουν διαθέσιμοι αθλητές",
      "Search Athletes": "Αναζήτηση Αθλητών",
      "Country/Residency": "Χώρα/Κατοικία",
      "Super Data": "Σούπερ Δεδομένα",
      "All Tournaments": "Όλα τα Τουρνουά",
      "Pending Tournaments": "Εκκρεμή Τουρνουά",
      Financials: "Οικονομικά",
      Accounting: "Λογιστική",
      Extra: "Επιπλέον",
      "Live scoring": "Ζωντανή βαθμολογία",
      "Live stream": "Ζωντανή μετάδοση",
      "Platform Settings": "Ρυθμίσεις πλατφόρμας",
      "My Platforms": "Οι πλατφόρμες μου",
      Team: "Ομάδα",
      Instructions: "Οδηγίες",
      "League Settings": "Ρυθμίσεις πρωταθλήματος",
      "League Members": "Μέλη πρωταθλήματος",
      Upload: "Μεταφόρτωση",
      "primary logo": "κύριο λογότυπο",
      "secondary logo": "δευτερεύον λογότυπο",
      "cover picture": "εξώφυλλη εικόνα",
      "Start Typing": "Ξεκινήστε την πληκτρολόγηση",
      "Leagues list": "Λίστα πρωταθλημάτων",
      "General & Appearance": "Γενικά & Εμφάνιση",
      "Instagram Link": "Σύνδεσμος Instagram",
      "Fact Sheet": "Φύλλο Πληροφοριών",
      "Fact sheet": "Φύλλο πληροφοριών",
      "Upload regulations": "Μεταφόρτωση κανονισμών",
      "Organization Abbreviation": "Συντομογραφία Οργανισμού",
      "Drag and Drop some file": "Σύρετε και αποθέστε αρχείο",
      "here, or click to Select file":
        "εδώ, ή κάντε κλικ για να επιλέξετε αρχείο",
      UPLOAD: "ΜΕΤΑΦΟΡΤΩΣΗ",
      "DISCARD AND CANCEL": "ΑΚΥΡΩΣΗ ΚΑΙ ΑΠΟΡΡΙΨΗ",
      SUBMIT: "ΥΠΟΒΟΛΗ",
      "Add new Organization Account": "Προσθήκη νέου Λογαριασμού Οργανισμού",
      "Edit Organization Account": "Επεξεργασία Λογαριασμού Οργανισμού",
      "Full Name": "Πλήρες Όνομα",
      Biography: "Βιογραφικό",
      "Add post": "Προσθήκη ανάρτησης",
      "Edit Post": "Επεξεργασία Ανάρτησης",
      "Edit Member": "Επεξεργασία Μέλους",
      "Organization name": "Όνομα Οργανισμού",
      "Organization email": "Email Οργανισμού",
      "Organization phone": "Τηλέφωνο Οργανισμού",
      "Display member on organizations public team page":
        "Εμφάνιση μέλους στη δημόσια σελίδα ομάδας του οργανισμού",
      "Select Roles": "Επιλογή Ρόλων",
      "For assiging points": "Για την ανάθεση πόντων",
      "Third party license": "Άδεια τρίτου",
      "Must be a valid email": "Πρέπει να είναι έγκυρο email",
      "Email is required": "Το email απαιτείται",
      "Organization Name is required": "Το όνομα του οργανισμού απαιτείται",
      "Organization Type is required": "Ο τύπος του οργανισμού απαιτείται",
      "organization abbreviation is required":
        "Απαιτείται συντόμευση οργανισμού",
      "Contact Email is required": "Απαιτείται email επικοινωνίας",
      "Password is Required": "Απαιτείται κωδικός πρόσβασης",
      "Password should be 8 chars minimum.":
        "Ο κωδικός πρόσβασης πρέπει να είναι τουλάχιστον 8 χαρακτήρες",
      "Passwords must match": "Οι κωδικοί πρόσβασης πρέπει να ταιριάζουν",
      "company name is required": "Το όνομα της εταιρείας απαιτείται",
      "company number is required": "Ο αριθμός της εταιρείας απαιτείται",
      "full address is required": "Απαιτείται πλήρης διεύθυνση",
      "Invalid email address": "Μη έγκυρη διεύθυνση email",
      "signee role is required": "Απαιτείται ρόλος υπογραφούντος",
      "signee full name is required": "Απαιτείται πλήρες όνομα υπογραφούντος",
      "partner entry fee is required": "Απαιτείται τέλος εισόδου εταίρου",
      "tournated license fee is required":
        "Απαιτείται τέλος άδειας τροφοδοτούμενου",
      "partner license fee is required": "Απαιτείται τέλος άδειας εταίρου",
      "Creating platform...": "Δημιουργία πλατφόρμας...",
      "Platform created successfully": "Η πλατφόρμα δημιουργήθηκε με επιτυχία",
      "Updating...": "Ενημέρωση...",
      "Platform updated": "Η πλατφόρμα ενημερώθηκε",
      "Add New Club": "Προσθήκη νέου συλλόγου",
      "organization is required": "Απαιτείται οργανισμός",
      "Adding new fact...": "Προσθήκη νέου γεγονότος...",
      "Fact added successfully": "Το γεγονός προστέθηκε με επιτυχία",
      "Deleting fact...": "Διαγραφή γεγονότος...",
      "fact deleted successfully": "Το γεγονός διαγράφηκε με επιτυχία",
      "Updating fact...": "Ενημέρωση γεγονότος...",
      "Fact updated successfully": "Το γεγονός ενημερώθηκε με επιτυχία",
      "Creating league...": "Δημιουργία πρωταθλήματος...",
      "League created successfully": "Το πρωτάθλημα δημιουργήθηκε με επιτυχία",
      "Updating league...": "Ενημέρωση πρωταθλήματος...",
      "League updated successfully": "Το πρωτάθλημα ενημερώθηκε με επιτυχία",
      "Approved & Public": "Έγκριση & Δημοσίευση",
      "ATP points": "Πόντοι ATP",
      "WTA points": "Πόντοι WTA",
      "name Email is required": "Απαιτείται το email του ονόματος",
      "title is require": "Απαιτείται τίτλος",
      "platform type required": "Απαιτείται τύπος πλατφόρμας",
      "time zone is required": "Απαιτείται ζώνη ώρας",
      "description is required": "Απαιτείται περιγραφή",
      "title is required": "Απαιτείται τίτλος",
      "abbreviation is required": "Απαιτείται συντόμευση",
      "platform is required": "Απαιτείται πλατφόρμα",
      "priority is required": "Απαιτείται προτεραιότητα",
      "type is required": "Απαιτείται τύπος",
      "sports is required": "Απαιτούνται αθλήματα",
      "email is required": "Απαιτείται email",
      "Organization added Successfully!":
        "Η προσθήκη οργανισμού ήταν επιτυχής!",
      "name is required": "Το όνομα απαιτείται",
      "surname is required": "Το επώνυμο απαιτείται",
      "organization name is required": "Το όνομα του οργανισμού απαιτείται",
      "organization type is required": "Ο τύπος του οργανισμού απαιτείται",
      "Organization Account Updated Successfully!":
        "Ο λογαριασμός του οργανισμού ενημερώθηκε με επιτυχία!",
      "Creating organization...": "Δημιουργία οργανισμού...",
      "Organization created successfully":
        "Ο οργανισμός δημιουργήθηκε με επιτυχία",
      "This User is already exist": "Αυτός ο χρήστης υπάρχει ήδη",
      "Password is required": "Απαιτείται κωδικός πρόσβασης",
      "Re-Enter password is required": "Απαιτείται επαναληπτικός κωδικός",
      "Password updated": "Ο κωδικός πρόσβασης ενημερώθηκε",
      "User Updated": "Ο χρήστης ενημερώθηκε",
      "Sports Updated": "Τα αθλήματα ενημερώθηκαν",
      "Sports Created": "Τα αθλήματα δημιουργήθηκαν",
      "Discipline Updated": "Η disipline ενημερώθηκε",
      "Discipline Created": "Η διεύθυνση δημιουργήθηκε",
      "At least one sport must be selected":
        "Πρέπει να επιλεγεί τουλάχιστον ένα αθλητικό",
      "License Updated": "Η άδεια ενημερώθηκε",
      "License Created": "Η άδεια δημιουργήθηκε",
      "Please provide Image": "Παρακαλούμε δώστε εικόνα",
      "Partner updated Successfully!": "Ο σύντροφος ενημερώθηκε με επιτυχία!",
      "Partner created successfully!": "Ο σύντροφος δημιουργήθηκε με επιτυχία!",
      "Please add correct user email":
        "Παρακαλούμε προσθέστε σωστό email χρήστη",
      "Coach added!": "Προστέθηκε προπονητής!",
      "user found": "Χρήστης βρέθηκε",
      "user not found": "Χρήστης δεν βρέθηκε",
      "country is required": "Η χώρα απαιτείται",
      "gender is required": "Το φύλο απαιτείται",
      "confirm password is required": "Απαιτείται επιβεβαίωση κωδικού",
      "Coache Updated Successfully!": "Ο προπονητής ενημερώθηκε με επιτυχία!",
      "Image field is required": "Απαιτείται πεδίο εικόνας",
      "author is required": "Απαιτείται συγγραφέας",
      "Updating ...": "Ενημέρωση ...",
      "Post updated successfully": "Το άρθρο ενημερώθηκε με επιτυχία",
      "Creating post ...": "Δημιουργία άρθρου ...",
      "Post created successfully": "Το άρθρο δημιουργήθηκε με επιτυχία",
      "User found": "Χρήστης βρέθηκε",
      "User not found": "Χρήστης δεν βρέθηκε",
      "paid is required": "Απαιτείται πληρωμή",
      "currency is required": "Απαιτείται νόμισμα",
      "Athlete is required": "Απαιτείται αθλητής",
      "license is required": "Απαιτείται άδεια",
      "start date is required": "Απαιτείται ημερομηνία έναρξης",
      "end date is required": "Απαιτείται ημερομηνία λήξης",
      "License created successfully!": "Η άδεια δημιουργήθηκε με επιτυχία!",
      "sport is required": "Απαιτείται αθλητισμός",
      "discipline is required": "Απαιτείται πειθαρχία",
      "Team updated successfully!": "Η ομάδα ενημερώθηκε με επιτυχία!",
      "Team created successfully!": "Η ομάδα δημιουργήθηκε με επιτυχία!",
      "city is required": "Η πόλη απαιτείται",
      "address is required": "Η διεύθυνση απαιτείται",
      "headline is required": "Απαιτείται τίτλος άρθρου",
      "subheadline is required": "Απαιτείται υποτίτλος άρθρου",
      "button text required": "Απαιτείται κείμενο κουμπιού",
      "button link required": "Απαιτείται σύνδεσμος κουμπιού",
      "Image are required": "Απαιτούνται εικόνες",
      "Updating banner...": "Ενημέρωση banner...",
      "Banner updated successfully": "Το banner ενημερώθηκε με επιτυχία",
      "Creating banner...": "Δημιουργία banner...",
      "Banner created successfully": "Το banner δημιουργήθηκε με επιτυχία",
      "League member added!": "Μέλος λίγκας προστέθηκε!",
      "League Member Updated Successfully!":
        "Το μέλος της λίγκας ενημερώθηκε με επιτυχία!",
      "order is required": "Η παραγγελία είναι απαραίτητη",
      "Age from must an integer": "Η ηλικία από πρέπει να είναι ακέραιος",
      "Age must be at least 0": "Η ηλικία πρέπει να είναι τουλάχιστον 0",
      "Age to must an integer": "Η ηλικία έως πρέπει να είναι ακέραιος",
      "Min members must an integer":
        "Οι ελάχιστοι μέλη πρέπει να είναι ακέραιοι",
      "Min members must be at least 0":
        "Οι ελάχιστοι μέλη πρέπει να είναι τουλάχιστον 0",
      "Max members must an integer":
        "Οι μέγιστοι μέλη πρέπει να είναι ακέραιοι",
      "Max members must be at least 0":
        "Οι μέγιστοι μέλη πρέπει να είναι τουλάχιστον 0",
      "Max members must be greater than and equal to min members":
        "Οι μέγιστοι μέλη πρέπει να είναι μεγαλύτεροι ή ίσοι με τους ελάχιστους μέλη",
      "Country is required": "Η χώρα απαιτείται",
      "competition is required": "Απαιτείται ανταγωνισμός",
      "competition start date is required":
        "Απαιτείται ημερομηνία έναρξης ανταγωνισμού",
      "competition end date is required":
        "Απαιτείται ημερομηνία λήξης ανταγωνισμού",
      "role is required": "Απαιτείται ρόλος",
      "partner is required": "Απαιτείται σύντροφος",
      "partner is updated": "Ο σύντροφος ενημερώθηκε",
      "partner is added": "Ο σύντροφος προστέθηκε",
      "and price in integer": "και η τιμή σε ακέραιο",
      "price must be at least 0": "η τιμή πρέπει να είναι τουλάχιστον 0",
      "and select integer": "και επιλέξτε ακέραιο",
      "and select at least 0": "και επιλέξτε τουλάχιστον 0",
      "the contact is required": "το επικοινωνίας απαιτείται",
      "full name is required": "Απαιτείται το πλήρες όνομα",
      "paid an integer": "πληρώθηκε ακέραιο",
      "date payment is required": "Απαιτείται ημερομηνία πληρωμής",
      "transit number is required": "Απαιτείται αριθμός μετάβασης",
      "date start is required": "Απαιτείται ημερομηνία έναρξης",
      "dare end is required": "Απαιτείται ημερομηνία λήξης",
      "private is required": "Απαιτείται ιδιωτικό",
      "professional field is required": "Απαιτείται επαγγελματικό πεδίο",
      "platform update successfully": "Η πλατφόρμα ενημερώθηκε με επιτυχία",
      "platform deleted successfully": "Η πλατφόρμα διαγράφηκε με επιτυχία",
      "contact update successfully": "Η επαφή ενημερώθηκε με επιτυχία",
      "contact deleted successfully": "Η επαφή διαγράφηκε με επιτυχία",
      "contact is required": "Το contact απαιτείται",
      "address updated successfully": "Η διεύθυνση ενημερώθηκε με επιτυχία",
      "address deleted successfully": "Η διεύθυνση διαγράφηκε με επιτυχία",
      "paid address is required": "Απαιτείται διεύθυνση πληρωμής",
      "payment time is required": "Απαιτείται χρόνος πληρωμής",
      "fee payment is required": "Απαιτείται πληρωμή τέλους",
      "payment methods is required": "Απαιτείται τρόπος πληρωμής",
      "list of contacts is required": "Απαιτείται λίστα επαφών",
      "the year is required": "απαιτείται το έτος",
      "personas of the employee is required":
        "απαιτούνται τα άτομα του υπαλλήλου",
      "an example is required": "απαιτείται ένα παράδειγμα",
      deleteRankingsWarning:
        "Οι κατατάξεις για αυτήν την ημερομηνία υπάρχουν ήδη. Με την ενημέρωση, οι παλιές βαθμολογίες για αυτήν την ημερομηνία θα διαγραφούν. Θέλετε να συνεχίσετε;",
      "Display athlete birth year in Participants": `Εμφάνιση έτους γέννησης αθλητή στους "Συμμετέχοντες"`,
      "Display athlete club": "Εμφάνιση συλλόγου αθλητή",
      "Display athlete city": "Εμφάνιση πόλης αθλητή",
      "Display athlete birth year": "Εμφάνιση έτους γέννησης αθλητή",
    },
  },
  ee: {
    translation: {
      "Athlete Portal": "Sportlase portaal",
      Create: "Loo",
      "Your subscription": "Sinu tellimus",
      Notifications: "Teavitused",
      "Add League": "Lisa liiga",
      "Add Tournament": "Lisa turniir",
      "Add Club": "Lisa klubi",
      "Add News": "Lisa uudis",
      "Add Team": "Lisa meeskond",
      Home: "Kodu",
      Platforms: "platvormid",
      "Search Platform": "Otsi platvormi",
      "ADD NEW Platform": "LISA UUS platvorm",
      "Platform name": "Platvormi nimi",
      "Platform image": "Platvormi pilt",
      Country: "Riik",
      Sport: "Sport",
      Type: "Tüüp",
      Organization: "Organisatsioon",
      Status: "Staatus",
      "There is no available platforms": "Puuduvad saadavalolevad platvormid",
      Dashboard: "Juhtpaneel",
      Edit: "Muuda",
      New: "Uus",
      "Add New Platform": "Lisa uus platvorm",
      "Edit Platforms": "Muuda platvorme",
      "Organization account": "Organisatsiooni konto",
      "Platform general": "Platvormi üldine",
      Appearance: "Välimus",
      "Company details": "Ettevõtte andmed",
      "Landing & Navigation": "Maandumine ja navigeerimine",
      Integrations: "Integratsioonid",
      "Additional requests": "Lisapäringud",
      "Service fees": "Teenustasud",
      "Update Organization Account": "Uuenda organisatsiooni kontot",
      "USER IS NOT IN THE LIST": "KASUTAJAT EI OLE LOETELUS",
      Name: "Nimi",
      "Enter name": "Sisesta nimi",
      Surname: "Perekonnanimi",
      "Enter surname": "Sisesta perekonnanimi",
      Email: "E-post",
      "Enter Email Address": "Sisesta e-posti aadress",
      "Change owner": "Muuda omanikku",
      "Save new owner": "Salvesta uus omanik",
      Password: "Parool",
      "Confirm Password": "Kinnita parool",
      "Organization full name": "Organisatsiooni täisnimi",
      "Enter Organization full name": "Sisesta organisatsiooni täisnimi",
      "Organization abbreviations": "Organisatsiooni lühendid",
      "Enter Organization abbreviation": "Sisesta organisatsiooni lühend",
      "Organization type": "Organisatsiooni tüüp",
      "About organization": "Organisatsiooni kohta",
      "Contact email": "Kontakt-e-post",
      "Enter Contact Email": "Sisesta kontakt-e-post",
      "Contact phone number": "Kontakttelefoninumber",
      "Enter Contact phone number": "Sisesta kontakttelefoninumber",
      Website: "Veebileht",
      "Enter website": "Sisesta veebileht",
      "Instagram link": "Instagrami link",
      "Insert Instagram link": "Sisesta Instagrami link",
      Continue: "Jätka",
      "Platform General": "Platvormi üldine",
      "Platform title": "Platvormi pealkiri",
      "Enter Platform Title": "Sisesta platvormi pealkiri",
      "Platform type": "Platvormi tüüp",
      "First selected country will act as primary country":
        "Esimene valitud riik toimib esmase riigina",
      "Select sports": "Vali spordialad",
      "First selected sport will act as primary sport":
        "Esimene valitud sport toimib esmase spordialana",
      Sports: "Spordialad",
      "Select discipline": "Vali distsipliin",
      "Select third-party licence": "Vali kolmanda osapoole litsents",
      "third-party licence": "Kolmanda osapoole litsents",
      "No third-party license Found": "Kolmanda osapoole litsentsi ei leitud",
      "Select languages": "Vali keeled",
      "First selected language will act as primary language":
        "Esimene valitud keel toimib esmase keelena",
      "Select language": "Vali keel",
      Timezone: "Ajavöönd",
      "Time Zone": "Ajavöönd",
      Currency: "Valuuta",
      "First selected currency will act as primary currency":
        "Esimene valitud valuuta toimib esmase valuutana",
      "Select currency": "Vali valuuta",
      Description: "Kirjeldus",
      "Do you have already your domain name?": "Kas teil on juba domeeninimi?",
      "Enter your domain name": "Sisesta oma domeeninimi",
      Back: "Tagasi",
      Save: "Salvesta",
      "Upload primary logo (svg or png)":
        "Laadi üles põhilogotüüp (svg või png)",
      "Upload secondary logo (svg or png)":
        "Laadi üles teisene logotüüp (svg või png)",
      "Upload cover picture": "Laadi üles kaanepilt",
      "No logo, you want us to make one for you":
        "Logot ei ole, soovite, et teeme selle teie jaoks",
      "Click Here": "Klõpsa siin",
      "Select primary color": "Vali esmane värv",
      "Select secondary color": "Vali teisene värv",
      "Company name": "Ettevõtte nimi",
      "Enter Company Name": "Sisesta ettevõtte nimi",
      "Registration Number": "Registreerimisnumber",
      "Enter Registration Number": "Sisesta registreerimisnumber",
      "Full Address": "Täielik aadress",
      "Enter Full Address": "Sisesta täielik aadress",
      "Signee full name": "Allkirjastaja täisnimi",
      "Signee role": "Allkirjastaja roll",
      "Contact Email": "Kontakt-e-post",
      "Landing / Home Screen": "Maandumisleht / Avaleht",
      "Do you need landing?": "Kas vajate maandumislehte?",
      "Select Template": "Vali mall",
      "No, I will use my own website as Landing page.":
        "Ei, kasutan oma veebisaiti maandumislehena.",
      "Do you need Integrations?": "Kas vajate integratsioone?",
      "Payment Service Provider": "Makseteenuse pakkuja",
      "Make sure to get familiar with Stripe service fees":
        "Veenduge, et olete tuttav Stripe'i teenustasudega",
      "Additional services": "Lisateenused",
      "Service Name": "Teenuse nimi",
      Price: "Hind",
      "Mobile app": "Mobiilirakendus",
      SMM: "SMM",
      "Graphics for SMM": "Graafika SMM-iks",
      "Your request": "Teie päring",
      "Type here your request": "Sisestage siia oma päring",
      "Upload file": "Laadi fail üles",
      "Entry service fee": "Sissepääsu teenustasu",
      "Tournated entry service fee": "Turniiri sissepääsu teenustasu",
      "Entery entery fee": "Sissekande sissekandetasu",
      "Partner entry service fee": "Partneri sissepääsu teenustasu",
      "Enter partner entry fee": "Sisesta partneri sissepääsu tasu",
      "License service fee": "Litsentsi teenustasu",
      "Tournated license service fee": "Turniiri litsentsi teenustasu",
      "Enter tournated license fee": "Sisestage turniiri litsentsitasu",
      "Partner license service fee": "Partneri litsentsi teenustasu",
      "Enter partner license fee": "Sisesta partneri litsentsitasu",
      "Enable Pricing": "Luba hinnakujundus",
      Leagues: "Liigad",
      "Create new League": "Loo uus liiga",
      "There is no available leagues": "Saadaolevaid liigasid pole",
      Completed: "Lõpule viidud",
      Upcoming: "Eelseisvad",
      Active: "Aktiivne",
      Total: "Kokku",
      "Search Leagues": "Otsi liigasid",
      "League Title": "Liiga pealkiri",
      "League Type": "Liiga tüüp",
      Logo: "Logo",
      Tournaments: "Turniirid",
      Athletes: "Sportlased",
      Regulations: "Regulatsioonid",
      Platform: "Platvorm",
      "We recommend an image that is 256 x 256 pixels":
        "Soovitame pilti suurusega 256 x 256 pikslit",
      "League Abbreviation": "Liiga lühend",
      "League owner": "Liiga omanik",
      "Select Organization": "Vali organisatsioon",
      "Select platform": "Vali platvorm",
      "League Priority": "Liiga prioriteet",
      "Go to Next": "Mine järgmisele",
      Phone: "Telefon",
      "League Website": "Liiga veebisait",
      Instagram: "Instagram",
      "Field Title": "Välja pealkiri",
      "Field Type": "Välja tüüp",
      "Add New Point": "Lisa uus punkt",
      "Title Name": "Pealkirja nimi",
      Select: "Vali",
      "Field Value": "Välja väärtus",
      "Add Another Option Below": "Lisa allpool veel üks valik",
      Update: "Uuenda",
      Management: "Haldus",
      "League status": "Liiga staatus",
      "Select one": "Vali üks",
      "Make this league featured": "Muuda see liiga esiletõstetuks",
      "Enable rankings": "Luba edetabelid",
      "enable ranking":
        "Loo kohandatud edetabelid oma liiga jaoks, ühendades turniiri kategooriad, määratledes vanuse/sugu piirangud ja lisades täiendavaid reegleid.",
      "Enable online payments": "Luba online-maksed",
      "enable online payment":
        "Alusta turniiride ja litsentside online-maksete vastuvõtmist. Tutvuge meie teenustasude mudeliga online-maksete haldamiseks siin. (klõpsates avaneb üksikasjalik modaal koos meie hinnakujundusega",
      pitchdeck: "pitchdeck",
      "Enable licences": "Luba litsentsid",
      "License description":
        "Seadistage litsentsid, hinnad, perioodid ja reeglid.",
      "Make club field mandatory and display club details as organizer on Tournament page":
        "Muutke klubi väli kohustuslikuks ja kuvage klubi üksikasjad korraldajana turniiri lehel",
      "Create League": "Loo liiga",
      "Organization Accounts": "Organisatsiooni kontod",
      "ADD NEW ACCOUNT": "LISA UUS KONTO",
      "There is no available organizations":
        "Saadaolevaid organisatsioone pole",
      "Search Organizations": "Otsi organisatsioone",
      "Full name": "Täisnimi",
      "Organization Abbrevation": "Organisatsiooni lühend",
      "UPDATE USER PROFILE": "UUENDA KASUTAJA PROFILIT",
      "Enter phone number": "Sisesta telefoninumber",
      "Organization Settings": "Organisatsiooni seaded",
      "Organization Type": "Organisatsiooni tüüp",
      "Organization Logo": "Organisatsiooni logo",
      "About Organization": "Organisatsiooni kohta",
      "Contact Details": "Kontaktandmed",
      Users: "Kasutajad",
      "Search User": "Otsi kasutajat",
      "ADD NEW USER": "LISA UUS KASUTAJA",
      Avatar: "Avatar",
      "Phone Number": "Telefoninumber",
      Role: "Roll",
      "IPIN License": "IPIN litsents",
      "DUPR License": "DUPR litsents",
      "Tennis Europe License": "Tennis Europe litsents",
      "LTS U10 License": "LTS U10 litsents",
      "FIDE License": "FIDE litsents",
      "Add new user": "Lisa uus kasutaja",
      "Edit User": "Muuda kasutajat",
      "User Account": "Kasutajakonto",
      "Date of birth": "Sünniaeg",
      Gender: "Sugu",
      "Representing country": "Esindatav riik",
      City: "Linn",
      "Enter city": "Sisesta linn",
      Address: "Aadress",
      "Enter address": "Sisesta aadress",
      Career: "Karjäär",
      Club: "Klubi",
      "Select club": "Vali klubi",
      "Select coach": "Vali treener",
      Parent: "Vanem",
      "Select parent": "Vali vanem",
      "Write a bio": "Kirjuta elulugu",
      CHECKBOXES: "MÄRGRUUDUD",
      "Give access to coach to purchase licences and manage entries on your behalf":
        "Andke treenerile ligipääs litsentside ostmiseks ja kirjete haldamiseks teie nimel",
      "Give access to club manager(-es) to purchase licences and manage entries on your behalf":
        "Andke klubihaldurile(-itele) ligipääs litsentside ostmiseks ja kirjete haldamiseks teie nimel",
      "Give access to parent(-s) to purchase licences and manage entries on your behalf":
        "Andke vanemale(-tele) ligipääs litsentside ostmiseks ja kirjete haldamiseks teie nimel",
      Socials: "Sotsiaalmeedia",
      "Insert link here": "Sisesta link siia",
      Facebook: "Facebook",
      Linkedin: "Linkedin",
      Tiktok: "Tiktok",
      Youtube: "Youtube",
      Licence: "Litsents",
      "ITF IPIN": "ITF IPIN",
      DUPRID: "DUPRID",
      "Tennis Europe ID": "Tennis Europe ID",
      "LTS U10": "LTS U10",
      ATP: "ATP",
      WTA: "WTA",
      FIDE: "FIDE",
      "Update password": "Uuenda parooli",
      "Re-Enter": "Sisesta uuesti",
      "Login settings": "Sisselogimise seaded",
      "Switch Standard login to Social login":
        "Lülita tavaline sisselogimine sotsiaalseks sisselogimiseks",
      "Connect Gmail": "Ühenda Gmail",
      "Enable 2FA Google Authorisation": "Luba 2FA Google'i autentimine",
      "Enable 2FA Email Authorisation": "Luba 2FA e-posti autentimine",
      "Role Name": "Rolli nimi",
      "Is user verified?": "Kas kasutaja on kontrollitud?",
      Yes: "Jah",
      No: "Ei",
      Tournament: "Turniir",
      "All tournaments": "Kõik turniirid",
      "League tournaments": "Liigaturniirid",
      "Search Tournaments": "Otsi turniire",
      "Create new Tournament": "Loo uus turniir",
      "Tournament Title": "Turniiri pealkiri",
      "Start - End": "Algus - Lõpp",
      Organizer: "Korraldaja",
      Location: "Asukoht",
      "List of categories": "Kategooriate loend",
      "Update Status": "Uuenda olekut",
      "There is no available tournaments": "Turniire pole saadaval",
      "ADD NEW SPORT": "LISA UUS SPORDILIIGA",
      "Search Sports": "Otsi sporti",
      "Sport Title": "Spordi pealkiri",
      "Add new Sport": "Lisa uus sport",
      "Edit Sports": "Muuda sporti",
      "Sports title": "Spordi pealkiri",
      "Enter title": "Sisesta pealkiri",
      "Sports abbreviation": "Spordi lühend",
      "Enter abbreviation": "Sisesta lühend",
      "Resource title": "Ressursi pealkiri",
      "Rule how to add results/score":
        "Reegel, kuidas tulemusi/tulemusi lisada",
      Icon: "Ikoon",
      "Enable disciplines": "Luba distsipliinid",
      "Enable courts": "Luba väljakud",
      "Enable stages": "Luba etapid",
      "Enable pairings": "Luba paarid",
      "Enable rounds": "Luba voorud",
      "Enable standings based on pairings": "Luba seisundid paaris",
      "Display draws": "Kuva loosid",
      "Display groups": "Kuva grupid",
      "Display live standings": "Kuva reaalajas seisundid",
      "Display order of play": "Kuva mängujärjekord",
      "Display list & results": "Kuva nimekiri ja tulemused",
      "Display pending results": "Kuva ootel tulemused",
      "Display final standings": "Kuva lõplikud seisundid",
      "Display athlete chess titles in Participants":
        "Kuva sportlase male tiitlid osalejatele",
      "Display seeds column Participants": "Kuva seemne veerg osalejatele",
      "Display segments column in Participants":
        "Kuva osalejate segmendi veerg",
      "Upload file here": "Laadi fail üles siia",
      Disciplines: "Distsipliinid",
      "ADD NEW DISCIPLINE": "LISA UUS DISKRIPLIIN",
      "There is no available disciplines": "Distsipliine pole saadaval",
      "Search Disciplines": "Otsi distsipliine",
      "Sport name": "Spordi nimi",
      "Add new Discipline": "Lisa uus distsipliin",
      "Edit Discipline": "Muuda distsipliini",
      "Discipline title": "Distsipliini pealkiri",
      "Select Sport": "Vali spordiala",
      "No sports found": "Spordialasid ei leitud",
      "Results type": "Tulemuste tüüp",
      "Third-party licences": "Kolmanda osapoole litsentsid",
      "Third party licence": "Kolmanda osapoole litsents",
      "There is no available third-party licences":
        "Kolmanda osapoole litsentse pole saadaval",
      "ADD NEW LICENCE": "LISA UUS LITSENTS",
      "Search license": "Otsi litsentsi",
      "license name": "litsentsi nimi",
      "License type": "Litsentsi tüüp",
      "Add licence": "Lisa litsents",
      "Add Third-party licence": "Lisa kolmanda osapoole litsents",
      "Edit Third-party licence": "Muuda kolmanda osapoole litsentsi",
      "Organization title": "Organisatsiooni pealkiri",
      "Organization abbreviation": "Organisatsiooni lühend",
      "Licence title": "Litsentsi pealkiri",
      "Enter license title": "Sisesta litsentsi pealkiri",
      "Licence type": "Litsentsi tüüp",
      "Request license in user profile":
        "Taotlege kasutaja profiilis litsentsi",
      "Request license on user registration in platform":
        "Taotlege kasutaja registreerimisel platvormil litsentsi",
      "Make licence mandatory": "Tehke litsents kohustuslikuks",
      "Link to get licence": "Link litsentsi saamiseks",
      "Enter link to get licence": "Sisestage link litsentsi saamiseks",
      Partners: "Partnerid",
      "ADD NEW PARTNER": "LISA UUS PARTNER",
      "There is no available partners": "Partnereid pole saadaval",
      "Search Partners": "Otsi partnereid",
      "Create new Partner": "Loo uus partner",
      Link: "Link",
      Text: "Tekst",
      Priority: "Prioriteet",
      "Showing dates": "Kuupäevade näitamine",
      "New Partner": "Uus partner",
      "Edit Partner": "Muuda partnerit",
      "Partner Logo": "Partneri logo",
      platform: "platvorm",
      "No platform available": "Platvorm pole saadaval",
      "No league available": "Liigat pole saadaval",
      "No Clubs available": "Klubisid pole saadaval",
      "Sponsor Text": "Sponsoritekst",
      "Date Settings": "Kuupäeva sätted",
      "Active From": "Aktiivne alates",
      "Active To": "Aktiivne kuni",
      "Platform managers": "Platvormi juhid",
      "ADD NEW MANAGER": "LISA UUS JUHT",
      "There is no available manager": "Juhte pole saadaval",
      "Search Manager": "Otsi juhti",
      "Export team": "Ekspordi meeskond",
      "Export team emails": "Ekspordi meeskonna e-postid",
      "Add platform manager": "Lisa platvormi juht",
      "Edit platform panager": "Muuda platvormi juhti",
      "Enter Organization": "Sisesta organisatsioon",
      "Select organization": "Vali organisatsioon",
      "Filter users": "Filtreeri kasutajaid",
      "Export users": "Ekspordi kasutajaid",
      "Export user emails": "Ekspordi kasutaja e-postid",
      "Age group": "Vanuserühm",
      "There is no available users": "Kasutajaid pole saadaval",
      Coaches: "Treenerid",
      "ADD NEW COACH": "LISA UUS TREENER",
      "There is no available account": "Kontosid pole saadaval",
      "Search Coach": "Otsi treenerit",
      "Add new coach": "Lisa uus treener",
      "Edit Coach": "Muuda treenerit",
      "Coach image": "Treeneri pilt",
      Category: "Kategooria",
      "There is no available sports": "Spordialasid pole saadaval",
      "there are not available clubs": "klubisid pole saadaval",
      "Date from": "Kuupäevast",
      "Date to": "Kuupäevani",
      "Display on organization's landing page": "Kuva organisatsiooni avalehel",
      "Platform tournaments": "Platvormi turniirid",
      Title: "Pealkiri",
      Posts: "Postitused",
      "ADD POST": "LISA POSTITUS",
      "There is no available post": "Postitusi pole saadaval",
      News: "Uudised",
      "Search Post": "Otsi postitusi",
      Image: "Pilt",
      Author: "Autor",
      Content: "Sisu",
      "Cover / featured image": "Katvus / esiletõstetud pilt",
      "Select league": "Vali liiga",
      League: "Liiga",
      "Add video from youtube.com": "Lisa video youtube.com-ist",
      Date: "Kuupäev",
      "Purchased Licenses": "Ostetud litsentsid",
      "Search Licenses": "Otsi litsentse",
      "Create new License": "Loo uus litsents",
      "Athlete name & surname": "Sportlase nimi ja perekonnanimi",
      "License Name": "Litsentsi nimi",
      "License Price": "Litsentsi hind",
      "License active from": "Litsents aktiivne alates",
      "License active to": "Litsents aktiivne kuni",
      "Paid(online/cash)": "Makstud (online/sularahas)",
      "There is no available purchased license":
        "Ostetud litsentse pole saadaval",
      "New License": "Uus litsents",
      "Edit License": "Muuda litsentsi",
      "Athlete Email": "Sportlase e-post",
      License: "Litsents",
      "No license found": "Litsentse ei leitud",
      "Payment Method": "Makseviis",
      "How much paid": "Kui palju makstud",
      Teams: "Meeskonnad",
      "ADD NEW TEAM": "LISA UUS MEESKOND",
      "There is no available teams": "Meeskondi pole saadaval",
      "Search team": "Otsi meeskonda",
      "Create new Team": "Loo uus meeskond",
      "Team Members": "Meeskonna liikmed",
      Discipline: "Distsipliin",
      "New Team": "Uus meeskond",
      "Edit Team": "Muuda meeskonda",
      "Team Logo": "Meeskonna logo",
      "No Clubs Found": "Klubisid ei leitud",
      "No disciplines found": "Distsipliine ei leitud",
      "Club status": "Klubi staatus",
      Members: "Liikmed",
      "Add new Members": "Lisa uusi liikmeid",
      "Add New Member": "Lisa uus liige",
      "Member email": "Liikme e-post",
      "Select Role": "Vali roll",
      "Add more": "Lisa rohkem",
      "ADD NEW CLUB": "LISA UUS KLUBI",
      "There is no available clubs": "Klubisid pole saadaval",
      Clubs: "Klubid",
      "Search Clubs": "Otsi klubisid",
      "Club Name": "Klubi nimi",
      "Add new Club": "Lisa uus klubi",
      "Edit Club": "Muuda klubi",
      "Appearance & Location": "Välimus ja asukoht",
      "Club title": "Klubi pealkiri",
      "Add club logo": "Lisa klubi logo",
      "How to find us": "Kuidas meid leida",
      "Contact details": "Kontaktandmed",
      "Contact Phone": "Kontakttelefon",
      Twitter: "Twitter",
      "Services & Resources": "Teenused ja ressursid",
      "Select services": "Vali teenused",
      Services: "Teenused",
      Resources: "Ressursid",
      "Resource Name": "Ressursi nimi",
      Service: "Teenus",
      "Add Resources": "Lisa ressursid",
      "Add New Resource": "Lisa uus ressurss",
      "Select Service": "Vali teenus",
      "Select type": "Vali tüüp",
      "Add Another": "Lisa veel üks",
      "Club page": "Klubi leht",
      "Select Coaches": "Vali treenerid",
      "Athlete Club": "Sportlase klubi",
      desc: "Kui teie klubi on lisatud, saavad sportlased selle valida oma sportlase profiili seadistamise lehel ja seejärel kuvatakse nad klubi lehel heakskiitmiseks/tagasilükkamiseks. Heaks kiidetud liikmed kuvatakse klubi lehel",
      "Online booking": "Online-broneerimine",
      "Club Main Administrator": "Klubi peamine administraator",
      "Main Adminitrator": "Põhiadministraator",
      "CLUB MAIN ADMINISTRATOR IS NOT IN THE LIST":
        "KLUBI PEAMINE ADMINISTRAATOR EI OLE LOETELUS",
      Administrator: "Administraator",
      "ADMINISTRATOR IS NOT IN THE LIST": "ADMINISTRAATOR EI OLE LOETELUS",
      Banners: "Bännerid",
      "There is no available banners": "Bännereid pole saadaval",
      "ADD BANNER": "LISA BÄNNER",
      "Search Banner": "Otsi bännerit",
      Headline: "Pealkiri",
      subheadline: "Alapealkiri",
      "Button Text": "Nupu tekst",
      "New Banner": "Uus bänner",
      "Edit Banner": "Muuda bännerit",
      Subheadline: "Alapealkiri",
      "Button text": "Nupu tekst",
      "Button link": "Nupu link",
      "Select roles": "Vali rollid",
      "Banner images": "Bänneri pildid",
      "Control Panel": "Juhtpaneel",
      "Run the tournament step by step from this control panel":
        "Käivitage turniir samm-sammult sellest juhtpaneelist",
      "Edit & Setup league": "Muuda ja seadista liiga",
      "Edit Now": "Muuda kohe",
      "Create & Setup league": "Loo ja seadista liiga",
      "Start Now": "Alusta kohe",
      "Add league members": "Lisa liiga liikmeid",
      "league member description":
        "Kaasa liiga liikmed, nagu juhid, turniiri korraldajad ja kohtunikud oma liigasse. Juhtidel on juurdepääs juhtpaneelile ja kõigile haldusfunktsioonidele, korraldajad saavad lisada turniire ja hallata neid ning kohtunikud pääsevad sissekannetele, loosidele, gruppidele, reaalajas seisunditele, mängujärjekorrale ja tulemuste haldamisele neis määratud turniiridel.",
      "Add Now": "Lisa kohe",
      "Add tournament categories": "Lisa turniiri kategooriad",
      "tournament category description":
        "Seadistage oma liigale turniiri kategooriad, määrates formaadid (üksikmängud/paarismängud/meeskonnad), vanuse/sugu piirangud ja ühendades need edetabelikategooriatega (kui olete järgmises etapis need edetabelikategooriad lisatud).",
      "Add rankings categories": "Lisa edetabelikategooriad",
      "ranking category description":
        "Seadistage oma liigale edetabelikategooriad. Valige turniiri kategooriad, mida edetabelitesse lisada, määrake, kui palju turniire arvestada, seadke ajaraamistiku, ühendage rahvusvaheliste liigadega ja määrake muud reeglid.",
      "Setup ranking points": "Seadista edetabelipunktid",
      "ranking description":
        "Konfigureerige punktitabel iga turniiri kategooria jaoks, määrates iga koha jaoks punktide arvu. Üksikasjalikum tabel lihtsustab ja automatiseerib protsessi.",
      Setup: "Seadista",
      "Setup licences": "Seadista litsentsid",
      "Add tournament": "Lisa turniir",
      "tournament description":
        "Järgige juhiseid oma turniiri loomiseks ja ürituse haldamiseks. Olge kindel, et saate põhjaliku loendi juhistest, mis kajastavad meie mitmeid spordiürituste haldamise funktsioone",
      "Leagues Members": "Liiga liikmed",
      "Add new Member": "Lisa uus liige",
      "There is no available members": "Liikmeid pole saadaval",
      "League members": "Liiga liikmed",
      "Search Members": "Otsi liikmeid",
      "ADD NEW MEMBER": "LISA UUS LIIGE",
      "Delete league member": "Kustuta liiga liige",
      "Are you sure you want to delete?": "Olete kindel, et soovite kustutada?",
      Cancel: "Tühista",
      Delete: "Kustuta",
      "Tournament Categories": "Turniiri kategooriad",
      "There is no available categories": "Kategooriaid pole saadaval",
      "ADD NEW CATEGORY": "LISA UUS KATEGOORIA",
      "Search Categories": "Otsi kategooriaid",
      "Copy Categories": "Kopeeri kategooriad",
      "Delete Categories": "Kustuta kategooriad",
      "Category Name": "Kategooria nimi",
      "Category Type": "Kategooria tüüp",
      "Select League": "Vali liiga",
      Copy: "Kopeeri",
      "Are you sure you want to delete these categories?":
        "Olete kindel, et soovite need kategooriad kustutada?",
      "New Category": "Uus kategooria",
      "Edit Category": "Muuda kategooriat",
      "Category name": "Kategooria nimi",
      "Add team selection on tournament registration":
        "Lisa meeskonna valik turniiri registreerimisele",
      "Add club selection on tournament registration":
        "Lisa klubi valik turniiri registreerimisele",
      "Skills level": "Oskuste tase",
      "Category Gender": "Kategooria sugu",
      "Age from": "Vanus alates",
      "Age to": "Vanus kuni",
      "Age Limit Type": "Vanuse piirangu tüüp",
      "No Types Found!": "Tüüpe ei leitud!",
      "Ask skills level on a tournament registration":
        "Küsi oskuste taset turniiri registreerimisel",
      "Enable License": "Luba sisse lülitada",
      "Display athlete club in Participants": "Kuva sportlase klubi osalejates",
      "Display athlete city in Participants": "Kuva sportlase linn osalejates",
      "Select License": "Vali litsents",
      "No License Found!": "Litsentse ei leitud!",
      "Ranking Category for men": "Edetabelikategooria meestele",
      "No Category Found!": "Kategooriaid ei leitud!",
      "Ranking Category for Women": "Edetabelikategooria naistele",
      "Third-party license": "Kolmanda osapoole litsents",
      "No third-party license Found!": "Kolmanda osapoole litsentse ei leitud!",
      "Time control": "Aja kontroll",
      "No time control found": "Aja kontrolli ei leitud",
      "Order display for seeding": "Seemnete järjestuse kuvamine",
      "Sport fishing settings": "Spordikalastuse seaded",
      "Identify team members on registration":
        "Määrake meeskonnaliikmed registreerimisel",
      "Min number of members": "Vähim liikmete arv",
      "Max number of members": "Maksimaalne liikmete arv",
      "Reservists required": "Reservväelased vajalikud",
      "Min number of reservists": "Vähim reservväelaste arv",
      "Max number of reservists": "Maksimaalne reservväelaste arv",
      "Captain required": "Kapten vajalik",
      "Transfer members to singles category":
        "Liikmete ülekanne üksikmängude kategooriasse",
      "Select singles category": "Vali üksikmängude kategooria",
      "No Category Found": "Kategooriaid ei leitud",
      "National tournament (country vs country)":
        "Riiklik turniir (riik vs riik)",
      "Club championships (club vs club)":
        "Klubide meistrivõistlused (klubi vs klubi)",
      "Create matches from team members and count points result based on matches result":
        "Loo mänge meeskonnaliikmetest ja loe punktide tulemus mängude tulemuse põhjal",
      "Count individual total": "Kokku individuaalselt",
      "Count teams total": "Kokku meeskonniti",
      "Number of athletes to count min":
        "Minimaalne sportlaste arv arvutamiseks",
      "Number of athletes to count max":
        "Maksimaalne sportlaste arv arvutamiseks",
      "Max Entries per team": "Maksimaalsed sisenemised meeskonna kohta",
      "Allow registration without team":
        "Luba registreerimine ilma meeskonnata",
      "Category Status": "Kategooria staatus",
      "Ranking Categories": "Edetabelikategooriad",
      "There is no available ranking categories":
        "Edetabelikategooriaid pole saadaval",
      Categories: "Kategooriad",
      "No of Tournaments": "Turniiride arv",
      "Time Period": "Aja periood",
      "No Leagues found": "Liigasid ei leitud",
      "New Ranking Category": "Uus edetabelikategooria",
      "Select Categories": "Vali kategooriad",
      "No Categories Found!": "Kategooriaid ei leitud!",
      "Max number of tournaments": "Maksimaalne turniiride arv",
      "Count specific tournaments": "Arvestage konkreetseid turniire",
      "Add penalty points for missing a tournament":
        "Lisage karistuspunkte turniiril puudumise eest",
      "Penalty rule": "Karistusreegel",
      "Enter points": "Sisesta punktid",
      "Additional Rules": "Lisareeglid",
      Sector: "Sektor",
      Coefficient: "Kordaja",
      "No of tournaments": "Turniiride arv",
      "Edit additional rule(s)": "Muuda lisareeglit (e)",
      "Add additional rule(s)": "Lisa lisareeglit (e)",
      "During which period tournament points are counted":
        "Millal arvestatakse turniiri punkte",
      "Select Time Period": "Vali ajaperiood",
      "Points sorting": "Punktide sorteerimine",
      "Sport Fishing": "Spordikalastus",
      "Select Stage form which take points":
        "Valige etapp, millest võtta punkte",
      "Ranking Points": "Edetabeli punktid",
      "Search Rankings": "Otsi edetabeleid",
      "Add points block": "Lisa punktiblokk",
      "Select / UnSelect All": "Vali / tühista kõik",
      Place: "Koht",
      Points: "Punktid",
      "Bracket type": "Sulgudes tüüp",
      Segment: "Segment",
      "Num of Entries": "Sisenemiste arv",
      "Ad.Rules": "Ad. Reeglid",
      "view rule": "vaade reegel",
      "Add new row": "Lisa uus rida",
      "There is no available ranking points": "Edetabelipunkte pole saadaval",
      "Edit Points": "Muuda punkte",
      "Add Points": "Lisa punkte",
      "Points block name": "Punktide bloki nimi",
      Specification: "Täpsustus",
      "Rule Settings": "Reegli seaded",
      "Select operator": "Valige operaator",
      "Select skills level": "Vali oskuste tase",
      "Number of players": "Mängijate arv",
      "Number of entries": "Sisenemiste arv",
      update: "uuenda",
      SAVE: "SALVESTA",
      DISCARD: "LOOBUMINE",
      "SET NEW RULE": "MÄÄRA UUS REEGEL",
      "Edit Row": "Muuda rida",
      "Add Row": "Lisa rida",
      ADD: "LISA",
      "Copy Ranking Point": "Kopeeri edetabelipunkt",
      "Select Ranking Points": "Vali edetabelipunktid",
      "Copy Ranking Point Blocks": "Kopeeri edetabelipunktide blokid",
      "Delete Ranking Point Block": "Kustuta edetabelipunktide blokk",
      Licenses: "Litsentsid",
      "There is no available licenses": "Litsentse pole saadaval",
      "Price Currency": "Hinna valuuta",
      "Select Leagues": "Vali liigad",
      "No League Found!": "Liigasid ei leitud!",
      "License price": "Litsentsi hind",
      "License Type": "Litsentsi tüüp",
      "License Status": "Litsentsi staatus",
      "Additional questions": "Lisaküsimused",
      "Add additional question": "Lisa täiendav küsimus",
      "Edit this": "Muuda seda",
      "Add new": "Lisa uus",
      question: "küsimus",
      Question: "Küsimus",
      "Field Option": "Välja valik",
      Remove: "Eemalda",
      "Add new option": "Lisa uus valik",
      "Ask this question to each athlete":
        "Küsige seda küsimust igalt sportlaselt",
      Submit: "Esita",
      "Delete Warning": "Kustuta hoiatus",
      "Are you sure you want to delete this question?":
        "Olete kindel, et soovite selle küsimuse kustutada?",
      "Confirm Deletion": "Kinnitage kustutamine",
      Rankings: "Edetabelid",
      Search: "Otsing",
      "ranking Category": "edetabeli kategooria",
      "No date Found": "Kuupäevi ei leitud",
      "Create Rankings": "Loo edetabelid",
      "Update Rankings": "Uuenda edetabeleid",
      close: "sulge",
      Year: "Aasta",
      "Points Details": "Punkti üksikasjad",
      "No points yet": "Punkte pole veel",
      Position: "Positsioon",
      Athlete: "Sportlane",
      "There is no available rankings": "Edetabeleid pole saadaval",
      "Select Ranking categories": "Vali edetabelikategooriad",
      "Remove Rankings": "Eemalda edetabelid",
      "Select Date": "Vali kuupäev",
      "Are you sure you want to delete rankings?":
        "Olete kindel, et soovite edetabelid kustutada?",
      "Rankings History": "Edetabelite ajalugu",
      "Ranking Category": "Edetabeli kategooria",
      "Last Updated": "Viimati uuendatud",
      Public: "Avalik",
      "Delete warning": "Kustuta hoiatus",
      "Are you sure you want to delete this ranking?":
        "Olete kindel, et soovite selle edetabeli kustutada?",
      "There is no available rankings history":
        "Edetabeli ajalugu pole saadaval",
      "Tournament Results": "Turniiri tulemused",
      Entry: "Sissekanne",
      "Add new result": "Lisa uus tulemus",
      "There is no available tournament results":
        "Turniiri tulemusi pole saadaval",
      "Pendding tournaments": "Ootel olevad turniirid",
      "There is no available athletes": "Sportlasi pole saadaval",
      "Search Athletes": "Otsi sportlasi",
      "Country/Residency": "Riik/elukoht",
      "Super Data": "Superandmed",
      "All Tournaments": "Kõik turniirid",
      "Pending Tournaments": "Ootel olevad turniirid",
      Financials: "Finantsid",
      Accounting: "Raamatupidamine",
      Extra: "Lisand",
      "Live scoring": "Live skoorimine",
      "Live stream": "Otseülekanne",
      "Platform Settings": "platvormi seaded",
      "My Platforms": "Minu platvormid",
      Team: "Meeskond",
      Instructions: "Juhised",
      "League Settings": "Liiga sätted",
      "League Members": "Liiga liikmed",
      Upload: "Üles laadima",
      "primary logo": "põhiline logo",
      "secondary logo": "teisene logo",
      "cover picture": "kaanepilt",
      "Start Typing": "Alusta tippimist",
      "Leagues list": "Liigade nimekiri",
      "General & Appearance": "Üldine ja välimus",
      "Instagram Link": "Instagrami link",
      "Fact Sheet": "Faktileht",
      "Fact sheet": "Faktileht",
      "Upload regulations": "Laadige üles määrused",
      "Organization Abbreviation": "Organisatsiooni lühend",
      "Drag and Drop some file": "Lohistage ja kukutage mõni fail",
      "here, or click to Select file": "siia või klõpsake faili valimiseks",
      UPLOAD: "ÜLES LAADIMA",
      "DISCARD AND CANCEL": "LOOBU JA TÜHISTA",
      SUBMIT: "ESITA",
      "Add new Organization Account": "Lisage uus organisatsiooni konto",
      "Edit Organization Account": "Muuda organisatsiooni kontot",
      "Full Name": "Täisnimi",
      Biography: "Biograafia",
      "Add post": "Lisa postitus",
      "Edit Post": "Muuda postitust",
      "Edit Member": "Muuda liiget",
      "Organization name": "Organisatsiooni nimi",
      "Organization email": "Organisatsiooni e-post",
      "Organization phone": "Organisatsiooni telefon",
      "Display member on organizations public team page":
        "Kuva liige organisatsiooni avalikul meeskonna lehel",
      "Select Roles": "Vali rollid",
      "For assiging points": "Punktide määramiseks",
      "Third party license": "Kolmanda osapoole litsents",
      "Must be a valid email": "Peab olema kehtiv e-posti aadress",
      "Email is required": "E-post on nõutud",
      "Organization Name is required": "Organisatsiooni nimi on nõutud",
      "Organization Type is required": "Organisatsiooni tüüp on nõutud",
      "organization abbreviation is required":
        "Organisatsiooni lühend on nõutud",
      "Contact Email is required": "Kontakt-e-post on nõutud",
      "Password is Required": "Parool on nõutud",
      "Password should be 8 chars minimum.":
        "Parool peab olema vähemalt 8 tähemärki pikk.",
      "Passwords must match": "Paroolid peavad kattuma",
      "company name is required": "Ettevõtte nimi on nõutud",
      "company number is required": "Ettevõtte number on nõutud",
      "full address is required": "Täielik aadress on nõutud",
      "Invalid email address": "Vigane e-posti aadress",
      "signee role is required": "Allkirjastaja roll on nõutud",
      "signee full name is required": "Allkirjastaja täisnimi on nõutud",
      "partner entry fee is required": "Partneri sisenemistasu on nõutud",
      "tournated license fee is required": "Turniiri litsentsitasu on nõutud",
      "partner license fee  is required": "Partnerlitsentsi tasu on nõutud",
      "Creating platform...": "Platformi loomine...",
      "Platform created successfully": "Platform loodi edukalt",
      "Updating...": "Uuendamine...",
      "Platform updated": "Platform uuendatud",
      "Add New Club": "Lisa uus klubi",
      "organization is required": "Organisatsioon on nõutud",
      "Adding new fact...": "Uue fakti lisamine...",
      "Fact added successfully": "Fakt lisati edukalt",
      "Deleting fact...": "Fakti kustutamine...",
      "fact deleted successfully": "Fakt kustutati edukalt",
      "Updating fact...": "Fakti uuendamine...",
      "Fact updated successfully": "Fakt uuendati edukalt",
      "Creating league...": "Liiga loomine...",
      "League created successfully": "Liiga loodi edukalt",
      "Updating league...": "Liiga uuendamine...",
      "League updated successfully": "Liiga uuendati edukalt",
      "Approved & Public": "Heaks kiidetud ja avalik",
      "ATP points": "ATP punktid",
      "WTA points": "WTA punktid",
      "name Email is required": "Nimi E-post on nõutud",
      "title is require": "Pealkiri on nõutud",
      "platform type required": "Platformi tüüp on nõutud",
      "time zone is required": "Ajavöönd on nõutud",
      "description is required": "Kirjeldus on nõutud",
      "title is required": "Pealkiri on nõutud",
      "abbreviation is required": "Lühend on nõutud",
      "platform is required": "Platform on nõutud",
      "priority is required": "Prioriteet on nõutud",
      "type is required": "Tüüp on nõutud",
      "sports is required": "Spordialad on nõutud",
      "email is required": "E-post on nõutud",
      "Organization added Successfully!": "Organisatsioon lisati edukalt!",
      "name is required": "Nimi on nõutud",
      "surname is required": "Perekonnanimi on nõutud",
      "organization name is required": "Organisatsiooni nimi on nõutud",
      "organization type is required": "Organisatsiooni tüüp on nõutud",
      "Organization Account Updated Successfully!":
        "Organisatsiooni konto uuendati edukalt!",
      "Creating organization...": "Organisatsiooni loomine...",
      "Organization created successfully": "Organisatsioon loodi edukalt",
      "This User is already exist": "See kasutaja on juba olemas",
      "Re-Enter password is required": "Parooli uuesti sisestamine on nõutud",
      "Password updated": "Parool uuendatud",
      "User Updated": "Kasutaja uuendatud",
      "Sports Updated": "Spordialad uuendatud",
      "Sports Created": "Spordialad loodud",
      "Discipline Updated": "Distsipliin uuendatud",
      "Discipline Created": "Distsipliin loodud",
      "At least one sport must be selected":
        "Peab valima vähemalt ühe spordiala",
      "License Updated": "Litsents uuendatud",
      "License Created": "Litsents loodud",
      "Please provide Image": "Palun esitage pilt",
      "Partner updated Successfully!": "Partner uuendati edukalt!",
      "Partner created successfully!": "Partner loodi edukalt!",
      "Please add correct user email":
        "Palun lisage õige kasutaja e-posti aadress",
      "Coach added!": "Treener lisatud!",
      "user found": "Kasutaja leitud",
      "user not found": "Kasutajat ei leitud",
      "country is required": "Riik on nõutud",
      "gender is required": "Sugu on nõutud",
      "confirm password is required": "Parooli kinnitamine on nõutud",
      "Coache Updated Successfully!": "Treener uuendatud edukalt!",
      "Image field is required": "Pildi väli on nõutud",
      "author is required": "Autor on nõutud",
      "Updating ...": "Uuendamine ...",
      "Post updated successfully": "Postitus uuendati edukalt",
      "Creating post ...": "Postituse loomine ...",
      "Post created successfully": "Postitus loodi edukalt",
      "User found": "Kasutaja leitud",
      "User not found": "Kasutajat ei leitud",
      "paid is required": "Makstud on nõutud",
      "currency is required": "Valuuta on nõutud",
      "Athlete is required": "Sportlane on nõutud",
      "license is required": "Litsents on nõutud",
      "start date is required": "Alguskuupäev on nõutud",
      "end date is required": "Lõppkuupäev on nõutud",
      "License created successfully!": "Litsents loodi edukalt!",
      "sport is required": "Spordiala on nõutud",
      "discipline is required": "Distipliin on nõutud",
      "Team updated successfully!": "Meeskond uuendati edukalt!",
      "Team created successfully!": "Meeskond loodi edukalt!",
      "city is required": "Linn on nõutud",
      "address is required": "Aadress on nõutud",
      "headline is required": "Pealkiri on nõutud",
      "subheadline is required": "Alapealkiri on nõutud",
      "button text required": "Nupu tekst on nõutud",
      "button link required": "Nupu link on nõutud",
      "Image are required": "Pildid on nõutud",
      "Updating banner...": "Bänneri uuendamine...",
      "Banner updated successfully": "Bänner uuendati edukalt",
      "Creating banner...": "Bänneri loomine...",
      "Banner created successfully": "Bänner loodi edukalt",
      "League member added!": "Liiga liige lisatud!",
      "League Member Updated Successfully!": "Liiga liige uuendati edukalt!",
      "order is required": "Tellimus on nõutud",
      "Age from must an integer": "Vanus alates peab olema täisarv",
      "Age must be at least 0": "Vanus peab olema vähemalt 0",
      "Age to must an integer": "Vanus kuni peab olema täisarv",
      "Min members must an integer":
        "Minimaalsed liikmed peavad olema täisarvud",
      "Min members must be at least 0":
        "Minimaalsed liikmed peavad olema vähemalt 0",
      "Max members must an integer":
        "Maksimaalsed liikmed peavad olema täisarvud",
      "Max members must be at least 0":
        "Maksimaalsed liikmed peavad olema vähemalt 0",
      "Max members must be greater than and equal to min members":
        "Maksimaalsed liikmed peavad olema suuremad või võrdsed minimaalsete liikmetega",
      "Min reservists must an integer":
        "Minimaalsed reservistid peavad olema täisarvud",
      "Min reservists must be at least 0":
        "Minimaalsed reservistid peavad olema vähemalt 0",
      "Max reservists must an integer":
        "Maksimaalsed reservistid peavad olema täisarvud",
      "Max reservists must be at least 0":
        "Maksimaalsed reservistid peavad olema vähemalt 0",
      "Max reservists must be greater than and equal to min reservists":
        "Maksimaalsed reservistid peavad olema suuremad või võrdsed minimaalsete reservistidega",
      "Min number of athletes must an integer":
        "Minimaalne arv sportlasi peab olema täisarv",
      "Min number of athletes must be at least 0":
        "Minimaalne arv sportlasi peab olema vähemalt 0",
      "Max number of athletes must an integer":
        "Maksimaalne arv sportlasi peab olema täisarv",
      "Max number of athletes must be at least 0":
        "Maksimaalne arv sportlasi peab olema vähemalt 0",
      "Max number of athletes must be greater than min number of athletes":
        "Maksimaalne arv sportlasi peab olema suurem kui minimaalne arv sportlasi",
      "status is required": "Staatus on nõutud",
      "Category Updated Successfully!": "Kategooria uuendati edukalt!",
      "Category Created!": "Kategooria loodi!",
      "time period is required": "Ajaperiood on nõutud",
      "sort is required": "Sorteerimine on nõutud",
      "at-least-one": "vähemalt üks",
      "At least one category is required": "Vähemalt üks kategooria on nõutud",
      "Value cannot be less than 1": "Väärtus ei saa olla väiksem kui 1",
      "place is required": "Koht on nõutud",
      "entries is required": "Sisenemised on nõutud",
      "points is required": "Punktid on nõutud",
      "You can't remove this category because this is used into addtional rules":
        "Te ei saa seda kategooriat eemaldada, kuna seda kasutatakse lisareeglites",
      "Rule added successfully": "Reegel lisati edukalt",
      "Rule updated successfully": "Reegel uuendati edukalt",
      "Points updated successfully": "Punktid uuendati edukalt",
      "Points added successfully": "Punktid lisati edukalt",
      "Removing...": "Eemaldamine...",
      "Removed successfully!": "Eemaldatud edukalt!",
      "fee is required": "Tasu on nõutud",
      "duration is required": "Kestus on nõutud",
      "partner license fee required": "Partnerlitsentsi tasu on nõutud",
      "tournated license fee required": "Turniiri litsentsitasu on nõutud",
      "License updating ...": "Litsentsi uuendamine...",
      "License updated Successfully!": "Litsents uuendati edukalt!",
      "License creating ...": "Litsentsi loomine...",
      "License created successfully": "Litsents loodi edukalt",
      deleteRankingsWarning:
        "Selle kuupäeva paremusjärjestus on juba olemas. Värskendamisel kustutatakse selle kuupäeva vanad pingeread. Kas soovite jätkata?",
      "Display athlete birth year in Participants": `Kuva sportlase sünniaasta "Osalejates"`,
      "Display athlete club": "Kuva sportlase klubi",
      "Display athlete city": "Kuva sportlase linn",
      "Display athlete birth year": "Kuva sportlase sünniaasta",
    },
  },
  lt: {
    translation: {
      "Athlete Portal": "Sportininkų portalas",
      Create: "Kurti",
      "Your subscription": "Jūsų prenumerata",
      Notifications: "Pranešimai",
      "Add League": "Pridėti lygą",
      "Add Tournament": "Pridėti turnyrą",
      "Add Club": "Pridėti klubą",
      "Add News": "Pridėti naujienas",
      "Add Team": "Pridėti komandą",
      Home: "Pradžia",
      Platforms: "Platformos",
      "Search Platform": "Ieškoti platformos",
      "ADD NEW Platform": "PRIDĖTI NAUJĄ Platformą",
      "Platform name": "Platformos pavadinimas",
      "Platform image": "Platformos nuotrauka",
      Country: "Šalis",
      Sport: "Sportas",
      Type: "Tipas",
      Organization: "Organizacija",
      Status: "Būsena",
      "There is no available platforms": "Nėra prieinamų platformų",
      Dashboard: "Valdymo skydas",
      Edit: "Redaguoti",
      New: "Naujas",
      "Add New Platform": "Pridėti naują platformą",
      "Edit Platforms": "Redaguoti platformas",
      "Organization account": "Organizacijos paskyra",
      "Platform general": "Bendra platforma",
      Appearance: "Išvaizda",
      "Company details": "Įmonės detalės",
      "Landing & Navigation": "Pagrindinis & Navigacija",
      Integrations: "Integracijos",
      "Additional requests": "Papildomi pageidavimai",
      "Service fees": "Paslaugų mokesčiai",
      "Update Organization Account": "Atnaujinti organizacijos paskyrą",
      "USER IS NOT IN THE LIST": "VARTOTOJO NĖRA SĄRAŠE",
      Name: "Vardas",
      "Enter name": "Įveskite vardą",
      Surname: "Pavardė",
      "Enter surname": "Įveskite pavardę",
      Email: "El. paštas",
      "Enter Email Address": "Įveskite el. pašto adresą",
      "Change owner": "Keisti savininką",
      "Save new owner": "Išsaugoti naują savininką",
      Password: "Slaptažodis",
      "Confirm Password": "Patvirtinkite slaptažodį",
      "Organization full name": "Organizacijos pilnas pavadinimas",
      "Enter Organization full name": "Įveskite organizacijos pilną pavadinimą",
      "Organization abbreviations": "Organizacijos sutrumpinimai",
      "Enter Organization abbreviation": "Įveskite organizacijos sutrumpinimą",
      "Organization type": "Organizacijos tipas",
      "About organization": "Apie organizaciją",
      "Contact email": "Kontaktinis el. paštas",
      "Enter Contact Email": "Įveskite kontaktinį el. paštą",
      "Contact phone number": "Kontaktinis telefono numeris",
      "Enter Contact phone number": "Įveskite kontaktinį telefono numerį",
      Website: "Svetainė",
      "Enter website": "Įveskite svetainės adresą",
      "Instagram link": "Instagram nuoroda",
      "Insert Instagram link": "Įdėkite Instagram nuorodą",
      Continue: "Tęsti",
      "Platform General": "Bendra platforma",
      "Platform title": "Platformos pavadinimas",
      "Enter Platform Title": "Įveskite platformos pavadinimą",
      "Platform type": "Platformos tipas",
      "First selected country will act as primary country":
        "Pirmiausia pasirinkta šalis veiks kaip pagrindinė šalis",
      "Select sports": "Pasirinkite sportą",
      "First selected sport will act as primary sport":
        "Pirmiausia pasirinktas sportas veiks kaip pagrindinis sportas",
      Sports: "Sportai",
      "Select discipline": "Pasirinkite discipliną",
      "Select third-party licence": "Pasirinkite trečiosios šalies licenciją",
      "third-party licence": "Trečiosios šalies licencija",
      "No third-party license Found": "Nerasta trečiosios šalies licencijos",
      "Select languages": "Pasirinkite kalbas",
      "First selected language will act as primary language":
        "Pirmiausia pasirinkta kalba veiks kaip pagrindinė kalba",
      "Select language": "Pasirinkite kalbą",
      Timezone: "Laiko juosta",
      "Time Zone": "Laiko juosta",
      Currency: "Valiuta",
      "First selected currency will act as primary currency":
        "Pirmiausia pasirinkta valiuta veiks kaip pagrindinė valiuta",
      "Select currency": "Pasirinkite valiutą",
      Description: "Aprašymas",
      "Do you have already your domain name?":
        "Ar jau turite savo domeno vardą?",
      "Enter your domain name": "Įveskite savo domeno vardą",
      Back: "Atgal",
      Save: "Išsaugoti",
      "Upload primary logo (svg or png)":
        "Įkelti pagrindinį logotipą (svg arba png)",
      "Upload secondary logo (svg or png)":
        "Įkelti antrinį logotipą (svg arba png)",
      "Upload cover picture": "Įkelti viršelio paveikslėlį",
      "No logo, you want us to make one for you":
        "Nėra logotipo, norite, kad mes jums jį padarytume",
      "Click Here": "Spustelėkite čia",
      "Select primary color": "Pasirinkite pagrindinę spalvą",
      "Select secondary color": "Pasirinkite antrinę spalvą",
      "Company name": "Įmonės pavadinimas",
      "Enter Company Name": "Įveskite įmonės pavadinimą",
      "Registration Number": "Registracijos numeris",
      "Enter Registration Number": "Įveskite registracijos numerį",
      "Full Address": "Pilnas adresas",
      "Enter Full Address": "Įveskite pilną adresą",
      "Signee full name": "Pasirašiusiojo pilnas vardas",
      "Signee role": "Pasirašiusiojo rolė",
      "Contact Email": "Kontaktinis el. paštas",
      "Landing / Home Screen": "Pagrindinis / Pradinis ekranas",
      "Do you need landing?": "Ar jums reikia pagrindinio ekrano?",
      "Select Template": "Pasirinkite šabloną",
      "No, I will use my own website as Landing page.":
        "Ne, naudosiu savo svetainę kaip pagrindinį puslapį.",
      "Do you need Integrations?": "Ar jums reikia integracijų?",
      "Payment Service Provider": "Mokėjimo paslaugų teikėjas",
      "Make sure to get familiar with Stripe service fees":
        "Įsitikinkite, kad susipažinote su „Stripe“ paslaugų mokesčiais",
      "Additional services": "Papildomos paslaugos",
      "Service Name": "Paslaugos pavadinimas",
      Price: "Kaina",
      "Mobile app": "Mobili programa",
      SMM: "Socialinių medijų marketingas (SMM)",
      "Graphics for SMM": "Grafika SMM",
      "Your request": "Jūsų užklausa",
      "Type here your request": "Įveskite savo užklausą čia",
      "Upload file": "Įkelti failą",
      "Entry service fee": "Dalyvio paslaugų mokestis",
      "Tournated entry service fee": "Turnyro dalyvio paslaugų mokestis",
      "Entery entery fee": "Dalyvio dalyvio mokestis",
      "Partner entry service fee": "Partnerio dalyvio paslaugų mokestis",
      "Enter partner entry fee": "Įveskite partnerio dalyvio mokestį",
      "License service fee": "Licencijos paslaugų mokestis",
      "Tournated license service fee": "Turnyro licencijos paslaugų mokestis",
      "Enter tournated license fee": "Įveskite turnyro licencijos mokestį",
      "Partner license service fee": "Partnerio licencijos paslaugų mokestis",
      "Enter partner license fee": "Įveskite partnerio licencijos mokestį",
      "Enable Pricing": "Įgalinti kainodarą",
      Leagues: "Lygos",
      "Create new League": "Kurti naują lygą",
      "There is no available leagues": "Nėra prieinamų lygų",
      Completed: "Užbaigta",
      Upcoming: "Artėjantis",
      Active: "Aktyvus",
      Total: "Viso",
      "Search Leagues": "Ieškoti lygų",
      "League Title": "Lygos pavadinimas",
      "League Type": "Lygos tipas",
      Logo: "Logotipas",
      Tournaments: "Turnyrai",
      Athletes: "Sportininkai",
      Regulations: "Taisyklės",
      Platform: "Platforma",
      "We recommend an image that is 256 x 256 pixels":
        "Rekomenduojame vaizdą, kurio dydis yra 256 x 256 pikselių",
      "League Abbreviation": "Lygos sutrumpinimas",
      "League owner": "Lygos savininkas",
      "Select Organization": "Pasirinkite organizaciją",
      "Select platform": "Pasirinkite platformą",
      "League Priority": "Lygos prioritetas",
      "Go to Next": "Eiti į kitą",
      Phone: "Telefonas",
      "League Website": "Lygos svetainė",
      Instagram: "Instagram",
      "Field Title": "Lauko pavadinimas",
      "Field Type": "Lauko tipas",
      "Add New Point": "Pridėti naują tašką",
      "Title Name": "Pavadinimo pavadinimas",
      Select: "Pasirinkti",
      "Field Value": "Lauko vertė",
      "Add Another Option Below": "Pridėti kitą parinktį žemiau",
      Update: "Atnaujinti",
      Management: "Valdymas",
      "League status": "Lygos būsena",
      "Select one": "Pasirinkite vieną",
      "Make this league featured": "Padaryti šią lygą išskirtine",
      "Enable rankings": "Įgalinti reitingus",
      "enable ranking":
        "Sukurti pritaikytus reitingus jūsų lygai, prijungiant turnyrų kategorijas, apibrėžiant amžiaus / lyties apribojimus ir pridedant papildomas taisykles.",
      "Enable online payments": "Įgalinti internetinius mokėjimus",
      "enable online payment":
        "Pradėkite priimti internetinius mokėjimus už turnyrų įėjimo bilietus ir licencijas. Įsitikinkite, kad susipažinote su mūsų paslaugų mokesčių modeliu, valdyti internetinius mokėjimus, patikrinkite čia. (spustelėjus atidaromas modulis su detalemis pagal mūsų kainodarą pitchdeck",
      "Enable licences": "Įgalinti licencijas",
      "License description":
        "Konfigūruoti licencijas, kainas, laikotarpius ir taisykles.",
      "Make club field mandatory and display club details as organizer on Tournament page":
        "Padaryti klubo lauką privalomą ir rodyti klubo informaciją kaip organizatorių turnyrų puslapyje.",
      "Create League": "Kurti lygą",
      "Organization Accounts": "Organizacijos paskyros",
      "ADD NEW ACCOUNT": "PRIDĖTI NAUJĄ PASKYRĄ",
      "There is no available organizations": "Nėra prieinamų organizacijų",
      "Search Organizations": "Ieškoti organizacijų",
      "Full name": "Pilnas vardas",
      "Organization Abbrevation": "Organizacijos santrumpa",
      "UPDATE USER PROFILE": "ATNAUJINTI NAUDOTOJO PROFILĮ",
      "Enter phone number": "Įveskite telefono numerį",
      "Organization Settings": "Organizacijos nustatymai",
      "Organization Type": "Organizacijos tipas",
      "Organization Logo": "Organizacijos logotipas",
      "About Organization": "Apie organizaciją",
      "Contact Details": "Kontaktinė informacija",
      Users: "Naudotojai",
      "Search User": "Ieškoti naudotojo",
      "ADD NEW USER": "PRIDĖTI NAUJĄ NAUDOTOJĄ",
      Avatar: "Avataras",
      "Phone Number": "Telefono numeris",
      Role: "Vaidmuo",
      "IPIN License": "IPIN Licencija",
      "DUPR License": "DUPR Licencija",
      "Tennis Europe License": "Teniso Europos Licencija",
      "LTS U10 License": "LTS U10 Licencija",
      "FIDE License": "FIDE Licencija",
      "Add new user": "Pridėti naują naudotoją",
      "Edit User": "Redaguoti naudotoją",
      "User Account": "Naudotojo paskyra",
      "Date of birth": "Gimimo data",
      Gender: "Lytis",
      "Representing country": "Atstovaujanti šalis",
      City: "Miestas",
      "Enter city": "Įveskite miestą",
      Address: "Adresas",
      "Enter address": "Įveskite adresą",
      Career: "Karjera",
      Club: "Klubas",
      "Select club": "Pasirinkite klubą",
      "Select coach": "Pasirinkite trenerį",
      Parent: "Tėvas / Motina",
      "Select parent": "Pasirinkite tėvą / motiną",
      "Write a bio": "Parašykite biografiją",
      CHECKBOXES: "ŽYMPADĖLIAI",
      "Give access to coach to purchase licences and manage entries on your behalf":
        "Suteikti treneriui pirkimo licencijas ir įrašų valdymo teisę Jūsų vardu",
      "Give access to club manager(-es) to purchase licences and manage entries on your behalf":
        "Suteikti klubo vadybininkui(-ams) pirkimo licencijas ir įrašų valdymo teisę Jūsų vardu",
      "Give access to parent(-s) to purchase licences and manage entries on your behalf":
        "Suteikti tėvui(-ams) teisę įsigyti licencijas ir valdyti įrašus Jūsų vardu",
      Socials: "Socialiniai tinklai",
      "Insert link here": "Įterpkite nuorodą čia",
      Facebook: "Facebook",
      Linkedin: "Linkedin",
      Tiktok: "Tiktok",
      Youtube: "Youtube",
      Licence: "Licencija",
      "ITF IPIN": "ITF IPIN",
      DUPRID: "DUPRID",
      "Tennis Europe ID": "Teniso Europos ID",
      "LTS U10": "LTS U10",
      ATP: "ATP",
      WTA: "WTA",
      FIDE: "FIDE",
      "Update password": "Atnaujinti slaptažodį",
      "Re-Enter": "Pakartotinai įvesti",
      "Login settings": "Prisijungimo nustatymai",
      "Switch Standard login to Social login":
        "Perjungti standartinį prisijungimą į socialinį prisijungimą",
      "Connect Gmail": "Prisijungti prie Gmail",
      "Enable 2FA Google Authorisation": "Įgalinti 2FA Google patvirtinimą",
      "Enable 2FA Email Authorisation": "Įgalinti 2FA El. pašto patvirtinimą",
      "Role Name": "Vaidmens pavadinimas",
      "Is user verified?": "Ar naudotojas patvirtintas?",
      Yes: "Taip",
      No: "Ne",
      Tournament: "Turnyras",
      "All tournaments": "Visi turnyrai",
      "League tournaments": "Lygos turnyrai",
      "Search Tournaments": "Ieškoti turnyrų",
      "Create new Tournament": "Kurti naują turnyrą",
      "Tournament Title": "Turnyro pavadinimas",
      "Start - End": "Pradžia - Pabaiga",
      Organizer: "Organizatorius",
      Location: "Vieta",
      "List of categories": "Kategorijų sąrašas",
      "Update Status": "Atnaujinti būseną",
      "There is no available tournaments": "Nėra prieinamų turnyrų",
      "ADD NEW SPORT": "PRIDĖTI NAUJĄ SPORTĄ",
      "Search Sports": "Ieškoti sporto šakų",
      "Sport Title": "Sporto pavadinimas",
      "Add new Sport": "Pridėti naują sportą",
      "Edit Sports": "Redaguoti sportą",
      "Sports title": "Sporto pavadinimas",
      "Enter title": "Įveskite pavadinimą",
      "Sports abbreviation": "Sporto santrumpa",
      "Enter abbreviation": "Įveskite santrumpą",
      "Resource title": "Resurso pavadinimas",
      "Rule how to add results/score":
        "Taisyklė, kaip įvesti rezultatus / rezultatus",
      Icon: "Piktograma",
      "Enable disciplines": "Įgalinti disciplinas",
      "Enable courts": "Įgalinti kortus",
      "Enable stages": "Įgalinti etapus",
      "Enable pairings": "Įgalinti poravimus",
      "Enable rounds": "Įgalinti raundus",
      "Enable standings based on pairings": "Įgalinti stovus pagal poravimus",
      "Display draws": "Rodyti traukinius",
      "Display groups": "Rodyti grupes",
      "Display live standings": "Rodyti tiesioginius rezultatus",
      "Display order of play": "Rodyti žaidimo tvarką",
      "Display list & results": "Rodyti sąrašą ir rezultatus",
      "Display pending results": "Rodyti laukiamus rezultatus",
      "Display final standings": "Rodyti galutinius rezultatus",
      "Display athlete chess titles in Participants":
        "Rodyti sportininko šachmatų titulus dalyviuose",
      "Display seeds column Participants": "Rodyti sėklų stulpelį dalyviuose",
      "Display segments column in Participants":
        "Rodyti segmentų stulpelį dalyviuose",
      "Upload file here": "Įkelti failą čia",
      Disciplines: "Disciplinos",
      "ADD NEW DISCIPLINE": "PRIDĖTI NAUJĄ DISCIPLINĄ",
      "There is no available disciplines": "Nėra prieinamų disciplinų",
      "Search Disciplines": "Ieškoti disciplinų",
      "Sport name": "Sporto pavadinimas",
      "Add new Discipline": "Pridėti naują discipliną",
      "Edit Discipline": "Redaguoti discipliną",
      "Discipline title": "Disciplinos pavadinimas",
      "Select Sport": "Pasirinkite sportą",
      "No sports found": "Sportų nerasta",
      "Results type": "Rezultatų tipas",
      "Third-party licences": "Trečiosios šalies licencijos",
      "Third party licence": "Trečiosios šalies licencija",
      "There is no available third-party licences":
        "Nėra prieinamų trečiosios šalies licencijų",
      "ADD NEW LICENCE": "PRIDĖTI NAUJĄ LICENCIJĄ",
      "Search license": "Ieškoti licencijos",
      "license name": "Licencijos pavadinimas",
      "License type": "Licencijos tipas",
      "Add licence": "Pridėti licenciją",
      "Add Third-party licence": "Pridėti trečiosios šalies licenciją",
      "Edit Third-party licence": "Redaguoti trečiosios šalies licenciją",
      "Organization title": "Organizacijos pavadinimas",
      "Organization abbreviation": "Organizacijos santrumpa",
      "Licence title": "Licencijos pavadinimas",
      "Enter license title": "Įveskite licencijos pavadinimą",
      "Licence type": "Licencijos tipas",
      "Request license in user profile":
        "Prašyti licencijos naudotojo profilyje",
      "Request license on user registration in platform":
        "Prašyti licencijos naudotojo registracijos platformoje",
      "Make licence mandatory": "Padaryti licenciją privalomą",
      "Link to get licence": "Nuoroda į licencijos gavimą",
      "Enter link to get licence": "Įveskite nuorodą į licencijos gavimą",
      Partners: "Partneriai",
      "ADD NEW PARTNER": "PRIDĖTI NAUJĄ PARTNERĮ",
      "There is no available partners": "Nėra prieinamų partnerių",
      "Search Partners": "Ieškoti partnerių",
      "Create new Partner": "Kurti naują partnerį",
      Link: "Nuoroda",
      Text: "Tekstas",
      Priority: "Prioritetas",
      "Showing dates": "Rodyti datas",
      "New Partner": "Naujas partneris",
      "Edit Partner": "Redaguoti partnerį",
      "Partner Logo": "Partnerio logotipas",
      platform: "platforma",
      "No platform available": "Platforma nepasiekiamas",
      "No league available": "Liga nepasiekiamas",
      "No Clubs available": "Nėra klubų",
      "Sponsor Text": "Rėmėjo tekstas",
      "Date Settings": "Datos nustatymai",
      "Active From": "Aktyvus nuo",
      "Active To": "Aktyvus iki",
      "Platform managers": "Platformos vadovai",
      "ADD NEW MANAGER": "PRIDĖTI NAUJĄ VADOVĄ",
      "There is no available manager": "Nėra prieinamų vadovų",
      "Search Manager": "Ieškoti vadovo",
      "Export team": "Eksportuoti komandą",
      "Export team emails": "Eksportuoti komandos el. pašto adresus",
      "Add platform manager": "Pridėti platformos vadovą",
      "Edit platform panager": "Redaguoti platformos vadovą",
      "Enter Organization": "Įveskite organizaciją",
      "Select organization": "Pasirinkite organizaciją",
      "Filter users": "Filtruoti naudotojus",
      "Export users": "Eksportuoti naudotojus",
      "Export user emails": "Eksportuoti naudotojo el. pašto adresus",
      "Age group": "Amžiaus grupė",
      "There is no available users": "Nėra prieinamų naudotojų",
      Coaches: "Treneriai",
      "ADD NEW COACH": "PRIDĖTI NAUJĄ TRENERĮ",
      "There is no available account": "Nėra prieinamos paskyros",
      "Search Coach": "Ieškoti trenerio",
      "Add new coach": "Pridėti naują trenerį",
      "Edit Coach": "Redaguoti trenerį",
      "Coach image": "Trenerio paveikslėlis",
      Category: "Kategorija",
      "There is no available sports": "Nėra prieinamų sporto šakų",
      "there are not available clubs": "nėra prieinamų klubų",
      "Date from": "Data nuo",
      "Date to": "Data iki",
      "Display on organization's landing page":
        "Rodyti organizacijos pagrindiniame puslapyje",
      "Platform tournaments": "Platformos turnyrai",
      Title: "Pavadinimas",
      Posts: "Pranešimai",
      "ADD POST": "PRIDĖTI PRANEŠIMĄ",
      "There is no available post": "Nėra prieinamų įrašų",
      News: "Naujienos",
      "Search Post": "Ieškoti įrašų",
      Image: "Vaizdas",
      Author: "Autorius",
      Content: "Turinys",
      "Cover / featured image": "Viršelis / paryškintas vaizdas",
      "Select league": "Pasirinkti lygą",
      League: "Lyga",
      "Add video from youtube.com": "Pridėti vaizdo įrašą iš youtube.com",
      Date: "Data",
      "Purchased Licenses": "Įsigytos licencijos",
      "Search Licenses": "Ieškoti licencijų",
      "Create new License": "Kurti naują licenciją",
      "Athlete name & surname": "Sportininko vardas ir pavardė",
      "License Name": "Licencijos pavadinimas",
      "License Price": "Licencijos kaina",
      "License active from": "Licencija aktyvi nuo",
      "License active to": "Licencija aktyvi iki",
      "Paid(online/cash)": "Apmokėta (internetu/grynais)",
      "There is no available purchased license":
        "Nėra prieinamų įsigytų licencijų",
      "New License": "Nauja licencija",
      "Edit License": "Redaguoti licenciją",
      "Athlete Email": "Sportininko el. paštas",
      License: "Licencija",
      "No license found": "Licencijos nerasta",
      "Payment Method": "Mokėjimo metodas",
      "How much paid": "Kiek sumokėta",
      Teams: "Komandos",
      "ADD NEW TEAM": "PRIDĖTI NAUJĄ KOMANDĄ",
      "There is no available teams": "Nėra prieinamų komandų",
      "Search team": "Ieškoti komandos",
      "Create new Team": "Kurti naują komandą",
      "Team Members": "Komandos nariai",
      Discipline: "Disciplina",
      "New Team": "Nauja komanda",
      "Edit Team": "Redaguoti komandą",
      "Team Logo": "Komandos logotipas",
      "No Clubs Found": "Klubų nerasta",
      "No disciplines found": "Disciplinų nerasta",
      "Club status": "Klubo būsena",
      Members: "Nariai",
      "Add new Members": "Pridėti naujus narius",
      "Add New Member": "Pridėti naują narį",
      "Member email": "Nario el. paštas",
      "Select Role": "Pasirinkti vaidmenį",
      "Add more": "Pridėti daugiau",
      "ADD NEW CLUB": "PRIDĖTI NAUJĄ KLUBĄ",
      "There is no available clubs": "Nėra prieinamų klubų",
      Clubs: "Klubai",
      "Search Clubs": "Ieškoti klubų",
      "Club Name": "Klubo pavadinimas",
      "Add new Club": "Pridėti naują klubą",
      "Edit Club": "Redaguoti klubą",
      "Appearance & Location": "Išvaizda ir vieta",
      "Club title": "Klubo pavadinimas",
      "Add club logo": "Pridėti klubo logotipą",
      "How to find us": "Kaip mus rasti",
      "Contact details": "Kontaktiniai duomenys",
      "Contact Phone": "Kontakto telefonas",
      Twitter: "Twitter",
      "Services & Resources": "Paslaugos ir ištekliai",
      "Select services": "Pasirinkti paslaugas",
      Services: "Paslaugos",
      Resources: "Ištekliai",
      "Resource Name": "Ištekliaus pavadinimas",
      Service: "Paslauga",
      "Add Resources": "Pridėti išteklius",
      "Add New Resource": "Pridėti naują išteklią",
      "Select Service": "Pasirinkti paslaugą",
      "Select type": "Pasirinkti tipą",
      "Add Another": "Pridėti kitą",
      "Club page": "Klubo puslapis",
      "Select Coaches": "Pasirinkti trenerius",
      "Athlete Club": "Sportininko klubas",
      desc: "Įtraukus klubą, sportininkai galės jį pasirinkti savo sportininko profilio nustatymų puslapyje ir tuomet jie bus matomi klubo puslapyje, kad būtų patvirtinti / atmesti. Patvirtinti nariai bus rodomi klubo puslapyje",
      "Online booking": "Internetinis užsakymas",
      "Club Main Administrator": "Pagrindinis klubo administratorius",
      "Main Adminitrator": "Pagrindinis administratorius",
      "CLUB MAIN ADMINISTRATOR IS NOT IN THE LIST":
        "PAGRINDINIS KLUBO ADMINISTRATORIUS NĖRA SĄRAŠE",
      Administrator: "Administratorius",
      "ADMINISTRATOR IS NOT IN THE LIST": "ADMINISTRATORIUS NĖRA SĄRAŠE",
      Banners: "Baneriai",
      "There is no available banners": "Nėra prieinamų banerių",
      "ADD BANNER": "PRIDĖTI BANERĮ",
      "Search Banner": "Ieškoti banerio",
      Headline: "Antraštė",
      subheadline: "Papildoma antraštė",
      "Button Text": "Mygtuko tekstas",
      "New Banner": "Naujas baneris",
      "Edit Banner": "Redaguoti banerį",
      Subheadline: "Papildoma antraštė",
      "Button text": "Mygtuko tekstas",
      "Button link": "Mygtuko nuoroda",
      "Select roles": "Pasirinkti vaidmenis",
      "Banner images": "Banerio vaizdai",
      "Control Panel": "Valdymo skydas",
      "Run the tournament step by step from this control panel":
        "Vykdykite turnyrą žingsnis po žingsnio iš šio valdymo skydo",
      "Edit & Setup league": "Redaguoti ir nustatyti lygą",
      "Edit Now": "Redaguoti dabar",
      "Create & Setup league": "Sukurti ir nustatyti lygą",
      "Start Now": "Pradėti dabar",
      "Add league members": "Pridėti lygos narius",
      "league member description":
        "Įtraukite lygos narius, tokius kaip vadovai, turnyrų organizatoriai ir teisėjai. Vadovai turės pilną prieigą prie skydelio ir visų valdymo funkcijų, organizatoriai galės pridėti turnyrus ir juos valdyti, o teisėjai turės prieigą prie įrašų, traukinių, grupių, gyvų rezultatų, žaidimo tvarkos ir rezultatų valdymo jiems priskirtiems turnyrams.",
      "Add Now": "Pridėti dabar",
      "Add tournament categories": "Pridėti turnyrų kategorijas",
      "tournament category description":
        "Nustatykite turnyrų kategorijas savo lygai, nurodydami formatą (vienoje / dviejų / komandų), amžiaus / lyties apribojimus ir juos sujungdami su įvertinimo kategorijomis (kai pridėsite tas įvertinimo kategorijas kitame žingsnyje).",
      "Add rankings categories": "Pridėti įvertinimo kategorijas",
      "ranking category description":
        "Sukurkite įvertinimo kategorijas savo lygai. Pasirinkite turnyrų kategorijas, kurias įtraukti į reitingus, nurodykite, kiek turnyrų skaičiuoti, nustatykite laiko tarpą, susisiekite su tarptautinėmis lygomis ir nustatykite kitas taisykles.",
      "Setup ranking points": "Nustatykite įvertinimo taškus",
      "ranking description":
        "Sukonfigūruokite taškų lentelę kiekvienai turnyrų kategorijai, nurodydami taškų skaičių už kiekvieną vietą. Išsamus lentelės aprašymas pagreitins ir automatizuos procesą.",
      Setup: "Nustatymas",
      "Setup licences": "Nustatyti licencijas",
      "Add tournament": "Pridėti turnyrą",
      "tournament description":
        "Sekite žingsniais, kad sukurtumėte savo turnyrą ir pradėtumėte tvarkyti savo sporto renginį. Būkite užtikrinti, kad gausite išsamią instrukcijų sąrašą, apimančią daugelį funkcijų, kurias siūlome sporto renginių valdymui.",
      "Leagues Members": "Lygos nariai",
      "Add new Member": "Pridėti naują narį",
      "There is no available members": "Nėra prieinamų narių",
      "League members": "Lygos nariai",
      "Search Members": "Ieškoti narių",
      "ADD NEW MEMBER": "PRIDĖTI NAUJĄ NARĮ",
      "Delete league member": "Ištrinti lygos narį",
      "Are you sure you want to delete?": "Ar tikrai norite ištrinti?",
      Cancel: "Atšaukti",
      Delete: "Ištrinti",
      "Tournament Categories": "Turnyrų kategorijos",
      "There is no available categories": "Nėra prieinamų kategorijų",
      "ADD NEW CATEGORY": "PRIDĖTI NAUJĄ KATEGORIJĄ",
      "Search Categories": "Ieškoti kategorijų",
      "Copy Categories": "Kopijuoti kategorijas",
      "Delete Categories": "Ištrinti kategorijas",
      "Category Name": "Kategorijos pavadinimas",
      "Category Type": "Kategorijos tipas",
      "Select League": "Pasirinkti lygą",
      Copy: "Kopijuoti",
      "Are you sure you want to delete these categories?":
        "Ar tikrai norite ištrinti šias kategorijas?",
      "New Category": "Nauja kategorija",
      "Edit Category": "Redaguoti kategoriją",
      "Category name": "Kategorijos pavadinimas",
      "Add team selection on tournament registration":
        "Pridėti komandos pasirinkimą turnyrų registracijoje",
      "Add club selection on tournament registration":
        "Pridėti klubo pasirinkimą turnyrų registracijoje",
      "Skills level": "Įgūdžių lygis",
      "Category Gender": "Kategorijos lytis",
      "Age from": "Amžius nuo",
      "Age to": "Amžius iki",
      "Age Limit Type": "Amžiaus ribos tipas",
      "No Types Found!": "Tipų nerasta!",
      "Ask skills level on a tournament registration":
        "Paklauskite įgūdžių lygio turnyrų registracijoje",
      "Enable License": "Įgalinti licenciją",
      "Display athlete club in Participants":
        "Rodyti sportininko klubą dalyviuose",
      "Display athlete city in Participants":
        "Rodyti sportininko miestą dalyviuose",
      "Select License": "Pasirinkite licenciją",
      "No License Found!": "Licencijų nerasta!",
      "Ranking Category for men": "Reitingo kategorija vyrams",
      "No Category Found!": "Kategorijų nerasta!",
      "Ranking Category for Women": "Reitingo kategorija moterims",
      "Third-party license": "Trečiųjų šalių licencija",
      "No third-party license Found!": "Trečiųjų šalių licencijų nerasta!",
      "Time control": "Laiko kontrolė",
      "No time control found": "Laiko kontrolės nerasta",
      "Order display for seeding": "Eilės rodymas sėjimui",
      "Sport fishing settings": "Sportinio žvejybos nustatymai",
      "Identify team members on registration":
        "Nustatykite komandos narius registracijoje",
      "Min number of members": "Mažiausias narių skaičius",
      "Max number of members": "Didžiausias narių skaičius",
      "Reservists required": "Reikalingi rezervistai",
      "Min number of reservists": "Mažiausias rezervistų skaičius",
      "Max number of reservists": "Didžiausias rezervistų skaičius",
      "Captain required": "Reikalingas kapitonas",
      "Transfer members to singles category":
        "Perkelti narius į vienetų kategoriją",
      "Select singles category": "Pasirinkite vienetų kategoriją",
      "No Category Found": "Kategorijų nerasta",
      "National tournament (country vs country)":
        "Nacionalinis turnyras (šalis prieš šalį)",
      "Club championships (club vs club)":
        "Klubų čempionatai (klubas prieš klubą)",
      "Create matches from team members and count points result based on matches result":
        "Sukurkite rungtynes iš komandos narių ir skaičiuokite taškų rezultatą pagal rungtynių rezultatą",
      "Count individual total": "Skaičiuoti individualią sumą",
      "Count teams total": "Skaičiuoti komandų visumą",
      "Number of athletes to count min":
        "Mažiausias sportininkų skaičius skaičiuoti",
      "Number of athletes to count max":
        "Didžiausias sportininkų skaičius skaičiuoti",
      "Max Entries per team": "Maksimalus įrašai per komandą",
      "Allow registration without team": "Leisti registraciją be komandos",
      "Category Status": "Kategorijos būsena",
      "Ranking Categories": "Reitingo kategorijos",
      "There is no available ranking categories":
        "Nėra prieinamų reitingo kategorijų",
      Categories: "Kategorijos",
      "No of Tournaments": "Turnyrų skaičius",
      "Time Period": "Laiko periodas",
      "No Leagues found": "Ligų nerasta",
      "New Ranking Category": "Nauja reitingo kategorija",
      "Select Categories": "Pasirinkite kategorijas",
      "No Categories Found!": "Kategorijų nerasta!",
      "Max number of tournaments": "Maksimalus turnyrų skaičius",
      "Count specific tournaments": "Skaičiuoti konkretų turnyrą",
      "Add penalty points for missing a tournament":
        "Pridėti bausmės taškus už praleistą turnyrą",
      "Penalty rule": "Baudžiamoji taisyklė",
      "Enter points": "Įveskite taškus",
      "Additional Rules": "Papildomos taisyklės",
      Sector: "Sektorius",
      Coefficient: "Koeficientas",
      "No of tournaments": "Turnyrų skaičius",
      "Edit additional rule(s)": "Redaguoti papildomas taisykles",
      "Add additional rule(s)": "Pridėti papildomas taisykles",
      "During which period tournament points are counted":
        "Per kokį laikotarpį skaičiuojami turnyrų taškai",
      "Select Time Period": "Pasirinkite laikotarpį",
      "Points sorting": "Taškų rūšiavimas",
      "Sport Fishing": "Sportinė žvejyba",
      "Select Stage form which take points":
        "Pasirinkite etapą, iš kurio imami taškai",
      "Ranking Points": "Reitingo taškai",
      "Search Rankings": "Ieškoti reitingų",
      "Add points block": "Pridėti taškų bloką",
      "Select / UnSelect All": "Pasirinkti / Atšaukti viską",
      Place: "Vieta",
      Points: "Taškai",
      "Bracket type": "Lentelės tipas",
      Segment: "Segmentas",
      "Num of Entries": "Įrašų skaičius",
      "Ad.Rules": "Papildomos taisyklės",
      "view rule": "žiūrėti taisyklę",
      "Add new row": "Pridėti naują eilutę",
      "There is no available ranking points": "Nėra prieinamų reitingo taškų",
      "Edit Points": "Redaguoti taškus",
      "Add Points": "Pridėti taškus",
      "Points block name": "Taškų bloko pavadinimas",
      Specification: "Specifikacija",
      "Rule Settings": "Taisyklių nustatymai",
      "Select operator": "Pasirinkite operatorių",
      "Select skills level": "Pasirinkite įgūdžių lygį",
      "Number of players": "Žaidėjų skaičius",
      "Number of entries": "Įrašų skaičius",
      update: "atnaujinti",
      SAVE: "IŠSAUGOTI",
      DISCARD: "ATMESTI",
      "SET NEW RULE": "NUSTATYTI NOVĖLIĄ TAPATĮ",
      "Edit Row": "Redaguoti eilutę",
      "Add Row": "Pridėti eilutę",
      ADD: "PRIDĖTI",
      "Copy Ranking Point": "Kopijuoti reitingo tašką",
      "Select Ranking Points": "Pasirinkti reitingo taškus",
      "Copy Ranking Point Blocks": "Kopijuoti reitingo taškų blokus",
      "Delete Ranking Point Block": "Ištrinti reitingo taškų bloką",
      Licenses: "Licencijos",
      "There is no available licenses": "Licencijų nėra",
      "Price Currency": "Kainos valiuta",
      "Select Leagues": "Pasirinkite ligas",
      "No League Found!": "Ligų nerasta!",
      "License price": "Licencijos kaina",
      "License Type": "Licencijos tipas",
      "License Status": "Licencijos būsena",
      "Additional questions": "Papildomi klausimai",
      "Add additional question": "Pridėti papildomą klausimą",
      "Edit this": "Redaguoti tai",
      "Add new": "Pridėti naują",
      question: "klausimas",
      Question: "Klausimas",
      "Field Option": "Lauko parinktis",
      Remove: "Pašalinti",
      "Add new option": "Pridėti naują parinktį",
      "Ask this question to each athlete":
        "Paklauskite šio klausimo kiekvienam sportininkui",
      Submit: "Pateikti",
      "Delete Warning": "Trinti įspėjimą",
      "Are you sure you want to delete this question?":
        "Ar tikrai norite ištrinti šį klausimą?",
      "Confirm Deletion": "Patvirtinti trynimą",
      Rankings: "Reitingai",
      Search: "Ieškoti",
      "ranking Category": "reitingo Kategorija",
      "No date Found": "Datos nerasta",
      "Create Rankings": "Sukurti reitingus",
      "Update Rankings": "Atnaujinti reitingus",
      close: "uždaryti",
      Year: "Metai",
      "Points Details": "Taškų detalės",
      "No points yet": "Dar nėra taškų",
      Position: "Padėtis",
      Athlete: "Sportininkas",
      "There is no available rankings": "Nėra prieinamų reitingų",
      "Select Ranking categories": "Pasirinkite reitingo kategorijas",
      "Remove Rankings": "Pašalinti reitingus",
      "Select Date": "Pasirinkite datą",
      "Are you sure you want to delete rankings?":
        "Ar tikrai norite ištrinti reitingus?",
      "Rankings History": "Reitingų istorija",
      "Ranking Category": "Reitingo kategorija",
      "Last Updated": "Paskutinį kartą atnaujinta",
      Public: "Viešas",
      "Delete warning": "Trinti įspėjimą",
      "Are you sure you want to delete this ranking?":
        "Ar tikrai norite ištrinti šį reitingą?",
      "There is no available rankings history":
        "Nėra prieinamos reitingų istorijos",
      "Tournament Results": "Turnyro rezultatai",
      Entry: "Įrašas",
      "Add new result": "Pridėti naują rezultatą",
      "There is no available tournament results":
        "Nėra prieinamų turnyro rezultatų",
      "Pendding tournaments": "Laukiančios turnyrai",
      "There is no available athletes": "Nėra prieinamų sportininkų",
      "Search Athletes": "Ieškoti sportininkų",
      "Country/Residency": "Šalis/Gyvenvietė",
      "Super Data": "Super Duomenys",
      "All Tournaments": "Visi turnyrai",
      "Pending Tournaments": "Laukiančios turnyrai",
      Financials: "Finansiniai",
      Accounting: "Apskaita",
      Extra: "Papildoma",
      "Live scoring": "Tiesioginis rezultatų skaičiavimas",
      "Live stream": "Tiesioginis srautas",
      "Platform Settings": "Platformos nustatymai",
      "My Platforms": "Mano platformos",
      Team: "Komanda",
      Instructions: "Instrukcijos",
      "League Settings": "Lygos nustatymai",
      "League Members": "Lygos nariai",
      Upload: "Įkelti",
      "primary logo": "pagrindinis logotipas",
      "secondary logo": "antrinis logotipas",
      "cover picture": "viršelio paveikslėlis",
      "Start Typing": "Pradėkite rašyti",
      "Leagues list": "Lygų sąrašas",
      "General & Appearance": "Bendrieji ir išvaizdos",
      "Instagram Link": "Instagram nuoroda",
      "Fact Sheet": "Informacinis lapas",
      "Fact sheet": "Informacinis lapas",
      "Upload regulations": "Įkelti nuostatas",
      "Organization Abbreviation": "Organizacijos santrumpa",
      "Drag and Drop some file": "Vilkite ir įmeskite failą",
      "here, or click to Select file":
        "čia, arba spustelėkite pasirinkti failą",
      UPLOAD: "ĮKELTI",
      "DISCARD AND CANCEL": "ATMESTI IR ATŠAUKTI",
      SUBMIT: "PATEIKTI",
      "Add new Organization Account": "Pridėti naują organizacijos sąskaitą",
      "Edit Organization Account": "Redaguoti organizacijos sąskaitą",
      "Full Name": "Pilnas vardas",
      Biography: "Biografija",
      "Add post": "Pridėti įrašą",
      "Edit Post": "Redaguoti įrašą",
      "Edit Member": "Redaguoti narį",
      "Organization name": "Organizacijos pavadinimas",
      "Organization email": "Organizacijos el. paštas",
      "Organization phone": "Organizacijos telefonas",
      "Display member on organizations public team page":
        "Rodyti narį organizacijos viešoje komandos puslapyje",
      "Select Roles": "Pasirinkti vaidmenis",
      "For assiging points": "Taškų priskyrimui",
      "Third party license": "Trečiosios šalies licencija",
      "Must be a valid email": "Turi būti galiojantis el. pašto adresas",
      "Email is required": "Reikalingas el. paštas",
      "Organization Name is required": "Reikalingas organizacijos pavadinimas",
      "Organization Type is required": "Reikalingas organizacijos tipas",
      "organization abbreviation is required":
        "Reikalingas organizacijos santrumpa",
      "Contact Email is required": "Reikalingas kontaktinio el. pašto adresas",
      "Password is Required": "Reikalingas slaptažodis",
      "Password should be 8 chars minimum.":
        "Slaptažodis turi būti bent 8 simbolių ilgio.",
      "Passwords must match": "Slaptažodžiai turi sutapti",
      "company name is required": "Reikalingas įmonės pavadinimas",
      "company number is required": "Reikalingas įmonės numeris",
      "full address is required": "Reikalingas pilnas adresas",
      "Invalid email address": "Netinkamas el. pašto adresas",
      "signee role is required": "Reikalinga parašiusiojo vaidmuo",
      "signee full name is required": "Reikalingas parašiusiojo pilnas vardas",
      "partner entry fee is required": "Reikalinga partnerio įstojimo mokestis",
      "tournated license fee is required":
        "Reikalingas turnyrinės licencijos mokestis",
      "partner license fee  is required":
        "Reikalingas partnerio licencijos mokestis",
      "Creating platform...": "Kuriamas platforma...",
      "Platform created successfully": "Platforma sėkmingai sukurta",
      "Updating...": "Atnaujinama...",
      "Platform updated": "Platforma atnaujinta",
      "Add New Club": "Pridėti naują klubą",
      "organization is required": "Reikalinga organizacija",
      "Adding new fact...": "Pridedama nauja faktą...",
      "Fact added successfully": "Faktas sėkmingai pridėtas",
      "Deleting fact...": "Trinamas faktas...",
      "fact deleted successfully": "Faktas sėkmingai ištrintas",
      "Updating fact...": "Atnaujinamas faktas...",
      "Fact updated successfully": "Faktas sėkmingai atnaujintas",
      "Creating league...": "Kuriamas turnyras...",
      "League created successfully": "Turnyras sėkmingai sukurtas",
      "Updating league...": "Atnaujinamas turnyras...",
      "League updated successfully": "Turnyras sėkmingai atnaujintas",
      "Approved & Public": "Patvirtinta ir vieša",
      "ATP points": "ATP taškai",
      "WTA points": "WTA taškai",
      "name Email is required": "Reikalingas vardo el. pašto adresas",
      "title is require": "Reikalingas pavadinimas",
      "platform type required": "Reikalingas platformos tipas",
      "time zone is required": "Reikalinga laiko juosta",
      "description is required": "Reikalingas aprašymas",
      "title is required": "Reikalingas pavadinimas",
      "abbreviation is required": "Reikalinga santrumpa",
      "platform is required": "Reikalinga platforma",
      "priority is required": "Reikalingas prioritetas",
      "type is required": "Reikalingas tipas",
      "sports is required": "Reikalingi sportai",
      "email is required": "Reikalingas el. paštas",
      "Organization added Successfully!": "Organizacija sėkmingai pridėta!",
      "name is required": "Reikalingas vardas",
      "surname is required": "Reikalinga pavardė",
      "organization name is required": "Reikalingas organizacijos pavadinimas",
      "organization type is required": "Reikalingas organizacijos tipas",
      "Organization Account Updated Successfully!":
        "Organizacijos sąskaita sėkmingai atnaujinta!",
      "Creating organization...": "Kuriamas organizacija...",
      "Organization created successfully": "Organizacija sėkmingai sukurta",
      "This User is already exist": "Šis vartotojas jau egzistuoja",
      "Re-Enter password is required": "Reikalingas slaptažodį pakartoti",
      "Password updated": "Slaptažodis atnaujintas",
      "User Updated": "Vartotojas atnaujintas",
      "Sports Updated": "Sportai atnaujinti",
      "Sports Created": "Sportai sukurti",
      "Discipline Updated": "Disciplina atnaujinta",
      "Discipline Created": "Disciplina sukurta",
      "At least one sport must be selected":
        "Bent vienas sportas turi būti pasirinktas",
      "License Updated": "Licencija atnaujinta",
      "License Created": "Licencija sukurta",
      "Please provide Image": "Pateikite nuotrauką",
      "Partner updated Successfully!": "Partneris sėkmingai atnaujintas!",
      "Partner created successfully!": "Partneris sėkmingai sukurtas!",
      "Please add correct user email":
        "Prašome įvesti teisingą vartotojo el. paštą",
      "Coach added!": "Treneris pridėtas!",
      "user found": "Vartotojas rastas",
      "user not found": "Vartotojas nerastas",
      "country is required": "Reikalinga šalis",
      "gender is required": "Reikalingas lytis",
      "confirm password is required": "Reikalingas slaptažodžio patvirtinimas",
      "Coache Updated Successfully!": "Treneris sėkmingai atnaujintas!",
      "Image field is required": "Reikalingas nuotraukos laukas",
      "author is required": "Reikalingas autorius",
      "Updating ...": "Atnaujinama ...",
      "Post updated successfully": "Pranešimas sėkmingai atnaujintas",
      "Creating post ...": "Kuriamas pranešimas ...",
      "Post created successfully": "Pranešimas sėkmingai sukurtas",
      "User found": "Vartotojas rastas",
      "User not found": "Vartotojas nerastas",
      "paid is required": "Reikalinga suma",
      "currency is required": "Reikalinga valiuta",
      "Athlete is required": "Reikalingas sportininkas",
      "license is required": "Reikalinga licencija",
      "start date is required": "Reikalinga pradžios data",
      "end date is required": "Reikalinga pabaigos data",
      "License created successfully!": "Licencija sėkmingai sukurta!",
      "sport is required": "Reikalingas sportas",
      "discipline is required": "Reikalinga disciplina",
      "Team updated successfully!": "Komanda sėkmingai atnaujinta!",
      "Team created successfully!": "Komanda sėkmingai sukurta!",
      "city is required": "Reikalingas miestas",
      "address is required": "Reikalingas adresas",
      "headline is required": "Reikalingas antraštė",
      "subheadline is required": "Reikalingas antraštinis tekstas",
      "button text required": "Reikalingas mygtuko tekstas",
      "button link required": "Reikalingas mygtuko nuoroda",
      "Image are required": "Reikalingos nuotraukos",
      "Updating banner...": "Atnaujinamas baneris...",
      "Banner updated successfully": "Baneris sėkmingai atnaujintas",
      "Creating banner...": "Kuriamas baneris...",
      "Banner created successfully": "Baneris sėkmingai sukurtas",
      "League member added!": "Turnyro narys pridėtas!",
      "League Member Updated Successfully!":
        "Turnyro narys sėkmingai atnaujintas!",
      "order is required": "Reikalingas užsakymas",
      "Age from must an integer": "Amžius nuo turi būti sveikasis skaičius",
      "Age must be at least 0": "Amžius turi būti bent 0",
      "Age to must an integer": "Amžius iki turi būti sveikasis skaičius",
      "Min members must an integer":
        "Mažiausias narių skaičius turi būti sveikasis skaičius",
      "Min members must be at least 0":
        "Mažiausias narių skaičius turi būti bent 0",
      "Max members must an integer":
        "Didžiausias narių skaičius turi būti sveikasis skaičius",
      "Max members must be at least 0":
        "Didžiausias narių skaičius turi būti bent 0",
      "Max members must be greater than and equal to min members":
        "Didžiausias narių skaičius turi būti didesnis arba lygus mažiausiam narių skaičiui",
      "Min reservists must an integer":
        "Mažiausias atsarginių skaičius turi būti sveikasis skaičius",
      "Min reservists must be at least 0":
        "Mažiausias atsarginių skaičius turi būti bent 0",
      "Max reservists must an integer":
        "Didžiausias atsarginių skaičius turi būti sveikasis skaičius",
      "Max reservists must be at least 0":
        "Didžiausias atsarginių skaičius turi būti bent 0",
      "Max reservists must be greater than and equal to min reservists":
        "Didžiausias atsarginių skaičius turi būti didesnis arba lygus mažiausiam atsarginių skaičiui",
      "Min number of athletes must an integer":
        "Mažiausias sportininkų skaičius turi būti sveikasis skaičius",
      "Min number of athletes must be at least 0":
        "Mažiausias sportininkų skaičius turi būti bent 0",
      "Max number of athletes must an integer":
        "Didžiausias sportininkų skaičius turi būti sveikasis skaičius",
      "Max number of athletes must be at least 0":
        "Didžiausias sportininkų skaičius turi būti bent 0",
      "Max number of athletes must be greater than min number of athletes":
        "Didžiausias sportininkų skaičius turi būti didesnis nei mažiausias sportininkų skaičius",
      "status is required": "Reikalingas statusas",
      "Category Updated Successfully!": "Kategorija sėkmingai atnaujinta!",
      "Category Created!": "Kategorija sukurtas!",
      "time period is required": "Reikalingas laiko periodas",
      "sort is required": "Reikalingas rūšiavimas",
      "at-least-one": "Bent viena kategorija yra privaloma",
      "At least one category is required":
        "Bent viena kategorija yra privaloma",
      "Value cannot be less than 1": "Reikšmė negali būti mažesnė nei 1",
      "place is required": "Reikalinga vieta",
      "entries is required": "Reikalingos įrašai",
      "points is required": "Reikalingi taškai",
      "You can't remove this category because this is used into addtional rules":
        "Negalite pašalinti šios kategorijos, nes ji naudojama papildomuose taisykliuose",
      "Rule added successfully": "Taisyklė sėkmingai pridėta",
      "Rule updated successfully": "Taisyklė sėkmingai atnaujinta",
      "Points updated successfully": "Taškai sėkmingai atnaujinti",
      "Points added successfully": "Taškai sėkmingai pridėti",
      "Removing...": "Pašalinama...",
      "Removed successfully!": "Sėkmingai pašalinta!",
      "fee is required": "Reikalingas mokestis",
      "duration is required": "Reikalinga trukmė",
      "partner license fee required":
        "Reikalingas partnerio licencijos mokestis",
      "tournated license fee required":
        "Reikalingas turnyrinės licencijos mokestis",
      "License updating ...": "Licencija atnaujinama ...",
      "License updated Successfully!": "Licencija sėkmingai atnaujinta!",
      "License creating ...": "Kuriama licencija ...",
      "License created successfully": "Licencija sėkmingai sukurta",
      deleteRankingsWarning:
        "Šios datos reitingai jau yra. Atnaujinus, senieji šios datos reitingai bus ištrinti. Ar norite testi?",
      "Display athlete birth year in Participants": `Rodyti sportininko gimimo metus „Dalyviuose“`,
      "Display athlete club": "Rodyti sportininko klubą",
      "Display athlete city": "Rodyti sportininko miestą",
      "Display athlete birth year": "Rodyti sportininko gimimo metus",
    },
  },
  it: {
    translation: {
      "Athlete Portal": "Portale Atleti",
      Create: "Crea",
      "Your subscription": "Il tuo abbonamento",
      Notifications: "Notifiche",
      "Add League": "Aggiungi Lega",
      "Add Tournament": "Aggiungi Torneo",
      "Add Club": "Aggiungi Club",
      "Add News": "Aggiungi Notizie",
      "Add Team": "Aggiungi Squadra",
      Home: "Home",
      Platforms: "Piattaforme",
      "Search Platform": "Cerca Piattaforma",
      "ADD NEW Platform": "AGGIUNGI NUOVA Piattaforma",
      "Platform name": "Nome Piattaforma",
      "Platform image": "Immagine Piattaforma",
      Country: "Paese",
      Sport: "Sport",
      Type: "Tipo",
      Organization: "Organizzazione",
      Status: "Stato",
      "There is no available platforms": "Non ci sono piattaforme disponibili",
      Dashboard: "Dashboard",
      Edit: "Modifica",
      New: "Nuovo",
      "Add New Platform": "Aggiungi Nuova Piattaforma",
      "Edit Platforms": "Modifica Piattaforme",
      "Organization account": "Account Organizzazione",
      "Platform general": "Generale Piattaforma",
      Appearance: "Aspetto",
      "Company details": "Dettagli Azienda",
      "Landing & Navigation": "Landing & Navigazione",
      Integrations: "Integrazioni",
      "Additional requests": "Richieste Aggiuntive",
      "Service fees": "Tariffe di Servizio",
      "Update Organization Account": "Aggiorna Account Organizzazione",
      "USER IS NOT IN THE LIST": "L'UTENTE NON È NELL'ELENCO",
      Name: "Nome",
      "Enter name": "Inserisci nome",
      Surname: "Cognome",
      "Enter surname": "Inserisci cognome",
      Email: "Email",
      "Enter Email Address": "Inserisci Indirizzo Email",
      "Change owner": "Cambia proprietario",
      "Save new owner": "Salva nuovo proprietario",
      Password: "Password",
      "Confirm Password": "Conferma Password",
      "Organization full name": "Nome completo dell'organizzazione",
      "Enter Organization full name":
        "Inserisci il nome completo dell'organizzazione",
      "Organization abbreviations": "Abbreviazioni Organizzazione",
      "Enter Organization abbreviation":
        "Inserisci abbreviazione Organizzazione",
      "Organization type": "Tipo di Organizzazione",
      "About organization": "Informazioni sull'organizzazione",
      "Contact email": "Email di contatto",
      "Enter Contact Email": "Inserisci Email di Contatto",
      "Contact phone number": "Numero di telefono di contatto",
      "Enter Contact phone number": "Inserisci numero di telefono di contatto",
      Website: "Sito Web",
      "Enter website": "Inserisci sito web",
      "Instagram link": "Link Instagram",
      "Insert Instagram link": "Inserisci link Instagram",
      Continue: "Continua",
      "Platform General": "Generale Piattaforma",
      "Platform title": "Titolo Piattaforma",
      "Enter Platform Title": "Inserisci Titolo Piattaforma",
      "Platform type": "Tipo di Piattaforma",
      "First selected country will act as primary country":
        "Il primo paese selezionato sarà il paese principale",
      "Select sports": "Seleziona sport",
      "First selected sport will act as primary sport":
        "Il primo sport selezionato sarà lo sport principale",
      Sports: "Sport",
      "Select discipline": "Seleziona disciplina",
      "Select third-party licence": "Seleziona licenza di terze parti",
      "third-party licence": "licenza di terze parti",
      "No third-party license Found": "Nessuna licenza di terze parti trovata",
      "Select languages": "Seleziona lingue",
      "First selected language will act as primary language":
        "La prima lingua selezionata sarà la lingua principale",
      "Select language": "Seleziona lingua",
      Timezone: "Fuso orario",
      "Time Zone": "Fuso orario",
      Currency: "Valuta",
      "First selected currency will act as primary currency":
        "La prima valuta selezionata sarà la valuta principale",
      "Select currency": "Seleziona valuta",
      Description: "Descrizione",
      "Do you have already your domain name?": "Hai già un nome di dominio?",
      "Enter your domain name": "Inserisci il tuo nome di dominio",
      Back: "Indietro",
      Save: "Salva",
      "Upload primary logo (svg or png)": "Carica logo principale (svg o png)",
      "Upload secondary logo (svg or png)":
        "Carica logo secondario (svg o png)",
      "Upload cover picture": "Carica immagine di copertina",
      "No logo, you want us to make one for you":
        "Nessun logo, vuoi che ne facciamo uno per te",
      "Click Here": "Clicca Qui",
      "Select primary color": "Seleziona colore primario",
      "Select secondary color": "Seleziona colore secondario",
      "Company name": "Nome Azienda",
      "Enter Company Name": "Inserisci Nome Azienda",
      "Registration Number": "Numero di Registrazione",
      "Enter Registration Number": "Inserisci Numero di Registrazione",
      "Full Address": "Indirizzo Completo",
      "Enter Full Address": "Inserisci Indirizzo Completo",
      "Signee full name": "Nome completo del firmatario",
      "Signee role": "Ruolo del firmatario",
      "Contact Email": "Email di contatto",
      "Landing / Home Screen": "Landing / Schermata Home",
      "Do you need landing?": "Hai bisogno di una landing?",
      "Select Template": "Seleziona Template",
      "No, I will use my own website as Landing page.":
        "No, userò il mio sito web come pagina di atterraggio.",
      "Do you need Integrations?": "Hai bisogno di integrazioni?",
      "Payment Service Provider": "Fornitore di Servizi di Pagamento",
      "Make sure to get familiar with Stripe service fees":
        "Assicurati di conoscere le tariffe di servizio di Stripe",
      "Additional services": "Servizi aggiuntivi",
      "Service Name": "Nome Servizio",
      Price: "Prezzo",
      "Mobile app": "App Mobile",
      SMM: "SMM",
      "Graphics for SMM": "Grafica per SMM",
      "Your request": "La tua richiesta",
      "Type here your request": "Scrivi qui la tua richiesta",
      "Upload file": "Carica file",
      "Entry service fee": "Tariffa di servizio di ingresso",
      "Tournated entry service fee": "Tariffa di servizio di ingresso Torneo",
      "Entery entery fee": "Tariffa d'ingresso",
      "Partner entry service fee": "Tariffa di servizio di ingresso Partner",
      "Enter partner entry fee": "Inserisci tariffa di ingresso partner",
      "License service fee": "Tariffa di servizio di licenza",
      "Tournated license service fee": "Tariffa di servizio licenza Torneo",
      "Enter tournated license fee": "Inserisci tariffa di licenza Torneo",
      "Partner license service fee": "Tariffa di servizio di licenza Partner",
      "Enter partner license fee": "Inserisci tariffa di licenza partner",
      "Enable Pricing": "Abilita Prezzi",
      Leagues: "Leghe",
      "Create new League": "Crea nuova Lega",
      "There is no available leagues": "Non ci sono leghe disponibili",
      Completed: "Completato",
      Upcoming: "In arrivo",
      Active: "Attivo",
      Total: "Totale",
      "Search Leagues": "Cerca Leghe",
      "League Title": "Titolo Lega",
      "League Type": "Tipo di Lega",
      Logo: "Logo",
      Tournaments: "Tornei",
      Athletes: "Atleti",
      Regulations: "Regolamenti",
      Platform: "Piattaforma",
      "We recommend an image that is 256 x 256 pixels":
        "Consigliamo un'immagine di 256 x 256 pixel",
      "League Abbreviation": "Abbreviazione Lega",
      "League owner": "Proprietario della Lega",
      "Select Organization": "Seleziona Organizzazione",
      "Select platform": "Seleziona piattaforma",
      "League Priority": "Priorità della Lega",
      "Go to Next": "Vai al Prossimo",
      Phone: "Telefono",
      "League Website": "Sito Web della Lega",
      Instagram: "Instagram",
      "Field Title": "Titolo del Campo",
      "Field Type": "Tipo di Campo",
      "Add New Point": "Aggiungi Nuovo Punto",
      "Title Name": "Nome del Titolo",
      Select: "Seleziona",
      "Field Value": "Valore del Campo",
      "Add Another Option Below": "Aggiungi un'Altra Opzione Sotto",
      Update: "Aggiorna",
      Management: "Gestione",
      "League status": "Stato della Lega",
      "Select one": "Seleziona uno",
      "Make this league featured": "Rendi questa lega in evidenza",
      "Enable rankings": "Abilita classifiche",
      "enable ranking":
        "Crea classifiche personalizzate per la tua lega collegando categorie di tornei, definendo limiti di età/genere e aggiungendo regole aggiuntive.",
      "Enable online payments": "Abilita pagamenti online",
      "enable online payment":
        "Inizia ad accettare pagamenti online per le iscrizioni ai tornei e le licenze. Familiarizzati con il nostro modello di commissioni di servizio per la gestione dei pagamenti online controllandolo qui. (cliccando apre una finestra con i dettagli secondo il nostro prezzo in",
      pitchdeck: "pitchdeck",
      "Enable licences": "Abilita licenze",
      "License description": "Configura licenze, prezzi, periodi e regole.",
      "Make club field mandatory and display club details as organizer on Tournament page":
        "Rendi obbligatorio il campo del club e mostra i dettagli del club come organizzatore nella pagina del torneo",
      "Create League": "Crea Lega",
      "Organization Accounts": "Account Organizzazioni",
      "ADD NEW ACCOUNT": "AGGIUNGI NUOVO ACCOUNT",
      "There is no available organizations":
        "Non ci sono organizzazioni disponibili",
      "Search Organizations": "Cerca Organizzazioni",
      "Full name": "Nome completo",
      "Organization Abbrevation": "Abbreviazione Organizzazione",
      "UPDATE USER PROFILE": "AGGIORNA PROFILO UTENTE",
      "Enter phone number": "Inserisci numero di telefono",
      "Organization Settings": "Impostazioni Organizzazione",
      "Organization Type": "Tipo di Organizzazione",
      "Organization Logo": "Logo dell'Organizzazione",
      "About Organization": "Informazioni sull'Organizzazione",
      "Contact Details": "Dettagli Contatto",
      Users: "Utenti",
      "Search User": "Cerca Utente",
      "ADD NEW USER": "AGGIUNGI NUOVO UTENTE",
      Avatar: "Avatar",
      "Phone Number": "Numero di Telefono",
      Role: "Ruolo",
      "IPIN License": "Licenza IPIN",
      "DUPR License": "Licenza DUPR",
      "Tennis Europe License": "Licenza Tennis Europe",
      "LTS U10 License": "Licenza LTS U10",
      "FIDE License": "Licenza FIDE",
      "Add new user": "Aggiungi nuovo utente",
      "Edit User": "Modifica Utente",
      "User Account": "Account Utente",
      "Date of birth": "Data di nascita",
      Gender: "Genere",
      "Representing country": "Paese rappresentato",
      City: "Città",
      "Enter city": "Inserisci città",
      Address: "Indirizzo",
      "Enter address": "Inserisci indirizzo",
      Career: "Carriera",
      Club: "Club",
      "Select club": "Seleziona club",
      "Select coach": "Seleziona allenatore",
      Parent: "Genitore",
      "Select parent": "Seleziona genitore",
      "Write a bio": "Scrivi una biografia",
      CHECKBOXES: "CHECKBOX",
      "Give access to coach to purchase licences and manage entries on your behalf":
        "Dai accesso all'allenatore per acquistare licenze e gestire iscrizioni per tuo conto",
      "Give access to club manager(-es) to purchase licences and manage entries on your behalf":
        "Dai accesso al gestore del club per acquistare licenze e gestire iscrizioni per tuo conto",
      "Give access to parent(-s) to purchase licences and manage entries on your behalf":
        "Dai accesso al genitore per acquistare licenze e gestire iscrizioni per tuo conto",
      Socials: "Social",
      "Insert link here": "Inserisci link qui",
      Facebook: "Facebook",
      Linkedin: "Linkedin",
      Tiktok: "Tiktok",
      Youtube: "Youtube",
      Licence: "Licenza",
      "ITF IPIN": "ITF IPIN",
      DUPRID: "DUPRID",
      "Tennis Europe ID": "ID Tennis Europe",
      "LTS U10": "LTS U10",
      ATP: "ATP",
      WTA: "WTA",
      FIDE: "FIDE",
      "Update password": "Aggiorna password",
      "Re-Enter": "Reinserisci",
      "Login settings": "Impostazioni di accesso",
      "Switch Standard login to Social login":
        "Passa dall'accesso standard all'accesso tramite social",
      "Connect Gmail": "Collega Gmail",
      "Enable 2FA Google Authorisation":
        "Abilita l'autenticazione Google a 2FA",
      "Enable 2FA Email Authorisation": "Abilita l'autenticazione e-mail a 2FA",
      "Role Name": "Nome del Ruolo",
      "Is user verified?": "L'utente è verificato?",
      Yes: "Sì",
      No: "No",
      Tournament: "Torneo",
      "All tournaments": "Tutti i tornei",
      "League tournaments": "Tornei della Lega",
      "Search Tournaments": "Cerca Tornei",
      "Create new Tournament": "Crea nuovo Torneo",
      "Tournament Title": "Titolo del Torneo",
      "Start - End": "Inizio - Fine",
      Organizer: "Organizzatore",
      Location: "Posizione",
      "List of categories": "Elenco delle categorie",
      "Update Status": "Aggiorna Stato",
      "There is no available tournaments": "Non ci sono tornei disponibili",
      "ADD NEW SPORT": "AGGIUNGI NUOVO SPORT",
      "Search Sports": "Cerca Sport",
      "Sport Title": "Titolo Sport",
      "Add new Sport": "Aggiungi nuovo Sport",
      "Edit Sports": "Modifica Sport",
      "Sports title": "Titolo dello Sport",
      "Enter title": "Inserisci titolo",
      "Sports abbreviation": "Abbreviazione dello Sport",
      "Enter abbreviation": "Inserisci abbreviazione",
      "Resource title": "Titolo della Risorsa",
      "Rule how to add results/score":
        "Regola su come aggiungere risultati/punteggi",
      Icon: "Icona",
      "Enable disciplines": "Abilita discipline",
      "Enable courts": "Abilita campi",
      "Enable stages": "Abilita fasi",
      "Enable pairings": "Abilita abbinamenti",
      "Enable rounds": "Abilita round",
      "Enable standings based on pairings":
        "Abilita classifiche basate sugli abbinamenti",
      "Display draws": "Visualizza pareggi",
      "Display groups": "Visualizza gruppi",
      "Display live standings": "Visualizza classifiche live",
      "Display order of play": "Visualizza ordine di gioco",
      "Display list & results": "Visualizza elenco e risultati",
      "Display pending results": "Visualizza risultati in sospeso",
      "Display final standings": "Visualizza classifiche finali",
      "Display athlete chess titles in Participants": `Visualizza titoli scacchistici degli atleti in "Partecipanti"`,
      "Display seeds column Participants": `Visualizza colonna teste di serie in "Partecipanti"`,
      "Display segments column in Participants": `Visualizza colonna segmenti in "Partecipanti"`,
      "Upload file here": "Carica file qui",
      Disciplines: "Discipline",
      "ADD NEW DISCIPLINE": "AGGIUNGI NUOVA DISCIPLINA",
      "There is no available disciplines": "Non ci sono discipline disponibili",
      "Search Disciplines": "Cerca discipline",
      "Sport name": "Nome dello sport",
      "Add new Discipline": "Aggiungi nuova disciplina",
      "Edit Discipline": "Modifica disciplina",
      "Discipline title": "Titolo disciplina",
      "Select Sport": "Seleziona sport",
      "No sports found": "Nessuno sport trovato",
      "Results type": "Tipo di risultati",
      "Third-party licences": "Licenze di terze parti",
      "Third party licence": "Licenza di terze parti",
      "There is no available third-party licences":
        "Non ci sono licenze di terze parti disponibili",
      "ADD NEW LICENCE": "AGGIUNGI NUOVA LICENZA",
      "Search license": "Cerca licenza",
      "license name": "Nome licenza",
      "License type": "Tipo di licenza",
      "Add licence": "Aggiungi licenza",
      "Add Third-party licence": "Aggiungi licenza di terze parti",
      "Edit Third-party licence": "Modifica licenza di terze parti",
      "Organization title": "Titolo organizzazione",
      "Organization abbreviation": "Abbreviazione organizzazione",
      "Licence title": "Titolo licenza",
      "Enter license title": "Inserisci titolo licenza",
      "Licence type": "Tipo di licenza",
      "Request license in user profile": "Richiedi licenza nel profilo utente",
      "Request license on user registration in platform":
        "Richiedi licenza durante la registrazione utente sulla piattaforma",
      "Make licence mandatory": "Rendi licenza obbligatoria",
      "Link to get licence": "Link per ottenere licenza",
      "Enter link to get licence": "Inserisci link per ottenere licenza",
      Partners: "Partner",
      "ADD NEW PARTNER": "AGGIUNGI NUOVO PARTNER",
      "There is no available partners": "Non ci sono partner disponibili",
      "Search Partners": "Cerca partner",
      "Create new Partner": "Crea nuovo partner",
      Link: "Link",
      Text: "Testo",
      Priority: "Priorità",
      "Showing dates": "Mostra date",
      "New Partner": "Nuovo partner",
      "Edit Partner": "Modifica partner",
      "Partner Logo": "Logo del partner",
      platform: "piattaforma",
      "No platform available": "Nessuna piattaforma disponibile",
      "No league available": "Nessuna lega disponibile",
      "No Clubs available": "Nessun club disponibile",
      "Sponsor Text": "Testo sponsor",
      "Date Settings": "Impostazioni data",
      "Active From": "Attivo da",
      "Active To": "Attivo fino a",
      "Platform managers": "Gestori della piattaforma",
      "ADD NEW MANAGER": "AGGIUNGI NUOVO GESTORE",
      "There is no available manager": "Non ci sono gestori disponibili",
      "Search Manager": "Cerca gestore",
      "Export team": "Esporta squadra",
      "Export team emails": "Esporta email squadra",
      "Add platform manager": "Aggiungi gestore piattaforma",
      "Edit platform manager": "Modifica gestore piattaforma",
      "Enter Organization": "Inserisci organizzazione",
      "Select organization": "Seleziona organizzazione",
      "Filter users": "Filtra utenti",
      "Export users": "Esporta utenti",
      "Export user emails": "Esporta email utenti",
      "Age group": "Fascia d'età",
      "There is no available users": "Non ci sono utenti disponibili",
      Coaches: "Allenatori",
      "ADD NEW COACH": "AGGIUNGI NUOVO ALLENATORE",
      "There is no available account": "Non ci sono account disponibili",
      "Search Coach": "Cerca allenatore",
      "Add new coach": "Aggiungi nuovo allenatore",
      "Edit Coach": "Modifica allenatore",
      "Coach image": "Immagine allenatore",
      Category: "Categoria",
      "There is no available sports": "Non ci sono sport disponibili",
      "there are not available clubs": "non ci sono club disponibili",
      "Date from": "Data da",
      "Date to": "Data a",
      "Display on organization's landing page":
        "Mostra sulla landing page dell'organizzazione",
      "Platform tournaments": "Tornei della piattaforma",
      Title: "Titolo",
      Posts: "Post",
      "ADD POST": "AGGIUNGI POST",
      ////////////////////////////////////////////////
      "There is no available post": "Non ci sono post disponibili",
      News: "Notizie",
      "Search Post": "Cerca post",
      Image: "Immagine",
      Author: "Autore",
      Content: "Contenuto",
      "Cover / featured image": "Immagine di copertina / in evidenza",
      "Select league": "Seleziona lega",
      League: "Lega",
      "Add video from youtube.com": "Aggiungi video da youtube.com",
      Date: "Data",
      "Purchased Licenses": "Licenze acquistate",
      "Search Licenses": "Cerca licenze",
      "Create new License": "Crea nuova licenza",
      "Athlete name & surname": "Nome e cognome atleta",
      "License Name": "Nome licenza",
      "License Price": "Prezzo licenza",
      "License active from": "Licenza attiva da",
      "License active to": "Licenza attiva fino a",
      "Paid(online/cash)": "Pagato (online/contanti)",
      "There is no available purchased license":
        "Non ci sono licenze acquistate disponibili",
      "New License": "Nuova licenza",
      "Edit License": "Modifica licenza",
      "Athlete Email": "Email atleta",
      License: "Licenza",
      "No license found": "Nessuna licenza trovata",
      "Payment Method": "Metodo di pagamento",
      "How much paid": "Quanto pagato",
      Teams: "Squadre",
      "ADD NEW TEAM": "AGGIUNGI NUOVA SQUADRA",
      "There is no available teams": "Non ci sono squadre disponibili",
      "Search team": "Cerca squadra",
      "Create new Team": "Crea nuova squadra",
      "Team Members": "Membri della squadra",
      Discipline: "Disciplina",
      "New Team": "Nuova squadra",
      "Edit Team": "Modifica squadra",
      "Team Logo": "Logo squadra",
      "No Clubs Found": "Nessun club trovato",
      "No disciplines found": "Nessuna disciplina trovata",
      "Club status": "Stato del club",
      Members: "Membri",
      "Add new Members": "Aggiungi nuovi membri",
      "Add New Member": "Aggiungi nuovo membro",
      "Member email": "Email membro",
      "Select Role": "Seleziona ruolo",
      "Add more": "Aggiungi altri",
      "ADD NEW CLUB": "AGGIUNGI NUOVO CLUB",
      "There is no available clubs": "Non ci sono club disponibili",
      Clubs: "Club",
      "Search Clubs": "Cerca Club",
      "Club Name": "Nome Club",
      "Add new Club": "Aggiungi nuovo Club",
      "Edit Club": "Modifica Club",
      "Appearance & Location": "Aspetto e Posizione",
      "Club title": "Titolo del Club",
      "Add club logo": "Aggiungi logo del club",
      "How to find us": "Come trovarci",
      "Contact details": "Dettagli di contatto",
      "Contact Phone": "Telefono di contatto",
      Twitter: "Twitter",
      "Services & Resources": "Servizi e Risorse",
      "Select services": "Seleziona servizi",
      Services: "Servizi",
      Resources: "Risorse",
      "Resource Name": "Nome Risorsa",
      Service: "Servizio",
      "Add Resources": "Aggiungi Risorse",
      "Add New Resource": "Aggiungi Nuova Risorsa",
      "Select Service": "Seleziona Servizio",
      "Select type": "Seleziona tipo",
      "Add Another": "Aggiungi Un Altro",
      "Club page": "Pagina del Club",
      "Select Coaches": "Seleziona Allenatori",
      "Athlete Club": "Club Atleta",
      desc: "Una volta che il tuo club è stato aggiunto, gli atleti potranno selezionarlo nella loro pagina di impostazioni del profilo e appariranno sulla pagina del club per essere approvati/rifiutati. I membri approvati verranno mostrati sulla pagina del club",
      "Online booking": "Prenotazione online",
      "Club Main Administrator": "Amministratore principale del Club",
      "Main Adminitrator": "Amministratore principale",
      "CLUB MAIN ADMINISTRATOR IS NOT IN THE LIST":
        "L'AMMINISTRATORE PRINCIPALE DEL CLUB NON È NELLA LISTA",
      Administrator: "Amministratore",
      "ADMINISTRATOR IS NOT IN THE LIST": "L'AMMINISTRATORE NON È NELLA LISTA",
      Banners: "Banner",
      "There is no available banners": "Non ci sono banner disponibili",
      "ADD BANNER": "AGGIUNGI BANNER",
      "Search Banner": "Cerca Banner",
      Headline: "Titolo",
      subheadline: "Sottotitolo",
      "Button Text": "Testo del Bottone",
      "New Banner": "Nuovo Banner",
      "Edit Banner": "Modifica Banner",
      Subheadline: "Sottotitolo",
      "Button text": "Testo del bottone",
      "Button link": "Link del bottone",
      "Select roles": "Seleziona ruoli",
      "Banner images": "Immagini del banner",
      "Control Panel": "Pannello di controllo",
      "Run the tournament step by step from this control panel":
        "Esegui il torneo passo dopo passo da questo pannello di controllo",
      "Edit & Setup league": "Modifica e configura lega",
      "Edit Now": "Modifica ora",
      "Create & Setup league": "Crea e configura lega",
      "Start Now": "Inizia ora",
      "Add league members": "Aggiungi membri della lega",
      "league member description":
        "Includi membri della lega come manager, organizzatori di tornei e arbitri per la tua lega. I manager avranno accesso completo al pannello di controllo e tutte le funzionalità manageriali, gli organizzatori potranno aggiungere tornei e gestirli, mentre gli arbitri avranno accesso a iscrizioni, sorteggi, gruppi, classifiche live, ordine di gioco e gestione dei risultati per i tornei a cui sono assegnati.",
      "Add Now": "Aggiungi ora",
      "Add tournament categories": "Aggiungi categorie di torneo",
      "tournament category description":
        "Configura le categorie di torneo per la tua lega, specificando i formati (singolo/doppio/squadra), limitazioni di età/sesso e collegandole alle categorie di classificazione (una volta aggiunte queste categorie di classificazione nel passo successivo).",
      "Add rankings categories": "Aggiungi categorie di classificazione",
      "ranking category description":
        "Stabilisci le categorie di classificazione per la tua lega. Scegli le categorie di torneo da includere nelle classifiche, specifica quanti tornei conteggiare, imposta il periodo di tempo, collegati alle leghe internazionali e stabilisci altre regole.",
      "Setup ranking points": "Configura punti di classificazione",
      "ranking description":
        "Configura la tabella dei punti per ogni categoria di torneo, specificando il numero di punti per ogni posizione. Una tabella più dettagliata semplificherà e automatizzerà il processo.",
      Setup: "Configura",
      "Setup licences": "Configura licenze",
      "Add tournament": "Aggiungi torneo",
      "tournament description":
        "Segui i passaggi per creare il tuo torneo e avviare la gestione del tuo evento. Sarai sicuro di ricevere un elenco completo di istruzioni che coprono le numerose funzionalità che offriamo per la gestione degli eventi sportivi",
      "Leagues Members": "Membri delle Leghe",
      "Add new Member": "Aggiungi nuovo membro",
      "There is no available members": "Non ci sono membri disponibili",
      "League members": "Membri della lega",
      "Search Members": "Cerca membri",
      "ADD NEW MEMBER": "AGGIUNGI NUOVO MEMBRO",
      "Delete league member": "Elimina membro della lega",
      "Are you sure you want to delete?": "Sei sicuro di voler eliminare?",
      Cancel: "Annulla",
      Delete: "Elimina",
      "Tournament Categories": "Categorie di Torneo",
      "There is no available categories": "Non ci sono categorie disponibili",
      "ADD NEW CATEGORY": "AGGIUNGI NUOVA CATEGORIA",
      "Search Categories": "Cerca categorie",
      "Copy Categories": "Copia categorie",
      "Delete Categories": "Elimina categorie",
      "Category Name": "Nome Categoria",
      "Category Type": "Tipo Categoria",
      "Select League": "Seleziona Lega",
      Copy: "Copia",
      "Are you sure you want to delete these categories?":
        "Sei sicuro di voler eliminare queste categorie?",
      "New Category": "Nuova Categoria",
      "Edit Category": "Modifica Categoria",
      "Category name": "Nome categoria",
      "Add team selection on tournament registration":
        "Aggiungi selezione squadra alla registrazione del torneo",
      "Add club selection on tournament registration":
        "Aggiungi selezione club alla registrazione del torneo",
      "Skills level": "Livello di abilità",
      "Category Gender": "Genere della categoria",
      "Age from": "Età da",
      "Age to": "Età a",
      "Age Limit Type": "Tipo di limite di età",
      "No Types Found!": "Nessun tipo trovato!",
      "Ask skills level on a tournament registration":
        "Chiedi il livello di abilità durante la registrazione del torneo",
      "Enable License": "Abilita Licenza",
      "Display athlete club in Participants":
        "Mostra club atleta in 'Partecipanti'",
      "Display athlete city in Participants":
        "Mostra città atleta in 'Partecipanti'",
      "Select License": "Seleziona Licenza",
      "No License Found!": "Nessuna Licenza Trovata!",
      "Ranking Category for men": "Categoria di classificazione per uomini",
      "No Category Found!": "Nessuna Categoria Trovata!",
      "Ranking Category for Women": "Categoria di classificazione per donne",
      "Third-party license": "Licenza di terze parti",
      "No third-party license Found!":
        "Nessuna licenza di terze parti trovata!",
      "Time control": "Controllo del tempo",
      "No time control found": "Nessun controllo del tempo trovato",
      "Order display for seeding": "Ordine di visualizzazione per il seeding",
      "Sport fishing settings": "Impostazioni pesca sportiva",
      "Identify team members on registration":
        "Identifica membri della squadra durante la registrazione",
      "Min number of members": "Numero minimo di membri",
      "Max number of members": "Numero massimo di membri",
      "Reservists required": "Riservisti richiesti",
      "Min number of reservists": "Numero minimo di riservisti",
      "Max number of reservists": "Numero massimo di riservisti",
      "Captain required": "Capitano richiesto",
      "Transfer members to singles category":
        "Trasferisci membri alla categoria singoli",
      "Select singles category": "Seleziona categoria singoli",
      "No Category Found": "Nessuna Categoria Trovata",
      "National tournament (country vs country)":
        "Torneo nazionale (paese contro paese)",
      "Club championships (club vs club)":
        "Campionati del club (club contro club)",
      "Create matches from team members and count points result based on matches result":
        "Crea partite dai membri della squadra e conta i punti in base ai risultati delle partite",
      "Count individual total": "Conta il totale individuale",
      "Count teams total": "Conta il totale delle squadre",
      "Number of athletes to count min":
        "Numero minimo di atleti da conteggiare",
      "Number of athletes to count max":
        "Numero massimo di atleti da conteggiare",
      "Max Entries per team": "Max iscrizioni per squadra",
      "Allow registration without team": "Consenti registrazione senza squadra",
      "Category Status": "Stato Categoria",
      "Ranking Categories": "Categorie di Classificazione",
      "There is no available ranking categories":
        "Non ci sono categorie di classificazione disponibili",
      Categories: "Categorie",
      "No of Tournaments": "Numero di Tornei",
      "Time Period": "Periodo di Tempo",
      "No Leagues found": "Nessuna Lega trovata",
      "New Ranking Category": "Nuova Categoria di Classifica",
      "Select Categories": "Seleziona Categorie",
      "No Categories Found!": "Nessuna Categoria Trovata!",
      "Max number of tournaments": "Numero massimo di tornei",
      "Count specific tournaments": "Conta tornei specifici",
      "Add penalty points for missing a tournament":
        "Aggiungi punti di penalità per mancata partecipazione a un torneo",
      "Penalty rule": "Regola di penalità",
      "Enter points": "Inserisci punti",
      "Additional Rules": "Regole Aggiuntive",
      Sector: "Settore",
      Coefficient: "Coefficiente",
      "No of tournaments": "Numero di tornei",
      "Edit additional rule(s)": "Modifica regola/e aggiuntiva/e",
      "Add additional rule(s)": "Aggiungi regola/e aggiuntiva/e",
      "During which period tournament points are counted":
        "Durante quale periodo vengono conteggiati i punti dei tornei",
      "Select Time Period": "Seleziona Periodo di Tempo",
      "Points sorting": "Ordinamento Punti",
      "Sport Fishing": "Pesca Sportiva",
      "Select Stage form which take points":
        "Seleziona la Fase da cui prendere i punti",
      "Ranking Points": "Punti di Classifica",
      "Search Rankings": "Cerca Classifiche",
      "Add points block": "Aggiungi blocco di punti",
      "Select / UnSelect All": "Seleziona / Deseleziona Tutti",
      Place: "Posizione",
      Points: "Punti",
      "Bracket type": "Tipo di Bracket",
      Segment: "Segmento",
      "Num of Entries": "Numero di Iscrizioni",
      "Ad.Rules": "Regole Aggiuntive",
      "view rule": "visualizza regola",
      "Add new row": "Aggiungi nuova riga",
      "There is no available ranking points":
        "Non ci sono punti di classifica disponibili",
      "Edit Points": "Modifica Punti",
      "Add Points": "Aggiungi Punti",
      "Points block name": "Nome del blocco di punti",
      Specification: "Specifiche",
      "Rule Settings": "Impostazioni Regole",
      "Select operator": "Seleziona operatore",
      "Select skills level": "Seleziona livello di abilità",
      "Number of players": "Numero di giocatori",
      "Number of entries": "Numero di iscrizioni",
      update: "aggiorna",
      SAVE: "SALVA",
      DISCARD: "ANNULLA",
      "SET NEW RULE": "IMPOSTA NUOVA REGOLA",
      "Edit Row": "Modifica Riga",
      "Add Row": "Aggiungi Riga",
      ADD: "AGGIUNGI",
      "Copy Ranking Point": "Copia Punto di Classifica",
      "Select Ranking Points": "Seleziona Punti di Classifica",
      "Copy Ranking Point Blocks": "Copia Blocchi di Punti di Classifica",
      "Delete Ranking Point Block": "Elimina Blocco di Punti di Classifica",
      Licenses: "Licenze",
      "There is no available licenses": "Non ci sono licenze disponibili",
      "Price Currency": "Valuta del Prezzo",
      "Select Leagues": "Seleziona Leghe",
      "No League Found!": "Nessuna Lega Trovata!",
      "License price": "Prezzo della Licenza",
      "License Type": "Tipo di Licenza",
      "License Status": "Stato della Licenza",
      "Additional questions": "Domande Aggiuntive",
      "Add additional question": "Aggiungi domanda aggiuntiva",
      "Edit this": "Modifica questo",
      "Add new": "Aggiungi nuovo",
      question: "domanda",
      Question: "Domanda",
      "Field Option": "Opzione del Campo",
      Remove: "Rimuovi",
      "Add new option": "Aggiungi nuova opzione",
      "Ask this question to each athlete": "Fai questa domanda a ogni atleta",
      Submit: "Invia",
      "Delete Warning": "Avviso di Eliminazione",
      "Are you sure you want to delete this question?":
        "Sei sicuro di voler eliminare questa domanda?",
      "Confirm Deletion": "Conferma Eliminazione",
      Rankings: "Classifiche",
      Search: "Cerca",
      "ranking Category": "Categoria di Classifica",
      "No date Found": "Nessuna data trovata",
      "Create Rankings": "Crea Classifiche",
      "Update Rankings": "Aggiorna Classifiche",
      close: "chiudi",
      Year: "Anno",
      "Points Details": "Dettagli Punti",
      "No points yet": "Nessun punto ancora",
      Position: "Posizione",
      Athlete: "Atleta",
      "There is no available rankings": "Non ci sono classifiche disponibili",
      "Select Ranking categories": "Seleziona categorie di classifica",
      "Remove Rankings": "Rimuovi classifiche",
      "Select Date": "Seleziona data",
      "Are you sure you want to delete rankings?":
        "Sei sicuro di voler eliminare le classifiche?",
      "Rankings History": "Storico Classifiche",
      "Ranking Category": "Categoria di Classifica",
      "Last Updated": "Ultimo Aggiornamento",
      Public: "Pubblico",
      "Delete warning": "Avviso di Eliminazione",
      "Are you sure you want to delete this ranking?":
        "Sei sicuro di voler eliminare questa classifica?",
      "There is no available rankings history":
        "Non ci sono storici di classifiche disponibili",
      "Tournament Results": "Risultati Tornei",
      Entry: "Partecipante",
      "Add new result": "Aggiungi nuovo risultato",
      "There is no available tournament results":
        "Non ci sono risultati di tornei disponibili",
      "Pendding tournaments": "Tornei in attesa",
      "There is no available athletes": "Non ci sono atleti disponibili",
      "Search Athletes": "Cerca Atleti",
      "Country/Residency": "Paese/Residenza",
      "Super Data": "Super Dati",
      "All Tournaments": "Tutti i Tornei",
      Data: "Dati",
      "Pending Tournaments": "Tornei in sospeso",
      Financials: "Finanze",
      Accounting: "Contabilità",
      Extra: "Extra",
      "Live scoring": "Punteggi in diretta",
      "Live stream": "Streaming in diretta",
      "Platform Settings": "Impostazioni della Piattaforma",
      "My Platforms": "Le Mie Piattaforme",
      Team: "Team",
      Instructions: "Istruzioni",
      "League Settings": "Impostazioni della Lega",
      "League Members": "Membri della Lega",
      Upload: "Carica",
      "primary logo": "logo principale",
      "secondary logo": "logo secondario",
      "cover picture": "immagine di copertura",
      "Start Typing": "Inizia a digitare",
      "Leagues list": "Elenco Leghe",
      "General & Appearance": "Generale & Aspetto",
      "Instagram Link": "Link Instagram",
      "Fact Sheet": "Scheda Informativa",
      "Fact sheet": "Scheda informativa",
      "Upload regulations": "Carica regolamenti",
      "Organization Abbreviation": "Abbreviazione dell'Organizzazione",
      "Drag and Drop some file": "Trascina e rilascia un file",
      "here, or click to Select file": "qui, o clicca per selezionare il file",
      UPLOAD: "CARICA",
      "DISCARD AND CANCEL": "ANNULLA E SCARTA",
      SUBMIT: "INVIA",
      "Add new Organization Account": "Aggiungi nuovo account organizzazione",
      "Edit Organization Account": "Modifica account organizzazione",
      "Full Name": "Nome completo",
      Biography: "Biografia",
      "Add post": "Aggiungi post",
      "Edit Post": "Modifica post",
      "Edit Member": "Modifica membro",
      "Organization name": "Nome organizzazione",
      "Organization email": "Email organizzazione",
      "Organization phone": "Telefono organizzazione",
      "Display member on organizations public team page":
        "Visualizza membro nella pagina pubblica del team dell'organizzazione",
      "Select Roles": "Seleziona ruoli",
      "For assiging points": "Per assegnare punti",
      "Third party license": "Licenza di terze parti",
      ///////////////////////////////////////////////////////// new ///////////////
      "Must be a valid email": "Deve essere un'email valida",
      "Email is required": "Email richiesta",
      "Organization Name is required": "Nome dell'organizzazione richiesto",
      "Organization Type is required": "Tipo di organizzazione richiesto",
      "organization abbreviation is required":
        "Abbreviazione dell'organizzazione richiesta",
      "Contact Email is required": "Email di contatto richiesta",
      "Password is Required": "Password richiesta",
      "Password should be 8 chars minimum.":
        "La password deve essere lunga almeno 8 caratteri.",
      "Passwords must match": "Le password devono corrispondere",
      "company name is required": "Nome azienda richiesto",
      "company number is required": "Numero azienda richiesto",
      "full address is required": "Indirizzo completo richiesto",
      "Invalid email address": "Indirizzo email non valido",
      "signee role is required": "Ruolo firmatario richiesto",
      "signee full name is required": "Nome completo del firmatario richiesto",
      "partner entry fee is required":
        "Tariffa di ingresso del partner richiesta",
      "tournated license fee is required":
        "Tariffa di licenza del torneo richiesta",
      "partner license fee  is required":
        "Tariffa di licenza partner richiesta",
      "Creating platform...": "Creazione della piattaforma...",
      "Platform created successfully": "Piattaforma creata con successo",
      "Updating...": "Aggiornamento in corso...",
      "Platform updated": "Piattaforma aggiornata",
      "Add New Club": "Aggiungi nuovo club",
      "organization is required": "Organizzazione richiesta",
      "Adding new fact...": "Aggiungendo nuovo fatto...",
      "Fact added successfully": "Fatto aggiunto con successo",
      "Deleting fact...": "Eliminando fatto...",
      "fact deleted successfully": "Fatto eliminato con successo",
      "Updating fact...": "Aggiornando fatto...",
      "Fact updated successfully": "Fatto aggiornato con successo",
      "Creating league...": "Creazione della lega...",
      "League created successfully": "Lega creata con successo",
      "Updating league...": "Aggiornamento lega in corso...",
      "League updated successfully": "Lega aggiornata con successo",
      "Approved & Public": "Approvato e pubblico",
      "ATP points": "Punti ATP",
      "WTA points": "Punti WTA",
      "name Email is required": "Email del nome richiesta",
      "title is require": "Titolo richiesto",
      "platform type required": "Tipo di piattaforma richiesto",
      "time zone is required": "Fuso orario richiesto",
      "description is required": "Descrizione richiesta",
      "title is required": "Titolo richiesto",
      "abbreviation is required": "Abbreviazione richiesta",
      "platform is required": "Piattaforma richiesta",
      "priority is required": "Priorità richiesta",
      "type is required": "Tipo richiesto",
      "sports is required": "Sport richiesto",
      "email is required": "Email richiesta",
      "Organization added Successfully!":
        "Organizzazione aggiunta con successo!",
      "name is required": "Nome richiesto",
      "surname is required": "Cognome richiesto",
      "organization name is required": "Nome organizzazione richiesto",
      "organization type is required": "Tipo di organizzazione richiesto",
      "Organization Account Updated Successfully!":
        "Account organizzazione aggiornato con successo!",
      "Creating organization...": "Creazione dell'organizzazione...",
      "Organization created successfully": "Organizzazione creata con successo",
      "This User is already exist": "Questo utente esiste già",
      "Password is required": "Password richiesta",
      "Re-Enter password is required": "Rinserire la password è richiesto",
      "Password updated": "Password aggiornata",
      "User Updated": "Utente aggiornato",
      "Sports Updated": "Sport aggiornati",
      "Sports Created": "Sport creati",
      "Discipline Updated": "Disciplina aggiornata",
      "Discipline Created": "Disciplina creata",
      "At least one sport must be selected":
        "Deve essere selezionato almeno uno sport",
      "License Updated": "Licenza aggiornata",
      "License Created": "Licenza creata",
      "Please provide Image": "Fornisci un'immagine",
      "Partner updated Successfully!": "Partner aggiornato con successo!",
      "Partner created successfully!": "Partner creato con successo!",
      "Please add correct user email": "Aggiungi l'email corretta dell'utente",
      "Coach added!": "Allenatore aggiunto!",
      "user found": "utente trovato",
      "user not found": "utente non trovato",
      "country is required": "Paese richiesto",
      "gender is required": "Genere richiesto",
      "confirm password is required": "Conferma della password richiesta",
      "Coache Updated Successfully!": "Allenatore aggiornato con successo!",
      "Image field is required": "Campo immagine richiesto",
      "author is required": "Autore richiesto",
      "Updating ...": "Aggiornamento in corso...",
      "Post updated successfully": "Post aggiornato con successo",
      "Creating post ...": "Creazione del post in corso...",
      "Post created successfully": "Post creato con successo",
      "User found": "Utente trovato",
      "User not found": "Utente non trovato",
      "paid is required": "Pagato richiesto",
      "currency is required": "Valuta richiesta",
      "Athlete is required": "Atleta richiesto",
      "license is required": "Licenza richiesta",
      "start date is required": "Data di inizio richiesta",
      "end date is required": "Data di fine richiesta",
      "License created successfully!": "Licenza creata con successo!",
      "sport is required": "Sport richiesto",
      "discipline is required": "Disciplina richiesta",
      "Team updated successfully!": "Squadra aggiornata con successo!",
      "Team created successfully!": "Squadra creata con successo!",
      "city is required": "Città richiesta",
      "address is required": "Indirizzo richiesto",
      "headline is required": "Titolo richiesto",
      "subheadline is required": "Sottotitolo richiesto",
      "button text required": "Testo del pulsante richiesto",
      "button link required": "Link del pulsante richiesto",
      "Image are required": "Immagini richieste",
      "Updating banner...": "Aggiornamento del banner...",
      "Banner updated successfully": "Banner aggiornato con successo",
      "Creating banner...": "Creazione del banner...",
      "Banner created successfully": "Banner creato con successo",
      "League member added!": "Membro della lega aggiunto!",
      "League Member Updated Successfully!":
        "Membro della lega aggiornato con successo!",
      "order is required": "Ordine richiesto",
      "Age from must an integer": "Età da deve essere un intero",
      "Age must be at least 0": "L'età deve essere almeno 0",
      "Age to must an integer": "Età a deve essere un intero",
      "Min members must an integer": "Minimo membri deve essere un intero",
      "Min members  must be at least 0": "Minimo membri deve essere almeno 0",
      "Max members must an integer": "Massimo membri deve essere un intero",
      "Max members must be at least 0": "Massimo membri deve essere almeno 0",
      "Max members must be greater than and equal to min members":
        "Massimo membri deve essere maggiore o uguale al minimo membri",
      "Min reservists must an integer":
        "Minimo riservisti deve essere un intero",
      "Min reservists  must be at least 0":
        "Minimo riservisti deve essere almeno 0",
      "Max reservists must an integer":
        "Massimo riservisti deve essere un intero",
      "Max reservists must be at least 0":
        "Massimo riservisti deve essere almeno 0",
      "Max reservists must be greater than and equal to min reservists":
        "Massimo riservisti deve essere maggiore o uguale ai riservisti minimi",
      "Min number of athletes  must an integer":
        "Il numero minimo di atleti deve essere un intero",
      "Min number of athletes  must be at least 0":
        "Il numero minimo di atleti deve essere almeno 0",
      "Max number of athletes must an integer":
        "Il numero massimo di atleti deve essere un intero",
      "Max number of athletes must be at least 0":
        "Il numero massimo di atleti deve essere almeno 0",
      "Max number of athletes must be greater than min number of athletes":
        "Il numero massimo di atleti deve essere maggiore del numero minimo di atleti",
      "status is required": "Stato richiesto",
      "Category Updated Successfully!": "Categoria aggiornata con successo!",
      "Category Created!": "Categoria creata!",
      "time period is required": "Periodo di tempo richiesto",
      "sort is required": "Ordinamento richiesto",
      "at-least-one": "almeno-uno",
      "At least one category is required": "È richiesta almeno una categoria",
      "Value cannot be less than 1": "Il valore non può essere inferiore a 1",
      "place is required": "Luogo richiesto",
      "entries is required": "Voci richieste",
      "points is required": "Punti richiesti",
      "You can't remove this category because this is used into addtional rules":
        "Non puoi rimuovere questa categoria perché è utilizzata in regole aggiuntive",
      "Rule added successfully": "Regola aggiunta con successo",
      "Rule updated successfully": "Regola aggiornata con successo",
      "Points updated successfully": "Punti aggiornati con successo",
      "Points added successfully": "Punti aggiunti con successo",
      "Removing...": "Rimozione in corso...",
      "Removed successfully!": "Rimosso con successo!",
      "fee is required": "Tariffa richiesta",
      "duration is required": "Durata richiesta",
      "partner license fee required": "Tariffa di licenza partner richiesta",
      "tournated license fee required": "Tariffa di licenza torneo richiesta",
      "License updating ...": "Aggiornamento della licenza in corso...",
      "License updated Successfully!": "Licenza aggiornata con successo!",
      "License creating ...": "Creazione licenza in corso...",
      "License created successfully": "Licenza creata con successo",
      deleteRankingsWarning:
        "Le classifiche per questa data esistono già. Aggiornando, le vecchie classifiche per questa data verranno eliminate. Vuoi procedere?",
      "Display athlete birth year in Participants":
        "Visualizza anno di nascita dell'atleta nei Partecipanti",
      "Display athlete club": "Visualizza club dell'atleta",
      "Display athlete city": "Visualizza città dell'atleta",
      "Display athlete birth year": "Visualizza anno di nascita dell'atleta",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});
