import { gql } from "@apollo/client";

const CREATE_TOURNAMENT_CATEGORY = gql`
  mutation createTournamentCategory(
    $league: Float!
    $name: String!
    $type: String!
    $gender: String!
    $categoryMen: Float
    $categoryWomen: Float
    $order: String!
    $discipline: Int
    $enableMetrics: Boolean
    $team: Float
    $isAllowed: Boolean
    $moveToSingles: Boolean
    $categoryEntries: String
    $status: String!
    $license: [Int!]
    $enableLicense: Boolean
    $ageLimit: Int
    $ageType: String
    $ageFrom: Int
    $identifyTeamMember: Boolean
    $minMembers: Int
    $maxMembers: Int
    $reservistsRequired: Boolean
    $minReservists: Int
    $maxReservists: Int
    $captainRequired: Boolean
    $countIndividualTotal: Boolean
    $countTeamTotal: Boolean
    $minAthletesToCount: Int
    $maxAthletesToCount: Int
    $nationalTournament: Boolean
    $clubChampionship: Boolean
    $countPointOnMatchResult: Boolean
    $skills: String
    $askSkillLevel: Boolean
    $singleCategory: Int
    $isClubSelection: Boolean
    $isTeamSelection: Boolean
    $skillsLevel: String
    $ageGroup: String
    $country: String
    $thirdPartyLicense: Int
    $isDisplayClub: Boolean
    $isDisplayCity: Boolean
    $isDisplayBirthYear: Boolean
    $isMixedDoubles: Boolean
    $timeControl: String
    $isDisplayRank: Boolean
    $minDUPRSkillLevel: Float
    $maxDUPRSkillLevel: Float
    $ageToType: String
    $ageFromType: String
    $clubs: [Int!]
    $isClubRegistrationRequired: Boolean
    $isSpecificClubRequired: Boolean
    $ageTo: Int # $mdwc: Float # $mde: Float # $mds: Float # $qe: Float # $qwc: Float # $qs: Float # $consolation: Float
  ) {
    createTournamentCategory(
      createTournamentCategoryInput: {
        league: $league
        name: $name
        type: $type
        gender: $gender
        categoryMen: $categoryMen
        categoryWomen: $categoryWomen
        order: $order
        discipline: $discipline
        enableMetrics: $enableMetrics
        team: $team
        isAllowed: $isAllowed
        moveToSingles: $moveToSingles
        categoryEntries: $categoryEntries
        status: $status
        license: $license
        enableLicense: $enableLicense
        ageLimit: $ageLimit
        ageType: $ageType
        ageFrom: $ageFrom
        ageTo: $ageTo
        identifyTeamMember: $identifyTeamMember
        minMembers: $minMembers
        maxMembers: $maxMembers
        reservistsRequired: $reservistsRequired
        minReservists: $minReservists
        maxReservists: $maxReservists
        captainRequired: $captainRequired
        countIndividualTotal: $countIndividualTotal
        countTeamTotal: $countTeamTotal
        minAthletesToCount: $minAthletesToCount
        maxAthletesToCount: $maxAthletesToCount
        nationalTournament: $nationalTournament
        clubChampionship: $clubChampionship
        countPointOnMatchResult: $countPointOnMatchResult
        askSkillLevel: $askSkillLevel
        skills: $skills
        singleCategory: $singleCategory
        isClubSelection: $isClubSelection
        isTeamSelection: $isTeamSelection
        skillsLevel: $skillsLevel
        ageGroup: $ageGroup
        country: $country
        thirdPartyLicense: $thirdPartyLicense
        isDisplayClub: $isDisplayClub
        isDisplayCity: $isDisplayCity
        isDisplayBirthYear: $isDisplayBirthYear
        isDisplayRank: $isDisplayRank
        timeControl: $timeControl
        isMixedDoubles: $isMixedDoubles
        minDUPRSkillLevel: $minDUPRSkillLevel
        maxDUPRSkillLevel: $maxDUPRSkillLevel
        ageToType: $ageToType
        ageFromType: $ageFromType
        clubs: $clubs
        isSpecificClubRequired: $isSpecificClubRequired
        isClubRegistrationRequired: $isClubRegistrationRequired
        # mde: $mde
        # mds: $mds
        # mdwc: $mdwc
        # qe: $qe
        # qwc: $qwc
        # qs: $qs
        # consolation: $consolation
      }
    ) {
      name
      type
      gender
      order
      enableMetrics
      team
      isAllowed
      moveToSingles
      categoryEntries
      status
      id
      ageLimit
      ageType
      enableLicense

      # mde
      # mds
      # mdwc
      # qe
      # qwc
      # qs
      # consolation
    }
  }
`;

export default CREATE_TOURNAMENT_CATEGORY;
