import { gql } from "@apollo/client";

const CREATE_LICENSE = gql`
  mutation createLicense(
    $title: String!
    $fee: Float!
    $currency: String!
    $duration: String!
    $status: String!
    $leagues: [Int!]
    $startDate: DateTime!
    $endDate: DateTime!
    $tournatedLicenseFee: Float
    $partnerLicenseFee: Float
    $isManagerPayingServiceFee: Boolean
    $gender: String!
    $nationality: String
    $additionalQuestion: [JSONObject!]
    $ageFrom: Int
    $ageTo: Int
    $ageFromType: String
    $ageToType: String
    $isManagerPayingStripeFee: Boolean
    $isManagerPayingPlatformFee: Boolean
  ) {
    createLicense(
      createLicenseInput: {
        title: $title
        fee: $fee
        currency: $currency
        duration: $duration
        status: $status
        leagues: $leagues
        startDate: $startDate
        endDate: $endDate
        tournatedLicenseFee: $tournatedLicenseFee
        partnerLicenseFee: $partnerLicenseFee
        isManagerPayingServiceFee: $isManagerPayingServiceFee
        gender: $gender
        nationality: $nationality
        additionalQuestion: $additionalQuestion
        ageFrom: $ageFrom
        ageTo: $ageTo
        ageFromType: $ageFromType
        ageToType: $ageToType
        isManagerPayingStripeFee: $isManagerPayingStripeFee
        isManagerPayingPlatformFee: $isManagerPayingPlatformFee
      }
    ) {
      id
      title
      fee
      currency
      duration
      status
      startDate
      endDate
    }
  }
`;

export default CREATE_LICENSE;
