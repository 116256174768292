import { gql } from "@apollo/client";

const ADD_SUBSCRIPTION = gql`
  mutation createSubscription(
    $createSubscriptionInput: CreateSubscriptionInput!
  ) {
    createSubscription(createSubscriptionInput: $createSubscriptionInput) {
      id
    }
  }
`;

export default ADD_SUBSCRIPTION;
