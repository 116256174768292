import { gql } from "@apollo/client";

const CREATE_CLUB = gql`
  mutation createClub(
    $clubName: String!
    $address: String
    $city: String
    $description: String
    $logo: Upload
    $contactEmail: String
    $howToFindUs: String
    $contactPhone: String
    $country: String!
    $instagramLink: String
    $facebookLink: String
    $twitterLink: String
    $tikTok: String
    $linkedin: String
    $website: String
    $clubCoaches: [Int!]!
    $clubMainAdministrator: Int!
    $clubAdministrators: [Int!]!
    $clubServices: [Int!]!
    $createClubServiceResourceInput: [CreateClubServiceResourceInput!]!
    $federation: Int!
    $platform: Int
    $isAthleteClub: Boolean
    $status: String
    $isEnableEmail: Boolean
    $isAdminVerificationRequired: Boolean
  ) {
    createClub(
      createClubInput: {
        clubName: $clubName
        address: $address
        city: $city
        logo: $logo
        howToFindUs: $howToFindUs
        contactEmail: $contactEmail
        contactPhone: $contactPhone
        tikTok: $tikTok
        country: $country
        instagramLink: $instagramLink
        facebookLink: $facebookLink
        twitterLink: $twitterLink
        linkedin: $linkedin
        website: $website
        clubCoaches: $clubCoaches
        clubMainAdministrator: $clubMainAdministrator
        clubAdministrators: $clubAdministrators
        clubServices: $clubServices
        federation: $federation
        platform: $platform
        isAthleteClub: $isAthleteClub
        status: $status
        description: $description
        isEnableEmail: $isEnableEmail
        isAdminVerificationRequired: $isAdminVerificationRequired
      }
      createClubServiceResourceInput: $createClubServiceResourceInput
    ) {
      id
      country
      logo
      instagramLink
      facebookLink
      twitterLink
    }
  }
`;
export default CREATE_CLUB;
