import { gql } from "@apollo/client";

const CHECK_SUBSCRIPTION = gql`
  query CheckSubscription {
    checkSubscription {
      message
      status
    }
  }
`;

export default CHECK_SUBSCRIPTION;
