import { gql } from "@apollo/client";

const UPDATE_LICENSE = gql`
  mutation updateLicense(
    $id: Int!
    $title: String!
    $fee: Float!
    $currency: String!
    $duration: String!
    $status: String!
    $startDate: DateTime!
    $endDate: DateTime!
    $tournatedLicenseFee: Float
    $partnerLicenseFee: Float
    $isManagerPayingServiceFee: Boolean
    $gender: String!
    $nationality: String
    $ageFrom: Int
    $ageTo: Int
    $ageFromType: String
    $ageToType: String
    $isManagerPayingStripeFee: Boolean
    $isManagerPayingPlatformFee: Boolean
  ) {
    updateLicense(
      updateLicenseInput: {
        id: $id
        title: $title
        fee: $fee
        currency: $currency
        duration: $duration
        status: $status
        startDate: $startDate
        endDate: $endDate
        tournatedLicenseFee: $tournatedLicenseFee
        partnerLicenseFee: $partnerLicenseFee
        isManagerPayingServiceFee: $isManagerPayingServiceFee
        gender: $gender
        nationality: $nationality
        ageFrom: $ageFrom
        ageTo: $ageTo
        ageFromType: $ageFromType
        ageToType: $ageToType
        isManagerPayingStripeFee: $isManagerPayingStripeFee
        isManagerPayingPlatformFee: $isManagerPayingPlatformFee
      }
    ) {
      id
      title
      fee
      currency
      duration
      status
      startDate
      endDate
    }
  }
`;

export default UPDATE_LICENSE;
