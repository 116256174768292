import { gql } from "@apollo/client";

const SUBSCRIPTION_FILTERS = gql`
  mutation SubscriptionFilters($connectedAccountId: String!) {
    subscriptionFilters(connectedAccountId: $connectedAccountId) {
      packages {
        name
        default_price
        id
      }
      sports {
        id
        title
      }
      paymentStatus
      organizations {
        id
        name
        type
        status
        email
      }
      sort
    }
  }
`;

export default SUBSCRIPTION_FILTERS;
